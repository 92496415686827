import { TextFieldVariants } from '@mui/material';
import React from 'react';

import EditInputAdornment from '../EditAdormentInput';
import { EditInputAdornmentWrapper } from './EditFieldWrapper.styled';

export const editFieldVariant = { variant: 'standard' as TextFieldVariants };

export const editFieldInputProps = {
  startAdornment: (
    <EditInputAdornmentWrapper>
      <EditInputAdornment position="start" />
    </EditInputAdornmentWrapper>
  ),
};
