import React, { useEffect, useState } from 'react';
import { FormikOnError, SubmitButtonStatus } from '@biotmed/base-components';
import { useSelector } from 'react-redux';
import { selectors } from 'src/routes/PortalBuilder';
import { UpdateViewRequest } from '@biotmed/settings-sdk';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import { LoadViewStatus, SaveButtonTypeEnum } from '../modules/constant';
import Footer from './Footer';
import ViewTabsSelector from './ViewTabsSelector';

interface FormikContentProps {
  loadViewStatus: LoadViewStatus;
  goToNextView: () => void;
}

export const FormikContent = (props: FormikContentProps) => {
  const { loadViewStatus, goToNextView } = props;
  const { dirty, resetForm, errors, values, handleSubmit, setFieldValue } = useFormikContext<UpdateViewRequest>();
  const intl = useIntl();
  const [selectedSaveButtonType, setSelectedSaveButtonType] = useState(SaveButtonTypeEnum.NONE);
  const [scrollToError, setScrollToError] = useState(false);
  const selectedView = useSelector(selectors.getSelectedView);
  const saveButtonStatus: SubmitButtonStatus = useSelector(selectors.getSaveBuilderStatus);

  useEffect(() => {
    if (saveButtonStatus === SubmitButtonStatus.SUCCESS) {
      // Finished updating
      if (selectedSaveButtonType === SaveButtonTypeEnum.SAVE_AND_NEXT) {
        goToNextView();
      }

      resetForm();
    } else if (saveButtonStatus === SubmitButtonStatus.NORMAL) {
      setSelectedSaveButtonType(SaveButtonTypeEnum.NONE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveButtonStatus]);

  const resetScrollToError = () => {
    setScrollToError(false);
  };

  const handleSaveButtonClick = (type: SaveButtonTypeEnum) => {
    if (
      type === SaveButtonTypeEnum.SAVE ||
      (type === SaveButtonTypeEnum.SAVE_AND_NEXT && loadViewStatus === LoadViewStatus.SUCCESS)
    ) {
      handleSubmit();
      setSelectedSaveButtonType(type);
    }
  };

  return (
    <FormikOnError scrollToError={scrollToError} resetScrollToError={resetScrollToError}>
      <ViewTabsSelector available={{ ...selectedView }} errors={errors} values={values} setValue={setFieldValue} />
      <Footer
        handleSaveClick={(type: SaveButtonTypeEnum) => handleSaveButtonClick(type)}
        handleDiscardClick={() => resetForm()}
        warningMessage={
          dirty
            ? intl.formatMessage({
                id: 'portal-builder.unsaved-changes.message',
                defaultMessage: 'There are some unsaved changes. Make sure to save them before you leave the view.',
              })
            : ''
        }
        saveButtonStatus={saveButtonStatus}
        selectedSaveButtonType={selectedSaveButtonType}
        isDisabled={!dirty}
      />
    </FormikOnError>
  );
};

export default FormikContent;
