import React from 'react';
import { useTheme } from 'styled-components';
import { Icon, Button } from '@biotmed/base-components';
import { ReactComponent as DeleteIcon } from 'src/images/delete.svg';

export interface DeleteProps {
  onDelete?: () => void;
}

const Delete: React.FC<DeleteProps> = props => {
  const { onDelete } = props;
  const theme = useTheme();

  return (
    <Button onClick={onDelete} className="delete">
      <Icon IconComponent={DeleteIcon} color={theme.palette.grayScale.darkest} />
    </Button>
  );
};

export default Delete;
