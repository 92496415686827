import React, { useState } from 'react';
import { Button, ConfirmationTooltip, Icon, InfoTooltip } from '@biotmed/base-components';
import { getEntityTypeIntlDisplayName } from '@biotmed/data-components';
import { ClickAwayListener } from '@mui/material';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';
import { TemplateOverviewResponse } from '@biotmed/settings-sdk';
import { useFormikContext } from 'formik';
import { isTemplateDeleteDisabled, messages } from '../../../../modules/utils';
import { ReactComponent as DeleteIcon } from '../../../../../../images/delete.svg';
import { ChildrenTypeButtonStyled, ChildrenTypeText, DeleteButtonContainer } from './ChildrenType.styled';

export interface ChildrenTypeProps {
  onDelete: () => void;
  onClick: (templateOverview?: TemplateOverviewResponse) => void;
  childrenType: any;
  entityName: string;
}

const ChildrenType: React.FC<ChildrenTypeProps> = props => {
  const { onDelete, onClick, childrenType, entityName, ...rest } = props;
  const theme = useTheme();
  const intl = useIntl();
  const formik = useFormikContext();
  const { status } = formik;

  const [showDeleteConfirmationTooltip, setShowDeleteConfirmationTooltip] = useState(false);

  const templateOverview = childrenType?.id && status.childrenOverviews?.[childrenType?.id];
  const deleteDisabled = isTemplateDeleteDisabled(templateOverview?.statistics?.entitiesCount, childrenType?.removable);

  const onConfirmDeleteClick = () => {
    setShowDeleteConfirmationTooltip(false);
    onDelete();
  };

  const onDeleteClick = () => {
    setShowDeleteConfirmationTooltip(!showDeleteConfirmationTooltip);
  };

  const closeDeleteConfirmationTooltip = () => {
    setShowDeleteConfirmationTooltip(false);
  };

  return (
    <ChildrenTypeButtonStyled
      onClick={() => onClick(templateOverview)}
      type="button"
      key={childrenType.index}
      variant="outlined"
      size="large"
      textColor={theme.palette.grayScale.darkest}
      {...rest}
    >
      <ChildrenTypeText>{childrenType.displayName}</ChildrenTypeText>
      <ClickAwayListener mouseEvent="onMouseDown" onClickAway={closeDeleteConfirmationTooltip}>
        <DeleteButtonContainer onClick={event => event.stopPropagation()}>
          {deleteDisabled.isDisabled ? (
            <InfoTooltip
              title={
                deleteDisabled.reasons?.length
                  ? `${intl.formatMessage(messages.BASE_MESSAGE_CHILD)} ${deleteDisabled.reasons.join(',')}`
                  : intl.formatMessage(messages.DELETE_DISABLED_DEFAULT_REASON_TOOLTIP)
              }
            >
              <div>
                <Button>
                  <Icon IconComponent={DeleteIcon} color={theme.palette.grayScale.darker} />
                </Button>
              </div>
            </InfoTooltip>
          ) : (
            <ConfirmationTooltip
              okText={intl.formatMessage({
                id: 'template.modal.children-type.delete-tooltip.discard',
                defaultMessage: 'Discard',
              })}
              title={intl.formatMessage(
                {
                  id: 'template.modal.children-type.delete-tooltip.title',
                  defaultMessage: 'Are you sure you want to discard this {childrenTypeName}?',
                },
                {
                  childrenTypeName: intl.formatMessage(getEntityTypeIntlDisplayName(entityName)).toLowerCase(),
                },
              )}
              onOkClick={onConfirmDeleteClick}
              okButtonStyle={{
                backgroundColor: theme.palette.error.medium,
                borderColor: theme.palette.error.medium,
                textColor: theme.palette.grayScale.lightest,
              }}
              open={showDeleteConfirmationTooltip}
              placement="bottom-start"
              onClickButton={onDeleteClick}
              onCancelClick={closeDeleteConfirmationTooltip}
            >
              <Button>
                <Icon IconComponent={DeleteIcon} color={theme.palette.grayScale.darkest} />
              </Button>
            </ConfirmationTooltip>
          )}
        </DeleteButtonContainer>
      </ClickAwayListener>
    </ChildrenTypeButtonStyled>
  );
};

export default ChildrenType;
