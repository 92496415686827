import { EntityInfiniteScroll } from '@biotmed/data-components';
import { getSdkApi } from '@biotmed/sdk-api-provider';
import { CommonAttributeResponse, SearchRequestV2 } from '@biotmed/settings-sdk';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { TooltipButton } from 'src/components/InputWithTooltip/Tooltip';
import { EntityTemplateForm } from 'src/routes/Templates/components/Template';
import { NameSection } from '.';
import { NewLinkedNameSection } from './ComponentSections.styled';

interface LinkedAttributeNameSectionProps {
  onSelectedTargetAttributeChange: (targetAttribute: CommonAttributeResponse) => void;
  allowedCategoriesToLinkTo: string[] | undefined;
  selectedTargetAttribute: CommonAttributeResponse;
  setName: (name: string | null) => void;
  attrFieldName: string;
  attributeValues?: any;
  attributeTouched?: any;
  attributeErrors?: any;
  isNew?: boolean;
  setDisplayName: (name: string) => void;
}

const LinkedAttributeNameSection: React.FC<LinkedAttributeNameSectionProps> = props => {
  const {
    onSelectedTargetAttributeChange,
    allowedCategoriesToLinkTo,
    selectedTargetAttribute,
    attrFieldName,
    setName,
    attributeValues,
    attributeTouched,
    attributeErrors,
    isNew = false,
    setDisplayName,
  } = props;
  const getOptionalTargetAttributes = getSdkApi().settings.attributesApi.search5;
  const [openTooltip, setOpenTooltip] = useState(false);
  const intl = useIntl();
  const formik = useFormikContext<EntityTemplateForm>();

  useEffect(() => {
    if (!isNew) {
      const searchRequest: SearchRequestV2 = {
        filter: {
          category: { in: allowedCategoriesToLinkTo },
          id: { eq: attributeValues.linkConfiguration.attributeId },
          templateId: { eq: attributeValues.linkConfiguration.templateId },
          entityTypeName: { eq: attributeValues.linkConfiguration.entityTypeName },
        },
      };

      const getTargetAttribute = async () => {
        const response = await getOptionalTargetAttributes(searchRequest);
        onSelectedTargetAttributeChange(response?.data?.data?.[0]);
      };

      getTargetAttribute();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedTargetAttribute && isNew) {
      formik.setFieldValue(`${attrFieldName}.linkConfiguration`, {
        entityTypeName: selectedTargetAttribute.entityTypeName,
        templateId: selectedTargetAttribute.templateId,
        attributeId: selectedTargetAttribute.id,
      });
      formik.setFieldValue(`${attrFieldName}.displayName`, selectedTargetAttribute.displayName);
      formik.setFieldValue(`${attrFieldName}.name`, selectedTargetAttribute.name);
    } else if (!selectedTargetAttribute && isNew) {
      formik.setFieldValue(`${attrFieldName}.linkConfiguration`, null);
      formik.setFieldValue(`${attrFieldName}.displayName`, '');
      formik.setFieldValue(`${attrFieldName}.name`, '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTargetAttribute]);

  return isNew ? (
    <NewLinkedNameSection>
      <EntityInfiniteScroll
        apiCall={getOptionalTargetAttributes}
        onChange={value => onSelectedTargetAttributeChange(value as CommonAttributeResponse)}
        apiFilter={{ category: { in: allowedCategoriesToLinkTo } }}
        getOptionLabel={option => option.displayName ?? ''}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        inputProps={{
          name: `${attrFieldName}.displayName`,
          error: attributeTouched?.displayName && Boolean(attributeErrors?.displayName),
          helperText: attributeTouched?.displayName ? attributeErrors?.displayName : '',
          placeholder: intl.formatMessage({
            id: 'template.usage-type.auto-complete',
            defaultMessage: 'Select',
          }),
          variant: 'standard',
        }}
      />
      <TooltipButton
        name={`${attrFieldName}.name`}
        open={openTooltip && !!selectedTargetAttribute}
        onOpenChange={setOpenTooltip}
        value={attributeValues.name}
        readOnly={false}
        onChange={(value, reason) => {
          if (reason === 'inputChange') {
            setName(value);
            formik.setFieldTouched(`${attrFieldName}.name` ?? '', true, false);
          } else if (reason === 'clickAway') {
            // the received value is the original json name
            setName(value);
          }
        }}
        onClickSave={value => {
          setName(value);
          setOpenTooltip(false);
        }}
        helperText={attributeTouched ? attributeErrors?.name : ''}
        error={attributeTouched?.name && Boolean(attributeErrors?.name)}
        disabled={!selectedTargetAttribute}
      />
    </NewLinkedNameSection>
  ) : (
    <NameSection
      value={attributeValues.displayName}
      canChangeTooltipValue
      tooltipValue={attributeValues.name}
      tooltipInputCanChangeProps={{
        onChange: (value, reason) => setName(value),
        onSave: setName,
        helperText: attributeTouched ? attributeErrors?.name : '',
        error: attributeTouched?.name && Boolean(attributeErrors?.name),
        updateTooltipValueByValue: false,
      }}
      onChange={setDisplayName}
      autoFocus={false}
      fieldName={`${attrFieldName}.displayName`}
      tooltipFieldName={`${attrFieldName}.name`}
      helperText={attributeTouched?.displayName ? attributeErrors?.displayName : ''}
      error={attributeTouched?.displayName && Boolean(attributeErrors?.displayName)}
      attributeId={attributeValues.id || attributeValues.arrayId}
      basePath={attributeValues?.basePath}
    />
  );
};

export default LinkedAttributeNameSection;
