import React from 'react';
import { Checkbox } from '@biotmed/base-components';
import { useIntl } from 'react-intl';
import { CheckboxContainer, StyledPHISection } from './ComponentSections.styled';

type UniquelyProps =
  | {
      withUniquely: true;
      uniquely: boolean;
      setUniquely: (checked: boolean) => void;
    }
  | {
      withUniquely: false;
      uniquely: false;
      setUniquely: (checked: boolean) => void;
    };

export type PHISelectSectionProps = UniquelyProps & {
  phi: boolean;
  setPHI: (value: boolean) => void;
};

const PHISelectSection: React.FC<PHISelectSectionProps> = props => {
  const { phi, setPHI, withUniquely, uniquely, setUniquely } = props;
  const { formatMessage } = useIntl();

  const handleChangeUniquely = (event: any, checked: boolean) => {
    setUniquely(checked);
  };

  const handleSetIsPHI = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPHI(event.target.checked);
  };

  return (
    <StyledPHISection>
      <CheckboxContainer>
        <Checkbox checked={phi} classes={{ root: 'rootControl' }} onChange={handleSetIsPHI} />
        {formatMessage({
          id: 'template.modal.attribute.PHISelect-section.isPHI',
          defaultMessage: 'PHI',
        })}
      </CheckboxContainer>
      {withUniquely && (
        <CheckboxContainer>
          <Checkbox checked={uniquely} onChange={handleChangeUniquely} />
          {formatMessage({
            id: 'template.modal.attribute.Reference-type.uniquely',
            defaultMessage: 'References Uniquely',
          })}
        </CheckboxContainer>
      )}
    </StyledPHISection>
  );
};

export default PHISelectSection;
