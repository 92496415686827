import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import TransparentInput from 'src/components/TransparentInput';
import { EntityTemplateForm } from 'src/routes/Templates/components/Template';
import { TypeComponentProps, TypeVariantComponentProps } from '../interfaces';
import TypeComponentContainer from '../TypeComponent';
import { InputContainer, NumericTypeContainer } from './NumericType.styled';
import Range from './Range';

interface NumericTypeVariantComponentProps extends TypeVariantComponentProps {
  integer?: boolean;
}
interface NumericTypeProps extends TypeComponentProps, NumericTypeVariantComponentProps {}
const NumericType: React.FC<NumericTypeProps> = props => {
  const {
    attrFieldName,
    variant,
    attributeValues,
    attributeErrors,
    attributeTouched,
    integer = false,
    readonly,
  } = props;
  const variantMap: Record<typeof variant, any> = {
    custom: CustomNumericType,
    builtIn: BuiltInNumericType,
  };
  const TypeComponent = variantMap[variant];
  return (
    <TypeComponent
      attrFieldName={attrFieldName}
      attributeValues={attributeValues}
      attributeErrors={attributeErrors}
      attributeTouched={attributeTouched}
      integer={integer}
      readonly={readonly}
    />
  );
};

const CustomNumericType: React.FC<NumericTypeVariantComponentProps> = props => {
  const { attrFieldName, attributeValues, readonly } = props;
  const formik = useFormikContext<EntityTemplateForm>();
  const intl = useIntl();
  const [unit, setUnit] = useState(attributeValues?.numericMetaData?.units ?? '');

  const handleChangeUnit = (event: any) => {
    const { value } = event.target;
    setUnit(value);
    formik.setFieldValue(`${attrFieldName}.numericMetaData.units`, value);
  };

  // TODO: This needs to return to nullish/0/"" values if type changed
  return (
    <TypeComponentContainer readonly={readonly}>
      <NumericTypeContainer>
        <RangeForEdit {...props} />
        <InputContainer>
          <TransparentInput
            value={unit}
            fullWidth={false}
            onChange={handleChangeUnit}
            name={`${attrFieldName}.numericMetaData.units`}
            placeholder={intl.formatMessage({
              id: 'template.modal.attribute.NumericType-unit-placeholder',
              defaultMessage: 'Set Unit',
            })}
            readonly={readonly}
          />
        </InputContainer>
      </NumericTypeContainer>
    </TypeComponentContainer>
  );
};
const BuiltInNumericType: React.FC<NumericTypeVariantComponentProps> = props => {
  const { readonly = false } = props;
  return (
    <TypeComponentContainer readonly={readonly}>
      <NumericTypeContainer>
        <RangeForEdit {...props} />
      </NumericTypeContainer>
    </TypeComponentContainer>
  );
};

const RangeForEdit: React.FC<NumericTypeVariantComponentProps> = props => {
  const { attrFieldName, attributeValues, attributeErrors, attributeTouched, integer, readonly = false } = props;

  const formik = useFormikContext<EntityTemplateForm>();
  const intl = useIntl();

  const handleChangeMin = (value: number | null) => {
    formik.setFieldValue(`${attrFieldName}.validation.min`, value);
  };

  const handleChangeMax = (value: number | null) => {
    formik.setFieldValue(`${attrFieldName}.validation.max`, value);
  };

  const handleChangeNormalMin = (value: number | null) => {
    formik.setFieldValue(`${attrFieldName}.numericMetaData.lowerRange`, value);
  };

  const handleChangeNormalMax = (value: number | null) => {
    formik.setFieldValue(`${attrFieldName}.numericMetaData.upperRange`, value);
  };

  const minError =
    attributeTouched?.validation && attributeTouched?.validation?.min && attributeErrors?.validation?.min;
  const maxError =
    attributeTouched?.validation && attributeTouched?.validation?.max && attributeErrors?.validation?.max;
  const normalMinError = attributeTouched?.numericMetaData?.lowerRange && attributeErrors?.numericMetaData?.lowerRange;
  const normalMaxError = attributeTouched?.numericMetaData?.upperRange && attributeErrors?.numericMetaData?.upperRange;
  // TODO: This needs to return to nullish/0/"" values if type changed
  return (
    <NumericTypeContainer>
      <Range
        title={intl.formatMessage({
          id: 'template.modal.attribute.numeric-type.value-range.title',
          defaultMessage: 'Value Range',
        })}
        minValue={attributeValues?.validation?.min}
        maxValue={attributeValues?.validation?.max}
        attrFieldName={attrFieldName}
        onChangeMin={handleChangeMin}
        onChangeMax={handleChangeMax}
        integer={integer}
        minError={minError}
        maxError={maxError}
        readonly={readonly}
      />
      <Range
        title={intl.formatMessage({
          id: 'template.modal.attribute.numeric-type.normal-range.title',
          defaultMessage: 'Normal Range',
        })}
        minValue={attributeValues?.numericMetaData?.lowerRange}
        maxValue={attributeValues?.numericMetaData?.upperRange}
        attrFieldName={attrFieldName}
        onChangeMin={handleChangeNormalMin}
        onChangeMax={handleChangeNormalMax}
        integer={integer}
        minError={normalMinError}
        maxError={normalMaxError}
      />
    </NumericTypeContainer>
  );
};
export default NumericType;
