import { createSlice, createSelector, createAction } from '@reduxjs/toolkit';

import { RootState } from '../../../reducer';
import { DATA_STATE_KEY } from '../../constants';

export const STATE_KEY = 'app';

interface AppState {
  isAppLoading: boolean;
  localeSetupFinished: boolean;
  biotVersion?: string;
}

export const getInitialState = (state?: AppState): AppState => ({
  // eslint-disable-next-line no-use-before-define
  isAppLoading: state ? state.isAppLoading : true,
  localeSetupFinished: state ? state.localeSetupFinished : false,
  biotVersion: state ? state.biotVersion : undefined,
});

const getState = (state: RootState) => {
  return state?.[DATA_STATE_KEY]?.[STATE_KEY] || getInitialState();
};
const getIsAppLoading = createSelector(getState, state => state.isAppLoading);
const selectLocaleSetupFinished = createSelector(getState, state => state.localeSetupFinished);
const selectBiotVersion = createSelector(getState, state => state.biotVersion);

/* eslint-disable no-param-reassign */
const slice = createSlice({
  name: STATE_KEY,
  initialState: getInitialState(),
  reducers: {
    appStartFinish: (state, action) => {
      state.isAppLoading = action.payload;
      return state;
    },
    onLocaleSetupSuccess: state => {
      state.localeSetupFinished = true;
      return state;
    },
    onBiotVersionFetch: (state, action) => {
      state.biotVersion = action.payload;
      return state;
    },
  },
});
/* eslint-enable no-param-reassign */

// For saga/reducer-less actions
const extraActions = {
  appStart: createAction(`${STATE_KEY}/appStart`),
};

export const selectors = {
  getIsAppLoading,
  selectLocaleSetupFinished,
  selectBiotVersion,
};

export const actions = { ...slice.actions, ...extraActions };

const { reducer } = slice;
export default reducer;
