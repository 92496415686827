import React from 'react';
import { StyledTitle } from './Title.styled';

export interface TitleProps {
  onClick?: () => void;
  bold?: boolean;
  className?: string;
}

const Title: React.FC<React.PropsWithChildren<TitleProps>> = props => {
  const { className, onClick, bold = true, children } = props;

  return (
    <StyledTitle $bold={bold} className={`modalTitle ${className}`} onClick={onClick}>
      {children}
    </StyledTitle>
  );
};

export default Title;
