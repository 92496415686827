import { errorNotice, ErrorTypeEnum, successNotice } from '@biotmed/system-notifications';
import { getSdkApi } from '@biotmed/sdk-api-provider';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { blobToJson, initDownloadExistingFile } from '@biotmed/base-components';
import globalIntl from '@biotmed/i18n';
import { actions } from '../slice/actionsSlice';
import { actions as readActions } from '../slice/readSlice';
import {
  DOWNLOAD_TRANSLATION_OPERATION_ID,
  UPLOAD_TRANSLATION_OPERATION_ID,
  UPLOAD_TRANSLATIONS_MODAL_CONTAINER_ID,
} from '../../constants';
import { uploadTranslationsErrorDictionary } from '../../errorsDictionary';

function* handleDownloadItem(action: ReturnType<typeof actions.download>): any {
  const { locale } = action.payload;

  const intl = globalIntl.current;

  try {
    const response: AxiosResponse<File> = yield call(
      getSdkApi()?.settings.translationApi.downloadLocaleTranslationFile,
      locale,
      {
        responseType: 'blob',
      },
    );

    if (!response.data)
      throw new Error(
        intl.formatMessage({
          id: 'translation.download.error.returned-undefined',
          defaultMessage: 'Server returned no data for translation',
        }),
      );

    initDownloadExistingFile(response.data, locale);

    yield put(
      successNotice({
        operationId: DOWNLOAD_TRANSLATION_OPERATION_ID,
      }),
    );
  } catch (error: any) {
    console.error(error);
    const displayedError = error?.response?.data ? yield call(blobToJson, error.response.data) : error;

    yield put(
      errorNotice({
        type: ErrorTypeEnum.GENERAL,
        errorParams: {
          error: displayedError,
        },
        operationId: DOWNLOAD_TRANSLATION_OPERATION_ID,
      }),
    );
  }
}

function* handleUpload(action: ReturnType<typeof actions.upload>) {
  const { locale, file } = action.payload;
  try {
    yield call(getSdkApi().settings.translationApi.updateLocaleTranslations, locale, file);

    yield put(successNotice({ operationId: UPLOAD_TRANSLATION_OPERATION_ID }));
    yield put(readActions.onLoadItems());
  } catch (e: any) {
    console.error('Failed to upload translation file, error:', e);
    const apiError = e.response?.data ?? e;

    yield put(
      errorNotice({
        type: ErrorTypeEnum.EMBEDDED,
        containerId: UPLOAD_TRANSLATIONS_MODAL_CONTAINER_ID,
        errorParams: {
          error: apiError,
          dictionary: uploadTranslationsErrorDictionary,
        },
        operationId: UPLOAD_TRANSLATION_OPERATION_ID,
      }),
    );
  }
}

export const saga = function* watch() {
  yield all([takeLatest(actions.download, handleDownloadItem), takeLatest(actions.upload, handleUpload)]);
};
