import { ExternalLink, IconButtonMenu, otherIcons, Tabs, VerticalLine } from '@biotmed/base-components';
import { EntityType, SelfProfile } from '@biotmed/data-components';
import { LocalePicker } from '@biotmed/i18n';
import { useFeature } from '@biotmed/react-flags-lib';
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import AppLogo from 'src/components/AppLogo';
import { FlexSeparator } from 'src/components/AttributeLayout';
import AppConfig from 'src/config/AppConfig';
import { useTheme } from 'styled-components';
import { checkIsAnalyticsDBDeployed } from 'src/utils/analyticsDBUtils';
import { selectors as analyticsDBSelector } from '../../../routes/ADB';
import { contentPadding } from '..';
import { ReactComponent as ApiRefIcon } from '../../../images/api-reference.svg';
import { ReactComponent as DevGuidesIcon } from '../../../images/dev-guides.svg';
import { ReactComponent as OrgIcon } from '../../../images/logo.svg';
import { actions as loginActions } from '../../../redux/data/login';
import { selectors } from '../../../redux/data/user';
import RoutesEnum from '../../../routes/Navigation/modules/Routes';
import { RightContent, StyledIconMenuItem, StyledLink, TopMenu as TopMenuStyled } from './AppLayout.styled';
import { PlatformsLinksMenu } from './PlatformLinksMenu';

interface TopMenuProps {
  children?: React.ReactNode;
}

const getCurrentTab = (tabsData: { tabKey: string }[], currentLocation: string) => {
  const tabKeys = tabsData.map(tabData => tabData.tabKey);
  if (currentLocation && !tabKeys.includes(currentLocation)) {
    return false;
  }
  return currentLocation;
};

const TopMenu: React.FC<TopMenuProps> = () => {
  const intl = useIntl();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userName = useSelector(selectors.selectFullName);
  const userRole = useSelector(selectors.selectUserRole);
  const userOwnerOrganization = useSelector(selectors.selectOwnerOrganization);
  const userType = useSelector(selectors.selectUserType);
  const isAnalyticsDBDeployed = checkIsAnalyticsDBDeployed(useSelector(analyticsDBSelector.getDeploymentState));
  const { isFeatureActive: isImportExportPageFeatureActive } = useFeature({ flag: 'IMPORT_EXPORT_PAGE' });
  const { isFeatureActive: isLanguagesPageFeatureActive } = useFeature({ flag: 'LANGUAGES_PAGE' });
  const { isFeatureActive: isADBPageFeatureActive } = useFeature({ flag: 'ANALYTICS_DB_PAGE' });

  const callRouter = (value: string) => navigate(value, { state: value });

  const handleCallToRouter = (event: React.ChangeEvent<any>, value: string) => {
    callRouter(value);
  };

  const onDisconnectClick = () => {
    dispatch(loginActions.logout());
  };

  const onChangePasswordClick = () => {
    navigate(RoutesEnum.CHANGE_PASSWORD, { state: RoutesEnum.CHANGE_PASSWORD });
  };

  const withLanguagesPage = isLanguagesPageFeatureActive
    ? [
        {
          title: intl.formatMessage({
            id: 'app-layout.top-menu.link.languages',
            defaultMessage: 'Languages',
          }),
          onClick: () => callRouter(RoutesEnum.LANGUAGES),
        },
      ]
    : [];

  const withFeatureImportExportPage = isImportExportPageFeatureActive
    ? [
        {
          title: intl.formatMessage({
            id: 'app-layout.top-menu.link.environment-configuration',
            defaultMessage: 'Environment configuration',
          }),
          onClick: () => callRouter(RoutesEnum.ENVIRONMENT_CONFIGURATION),
        },
      ]
    : [];

  const withFeatureADBPage = isADBPageFeatureActive
    ? [
        {
          title: intl.formatMessage({
            id: 'app-layout.top-menu.link.analytics-db',
            defaultMessage: 'Analytics DB',
          }),
          onClick: () => callRouter(RoutesEnum.ANALYTICS_DB),
          disabled: !isAnalyticsDBDeployed,
        },
      ]
    : [];

  const tabsData = [
    {
      label: intl.formatMessage({
        id: 'app-layout.top-menu.tab.templates',
        defaultMessage: 'Templates',
        description: 'tab title for templates page',
      }),
      tabKey: RoutesEnum.TEMPLATES,
    },
    {
      label: intl.formatMessage({
        id: 'app-layout.top-menu.tab.portal-builder',
        defaultMessage: 'Portal Builder',
        description: 'tab title for portal builder page',
      }),
      tabKey: RoutesEnum.PORTAL_BUILDER,
    },
  ];

  const SettingsOptions = [
    ...withLanguagesPage,
    {
      title: intl.formatMessage({
        id: 'app-layout.top-menu.link.plugin-configuration',
        defaultMessage: 'Plugin configuration',
      }),
      onClick: () => callRouter(RoutesEnum.PLUGIN_CONFIGURATION),
    },
    {
      title: intl.formatMessage({
        id: 'app-layout.top-menu.link.technical-information',
        defaultMessage: 'Technical information',
      }),
      onClick: () => callRouter(RoutesEnum.TECHNICAL_INFORMATION),
    },
    ...withFeatureImportExportPage,
    {
      title: intl.formatMessage({
        id: 'app-layout.top-menu.link.request-feature',
        defaultMessage: 'Request feature',
      }),
      onClick: () => window.open(AppConfig.REQUEST_FEAUTE_URL ? AppConfig.REQUEST_FEAUTE_URL : ''),
    },
    ...withFeatureADBPage,
  ];

  const location = useLocation();
  const currentTab = getCurrentTab(tabsData, location?.state);
  const { settings: SettingsIcon } = otherIcons;

  const rightContent = (
    <RightContent>
      <FlexSeparator style={{ width: '3px' }} />
      <ExternalLink
        svg={ApiRefIcon}
        title={intl.formatMessage({
          id: 'app-layout.top-menu.link.apiReference',
          defaultMessage: 'api reference',
        })}
        link={AppConfig.API_REFERENCE_URL ? AppConfig.API_REFERENCE_URL : ''}
      />
      <FlexSeparator style={{ width: '3px' }} />
      <ExternalLink
        svg={DevGuidesIcon}
        title={intl.formatMessage({
          id: 'app-layout.top-menu.link.devGuides',
          defaultMessage: 'dev guides',
        })}
        link={AppConfig.DEV_GUIDE_URL ? AppConfig.DEV_GUIDE_URL : ''}
      />
      <FlexSeparator style={{ width: '10px' }} />
      {/* TODO: Move this component to base components */}
      <VerticalLine />
      <IconButtonMenu
        menuItems={SettingsOptions}
        Icon={(props: any) => {
          return <SettingsIcon {...props} color={theme.palette.grayScale.darker2} />;
        }}
        renderItem={(onClick: () => void, title: string, disabled?: boolean) => (
          <StyledIconMenuItem key={title} onClick={onClick} disabled={disabled}>
            {title}
          </StyledIconMenuItem>
        )}
      />
      <PlatformsLinksMenu />
      <FlexSeparator style={{ width: '10px' }} />
      <LocalePicker />
      <SelfProfile
        orgIcon={OrgIcon}
        userName={userName}
        userRole={userRole}
        userOwnerOrganization={userOwnerOrganization?.name}
        onDisconnectClick={onDisconnectClick}
        userType={userType as EntityType}
        buttons={[
          {
            text: intl.formatMessage({
              id: 'self-profile.change-password',
              defaultMessage: 'Change Password',
            }),
            onClick: onChangePasswordClick,
          },
        ]}
      />
    </RightContent>
  );

  const leftContent = (
    <StyledLink to="./">
      <AppLogo />
    </StyledLink>
  );

  return (
    <TopMenuStyled>
      <Tabs
        tabBarExtraContent={{
          left: leftContent,
          right: rightContent,
          spaceBetween: 80,
        }}
        tabsData={tabsData}
        textColorActive={theme.palette.primary.medium}
        indicatorOverAll
        selectedTabKey={currentTab}
        padding={`0 ${contentPadding}`}
        onChange={handleCallToRouter} // eslint-disable-line
        variant="scrollable"
        scrollButtons="auto"
      />
    </TopMenuStyled>
  );
};
export default TopMenu;
