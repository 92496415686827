import React, { useMemo } from 'react';
import { AvailablePortalAttribute, BooleanCodeSnippetBasedValue, SelectedPortalAttribute } from '@biotmed/settings-sdk';
import { CodeSnippet, Parameter, joinFieldPath } from '@biotmed/data-components';
import { InfoTooltip } from '@biotmed/base-components';
import { useIntl } from 'react-intl';
import { useFeature } from '@biotmed/react-flags-lib';
import { LowCodeValueTypeEnum } from '@biotmed/utils';
import { NameContainer, SelectionContainer } from './attributesPortalCategoryRenderer.styled';
import { mapAttributes } from '../../modules/mappers';
import PortalCategoryInput from './PortalCategoryInput';

interface AttributesPortalCategoryRendererProps {
  title?: string;
  attributes?: AvailablePortalAttribute[];
  selected?: SelectedPortalAttribute[];
  onChange: (selectedItems: SelectedPortalAttribute[]) => void;
  fullScreen: boolean;
  codeSnippetAvailableParameters: Parameter[];
}

const AttributesPortalCategoryRenderer: React.FC<AttributesPortalCategoryRendererProps> = props => {
  const { title, attributes = [], onChange, fullScreen, codeSnippetAvailableParameters } = props;

  // if null, the default value wont work... we should be able to remove this, but for now I keep it.
  const { selected = [] } = props;

  const { isFeatureActive: isConditionalViewsFeatureActive } = useFeature({
    flag: 'CONDITIONAL_VIEWS_BY_CODE_SNIPPET',
  });
  const { isFeatureActive: isConditionalAttributesFeatureActive } = useFeature({
    flag: 'CONDITIONAL_VIEWS_BY_CODE_SNIPPET_OF_ATTRIBUTES',
  });
  const isCodeSnippetFeatureActive = isConditionalViewsFeatureActive && isConditionalAttributesFeatureActive;

  const intl = useIntl();
  const mappedAttributes: { [x: string]: AvailablePortalAttribute } = useMemo(() => {
    return attributes ? mapAttributes(attributes) : {};
  }, [attributes]);

  const handleClickCheckBox = (checked: boolean, record: { basePath?: string; name?: string }) => {
    if (checked) {
      onChange(selected?.filter(item => extractKey(item) !== extractKey(record) ?? []));
    } else {
      onChange([...(selected ?? []), { name: record.name, basePath: record.basePath }]);
    }
  };

  const onDisplayChange = (display: BooleanCodeSnippetBasedValue, record: { basePath?: string; name?: string }) => {
    const newSelected = selected.map(currentValue =>
      extractKey(currentValue) === extractKey(record) ? { ...currentValue, display } : currentValue,
    );

    onChange(newSelected);
  };

  const attributesSorter = (a: AvailablePortalAttribute, b: AvailablePortalAttribute): number =>
    a.displayName?.localeCompare(b.displayName || '', undefined, { sensitivity: 'base' }) || 0;

  const extractKey = (item: { basePath?: string; name?: string }) => joinFieldPath(item.name || '', item.basePath);
  const getAttribute = (selection: SelectedPortalAttribute) => {
    return mappedAttributes[extractKey(selection)];
  };
  const isOptionDisabled = (attribute: AvailablePortalAttribute) => attribute.readOnly || false;
  const isSelectionDisabled = (selection: SelectedPortalAttribute) => {
    const attribute = getAttribute(selection);
    return attribute && isOptionDisabled(getAttribute(selection));
  };

  const renderSelection = (selection: SelectedPortalAttribute) => {
    const { display } = selection;
    const isDisabled = selection && isSelectionDisabled(selection);
    const displayValue = display ?? {
      value: true,
      codeSnippetId: null,
      type: LowCodeValueTypeEnum.CONST,
    };

    return (
      <SelectionContainer>
        <NameContainer>{mappedAttributes[extractKey(selection)]?.displayName}</NameContainer>
        {isCodeSnippetFeatureActive && (
          <InfoTooltip
            placement="top"
            title={
              isDisabled
                ? intl.formatMessage({
                    id: 'portal-category-input.info-tooltip.code-snippet.mandatory',
                    defaultMessage: 'Attribute is mandatory',
                  })
                : ''
            }
          >
            <div>
              <CodeSnippet
                value={displayValue}
                // below is not intl on purpose
                returnType="boolean"
                // TODO: Add entity params when BE is ready. (entity + entityType)
                parameters={codeSnippetAvailableParameters}
                onChange={value => onDisplayChange(value, selection)}
                disabled={isDisabled}
                hideTooltip={isDisabled}
                // eslint-disable-next-line react/jsx-boolean-value
                inputResetValue={true}
                title={intl.formatMessage({ id: 'attribute.code-snippet.title', defaultMessage: 'Display' })}
              />
            </div>
          </InfoTooltip>
        )}
      </SelectionContainer>
    );
  };

  return (
    <PortalCategoryInput
      title={title || ''}
      options={attributes}
      selections={selected}
      renderOption={(attribute: AvailablePortalAttribute) => attribute.displayName}
      renderSelection={renderSelection}
      onCheckboxToggle={handleClickCheckBox}
      onOrderChange={onChange}
      fullScreen={fullScreen}
      optionsSorter={attributesSorter}
      extractSelectionKey={(selection: SelectedPortalAttribute) => extractKey(selection)}
      extractOptionKey={(attribute: AvailablePortalAttribute): string => extractKey(attribute) || ''}
      isOptionDisabled={isOptionDisabled}
    />
  );
};

export default AttributesPortalCategoryRenderer;
