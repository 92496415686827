import React from 'react';
import { InputFieldWrapper } from '@biotmed/base-components';
import { FormikErrors, FormikTouched } from 'formik';
import { useIntl } from 'react-intl';
import {
  StyledStep,
  StyledResetSystemForm,
  ResetSystemContentTitle,
  ResetSystemContentText,
  ResetSystemContentMessage,
} from '../EnvironmentConfiguration.styled';
import { ResetConfirmationForm } from './ResetSystemModal';
import PasswordField from './PasswordField';

interface ResetSystemModalProps {
  values: ResetConfirmationForm;
  errors: FormikErrors<ResetConfirmationForm>;
  handleChange: (e: React.ChangeEvent<any>) => void;
  touched: FormikTouched<ResetConfirmationForm>;
  handleBlur: (e: React.FocusEvent<any>) => void;
}

export const ResetConfirmation = (props: ResetSystemModalProps) => {
  const { values, errors, handleChange, touched, handleBlur } = props;
  const intl = useIntl();

  return (
    <StyledStep>
      <ResetSystemContentMessage>
        <ResetSystemContentTitle>
          {intl.formatMessage({
            id: 'reset-system.modal.reset-confirmation.content.title',
            defaultMessage: 'Reset Confirmation',
          })}
        </ResetSystemContentTitle>
        <ResetSystemContentText>
          {intl.formatMessage(
            {
              id: 'reset-system.modal.reset-confirmation.content.text',
              defaultMessage:
                'Warning: This action will delete all data in this deployment. Following this action a new clean deployment will be generated and the system will prompt you to define a new admin for the deployment.{lineBreak}Note: this action can only be executed by a system admin',
            },
            { lineBreak: <br /> },
          )}
        </ResetSystemContentText>
      </ResetSystemContentMessage>
      <StyledResetSystemForm>
        <InputFieldWrapper
          label={intl.formatMessage({
            id: 'reset-system.modal.reset-confirmation.label.email',
            defaultMessage: 'Email',
          })}
          name="username"
          type="email"
          variant="outlined"
          required
        />
        <PasswordField
          label={intl.formatMessage({
            id: 'reset-system.modal.reset-confirmation.label.password',
            defaultMessage: 'Password',
          })}
          required
          handleBlur={handleBlur}
          name="password"
          handleChange={handleChange}
          error={errors.password}
          value={values.password}
          touched={touched.password}
        />
      </StyledResetSystemForm>
    </StyledStep>
  );
};

export default ResetConfirmation;
