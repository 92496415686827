import React from 'react';
import AppConfig from 'src/config/AppConfig';
import { useIntl } from 'react-intl';
import messages from './dictionary';

function Information() {
  const intl = useIntl();

  return (
    <div>
      {intl.formatMessage(messages.TEMPLATE_ANALYTICS_DB_SYNC_ON_CONSEQUENCES_ACTION)}{' '}
      <a href={AppConfig.ADB_LEARN_MORE_URL} target="_blank" rel="noreferrer">
        Analytics DB User Guide
      </a>
    </div>
  );
}

export default Information;
