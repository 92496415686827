import styled from 'styled-components';
import { Loader } from '@biotmed/base-components';

export const SpinBox = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Spin = styled(Loader)`
  color: ${props => props.theme.palette.primary.medium};
  width: 60px;
  height: 60px;
`;
