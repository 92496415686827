import React from 'react';
import { ActionTriggerComponentProps } from '@biotmed/data-components';
import { LocaleTranslationsOverview } from '@biotmed/settings-sdk';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { actions } from '../modules/slice/actionsSlice';

type DownloadTranslationsProps = {
  translationOverview: LocaleTranslationsOverview;
  TriggerComponent: React.FC<ActionTriggerComponentProps>;
  // eslint-disable-next-line react/require-default-props
  onClose?: () => void;
};

export const DownloadTranslations = (props: DownloadTranslationsProps) => {
  const { translationOverview, TriggerComponent, onClose } = props;

  const intl = useIntl();

  const dispatch = useDispatch();

  const { locale } = translationOverview || {};

  const onDownloadClick = async () => {
    if (locale) {
      dispatch(actions.download({ locale }));
      onClose?.();
    }
  };

  return (
    <TriggerComponent
      text={intl.formatMessage({
        id: 'translations-actions-download',
        defaultMessage: 'Download',
      })}
      onClick={onDownloadClick}
    />
  );
};
