import { CUSTOM_ATTRIBUTE_IN_USE_ERROR_CODE } from 'src/routes/Observation';
import { ANALYTICS_DB_DATA_SYNCHRONIZATION_ON_ERROR_CODE } from 'src/routes/ADB/components/force/constants';

// eslint-disable-next-line no-shadow
export enum EditedFieldEnum {
  RSAN = 'rsan',
  JSON_NAME = 'jsonName',
}

export const EDIT_FORCE_CONFIRMATION_ERROR_CODES = [
  CUSTOM_ATTRIBUTE_IN_USE_ERROR_CODE,
  ANALYTICS_DB_DATA_SYNCHRONIZATION_ON_ERROR_CODE,
];
export const ADD_FORCE_CONFIRMATION_ERROR_CODES = [ANALYTICS_DB_DATA_SYNCHRONIZATION_ON_ERROR_CODE];
export const DELETE_FORCE_CONFIRMATION_ERROR_CODES = [ANALYTICS_DB_DATA_SYNCHRONIZATION_ON_ERROR_CODE];
