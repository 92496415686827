import { combineReducers } from 'redux';
import { fork, all } from 'redux-saga/effects';
import { DATA_STATE_KEY } from './constants';
import {
  STATE_KEY as userStateKey,
  reducer as userReducer,
  saga as userSaga,
  getInitialState as getUserInitialState,
} from './user';
import {
  STATE_KEY as entityStateKey,
  reducer as entityReducer,
  saga as entitySaga,
  getInitialState as getEntityInitialState,
} from './entity';
import {
  STATE_KEY as organizationStateKey,
  reducer as organizationReducer,
  saga as organizationSaga,
  getInitialState as getOrganizationInitialState,
} from './organization';
import {
  STATE_KEY as templateStateKey,
  reducer as templateReducer,
  saga as templateSaga,
  getInitialState as getTemplateInitialState,
} from './template';
import {
  STATE_KEY as appStateKey,
  reducer as appReducer,
  saga as appSaga,
  getInitialState as getAppInitialState,
} from './app';
import {
  STATE_KEY as loginStateKey,
  reducer as loginReducer,
  saga as loginSaga,
  getInitialState as getLoginInitialState,
} from './login';
import {
  STATE_KEY as pluginStateKey,
  reducer as pluginReducer,
  saga as pluginSaga,
  getInitialState as getPluginInitialState,
} from './plugin'; // FlagOff: PLUGIN_CONFIGURATION_PAGE-V2
import {
  STATE_KEY as attributeStateKey,
  reducer as attributeReducer,
  saga as attributeSaga,
  getInitialState as getAttributeInitialState,
} from './attribute';
import {
  STATE_KEY as localeStateKey,
  reducer as localeReducer,
  saga as localeSaga,
  getInitialState as getLocaleInitialState,
} from './locale';

export const STATE_KEY = DATA_STATE_KEY;

export const getDataInitialState = (state?: any) => ({
  [appStateKey]: getAppInitialState(state?.[appStateKey]),
  [userStateKey]: getUserInitialState(state?.[userStateKey]),
  [entityStateKey]: getEntityInitialState(state?.[entityStateKey]),
  [templateStateKey]: getTemplateInitialState(state?.[templateStateKey]),
  [organizationStateKey]: getOrganizationInitialState(state?.[organizationStateKey]),
  [loginStateKey]: getLoginInitialState(state?.[loginStateKey]),
  [pluginStateKey]: getPluginInitialState(state?.[pluginStateKey]), // FlagOff: PLUGIN_CONFIGURATION_PAGE-V2
  [attributeStateKey]: getAttributeInitialState(state?.[attributeStateKey]),
  [localeStateKey]: getLocaleInitialState(state?.[localeStateKey]),
});

export const reducer = combineReducers({
  [appStateKey]: appReducer,
  [userStateKey]: userReducer,
  [entityStateKey]: entityReducer,
  [templateStateKey]: templateReducer,
  [organizationStateKey]: organizationReducer,
  [loginStateKey]: loginReducer,
  [pluginStateKey]: pluginReducer, // FlagOff: PLUGIN_CONFIGURATION_PAGE-V2
  [attributeStateKey]: attributeReducer,
  [localeStateKey]: localeReducer,
});

export const sagas = function* root() {
  yield all([
    fork(appSaga),
    fork(userSaga),
    fork(loginSaga),
    fork(entitySaga),
    fork(templateSaga),
    fork(organizationSaga),
    fork(pluginSaga), // FlagOff: PLUGIN_CONFIGURATION_PAGE-V2
    fork(attributeSaga),
    fork(localeSaga),
  ]);
};
