import React, { useMemo, useState } from 'react';
import { EntityType } from '@biotmed/data-components';
import { GetTemplateResponse, TemplateOverviewResponse } from '@biotmed/settings-sdk';
import { Entity } from 'src/redux/data/entity/modules/interfaces';
import TemplateComponent, { Mode, TemplateMode } from '../../../Template';
import useAdjustedChildrenEntity from './useAdjustedChildrenEntity';
import useAdjustFormChildrenEntity from './useAdjustFormChildrenEntity';

export const emptyRow = {
  entityTypeName: undefined as unknown as EntityType,
  displayName: '',
  name: '',
  description: '',
  builtInAttributes: [],
  dynamicAttributes: [],
  id: '',
  creationTime: '',
  lastModifiedTime: '',
};

const useChildren = (entity: Entity) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [template, setTemplate] = useState<GetTemplateResponse>(emptyRow);
  const [mode, setMode] = useState<Mode>(TemplateMode.ADD);
  const [onSubmit, setOnSubmit] = useState(() => () => {});
  const [currentTemplateOverview, setCurrentTemplateOverview] = useState<TemplateOverviewResponse | undefined>();

  // These two hooks update the children's reference attributes in the formik for edit and update the entity template children's reference attributes for add new child
  // The change is for the child template's reference attribute's parent template type
  // For example, a patient's alert child with a _patient ref attribute will have a _patients template type
  useAdjustFormChildrenEntity(entity);
  const adjustedEntity = useAdjustedChildrenEntity(entity);

  // eslint-disable-next-line no-shadow
  const openDrawer = (
    template: GetTemplateResponse,
    mode: TemplateMode,
    onSubmit: any,
    templateOverview?: TemplateOverviewResponse,
  ) => {
    setTemplate(template);
    setMode(mode);
    setIsOpen(true);
    setOnSubmit(() => onSubmit);
    setCurrentTemplateOverview(templateOverview);
  };

  const closeDrawer = () => {
    setIsOpen(false);
  };

  const Template = useMemo(() => {
    return (props: { containerId: string }) => (
      <TemplateComponent
        entity={adjustedEntity}
        template={template}
        handleClose={closeDrawer}
        open={isOpen}
        mode={mode}
        widthDiff="-70px"
        onFormSubmit={onSubmit}
        currentTemplateOverview={currentTemplateOverview}
        {...props}
      />
    );
  }, [template, mode, onSubmit, isOpen, entity]);

  return [openDrawer, closeDrawer, Template] as const;
};

export default useChildren;
