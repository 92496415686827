export const toJsonNameFormat = (str: string = ''): string =>
  str
    .toLowerCase()
    .replace(/ +/g, '_')
    .replace(/-+/g, '_')
    .match(/[a-zA-Z][a-zA-Z0-9_]*/g)
    ?.join('')
    .trim() || '';

export const capitilizeFirstLetter = (str: string = '') => {
  return `${str?.charAt(0).toUpperCase()}${str?.slice(1)}`;
};
