import { Button, convertTypographySettingsToCss } from '@biotmed/base-components';
import styled from 'styled-components';

export const Wrapper = styled.div`
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 32px;
  padding-bottom: 25px;
  display: flex;
  flex-direction: column;
  ${props => convertTypographySettingsToCss(props.theme.typography.body1)};
  max-width: 430px;
  align-items: center;
  gap: 20px;
`;

export const MessageHeader = styled.div`
  ${props => convertTypographySettingsToCss(props.theme.typography.h4)};
`;

export const Content = styled.span`
  overflow-wrap: break-word;
  hyphens: manual;
  white-space: pre-line;
`;

export const ButtonsSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  gap: 20px;
`;

export const ForceDeleteButton = styled(Button)`
  &&.MuiButton-contained {
    display: flex;
    align-items: center;
    background-color: ${props => props.theme.palette.error.medium};
    &:hover:not(:disabled) {
      background-color: ${props => props.theme.palette.error.dark};
    }
    &:disabled {
      background-color: ${props => props.theme.palette.error.dark};
    }
  }
`;

export const InUseAttributesList = styled.ul`
  overflow-y: auto;
  max-height: 90px;
  padding: 0 0 0 15px;
`;
