import React, { useMemo } from 'react';
import { Column, ColumnsType, Icon, InfoTooltip, Link, otherIcons } from '@biotmed/base-components';
import { createTablePageComponent, EMPTY_VALUE_STRING } from '@biotmed/data-components';
import {
  FilterV2,
  LocaleTranslationsCoverage,
  LocaleTranslationsOverview,
  OrderOrderEnum,
  SearchRequestV2,
} from '@biotmed/settings-sdk';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';
import { selectors as localeSelectors } from 'src/redux/data/locale';
import { useSelector } from 'react-redux';
import { actions as readActions, selectors as readSelectors } from '../modules/slice/readSlice';
import { extractId } from '../utils';
import UploadTranslations from './UploadTranslations';
import MoreActionsColumn from './MoreActionsColumn';
import LanguageColumn from './LanguageColumn';
import { HeaderWithInfo, HeaderIconWrapper } from './LanguagesTable.styled';

export interface LanguagesProps {}

const LanguagesComponent = createTablePageComponent<
  LocaleTranslationsOverview,
  FilterV2,
  OrderOrderEnum,
  SearchRequestV2
>({
  slice: { actions: readActions, selectors: readSelectors },
  extractId,
});

export const Languages = (props: LanguagesProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const systemLocale = useSelector(localeSelectors.getSystemLocale);
  const defaultLocale = useSelector(localeSelectors.getDefaultLocale);

  const columns: ColumnsType<LocaleTranslationsOverview> = useMemo(
    () => [
      {
        title: intl.formatMessage({ id: 'translations.table.column-language', defaultMessage: 'Language' }),
        dataIndex: 'language',
        key: 'language',
        fixed: 'left',
        width: '25%',
        render: (value: string, record: LocaleTranslationsOverview) => {
          return (
            <Column width="fit-content">
              <LanguageColumn systemLocale={systemLocale} defaultLocale={defaultLocale} value={record.locale || ''} />
            </Column>
          );
        },
      },
      {
        title: (
          <HeaderWithInfo>
            {intl.formatMessage({
              id: 'translations.table.column-code.header',
              defaultMessage: 'Code',
            })}
            <InfoTooltip
              title={intl.formatMessage(
                {
                  id: 'translations.table.column-code.header.tooltip',
                  defaultMessage:
                    'The locale format can optionally be included in the API request headers to specify the preferred response language. For more details, refer to the  {documentationLink}',
                },
                {
                  documentationLink: (
                    <Link
                      onClick={() => {
                        window.open('https://docs.biot-med.com/docs/language-and-translations');
                      }}
                    >
                      {intl.formatMessage({
                        id: 'translations.table.column-code.header.tooltip-link',
                        defaultMessage: 'BioT documentation',
                      })}
                    </Link>
                  ),
                },
              )}
            >
              <HeaderIconWrapper>
                <Icon IconComponent={otherIcons.info} color={theme.palette.grayScale.dark} height="16px" />
              </HeaderIconWrapper>
            </InfoTooltip>
          </HeaderWithInfo>
        ),
        dataIndex: 'locale',
        key: 'locale',
        width: '10%',
        render: (value, record: LocaleTranslationsOverview) => {
          return <Column>{record.locale}</Column>;
        },
        sorter: true,
      },
      {
        title: intl.formatMessage({
          id: 'translations.table.column-coverage',
          defaultMessage: 'Coverage',
        }),

        dataIndex: 'coverage',
        key: 'coverage',
        width: '10%',
        render: (value: LocaleTranslationsCoverage) => {
          const { numberOfTranslatedKeys = 0, numberOfAvailableKeys } = value;
          const coveragePercentage = numberOfAvailableKeys
            ? (numberOfTranslatedKeys * 100) / numberOfAvailableKeys
            : 100;

          const fixedCoveragePercentage = +parseFloat(coveragePercentage.toString()).toFixed(2);
          const getColor = () => {
            return coveragePercentage < 80 ? theme.palette.error.medium : theme.palette.grayScale.darkest;
          };

          return <Column color={getColor()}>{fixedCoveragePercentage}%</Column>;
        },
      },
      {
        title: '',
      },
      {
        title: intl.formatMessage({
          id: 'translations.table.column-last-modified',
          defaultMessage: 'Last Modified',
        }),
        key: 'lastTranslationTime',
        dataIndex: 'lastTranslationTime',
        width: '15%',
        render: value => {
          return (
            <Column width="fit-content">
              {value ? intl.formatDate(value, { dateStyle: 'short', timeStyle: 'short' }) : EMPTY_VALUE_STRING}
            </Column>
          );
        },
      },
      {
        title: intl.formatMessage({
          id: 'translations.table.column-translation-file',
          defaultMessage: 'Translation File',
        }),
        key: 'translationsFile',
        fixed: 'right',
        width: '10%',
        render: (value, record: LocaleTranslationsOverview, index) => {
          return (
            <Column width="fit-content">
              {systemLocale !== record.locale && (
                <UploadTranslations
                  locale={record.locale || ''}
                  isFileExist={record.coverage?.numberOfTranslatedKeys === 0}
                />
              )}
            </Column>
          );
        },
      },
      {
        title: '',
        fixed: 'right',
        key: 'actions',
        width: '40px',
        render: (value, record, index) => {
          return (
            <Column width="fit-content">
              <MoreActionsColumn record={record} systemLocale={systemLocale} />
            </Column>
          );
        },
      },
    ],
    [systemLocale, defaultLocale],
  );

  return (
    <LanguagesComponent
      withSearchbox
      withActions={false}
      withPreview={false}
      title={intl.formatMessage({ id: 'languages.title', defaultMessage: 'Languages' })}
      columns={columns}
      withExplained
      explainedProps={{
        text: intl.formatMessage({
          id: 'translations.tip',
          defaultMessage:
            'Translation files allows you to easily translate your UI to any language in your supported locales',
        }),
      }}
    />
  );
};

export default Languages;
