import React from 'react';
import { useIntl } from 'react-intl';
import NumberEditInput from 'src/components/NumberEditInput';
import { InputContainer, InputsContainer, RangeTitle, NumberNonEditInput } from './NumericType.styled';

interface RangeProps {
  title: string;
  minValue: number;
  maxValue: number;
  attrFieldName: string;
  onChangeMin: (value: number | null) => void;
  onChangeMax: (value: number | null) => void;
  integer?: boolean;
  minError?: string;
  maxError?: string;
  readonly?: boolean | undefined;
}
const Range: React.FC<RangeProps> = props => {
  const {
    title,
    minValue,
    maxValue,
    attrFieldName,
    onChangeMin,
    onChangeMax,
    integer = false,
    minError,
    maxError,
    readonly = false,
  } = props;
  const intl = useIntl();

  return (
    <div>
      <RangeTitle>{title}</RangeTitle>
      <InputsContainer>
        <InputContainer>
          <div>
            {intl.formatMessage({
              id: 'template.modal.attribute.NumericType-min',
              defaultMessage: 'Min Number',
            })}
          </div>
          {readonly ? (
            <NumberNonEditInput variant="standard" value={minValue} size="small" fullWidth={false} type="text" />
          ) : (
            <NumberEditInput
              variant="standard"
              value={minValue}
              size="small"
              integer={integer}
              fullWidth={false}
              onChange={onChangeMin}
              name={`${attrFieldName}.validation.min`}
              error={Boolean(minError)}
              helperText={minError}
            />
          )}
        </InputContainer>
        <InputContainer>
          <div>
            {intl.formatMessage({
              id: 'template.modal.attribute.NumericType-max',
              defaultMessage: 'Max Number',
            })}
          </div>
          {readonly ? (
            <NumberNonEditInput variant="standard" value={maxValue} size="small" fullWidth={false} type="text" />
          ) : (
            <NumberEditInput
              variant="standard"
              value={maxValue}
              fullWidth={false}
              size="small"
              integer={integer}
              onChange={onChangeMax}
              name={`${attrFieldName}.validation.max`}
              error={Boolean(maxError)}
              helperText={maxError}
            />
          )}
        </InputContainer>
      </InputsContainer>
    </div>
  );
};
export default Range;
