import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import TransparentInput from 'src/components/TransparentInput';
import { EntityTemplateForm } from 'src/routes/Templates/components/Template';
import { TypeComponentProps, TypeVariantComponentProps } from '../interfaces';
import TypeComponentContainer from '../TypeComponent';

interface LabelTypeVariantComponentProps extends TypeVariantComponentProps {}

interface LabelTypeProps extends TypeComponentProps {}

const LabelType: React.FC<LabelTypeProps> = props => {
  const { variant, attrFieldName, attributeValues } = props;
  const variantMap: Record<typeof variant, any> = {
    custom: CustomLabelType,
    builtIn: BuiltInLabelType,
  };
  const TypeComponent = variantMap[variant];

  return <TypeComponent attrFieldName={attrFieldName} attributeValues={attributeValues} />;
};

const CustomLabelType: React.FC<LabelTypeVariantComponentProps> = props => <LabelTypeForEditing {...props} />;

const BuiltInLabelType: React.FC<LabelTypeVariantComponentProps> = props => <LabelTypeForEditing {...props} />;

const LabelTypeForEditing: React.FC<LabelTypeVariantComponentProps> = props => {
  const { attrFieldName, attributeValues, readonly } = props;
  const formik = useFormikContext<EntityTemplateForm>();
  const intl = useIntl();
  const regex = attributeValues?.validation?.regex;

  const [value, setValue] = useState(regex || '');

  const handleChange = (event: any) => {
    const updatedValue = event.target.value ? event.target.value : null;
    setValue(updatedValue);
    formik.setFieldValue(`${attrFieldName}.validation.regex`, updatedValue);
  };

  return (
    <TypeComponentContainer readonly={readonly}>
      <TransparentInput
        value={value}
        onChange={handleChange}
        name={`${attrFieldName}.validation.regex`}
        placeholder={intl.formatMessage({
          id: 'template.modal.attribute.label-type.placeholder',
          defaultMessage: 'Set Regular Expression (Optional)',
        })}
        readonly={readonly}
      />
    </TypeComponentContainer>
  );
};
export default LabelType;
