import React from 'react';
import { SelectFieldWrapper, SelectFieldWrapperProps } from '@biotmed/base-components';

import { editFieldInputProps, editFieldVariant } from './constants';

const EditSelectFieldWrapper = <
  T,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
>(
  props: SelectFieldWrapperProps<T, Multiple, DisableClearable, FreeSolo>,
) => {
  const { inputProps, ...restProps } = props;

  return (
    <SelectFieldWrapper
      {...restProps}
      inputProps={{
        ...inputProps,
        ...editFieldVariant,
        InputProps: {
          ...inputProps?.InputProps,
          ...editFieldInputProps,
        },
      }}
    />
  );
};

export default EditSelectFieldWrapper;
