import React from 'react';
import { useFormikContext } from 'formik';
import { EntityTemplateForm } from 'src/routes/Templates/components/Template';
import { LabelInput } from './styled/Renderers.styled';
import { DefaultValueExtractedRendererProps } from '../../modules';

export type LabelExtraProps = DefaultValueExtractedRendererProps<string> & { placeholder: string };

export const Label: React.FC<LabelExtraProps> = props => {
  const { name, value, error, helperText, InputProps, placeholder } = props;
  const { endAdornment, ...inputProps } = InputProps;

  const formik = useFormikContext<EntityTemplateForm>();

  const handleBlur: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = event => {
    formik.setFieldTouched(name, true, true);
  };

  const handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = event => {
    formik.setFieldValue(name, event.target.value);
    formik.setFieldTouched(name, true, false);
  };
  const passedProps = {
    value,
    error,
    helperText,
    placeholder,
    onChange: handleChange,
    onBlur: handleBlur,
    ...inputProps,
  };

  return <LabelInput {...passedProps} />;
};

export default Label;
