import { defineMessages } from 'react-intl';
import { AnalyticsDBInformationResponseActivationStateEnum } from '@biotmed/dms-sdk';

const analyticsDBStatusDictionary = defineMessages({
  [AnalyticsDBInformationResponseActivationStateEnum.Inactive]: {
    id: 'analytics-db.inactive-status',
    defaultMessage: 'Inactive',
  },
  [AnalyticsDBInformationResponseActivationStateEnum.Initializing]: {
    id: 'analytics-db.initializing-status',
    defaultMessage: 'Initializing the DB may take several hours depending on DB size',
  },
  [AnalyticsDBInformationResponseActivationStateEnum.StoppingInitialization]: {
    id: 'analytics-db.stopping-initializing-status',
    defaultMessage: 'Stopping initialization',
  },
  [AnalyticsDBInformationResponseActivationStateEnum.ActiveAndSyncOn]: {
    id: 'analytics-db.active-sync-on-status',
    defaultMessage: 'Active, sync on',
  },
  [AnalyticsDBInformationResponseActivationStateEnum.ActiveAndSyncOff]: {
    id: 'analytics-db.active-sync-off-status',
    defaultMessage: 'Active, sync off',
  },
});

export const getStatusText = (syncState: AnalyticsDBInformationResponseActivationStateEnum) => {
  return analyticsDBStatusDictionary[syncState];
};

const analyticsDBSyncControlDictionary = defineMessages({
  [AnalyticsDBInformationResponseActivationStateEnum.Inactive]: {
    id: 'analytics-db.inactive-sync-system',
    defaultMessage:
      'Starting the sync will build the analytics DB and sync it with the operational DB. This action may take several hours. The analytics DB will not be available during sync.',
  },
  [AnalyticsDBInformationResponseActivationStateEnum.Initializing]: {
    id: 'analytics-db.initializing-sync-system',
    defaultMessage:
      'Stopping the initialization will force stop the build of the analytics DB. The analytics DB will not be available and will require to start sync again in order to re-build it.',
  },
  [AnalyticsDBInformationResponseActivationStateEnum.StoppingInitialization]: {
    id: 'analytics-db.stopping-initializing-sync-system',
    defaultMessage:
      'Stopping the initialization will force stop the build of the analytics DB. The analytics DB will not be available and will require to start sync again in order to re-build it.',
  },
  [AnalyticsDBInformationResponseActivationStateEnum.ActiveAndSyncOn]: {
    id: 'analytics-db.active-sync-on-sync-system',
    defaultMessage:
      'Stopping the real-time sync of the analytics DB. The Analytics DB will be still available but not up to date.',
  },
  [AnalyticsDBInformationResponseActivationStateEnum.ActiveAndSyncOff]: {
    id: 'analytics-db.active-sync-off-sync-system',
    defaultMessage:
      'Starting the sync will clear the analytics DB and re-sync it with the operational DB. This action may take several hours. The analytics DB will not be available during sync.',
  },
});

export const getSyncControlText = (syncState: AnalyticsDBInformationResponseActivationStateEnum) => {
  return analyticsDBSyncControlDictionary[syncState];
};

const analyticsDBButtonDictionary = defineMessages({
  [AnalyticsDBInformationResponseActivationStateEnum.Inactive]: {
    id: 'analytics-db.inactive-button-text',
    defaultMessage: 'Start Initializing',
  },
  [AnalyticsDBInformationResponseActivationStateEnum.Initializing]: {
    id: 'analytics-db.initializing-button-text',
    defaultMessage: 'Stop Initializing',
  },
  [AnalyticsDBInformationResponseActivationStateEnum.StoppingInitialization]: {
    id: 'analytics-db.stopping-initializing-button-text',
    defaultMessage: 'Stop Initializing',
  },
  [AnalyticsDBInformationResponseActivationStateEnum.ActiveAndSyncOn]: {
    id: 'analytics-db.active-sync-on-button-text',
    defaultMessage: 'Stop Sync',
  },
  [AnalyticsDBInformationResponseActivationStateEnum.ActiveAndSyncOff]: {
    id: 'analytics-db.active-sync-off-button-text',
    defaultMessage: 'Start Sync',
  },
});

export const getButtonText = (syncState: AnalyticsDBInformationResponseActivationStateEnum) => {
  return analyticsDBButtonDictionary[syncState];
};
