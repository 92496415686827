import { Checkbox, InputFieldWrapper } from '@biotmed/base-components';
import { FormikErrors, FormikTouched } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { PasswordStrength } from '@biotmed/auth-pages';
import { ValidateUserCredentialsResponse } from '@biotmed/auth-pages/lib/components/PasswordStrength/types';
import { useFeature } from '@biotmed/react-flags-lib';
import {
  StyledName,
  StyledStep,
  StyledResetSystemForm,
  StyledField,
  CheckboxContainer,
  HelperTextContainer,
  StyledFormControlLabel,
  ResetSystemContentText,
  ResetSystemContentTitle,
  ResetSystemContentMessage,
} from '../EnvironmentConfiguration.styled';
import PasswordField from './PasswordField';
import { NewOwnerForm } from './ResetSystemModal';

interface NewOwnerProps {
  values: NewOwnerForm;
  errors: FormikErrors<NewOwnerForm>;
  touched: FormikTouched<NewOwnerForm>;
  onPasswordChange: (e: React.ChangeEvent<any>) => void;
  onConfirmPasswordChange: (e: React.ChangeEvent<any>) => void;
  onAgreeToggle: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent<any>) => void;
  handlePasswordFocus: (e: React.FocusEvent<any>) => void;
  setTouched: (touched: FormikTouched<NewOwnerForm>, shouldValidate?: boolean | undefined) => void;
  passwordStrengthModalProps: {
    setOpen: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    open?: boolean;
    credentialsValidationLoading?: boolean;
    passwordStrength?: ValidateUserCredentialsResponse['passwordStrength'];
    success: boolean;
  };
}

export const NewOwner = (props: NewOwnerProps) => {
  const {
    values,
    errors,
    onPasswordChange,
    onConfirmPasswordChange,
    onAgreeToggle,
    touched,
    handleBlur,
    handlePasswordFocus,
    setTouched,
    passwordStrengthModalProps,
  } = props;
  const intl = useIntl();
  const { isFeatureActive: isPasswordStrengthFeatureActive } = useFeature({ flag: 'PASSWORD_STRENGTH' });

  function withStrength(MyElement: React.ReactElement) {
    return isPasswordStrengthFeatureActive ? (
      <PasswordStrength {...passwordStrengthModalProps}>{MyElement}</PasswordStrength>
    ) : (
      MyElement
    );
  }

  function getPasswordError() {
    if (passwordStrengthModalProps.open) {
      return undefined;
    }

    if (errors.newPassword) {
      return errors.newPassword;
    }

    return !passwordStrengthModalProps.success
      ? passwordStrengthModalProps?.passwordStrength?.warningMessage
      : undefined;
  }

  return (
    <StyledStep>
      <ResetSystemContentMessage>
        <ResetSystemContentTitle>
          {intl.formatMessage({
            id: 'reset-system.modal.new-owner.content.title',
            defaultMessage: 'New User Registration',
          })}
        </ResetSystemContentTitle>
        <ResetSystemContentText>
          {intl.formatMessage({
            id: 'reset-system.modal.new-owner.content.text',
            defaultMessage: 'In order to reset the system settings, you must pass the system on to a new owner.',
          })}
        </ResetSystemContentText>
      </ResetSystemContentMessage>
      <StyledResetSystemForm>
        <StyledName>
          <StyledField>
            <InputFieldWrapper
              label={intl.formatMessage({
                id: 'reset-system.modal.new-owner.label.first-name',
                defaultMessage: 'First Name',
              })}
              name="firstName"
              required
            />
          </StyledField>
          <StyledField>
            <InputFieldWrapper
              label={intl.formatMessage({
                id: 'reset-system.modal.new-owner.label.last-name',
                defaultMessage: 'Last Name',
              })}
              name="lastName"
              required
            />
          </StyledField>
        </StyledName>
        <InputFieldWrapper
          label={intl.formatMessage({
            id: 'reset-system.modal.new-owner.label.email',
            defaultMessage: 'Email Address',
          })}
          name="email"
          type="email"
          required
        />
        {withStrength(
          <PasswordField
            label={intl.formatMessage({
              id: 'reset-system.modal.new-owner.label.password',
              defaultMessage: 'Password',
            })}
            required
            handleBlur={handleBlur}
            handleFocus={handlePasswordFocus}
            name="newPassword"
            handleChange={onPasswordChange}
            error={getPasswordError()}
            value={values.newPassword}
            touched={touched.newPassword}
          />,
        )}
        <PasswordField
          label={intl.formatMessage({
            id: 'reset-system.modal.new-owner.label.password-confirmation',
            defaultMessage: 'Confirm Password',
          })}
          required
          handleBlur={handleBlur}
          name="newPasswordConfirmation"
          handleChange={onConfirmPasswordChange}
          error={errors.newPasswordConfirmation}
          value={values.newPasswordConfirmation}
          touched={touched.newPasswordConfirmation}
        />
        <CheckboxContainer>
          <StyledFormControlLabel
            control={<Checkbox checked={values.agree} />}
            label={intl.formatMessage({
              id: 'reset-system.modal.new-owner.checkbox.label',
              defaultMessage:
                'By selecting "Reset factory settings" you agree that all data in the deployment you are accessing will be deleted.',
            })}
            name="agree"
            onChange={e => {
              setTouched({ ...touched, agree: true });
              onAgreeToggle(e);
            }}
          />
          {errors.agree && touched.agree && <HelperTextContainer>{errors.agree}</HelperTextContainer>}
        </CheckboxContainer>
      </StyledResetSystemForm>
    </StyledStep>
  );
};
