import { Avatar, Explained, FreeTextSearch, SearchTerms, SidePreview } from '@biotmed/base-components';
import { EMPTY_VALUE_STRING } from '@biotmed/data-components';
import { FilterV2, OrderOrderEnum, PluginResponse } from '@biotmed/settings-sdk';
import { TablePaginationConfig } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import AppConfig from 'src/config/AppConfig';
import { actions as pluginActions, selectors as pluginSelectors } from 'src/redux/data/plugin';
import { ReactComponent as PluginIcon } from '../../../images/plugin.svg';
import {
  ContentWrapper,
  FreeTextSearchContainer,
  PluginsPageLayout,
  PluginsTitle,
  PluginsTotalRow,
  PluginsTotalSubTitle,
  RightSide,
  StyledPageTitleContainer,
} from './PluginConfiguration.styled';
import PluginDetailsDisplay from './PluginDetailsDisplay';
import PluginTable from './PluginTable';

interface PluginConfigurationProps {}

const PluginConfiguration: React.FC<PluginConfigurationProps> = props => {
  const [selectedRow, setSelectedRow] = useState<PluginResponse>();
  const intl = useIntl();
  const dispatch = useDispatch();

  const pluginsTotal = useSelector(pluginSelectors.getPluginsTotal);
  const pageLimit = useSelector(pluginSelectors.selectPageLimit);
  const currentPage = useSelector(pluginSelectors.selectCurrentPage);
  const pluginsList = useSelector(pluginSelectors.getPluginsList);

  const currentPlugin = useSelector(pluginSelectors.getPluginByName(selectedRow?.name ?? '')); // to get updated row data
  const loading = useSelector(pluginSelectors.selectLoading);
  const freeTextSearch = useSelector(pluginSelectors.selectFreeTextSearch);

  useEffect(() => {
    setSelectedRow(currentPlugin); // in order to update the data in preview, etc
  }, [currentPlugin]);

  const itemsCountText = useMemo(() => {
    if (freeTextSearch && freeTextSearch.trim().length > 0) {
      return intl.formatMessage(
        {
          id: 'pluginConfiguration.itemsReturnedForSearchTerms.count',
          defaultMessage: '{count} Items found for search terms',
        },
        { count: pluginsTotal },
      );
    }
    return intl.formatMessage(
      { id: 'pluginConfiguration.items.count', defaultMessage: '{count} Plugins' },
      { count: pluginsTotal },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [freeTextSearch, pluginsTotal]);

  useEffect(() => {
    dispatch(pluginActions.onLoadPlugin());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      dispatch(pluginActions.resetSearchRequest());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatDate = (date: string) => {
    return date ? intl.formatDate(date, { dateStyle: 'short', timeStyle: 'short' }) : EMPTY_VALUE_STRING;
  };

  const pluginsDataSource = pluginsList.map((pluginListItem: PluginResponse, index: number) => {
    const { creationTime, name, lastModifiedTime } = pluginListItem;
    const creationTimeMessage = formatDate(creationTime);
    const lastModifiedMessage = formatDate(lastModifiedTime);

    return {
      ...pluginListItem,
      key: pluginListItem.name === selectedRow?.name ? 'selected-plugin' : `plugin-${index}`,
      creationTime: creationTimeMessage,
      lastModifiedTime: lastModifiedMessage,
      name,
    };
  });

  const handleChange = (
    pagination: TablePaginationConfig,
    sorterUnboxed: { field: string; order: 'ascend' | 'descend' | undefined },
    filters: { [key: string]: FilterV2 },
  ) => {
    dispatch(
      pluginActions.updateSearchRequest({
        filter: filters,
        page: pagination.current ? pagination.current - 1 : 0,
        limit: pagination.pageSize,
        sort: sorterUnboxed.order && [
          {
            prop: sorterUnboxed.field,
            order: sorterUnboxed.order === 'ascend' ? OrderOrderEnum.Asc : OrderOrderEnum.Desc,
          },
        ],
      }),
    );
    dispatch(pluginActions.onLoadPlugin());
  };

  const onLearnMoreClick = () => {
    window.open(AppConfig.PLUGIN_CONFIGURATION_LEARN_MORE_URL);
  };

  const onFreeTextSearchChange = (value: string) => {
    dispatch(pluginActions.updateFreeTextSearch({ freeTextSearch: value }));
    dispatch(pluginActions.onLoadPlugin());
  };

  return (
    <PluginsPageLayout>
      <ContentWrapper>
        <StyledPageTitleContainer>
          <PluginsTitle>
            {intl.formatMessage({ id: 'pluginConfiguration.mainTitle', defaultMessage: 'Plugin Configuration' })}
          </PluginsTitle>
          <FreeTextSearchContainer>
            <FreeTextSearch freeTextSearch={freeTextSearch} onFreeTextSearchChange={onFreeTextSearchChange} />
          </FreeTextSearchContainer>
        </StyledPageTitleContainer>
        <Explained
          text={intl.formatMessage({
            id: 'pluginConfiguration.explained',
            defaultMessage: "Plugins enable you to customize BioT's logic to your needs",
          })}
          onLearnMore={onLearnMoreClick}
        />
        <PluginsTotalRow>
          <PluginsTotalSubTitle>{itemsCountText}</PluginsTotalSubTitle>
        </PluginsTotalRow>
        <SearchTerms freeTextSearch={freeTextSearch} onFreeTextSearchChange={onFreeTextSearchChange} />
        <PluginTable
          dataSource={pluginsDataSource}
          pagination={{ current: currentPage + 1, total: pluginsTotal, pageSize: pageLimit }}
          onRow={(record, rowIndex) => ({
            onClick: () => {
              setSelectedRow(record);
            },
          })}
          onChange={(pagination, filters, sorter, extra) => {
            const sorterUnboxed = sorter as { field: string; order: 'ascend' | 'descend' | undefined };
            const parsedFilters: Record<string, FilterV2> = {};
            for (const [dataIndex, filterValue] of Object.entries(filters)) {
              if (filterValue !== null) {
                const processedDataIndex = dataIndex === 'category' ? 'entityTypeName' : dataIndex;
                parsedFilters[processedDataIndex] = JSON.parse(filterValue as unknown as string) as FilterV2;
              }
            }

            handleChange(pagination, sorterUnboxed, parsedFilters);
          }}
          isLoading={loading}
        />
      </ContentWrapper>
      <RightSide>
        <SidePreview
          withDelete={false}
          withExpand={false}
          displaySidePreview={!!selectedRow?.name}
          name={currentPlugin?.displayName}
          renderAvatar={() => <Avatar IconItem={PluginIcon} />}
          collapsibleSidePanelProps={{ disabled: true }}
        >
          <PluginDetailsDisplay selectedRow={selectedRow} />
        </SidePreview>
      </RightSide>
    </PluginsPageLayout>
  );
};

export default PluginConfiguration;
