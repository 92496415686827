import {
  Button,
  Table,
  SidePreview,
  Explained,
  entityIcons,
  Menu,
  MenuItem,
  Icon,
  SubmitButtonStatus,
  ValidationModal,
  ValidationModalProps,
  FreeTextSearch,
  SearchTerms,
  AvatarSize,
  InfoTooltip,
  ActionButton,
  actionIcons,
  EntityIconsOptionsEnum,
  PopupModal,
  IconPosition,
} from '@biotmed/base-components';
import React, { useEffect, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectors as entitySelectors } from 'src/redux/data/entity';
import { actions as templateActions, selectors as templateSelectors } from 'src/redux/data/template';
// TODO: import { OrderOrderEnum } from '@biotmed/sdk-api-provider/lib/types/settings';
import { FilterV2, OrderOrderEnum, GetTemplateResponse, EntityType as EntityTypeBE } from '@biotmed/settings-sdk';
import {
  getEntityTypeIntlDisplayName,
  EntityType,
  AttributeTypeEnum,
  createCustomFilter,
  EMPTY_VALUE_STRING,
  EntityAvatar,
} from '@biotmed/data-components';

import { TablePaginationConfig } from 'antd';

import AppConfig from 'src/config/AppConfig';

import { Entity } from 'src/redux/data/entity/modules/interfaces';
import {
  TemplatesPageLayout,
  ContentWrapper,
  TemplatesTitle,
  TemplatesTotalSubTitle,
  TemplatesTotalRow,
  CategoryContent,
  RightSide,
  StyledPageTitleContainer,
  FreeTextSearchContainer,
  AlignedDeleteActionButtonWrapper,
} from './Templates.styled';
import Template, { EntityTemplateForm, Mode } from './Template';
import { cleanTemplate, TEMPLATE_FORM_CONTAINER_ID } from '../modules/constant';
import { useTemplateOverview } from '../hooks/useTemplateOverview';
import { isTemplateDeleteDisabled, mapDisabledDeleteToReason } from '../modules/utils';
import { ForceConfirmationPopup } from './formStepsComponents/forceConfirmation/ForceConfirmationPopup';
import useForceConfirmationProps from './formStepsComponents/forceConfirmation/hooks/useForceConfirmationProps';
import getDeleteTemplateForceConfirmationText from './formStepsComponents/forceConfirmation/deleteForceConfirmation/getDeleteForceConfirmationText';
import { DeleteTemplateForceErrorCodesType } from './formStepsComponents/forceConfirmation/types';

interface TemplatesProps {}

const emptyRow = {
  entityTypeName: undefined as unknown as EntityType,
  displayName: '',
  name: '',
  description: '',
  builtInAttributes: [],
  dynamicAttributes: [],
  id: '',
  creationTime: '',
  lastModifiedTime: '',
};

const Templates: React.FC<TemplatesProps> = props => {
  const [mode, setMode] = useState<Mode>('ADD');
  const [selectedRow, setSelectedRow] = useState<GetTemplateResponse>(emptyRow);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useDispatch();

  const theme = useTheme();
  const open = useSelector(templateSelectors.getIsOpen);
  const setOpen = (isOpen: boolean) => {
    dispatch(isOpen ? templateActions.openModal() : templateActions.closeModal());
  };
  const templatesTotal = useSelector(templateSelectors.getTemplatesTotal);
  const pageLimit = useSelector(templateSelectors.selectPageLimit);
  const currentPage = useSelector(templateSelectors.selectCurrentPage);
  const templatesList = useSelector(templateSelectors.getTemplatesList);

  const currentTemplate = useSelector(templateSelectors.getTemplateById(selectedRow.id || '')); // to get updated row data

  const currentTemplateOverview = useTemplateOverview(selectedRow.id);
  const selectedRowEntity: EntityTypeBE = useSelector(
    entitySelectors.selectEntityByEntityName(selectedRow.entityTypeName || ''),
  );
  const [selectedAddEntity, setSelectedAddEntity] = useState<EntityTypeBE>();
  const intl = useIntl();
  const loading = useSelector(templateSelectors.selectLoading);

  const deleteStatus = useSelector(templateSelectors.getDeleteStatus);
  const supportedToAddEntityTypes = useSelector(entitySelectors.SelectSupportedToAddEntityTypes);
  const entitiesFilterValues = useSelector(entitySelectors.SelectOrphanEntityTypes);

  const freeTextSearch = useSelector(templateSelectors.selectFreeTextSearch);
  const isForceDeletePopupOpen = useSelector(templateSelectors.getIsForceDeletePopupOpen);
  const isValidationModalOpen = useSelector(templateSelectors.getIsValidationModalOpen);
  const { title, submitLabel, information } = useForceConfirmationProps({
    textMapper: getDeleteTemplateForceConfirmationText,
    forceErrorCode: useSelector(templateSelectors.getForceDeleteErrorCode) as DeleteTemplateForceErrorCodesType,
  });
  const templateDeleteButtonDisabledWithReason = isTemplateDeleteDisabled(
    currentTemplateOverview?.statistics?.entitiesCount,
    currentTemplate?.removable,
  );

  const itemsCountText = useMemo(() => {
    if (freeTextSearch && freeTextSearch.trim().length > 0) {
      return intl.formatMessage(
        {
          id: 'templates.itemsReturnedForSearchTerms.count',
          defaultMessage: '{count} Items found for search terms',
        },
        { count: templatesTotal },
      );
    }
    return intl.formatMessage(
      { id: 'templates.items.count', defaultMessage: '{count} Templates' },
      { count: templatesTotal },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [freeTextSearch, templatesTotal]);

  useEffect(() => {
    dispatch(templateActions.onLoadTemplate());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      dispatch(templateActions.resetSearchRequest());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const templatesDataSource: GetTemplateResponse[] = templatesList.map(
    (templateListItem: GetTemplateResponse, index: number) => {
      const { creationTime, displayName } = templateListItem;
      const dateMessage = creationTime
        ? intl.formatDate(creationTime, { dateStyle: 'short', timeStyle: 'short' })
        : EMPTY_VALUE_STRING;

      return {
        ...templateListItem,
        key: templateListItem.id === selectedRow.id ? 'selected-template' : `template-${index}`,
        creationTime: dateMessage,
        name: displayName,
      };
    },
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = (force = false) => {
    dispatch(templateActions.deleteTemplate({ templateId: selectedRow.id, templateName: selectedRow.name, force }));
  };

  const handleExpand = () => {
    setMode('EDIT');
    setOpen(true);
  };

  const handleAddButtonClicked = (event: React.MouseEvent<HTMLElement>) => {
    if (anchorEl === null) {
      setAnchorEl(event.currentTarget);
    } else {
      handleCloseMenu();
    }
  };

  const openAddModal = (entityType: EntityTypeBE) => {
    setSelectedAddEntity(entityType);
    handleCloseMenu();
    setMode('ADD');
    setOpen(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const menuItems = supportedToAddEntityTypes.map(entityType => ({
    text: intl.formatMessage(getEntityTypeIntlDisplayName(entityType.name)),
    Logo: entityIcons[entityType.name as EntityIconsOptionsEnum],
    onClick: () => {
      openAddModal(entityType);
    },
  }));

  const handleChange = (
    pagination: TablePaginationConfig,
    sorterUnboxed: { field: string; order: 'ascend' | 'descend' | undefined },
    filters: { [key: string]: FilterV2 },
  ) => {
    dispatch(
      templateActions.updateSearchRequest({
        filter: filters,
        page: pagination.current ? pagination.current - 1 : 0,
        limit: pagination.pageSize,
        sort: sorterUnboxed.order && [
          {
            prop: sorterUnboxed.field,
            order: sorterUnboxed.order === 'ascend' ? OrderOrderEnum.Asc : OrderOrderEnum.Desc,
          },
        ],
      }),
    );
    dispatch(templateActions.onLoadTemplate());
  };

  const categoryColumnSelectableValues = useMemo(
    () =>
      entitiesFilterValues?.map(value => ({
        name: value.name,
        displayName: intl.formatMessage(getEntityTypeIntlDisplayName(value.name)),
      })) || [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [entitiesFilterValues],
  );

  const validationModalProps: ValidationModalProps = {
    open: isValidationModalOpen,
    onClose: () => dispatch(templateActions.onCloseValidationModal()),
    agreeButtonLabel: intl.formatMessage({
      id: 'templates.validation.component.agree.label',
      defaultMessage: 'Delete',
    }),
    dismissButtonLabel: intl.formatMessage({
      id: 'templates.validation.component.dismiss.label',
      defaultMessage: 'Cancel',
    }),
    onAgree: handleDelete,
    children: intl.formatMessage({
      id: 'templates.validation.component.message',
      defaultMessage: 'Are you sure you wish to delete this item?',
    }),
    submitProps: {
      submit: true,
      submitButtonProps: {
        status: deleteStatus,
        successLabel: intl.formatMessage({
          id: 'templates.validation.component.agree.success-label',
          defaultMessage: 'Deleted',
        }),
        iconPosition: IconPosition.END,
      },
    },
  };

  const onLearnMoreClick = () => {
    window.open(AppConfig.TEMPLATES_LEARN_MORE_URL);
  };

  const onSubmitAdd = (
    entityType: string,
    templateId: string,
    values: EntityTemplateForm,
    originalChildrenTemplates: any,
    force: boolean,
  ) => {
    dispatch(
      templateActions.createTemplate({
        entityTemplate: values.entityTemplate,
        childrenTemplates: values.childrenTemplates,
        containerId: TEMPLATE_FORM_CONTAINER_ID,
        force,
      }),
    );
  };

  const onSubmitEdit = (
    entityType: string,
    templateId: string,
    values: EntityTemplateForm,
    originalChildrenTemplates: GetTemplateResponse[],
    forceUpdate: boolean,
  ) => {
    dispatch(
      templateActions.editTemplate({
        // TODO: REMOVE || '' FROM ENTITY TYPE AND TEMPLATE ID
        entityType: entityType || '',
        templateId,
        entityTemplate: cleanTemplate(values.entityTemplate),
        childrenTemplates: values.childrenTemplates?.map(childTemplate => cleanTemplate(childTemplate)),
        originalChildrenTemplates,
        forceUpdate,
        containerId: TEMPLATE_FORM_CONTAINER_ID,
      }),
    );
  };

  const onFreeTextSearchChange = (value: string) => {
    dispatch(templateActions.updateFreeTextSearch({ freeTextSearch: value }));
    dispatch(templateActions.onLoadTemplate());
  };

  const onChangeRow = (record: GetTemplateResponse) => {
    setSelectedRow(record);
  };

  return (
    <TemplatesPageLayout>
      <ContentWrapper>
        <ValidationModal {...validationModalProps} />

        <PopupModal open={isForceDeletePopupOpen}>
          <ForceConfirmationPopup
            onSubmit={() => handleDelete(true)}
            onCancel={() => dispatch(templateActions.onForceDeleteCancel())}
            title={title}
            submitLabel={submitLabel}
            information={information()}
            submit
            submitButtonProps={{ status: deleteStatus }}
            isButtonsDisabled={deleteStatus === SubmitButtonStatus.LOADING}
          />
        </PopupModal>

        <StyledPageTitleContainer>
          <TemplatesTitle>
            {intl.formatMessage({ id: 'templates.mainTitle', defaultMessage: 'Templates' })}
          </TemplatesTitle>
          <FreeTextSearchContainer>
            <FreeTextSearch freeTextSearch={freeTextSearch} onFreeTextSearchChange={onFreeTextSearchChange} />
          </FreeTextSearchContainer>
        </StyledPageTitleContainer>
        <Explained
          text={intl.formatMessage({
            id: 'templates.explained',
            defaultMessage: "Templates enable you to customize BioT's data model to your needs",
          })}
          onLearnMore={onLearnMoreClick}
        />
        <TemplatesTotalRow>
          <TemplatesTotalSubTitle>{itemsCountText}</TemplatesTotalSubTitle>
          <Button variant="outlined" onClick={handleAddButtonClicked} size="medium">
            {intl.formatMessage({ id: 'templates.add', defaultMessage: 'Add +' })}
          </Button>
          <Menu
            title={intl.formatMessage({ id: 'templates.addMenuTitle', defaultMessage: 'new template for' })}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
            menuPosition={{ left: '30px' }}
            PaperProps={{
              style: {},
            }}
          >
            {menuItems.map(menuItemProps => (
              <MenuItem {...menuItemProps} key={`${menuItemProps.text}-menu-item`} />
            ))}
          </Menu>
        </TemplatesTotalRow>
        <Template
          mode={mode}
          // TODO: This is here until we remove Entity interface and only use the BE one
          // https://softimize.atlassian.net/browse/SOFT-7309
          entity={(mode === 'EDIT' ? selectedRowEntity : selectedAddEntity) as unknown as Entity}
          currentTemplateOverview={mode === 'EDIT' ? currentTemplateOverview : undefined}
          template={currentTemplate || selectedRow}
          handleClose={handleClose}
          open={open}
          onFormSubmit={mode === 'EDIT' ? onSubmitEdit : onSubmitAdd}
          containerId={TEMPLATE_FORM_CONTAINER_ID}
        />
        <SearchTerms freeTextSearch={freeTextSearch} onFreeTextSearchChange={onFreeTextSearchChange} />
        <Table
          columns={[
            {
              title: intl.formatMessage({ id: 'templates.categoryColumn', defaultMessage: 'Category' }),
              dataIndex: 'entityTypeName',
              key: 'category',
              width: '300px',
              sorter: true,
              showSorterTooltip: false,
              filters: [],
              filterDropdown: createCustomFilter(
                AttributeTypeEnum.MultiSelect,
                {
                  selectableValues: categoryColumnSelectableValues,
                },
                '',
              ),
              render: (entityType, record, index) => {
                const EntityIcon = entityType ? entityIcons[entityType as EntityType] : undefined;
                return (
                  <CategoryContent key={`category-${index}`}>
                    {EntityIcon && <Icon IconComponent={EntityIcon} height="20px" color={theme.palette.primary.dark} />}
                    {intl.formatMessage(getEntityTypeIntlDisplayName(entityType))}
                  </CategoryContent>
                );
              },
            },
            {
              title: intl.formatMessage({ id: 'templates.column-name', defaultMessage: 'Name' }),
              dataIndex: 'displayName',
              key: 'name',
            },
            {
              title: intl.formatMessage({ id: 'templates.column-creationTime', defaultMessage: 'Creation Time' }),
              dataIndex: 'creationTime',
              key: 'creationTime',
            },
          ]}
          dataSource={templatesDataSource}
          pagination={{ current: currentPage + 1, pageSize: pageLimit, total: templatesTotal }}
          onRow={(record, rowIndex) => ({
            onClick: () => {
              if (record) onChangeRow(record);
            },
          })}
          onChange={(pagination, filters, sorter, extra) => {
            const sorterUnboxed = sorter as { field: string; order: 'ascend' | 'descend' | undefined };
            const parsedFilters: Record<string, FilterV2> = {};
            for (const [dataIndex, filterValue] of Object.entries(filters)) {
              if (filterValue !== null) {
                const processedDataIndex = dataIndex === 'category' ? 'entityTypeName' : dataIndex;
                parsedFilters[processedDataIndex] = JSON.parse(filterValue as unknown as string) as FilterV2;
              }
            }

            handleChange(pagination, sorterUnboxed, parsedFilters);
          }}
          isLoading={loading}
        />
      </ContentWrapper>
      <RightSide>
        <SidePreview
          displaySidePreview={!!selectedRow.entityTypeName}
          name={currentTemplate?.displayName || selectedRow.displayName}
          subtitle={
            selectedRow.entityTypeName
              ? intl.formatMessage(getEntityTypeIntlDisplayName(selectedRow.entityTypeName))
              : undefined
          }
          description={currentTemplate?.description || selectedRow.description}
          withDelete
          withExpand
          renderDelete={() => (
            <InfoTooltip
              title={
                templateDeleteButtonDisabledWithReason.isDisabled
                  ? mapDisabledDeleteToReason(templateDeleteButtonDisabledWithReason.reasons)
                  : ''
              }
            >
              <AlignedDeleteActionButtonWrapper>
                <ActionButton
                  text={intl.formatMessage({ id: 'templates.delete', defaultMessage: 'Delete template' })}
                  onClick={() => dispatch(templateActions.onClickDeleteTemplate())}
                  Logo={actionIcons.delete}
                  disabled={templateDeleteButtonDisabledWithReason.isDisabled}
                />
              </AlignedDeleteActionButtonWrapper>
            </InfoTooltip>
          )}
          expandButtonProps={{ onClick: handleExpand }}
          renderAvatar={(size: AvatarSize) => (
            <EntityAvatar size={size} type={selectedRow.entityTypeName as EntityType} />
          )}
          collapsibleSidePanelProps={{ disabled: true }}
        />
      </RightSide>
    </TemplatesPageLayout>
  );
};

export default Templates;
