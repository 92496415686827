// eslint-disable-next-line no-shadow
export enum AttributeTypesEnum {
  Label = 'LABEL',
  Paragraph = 'PARAGRAPH',
  Integer = 'INTEGER',
  Decimal = 'DECIMAL',
  Boolean = 'BOOLEAN',
  SingleSelect = 'SINGLE_SELECT',
  MultiSelect = 'MULTI_SELECT',
  Address = 'ADDRESS',
  Phone = 'PHONE',
  Email = 'EMAIL',
  DateTime = 'DATE_TIME',
  Date = 'DATE',
  Timezone = 'TIMEZONE',
  Locale = 'LOCALE',
  Reference = 'REFERENCE',
  Name = 'NAME',
  Picture = 'PICTURE',
  File = 'FILE',
  Image = 'IMAGE',
  Waveform = 'WAVEFORM',
  Linked = 'LINK',
}
