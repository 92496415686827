import React from 'react';
import { TypeComponentContainer } from './typeComponents.styled';

interface TypeComponentProps {
  readonly?: boolean;
}

const TypeComponent: React.FC<React.PropsWithChildren<TypeComponentProps>> = props => {
  const { readonly = false, children } = props;

  return <TypeComponentContainer $readonly={readonly}>{children}</TypeComponentContainer>;
};

export default TypeComponent;
