import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { selectors as entitySelectors } from 'src/redux/data/entity';
import { selectors as templateSelectors, actions as templateActions } from 'src/redux/data/template';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import TransparentInput from 'src/components/TransparentInput';
import { GetTemplateResponse, EntityType } from '@biotmed/settings-sdk';
import { getEntityTypeIntlDisplayName } from '@biotmed/data-components';
import { EntityTemplateForm } from 'src/routes/Templates/components/Template';
import { InputsContainer, ReferenceTypeContainer, Separator, SingleSelectStyled } from './ReferenceType.styled';
import { ReferenceTypeVariantComponentProps } from './ReferenceType';
import AdaptedMultiSelect from './AdaptedMultiSelect';
import TypeComponent from '../TypeComponent';

const CustomReferenceType: React.FC<ReferenceTypeVariantComponentProps> = props => {
  const { attrFieldName, attributeValues, attributeErrors, attributeTouched, readonly } = props;

  const formik = useFormikContext<EntityTemplateForm>();
  const intl = useIntl();
  const dispatch = useDispatch();

  const [referencedSideAttributeDisplayName, setReferencedSideAttributeDisplayName] = useState(
    attributeValues?.referenceConfiguration?.referencedSideAttributeDisplayName ?? '',
  );

  useEffect(() => {
    // TODO: FIX BUG OF FORMIK TOUCHED IN OTHER WAY
    dispatch(templateActions.onLoadAllTemplate());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const entityTypeError = attributeErrors?.referenceConfiguration?.entityType;
  const referencedSideAttributeDisplayNameError =
    attributeErrors?.referenceConfiguration?.referencedSideAttributeDisplayName;

  const entityTypeTouched = attributeTouched?.referenceConfiguration?.entityType;
  const referencedSideAttributeDisplayNameTouched =
    attributeTouched?.referenceConfiguration?.referencedSideAttributeDisplayName;

  const validTemplatesToReferenceError = attributeErrors?.referenceConfiguration?.validTemplatesToReference;
  const validTemplatesToReferenceTouched = attributeTouched?.referenceConfiguration?.validTemplatesToReference;

  const [entityType, setEntityType] = useState(attributeValues?.referenceConfiguration?.entityType ?? '');

  const [entityTemplate, setEntityTemplate] = useState(
    attributeValues?.referenceConfiguration?.validTemplatesToReference ?? [],
  );

  const entity = useSelector(entitySelectors.selectEntityByEntityName(entityType));
  const entityTypesMap = useSelector(entitySelectors.selectEntityTypes);

  const entityTemplateList: Array<GetTemplateResponse> = useSelector(
    templateSelectors.getAllTemplatesListByEntityType(entityType) ?? [],
  );

  const handleChangeEntityType = (event: any) => {
    const { value } = event.target;
    setEntityType(value);
    setEntityTemplate([]);
  };

  const handleChangeEntityTemplate = (value: string[] | null) => {
    setEntityTemplate(value);
  };

  const handleChangeReferencedSideAttributeDisplayName = (event: any) => {
    const { value } = event.target;
    setReferencedSideAttributeDisplayName(value);
  };

  useEffect(() => {
    formik.setFieldValue(`${attrFieldName}.referenceConfiguration.validTemplatesToReference`, entityTemplate);
  }, [entityTemplate]);

  useEffect(() => {
    formik.setFieldValue(`${attrFieldName}.referenceConfiguration.entityType`, entityType);
  }, [entityType]);

  useEffect(() => {
    formik.setFieldValue(
      `${attrFieldName}.referenceConfiguration.referencedSideAttributeName`,
      referencedSideAttributeDisplayName,
      false,
    );
    formik.setFieldValue(
      `${attrFieldName}.referenceConfiguration.referencedSideAttributeDisplayName`,
      referencedSideAttributeDisplayName,
    );
  }, [referencedSideAttributeDisplayName]);

  const getEntityTypeTitle = (currentEntityType: EntityType) => {
    const parentTitle = currentEntityType.parentTemplateType
      ? intl.formatMessage(getEntityTypeIntlDisplayName(currentEntityType.parentTemplateType))
      : null;
    const entityTypeTitle = intl.formatMessage(getEntityTypeIntlDisplayName(currentEntityType.name));

    return parentTitle ? `${parentTitle} - ${entityTypeTitle}` : entityTypeTitle;
  };

  return (
    <TypeComponent readonly={readonly}>
      <ReferenceTypeContainer>
        <InputsContainer>
          <SingleSelectStyled
            onChange={event => {
              handleChangeEntityType(event);
              formik.handleChange(event);
            }}
            selectList={Object.values(entityTypesMap)
              .map(currentEntityType => ({
                value: currentEntityType.name,
                title: getEntityTypeTitle(currentEntityType),
              }))
              .sort((a, b) => a.title.localeCompare(b.title))}
            placeholder={intl.formatMessage({
              id: 'template.modal.attribute.Reference-type.entity-type.placeholder',
              defaultMessage: 'Select Entity',
            })}
            inputProps={{
              variant: 'standard',
              error: Boolean(entityTypeError) && entityTypeTouched,
              helperText: entityTypeTouched ? entityTypeError : null,
            }}
            defaultValue={entityType}
            name={`${attrFieldName}.referenceConfiguration.entityType`}
          />
          <Separator>-</Separator>
          <AdaptedMultiSelect
            handleChangeEntityTemplate={handleChangeEntityTemplate}
            entityTemplateList={entityTemplateList}
            entityTemplate={entityTemplate}
            entity={entity}
            attrFieldName={attrFieldName}
            disabled={Boolean(!entityType) || !entity.addingTemplatesSupported}
            error={Boolean(validTemplatesToReferenceError) && validTemplatesToReferenceTouched}
            helperText={validTemplatesToReferenceTouched ? validTemplatesToReferenceError : null}
          />
          <Separator>-</Separator>
          <TransparentInput
            value={referencedSideAttributeDisplayName}
            onChange={event => {
              handleChangeReferencedSideAttributeDisplayName(event);
              if (!event.target.value || event.target.value.length === 1) {
                formik.handleChange(event);
              }
            }}
            placeholder={intl.formatMessage({
              id: 'template.modal.attribute.Reference-type.referenced-side.placeholder',
              defaultMessage: 'Referenced side attribute name',
            })}
            error={Boolean(referencedSideAttributeDisplayNameError) && referencedSideAttributeDisplayNameTouched}
            helperText={referencedSideAttributeDisplayNameTouched ? referencedSideAttributeDisplayNameError : null}
            readonly={readonly}
          />
        </InputsContainer>
      </ReferenceTypeContainer>
    </TypeComponent>
  );
};
export default CustomReferenceType;
