import React from 'react';
import intl from '@biotmed/i18n';
import messages from '../dictionary';
import Information from '../Information';

const addForceConfirmationProps = () => {
  return {
    submitLabel: intl.current.formatMessage(messages.TEMPLATE_ANALYTICS_DB_SYNC_ON_SUBMIT),
    title: intl.current.formatMessage(messages.ADD_TEMPLATE_ANALYTICS_DB_SYNC_ON_TITLE),
    information: () => <Information />,
  };
};

export default addForceConfirmationProps;
