import { convertTypographySettingsToCss, Input } from '@biotmed/base-components';
import styled from 'styled-components';

export const TransparentEditInput = styled(Input)`
  &.rootControl .MuiOutlinedInput-notchedOutline,
  .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  /* HOVER */

  &.rootControl .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  /* FOCUS */

  &.rootControl .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  .MuiOutlinedInput-adornedStart {
    padding-left: 0;
  }
  .MuiInputAdornment-positionEnd {
    margin-left: 0;
  }
`;

export const TransparentNonEditInput = styled.span`
  ${props => convertTypographySettingsToCss(props.theme.typography.body1)};
`;
