import { CommonAttributeResponse } from '@biotmed/settings-sdk/service/model';
import React from 'react';
import { TypesComponentsMap } from '../..';
import { TypeComponentProps } from '../interfaces';

interface LinkedTypeVariantProps extends LinkedTypeProps {}

interface LinkedTypeProps extends TypeComponentProps {
  targetAttribute: CommonAttributeResponse;
}

const LinkedType: React.FC<LinkedTypeProps> = props => {
  const { variant, attrFieldName, attributeValues, targetAttribute } = props;
  const variantMap: Record<typeof variant, any> = {
    custom: CustomLinkedType,
    builtIn: BuiltInLinkedType,
  };
  const TypeComponent = variantMap[variant];

  return (
    <TypeComponent
      attrFieldName={attrFieldName}
      attributeValues={attributeValues}
      targetAttribute={targetAttribute}
      variant={variant}
    />
  );
};

const CustomLinkedType: React.FC<LinkedTypeVariantProps> = props => {
  return <LinkedTypeAttribute {...props} />;
};

const BuiltInLinkedType: React.FC<LinkedTypeVariantProps> = props => {
  return <div />;
};

const LinkedTypeAttribute: React.FC<LinkedTypeVariantProps> = props => {
  const { targetAttribute, attrFieldName, readonly = true } = props;

  const TypeComponent = TypesComponentsMap[targetAttribute?.type?.valueOf() as keyof typeof TypesComponentsMap]
    ? TypesComponentsMap[targetAttribute?.type?.valueOf() as keyof typeof TypesComponentsMap]
    : null;

  return targetAttribute && TypeComponent ? (
    <TypeComponent {...props} attrFieldName={attrFieldName} attributeValues={targetAttribute} readonly={readonly} />
  ) : null;
};

export default LinkedType;
