import React, { useEffect } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { useFormikContext } from 'formik';
import { EntityTemplateForm } from 'src/routes/Templates/components/Template';
import { DefaultValueExtractedRendererProps } from '../../modules';
import { MultiSelectInput } from './styled/Renderers.styled';

export type MultiSelectExtraProps = {
  placeholder: string;
};

export type MultiSelectRendererProps = DefaultValueExtractedRendererProps<string[]> & MultiSelectExtraProps;

export const MultiSelectRenderer: React.FC<MultiSelectRendererProps> = props => {
  const { value, error, helperText, InputProps, name, placeholder, selectsProps } = props;
  const { selectableValues, selectList, shouldFilterSelectList } = selectsProps;
  const { endAdornment, ...inputProps } = InputProps;

  const formik = useFormikContext<EntityTemplateForm>();

  const handleMenuClose = () => {
    formik.setFieldTouched(name, true);
  };

  const handleChange = (event: SelectChangeEvent<unknown>, child: React.ReactNode) => {
    formik.setFieldValue(name, event.target.value);
  };

  // Filter after a change in the attribute selectable values
  useEffect(() => {
    if (shouldFilterSelectList) {
      const adjustedValue = value?.flatMap((valueItem: string) =>
        selectableValues.filter((item: { name: string; displayName: string }) => item.name === valueItem).length > 0
          ? valueItem
          : [],
      );
      formik.setFieldValue(name, adjustedValue || []);
    }
  }, [selectableValues]);

  return (
    <MultiSelectInput
      inputProps={{ error, helperText, ...inputProps }}
      value={value || []}
      onClose={handleMenuClose}
      onChange={handleChange}
      multiple
      selectList={selectList}
      placeholder={placeholder}
    />
  );
};
