import React from 'react';

import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import {
  Explained,
  InputFieldWrapper,
  NumberFieldWrapper,
  SelectFieldWrapper,
  SubmitButtonStatus,
} from '@biotmed/base-components';
import { AddModal } from 'src/components/Modals';
import { EditInputFieldWrapper, EditNumberFieldWrapper, EditSelectFieldWrapper } from 'src/components/EditFieldWrapper';
import FocusInputWithTooltipWrapper from 'src/components/InputWithTooltipV2/FocusInputWithTooltipWrapper';

export interface PluginModalProps {
  isOpen: boolean;
}

const PluginAddModal = (props: PluginModalProps) => {
  const { isOpen } = props;
  const intl = useIntl();

  const steps = [
    {
      tabName: intl.formatMessage({ id: 'plugin-add.modal.temp-tab.tab-name', defaultMessage: 'Temp' }),
      render: () => (
        <div>
          <FocusInputWithTooltipWrapper
            inputField={{ name: 'inputName' }}
            tooltipField={{ name: 'tooltipName' }}
            autoFocus
            placeholder="placeholder"
          />
        </div>
      ),
      validations: () =>
        Yup.object().shape({
          inputName: Yup.string().required(),
          tooltipName: Yup.string()
            .matches(/^[a-zA-Z0-9_]*$/g, 'wrong format')
            .matches(/^[a-zA-Z]/, 'must be a letter')
            .max(36, 'must be shorter than 36')
            .required('required'),
        }),
    },
    {
      tabName: intl.formatMessage({ id: 'plugin-add.modal.details-tab.tab-name', defaultMessage: 'Plugin Details' }),
      render: () => (
        <div>
          <EditInputFieldWrapper name="name" />
          <InputFieldWrapper name="name2" />
          <EditNumberFieldWrapper name="age" onChange={value => console.log('age:', value)} integer />
          <NumberFieldWrapper name="age2" onChange={age2 => console.log(age2)} integer />
          <EditSelectFieldWrapper
            name="select"
            onChange={(value: any) => console.log(value)}
            options={['option a', 'option b']}
          />
          <SelectFieldWrapper
            name="select2"
            onChange={(value: any) => console.log(value)}
            options={['option 1', 'option 2']}
          />
        </div>
      ),
      validations: () =>
        Yup.object().shape({
          firstName: Yup.string(),
          lastName: Yup.string(),
        }),
    },
    {
      tabName: intl.formatMessage({ id: 'plugin-add.modal.behavior-tab.tab-name', defaultMessage: 'Plugin Behavior' }),
      render: () => (
        <div>
          <EditInputFieldWrapper name="address" />
          <EditNumberFieldWrapper name="favoriteNumber" onChange={value => console.log('number:', value)} />
        </div>
      ),
      validations: () =>
        Yup.object().shape({
          age: Yup.number(),
          address: Yup.string(),
        }),
    },
  ];

  const initialValues = { firstName: '', lastName: '', age: '', address: '' };

  return (
    <AddModal
      isOpen={isOpen}
      handleSubmit={(values: any) => alert(`submitting form with values: ${JSON.stringify(values)}`)}
      initialValues={initialValues}
      handleClose={() => alert('closing form')}
      renderTitle={
        () => intl.formatMessage({ id: 'plugin-add.modal.header-title', defaultMessage: 'Create Plugin' }) // Create plugin? maybe new plugin?
      }
      renderTopContent={() => (
        <Explained
          text={intl.formatMessage({
            id: 'plugin-add.modal.explained',
            defaultMessage: 'Plugin Explained Text...',
          })}
          onLearnMore={undefined}
        />
      )}
      steps={steps}
      containerId="PLUGIN_ADD_MODAL"
      submitButtonStatus={SubmitButtonStatus.NORMAL} // .should be taken from redux state.can I remove?
    />
  );
};

export default PluginAddModal;
