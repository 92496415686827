import { convertTypographySettingsToCss, Loader } from '@biotmed/base-components';
import styled from 'styled-components';

export const ChildrenTypesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;
export const SpinBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Spin = styled(Loader)`
  color: ${props => props.theme.palette.primary.medium};
  width: 24px;
  height: 24px;
`;

export const StyledWarning = styled.div`
  color: ${props => props.theme.palette.error.medium};
`;

export const NoChildrenTypes = styled.div`
  display: flex;
  padding-top: 30px;
  padding-bottom: 10px;
  justify-content: center;
  text-align: center;
  ${props => convertTypographySettingsToCss(props.theme.typography.body1)};
`;
