import { defineMessages } from 'react-intl';

const messages = defineMessages({
  FORCE_ERROR_DEFAULT_TITLE: {
    id: 'templates.force-error.default.title',
    defaultMessage: 'Your changes may cause data interruption',
  },
  FORCE_ERROR_DEFAULT_SUBMIT: {
    id: 'templates.force-error.default.submit',
    defaultMessage: 'Continue with this action',
  },
  FORCE_ERROR_DEFAULT_CONSEQUENCES_ACTION: {
    id: 'templates.force-error.default.consequences-action',
    defaultMessage: 'Applying this change could make unknown data interruption',
  },
});

export default messages;
