import { Formik, FormikErrors, FormikTouched, FormikValues } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';

import {
  Button,
  ButtonProps,
  DrawerModal,
  FormikOnError,
  ModalFooter,
  ModalHeader,
  scrollToTop,
  SubmitButtonStatus,
} from '@biotmed/base-components';
import { EmbeddedError } from '@biotmed/system-notifications';

import { SubmitButton, Tabs } from './EditModal.styled';
import {
  ModalContainer,
  ModalContentContainer,
  ModalFooterContainer,
  ModalHeaderContainer,
  StyledModalContent,
  Title,
} from './Modals.styled';
import { ModalProps } from './types';

const EditModal = <V extends FormikValues>(props: ModalProps<V>) => {
  const {
    handleSubmit,
    initialValues,
    handleClose,
    renderTitle,
    renderTopContent,
    steps,
    containerId,
    isOpen,
    submitButtonStatus,
    modalWidthDiff,
    additionalFormProps,
    additionalValidationData,
    renderPageSubmitButton = ({ buttonElement }) => buttonElement,
  } = props;

  const intl = useIntl();
  const theme = useTheme();

  const [scrollToError, setScrollToError] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const modalContentRef = useRef<any>(null);

  const tabsData = useMemo(() => {
    return steps.map((step: any, index: number) => ({
      label: step.tabName,
      tabKey: step.tabName,
      value: index,
    }));
  }, [steps]);

  const resetModal = () => {
    setPageIndex(0);
  };

  useEffect(() => {
    if (!isOpen) {
      resetModal();
    }
  }, [isOpen]);

  const resetScrollToError = () => {
    setScrollToError(false);
  };

  const onChangeTab = (
    validateForm: (values?: V) => Promise<FormikErrors<V>>,
    setTouched: (touched: FormikTouched<V>, shouldValidate?: boolean | undefined) => void,
  ) => {
    return async (event: React.ChangeEvent<any>, newValue: string) => {
      await validateForm().then((e: any) => {
        setTouched(e);
        if (!Object.keys(e).length) {
          handlePageChange(Number(newValue));
        } else {
          setScrollToError(true);
        }
      });
    };
  };

  const handlePageChange = (newValue: number) => {
    setPageIndex(newValue);
    scrollToTop(modalContentRef);
  };

  return (
    <DrawerModal disableEnforceFocus open={isOpen} variant="halfScreen" widthDiff={modalWidthDiff}>
      <Formik
        initialValues={initialValues}
        validationSchema={steps?.[pageIndex]?.validations(additionalValidationData)}
        onSubmit={handleSubmit}
        validateOnBlur
        {...additionalFormProps}
      >
        {({ handleSubmit: formikHandleSubmit, validateForm, setTouched, errors, values, ...rest }) => {
          const pageSubmitButtonProps: ButtonProps = {
            onClick: () => formikHandleSubmit(),
            paddingHorizontal: '32px',
            paddingVertical: '15px',
            variant: 'contained',
            submit: true,
            submitButtonProps: {
              loadingLabel: intl.formatMessage({
                id: 'console.edit.modal.footer.save-button.loading',
                defaultMessage: 'Saving',
              }),
              successLabel: intl.formatMessage({
                id: 'console.edit.modal.footer.save-button.success',
                defaultMessage: 'Saved',
              }),
              status: submitButtonStatus,
            },
            children: intl.formatMessage({
              id: 'console.edit.modal.footer.save-button',
              defaultMessage: 'Save Changes',
            }),
          };

          return (
            <FormikOnError scrollToError={scrollToError} resetScrollToError={resetScrollToError}>
              <ModalContainer>
                <ModalHeader>
                  <ModalHeaderContainer>
                    {containerId && <EmbeddedError containerId={containerId} />}
                    <Title>{renderTitle()}</Title>
                  </ModalHeaderContainer>
                </ModalHeader>

                <StyledModalContent isDisabled={submitButtonStatus !== SubmitButtonStatus.NORMAL}>
                  {renderTopContent?.()}
                  <Tabs
                    variant="scrollable"
                    scrollButtons
                    value={pageIndex}
                    tabsData={tabsData}
                    selectedTabKey={pageIndex}
                    onChange={onChangeTab(validateForm, setTouched)}
                  />
                  <ModalContentContainer ref={modalContentRef}>{steps[pageIndex].render()}</ModalContentContainer>
                </StyledModalContent>

                <ModalFooter isDisabled={submitButtonStatus !== SubmitButtonStatus.NORMAL}>
                  <ModalFooterContainer>
                    <Button
                      paddingHorizontal="32px"
                      paddingVertical="15px"
                      textColor={theme.palette.grayScale.darker2}
                      size="large"
                      variant="text"
                      onClick={handleClose}
                    >
                      {intl.formatMessage({
                        id: 'console.add.modal.footer.cancel-button',
                        defaultMessage: 'Cancel',
                      })}
                    </Button>
                    {renderPageSubmitButton({
                      onClick: pageSubmitButtonProps.onClick,
                      buttonProps: pageSubmitButtonProps,
                      buttonElement: <SubmitButton {...pageSubmitButtonProps} />,
                      buttonPurpose: 'submit',
                      values,
                    })}
                  </ModalFooterContainer>
                </ModalFooter>
              </ModalContainer>
            </FormikOnError>
          );
        }}
      </Formik>
    </DrawerModal>
  );
};

export default EditModal;
