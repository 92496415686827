import React from 'react';
import { useFormikContext } from 'formik';
import { EntityTemplateForm } from 'src/routes/Templates/components/Template';
import { InputNumberProps } from '@biotmed/base-components';
import { NumericInput } from './styled/Renderers.styled';
import { DefaultValueExtractedRendererProps } from '../../modules';

export type NumericRendererExtraProps = DefaultValueExtractedRendererProps<string> & {
  placeholder: string;
  integer: boolean;
};

export const NumericRenderer: React.FC<NumericRendererExtraProps> = props => {
  const { InputProps, name, value, error, placeholder, helperText, integer } = props;
  const { endAdornment, ...inputProps } = InputProps;

  const formik = useFormikContext<EntityTemplateForm>();

  const handleChange = (newValue: number | null) => {
    formik.setFieldValue(name, newValue);
    formik.setFieldTouched(name);
  };

  const passedProps: InputNumberProps = {
    onChange: handleChange,
    name,
    value,
    error,
    placeholder,
    helperText,
    integer,
    InputProps: { endAdornment },
    ...inputProps,
  };

  return <NumericInput {...passedProps} />;
};

export default NumericRenderer;
