import { SelectAutoComplete } from '@biotmed/base-components';
import { getEntityTypeIntlDisplayName } from '@biotmed/data-components';
import { AvailableTemplateTabData } from '@biotmed/settings-sdk';
import React, { useMemo, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Container, InputWrapper, StyledInput } from './DraggableTab.styled';
import { GenericTabProps } from './interfaces';

export interface TemplateDraggableTabProps extends GenericTabProps {
  availableData: AvailableTemplateTabData[];
  isAvailableOptionInUse: (option: any) => boolean;
}

const TemplateDraggableTab: React.FC<TemplateDraggableTabProps> = props => {
  const { data = {}, onChange, availableData = [], isAvailableOptionInUse, errors, name } = props;

  const intl = useIntl();
  const [selectedEntity, setSelectedEntity] = useState<string | null>(null);
  const [selectedTemplateTab, setSelectedTemplateTab] = useState<AvailableTemplateTabData | null>(null);
  useEffect(() => {
    const fullTabData = availableData?.find(tab => tab?.id === data?.id);
    setSelectedTemplateTab(fullTabData || null);
    if (data?.id) {
      setSelectedEntity(fullTabData?.entityTypeName || null);
    }
  }, [data]);

  const handleChange = (value?: AvailableTemplateTabData | null) => {
    onChange({
      ...data,
      id: value?.id,
      title: value?.displayName,
    });
  };
  const updateEntity = (event: any, selectedOption: string | null) => {
    let updatedTemplate = null;

    if (
      selectedOption &&
      Object.keys(sortedAvailableData[selectedOption]).length === 1 &&
      !isAvailableOptionInUse(sortedAvailableData[selectedOption][0])
    ) {
      [updatedTemplate] = sortedAvailableData[selectedOption];
      setSelectedTemplateTab(updatedTemplate);
    }

    setSelectedEntity(selectedOption);
    handleChange(updatedTemplate);
  };

  const updateTemplate = (event: any, selectedOption: AvailableTemplateTabData | null) => {
    // In case template field is cleared save only the entity type

    setSelectedTemplateTab(selectedOption);
    handleChange(selectedOption);
  };

  const updateTitle = (event: any) => {
    onChange({
      ...data,
      title: event.target.value,
    });
  };

  // Object of arrays. Key: entityTypeName
  // Value: array of the available data that is related to the entity type. the array is already sorted by displayName.
  const sortedAvailableData = useMemo(() => {
    const sortedData = availableData.sort((data1, data2) =>
      data1.displayName && data2.displayName ? data1.displayName.localeCompare(data2.displayName) : 0,
    );
    return sortedData.reduce<Record<string, AvailableTemplateTabData[]>>((prevObj, currentData) => {
      let object = prevObj;
      if (currentData.entityTypeName) {
        if (!prevObj[currentData.entityTypeName]) {
          object = { ...prevObj, [currentData.entityTypeName]: [currentData] };
        } else {
          object[currentData.entityTypeName].push(currentData);
        }
      }
      return object;
    }, {});
  }, [availableData]);

  const formatEntityToIntl = (entity: string) => intl.formatMessage(getEntityTypeIntlDisplayName(entity));

  const sortedEntityOptions = useMemo(
    () =>
      Object.keys(sortedAvailableData).sort((name1, name2) =>
        name1 && name2 ? formatEntityToIntl(name1).localeCompare(formatEntityToIntl(name2)) : 0,
      ),
    [sortedAvailableData],
  ); // Gil - or maybe availableData?

  return (
    <Container>
      <InputWrapper>
        <SelectAutoComplete
          value={selectedEntity}
          options={sortedEntityOptions}
          getOptionLabel={formatEntityToIntl}
          inputProps={{
            name: `${name}.entity`,
            label: intl.formatMessage({
              id: 'TemplateDraggableTab.label.entity',
              defaultMessage: 'Entity',
            }),
            variant: 'standard',
          }}
          onChange={updateEntity}
        />
      </InputWrapper>
      <InputWrapper>
        <SelectAutoComplete
          value={selectedTemplateTab?.id ? selectedTemplateTab : null}
          options={
            selectedEntity && Object.keys(sortedAvailableData).length > 0 ? sortedAvailableData[selectedEntity] : []
          }
          isOptionEqualToValue={(option, value) => value && option?.id === value?.id}
          getOptionDisabled={option => isAvailableOptionInUse(option)}
          getOptionLabel={option => option?.displayName || ''}
          inputProps={{
            name: `${name}.id`,
            label: intl.formatMessage({
              id: 'TemplateDraggableTab.label.template',
              defaultMessage: 'Template',
            }),
            variant: 'standard',
            error: Boolean(errors?.id),
            helperText: errors?.id ?? ' ',
          }}
          onChange={updateTemplate}
        />
      </InputWrapper>
      <InputWrapper>
        <StyledInput
          label={intl.formatMessage({
            id: 'TemplateDraggableTab.label.title',
            defaultMessage: 'Title',
          })}
          variant="standard"
          error={Boolean(errors?.title)}
          helperText={errors?.title ?? ' '}
          value={data?.title || ''}
          onChange={updateTitle}
          name={`${name}.title`}
        />
      </InputWrapper>
    </Container>
  );
};

export default TemplateDraggableTab;
