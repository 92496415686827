import { createDeleteItemsSaga, DeleteItemsSlice, ReadItemsSlice } from '@biotmed/data-components';
import { LocaleTranslationsOverview, SearchRequestV2 } from '@biotmed/settings-sdk';
import { FormatterDictionary } from '@biotmed/system-notifications';
import { OPERATION_ID_SUFFIX } from '../../constants';
import { actions, selectors } from '../slice';

export const deleteSaga = createDeleteItemsSaga<
  LocaleTranslationsOverview,
  SearchRequestV2,
  ReadItemsSlice<LocaleTranslationsOverview, SearchRequestV2> & DeleteItemsSlice
>({
  slice: {
    actions,
    selectors,
  },
  operationIdSuffix: OPERATION_ID_SUFFIX,
  deleteApi: api => api.settings.translationApi.deleteLocaleTranslations,
  detailsFormatterDictionary: {} as FormatterDictionary,
  errorDictionary: {},
});
