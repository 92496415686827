import React, { useEffect, useRef, useState } from 'react';
import { InputPropsType } from '@biotmed/base-components';
import { ClickAwayListener, PopperProps } from '@mui/material';
import TooltipContent, { TooltipContentProps } from './TooltipContent';
import { Arrow, StyledPopper, StyledTooltipWrapper } from '../InputWithTooltip.styled';

export type TooltipProps<WithCharactersCounter extends boolean | undefined = undefined> =
  TooltipContentProps<WithCharactersCounter> & {
    tooltipPopperProps: Omit<PopperProps, 'onChange' | 'title'> & {
      open: boolean;
      onTogglePopper: (value: boolean | undefined) => void;
      anchorEl: null | HTMLElement;
      handleClickAway: (value: string) => void;
    };
    children: React.ReactNode;
  };

export const Tooltip = <WithCharactersCounter extends boolean | undefined = undefined>(
  props: React.PropsWithChildren<TooltipProps<WithCharactersCounter>>,
) => {
  const {
    tooltipPopperProps: { onTogglePopper, open, anchorEl, handleClickAway, className, ...restPopperProps },
    inputProps = {},
    children,
    ...tooltipProps
  } = props;
  const [firstValue, setFirstValue] = useState(inputProps.value ?? '');

  useEffect(() => {
    setFirstValue(inputProps.value ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const popperRef = useRef(null);
  const arrowRef = useRef(null);

  const onClickAwayHandler = () => {
    handleClickAway(firstValue as string);
  };

  return (
    <ClickAwayListener onClickAway={onClickAwayHandler}>
      <StyledTooltipWrapper ref={popperRef}>
        {children}
        <StyledPopper
          placement="top"
          {...restPopperProps}
          anchorEl={anchorEl}
          open={open}
          keepMounted
          modifiers={[
            {
              name: 'flip',
              enabled: false,
            },
            {
              name: 'preventOverflow',
              enabled: true,
              options: {
                boundariesElement: 'scrollParent',
              },
            },
            {
              name: 'arrow',
              enabled: true,
              options: {
                element: arrowRef.current,
              },
            },
            { name: 'offset', options: { offset: [0, 8] } },
          ]}
        >
          <TooltipContent {...tooltipProps} inputProps={inputProps} />
          <Arrow className="arrow" ref={arrowRef} />
        </StyledPopper>
      </StyledTooltipWrapper>
    </ClickAwayListener>
  );
};

export default Tooltip;
