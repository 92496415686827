import { defineMessages } from 'react-intl';

export default defineMessages({
  // 400
  REQUEST_VALIDATION_FAILED: {
    id: 'plugin.update.error.request_validation_failed',
    defaultMessage: 'Request validation failed with details: {details}',
  },
  // 404
  PLUGIN_NOT_FOUND: {
    id: 'plugin.update.error.request_validation_failed',
    defaultMessage: 'Plugin {pluginName} was not found',
  },
});
