import { Checkbox } from '@biotmed/base-components';
import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { EntityTemplateForm } from 'src/routes/Templates/components/Template';
import { DefaultValueExtractedRendererProps } from '../../modules';

export type BooleanRendererExtraProps = { placeholder: string };

export const BooleanRenderer: React.FC<DefaultValueExtractedRendererProps<boolean> & BooleanRendererExtraProps> =
  props => {
    const { value, name, placeholder, InputProps } = props;
    const { disabled } = InputProps;

    const formik = useFormikContext<EntityTemplateForm>();

    useEffect(() => {
      if (value === null || value === undefined) {
        formik.setFieldValue(name, false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      formik.setFieldValue(name, checked);
    };

    return (
      <>
        {placeholder}
        <Checkbox onChange={handleChange} value={value || false} size={20} checked={value} disabled={disabled} />
      </>
    );
  };
