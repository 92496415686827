import styled from 'styled-components';

export const SelectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const NameContainer = styled.div`
  align-items: center;
  display: flex;
`;
