import React from 'react';
import intl from '@biotmed/i18n';
import { AttributeTypesEnum } from 'src/utils/attributeTypeEnum';
import { DefaultValueComponentProps } from 'src/routes/Templates/components/Attribute';
import { SelectableAttributeValue } from '@biotmed/settings-sdk';
import {
  Label,
  ParagraphRenderer,
  LabelExtraProps,
  MultiSelectRenderer,
  BooleanRenderer,
  NumericRenderer,
  MultiSelectExtraProps,
  DateRenderer,
  DateTimeRenderer,
  NumericRendererExtraProps,
  BooleanRendererExtraProps,
  DateTimeRendererProps,
  DateRendererProps,
} from '../Components';
import { SingleSelectExtraProps, SingleSelectRenderer } from '../Components/Renderers/SingleSelect';
import DefaultValueWrapper from '../Components/WrapperComponents/DefaultValueWrapper';
import { ParagraphRendererExtraProps } from '../Components/Renderers/ParagraphRenderer';
import { ExtraPropsFormFieldValues, extractFunction } from './extractFunctions';
import { getInputsSpreader, SubFieldMapper } from '../Components/InputsSpreader';
import {
  SelectAutoCompleteRenderer,
  SelectAutoCompleteRendererExtraProps,
} from '../Components/Renderers/SelectAutoCompleteRenderer';
import { selectors } from '../../../../redux/data/attribute';
import { defaultValuePlaceholdersDictionary } from './dictionaries';
import Phone, { PhoneExtraProps } from '../Components/Renderers/Phone';
import Country from '../Components/Renderers/Country';

type DefaultValueComponentsMap<T> = Partial<Record<AttributeTypesEnum, T>>;

export type DefaultValueSubFieldMapper<UNIQUE_RENDERER_PROPS, V> = SubFieldMapper<
  ExtraPropsFormFieldValues,
  UNIQUE_RENDERER_PROPS,
  V
>;

const labelSubFieldMapper: DefaultValueSubFieldMapper<LabelExtraProps, string> = {
  render: ({ renderProps }) => (
    <Label {...renderProps} placeholder={intl.current.formatMessage(defaultValuePlaceholdersDictionary.LABEL)} />
  ),
};

const phoneSubFieldMapper: DefaultValueSubFieldMapper<PhoneExtraProps, string> = {
  render: ({ renderProps }) => (
    <Phone {...renderProps} placeholder={intl.current.formatMessage(defaultValuePlaceholdersDictionary.PHONE)} />
  ),
};

const paragraphSubFieldMapper: DefaultValueSubFieldMapper<ParagraphRendererExtraProps, string> = {
  render: ({ renderProps }) => (
    <ParagraphRenderer
      {...renderProps}
      placeholder={intl.current.formatMessage(defaultValuePlaceholdersDictionary.PARAGRAPH)}
    />
  ),
};

const nameSubFieldMapper: DefaultValueSubFieldMapper<LabelExtraProps, string> = [
  {
    subFieldName: 'firstName',
    render: ({ renderProps }) => (
      <Label {...renderProps} placeholder={intl.current.formatMessage(defaultValuePlaceholdersDictionary.NAME_FIRST)} />
    ),
  },
  {
    subFieldName: 'lastName',
    render: ({ renderProps }) => (
      <Label {...renderProps} placeholder={intl.current.formatMessage(defaultValuePlaceholdersDictionary.NAME_LAST)} />
    ),
  },
];

const addressSubFieldMapper: DefaultValueSubFieldMapper<LabelExtraProps, string> = [
  {
    subFieldName: 'countryCode',
    render: ({ renderProps }) => (
      <Country
        {...renderProps}
        placeholder={intl.current.formatMessage(defaultValuePlaceholdersDictionary.ADDRESS_COUNTRY_CODE)}
      />
    ),
  },
  {
    subFieldName: 'state',
    render: ({ renderProps }) => (
      <Label
        {...renderProps}
        placeholder={intl.current.formatMessage(defaultValuePlaceholdersDictionary.ADDRESS_STATE)}
      />
    ),
  },
  {
    subFieldName: 'city',
    render: ({ renderProps }) => (
      <Label
        {...renderProps}
        placeholder={intl.current.formatMessage(defaultValuePlaceholdersDictionary.ADDRESS_CITY)}
      />
    ),
  },
  {
    subFieldName: 'zipCode',
    render: ({ renderProps }) => (
      <Label
        {...renderProps}
        placeholder={intl.current.formatMessage(defaultValuePlaceholdersDictionary.ADDRESS_ZIPCODE)}
      />
    ),
  },
  {
    subFieldName: 'address1',
    render: ({ renderProps }) => (
      <Label
        {...renderProps}
        placeholder={intl.current.formatMessage(defaultValuePlaceholdersDictionary.ADDRESS_STREET)}
      />
    ),
  },
  {
    subFieldName: 'address2',
    render: ({ renderProps }) => (
      <Label
        {...renderProps}
        placeholder={intl.current.formatMessage(defaultValuePlaceholdersDictionary.ADDRESS_ADDITIONAL_INFO)}
      />
    ),
  },
];

const singleSelectSubFieldMapper: DefaultValueSubFieldMapper<SingleSelectExtraProps, string> = {
  render: ({ renderProps }) => {
    return (
      <SingleSelectRenderer
        {...renderProps}
        placeholder={intl.current.formatMessage(defaultValuePlaceholdersDictionary.SINGLE_SELECT)}
      />
    );
  },
};

const multiSelectSubFieldMapper: DefaultValueSubFieldMapper<MultiSelectExtraProps, string[]> = {
  render: ({ renderProps }) => {
    return (
      <MultiSelectRenderer
        {...renderProps}
        placeholder={intl.current.formatMessage(defaultValuePlaceholdersDictionary.MULTI_SELECT)}
      />
    );
  },
};

const booleanSubFieldMapper: DefaultValueSubFieldMapper<BooleanRendererExtraProps, boolean> = {
  render: ({ renderProps }) => (
    <BooleanRenderer
      {...renderProps}
      placeholder={intl.current.formatMessage(defaultValuePlaceholdersDictionary.BOOLEAN)}
    />
  ),
};

const integerSubFieldMapper: DefaultValueSubFieldMapper<NumericRendererExtraProps, string> = {
  render: ({ renderProps }) => {
    return (
      <NumericRenderer
        {...renderProps}
        placeholder={intl.current.formatMessage(defaultValuePlaceholdersDictionary.INTEGER)}
        integer
      />
    );
  },
};

const decimalSubFieldMapper: DefaultValueSubFieldMapper<NumericRendererExtraProps, string> = {
  render: ({ renderProps }) => {
    return (
      <NumericRenderer
        {...renderProps}
        placeholder={intl.current.formatMessage(defaultValuePlaceholdersDictionary.DECIMAL)}
        integer={false}
      />
    );
  },
};

const dateSubFieldMapper: DefaultValueSubFieldMapper<DateRendererProps, string> = {
  render: ({ renderProps }) => {
    const { value, ...restRenderProps } = renderProps;
    return (
      <DateRenderer
        {...restRenderProps}
        value={value ? new Date(value) : null}
        placeholder={intl.current.formatMessage(defaultValuePlaceholdersDictionary.DATE)}
      />
    );
  },
};

const dateTimeSubFieldMapper: DefaultValueSubFieldMapper<DateTimeRendererProps, string> = {
  render: ({ renderProps }) => {
    const { value, ...restRenderProps } = renderProps;
    return (
      <DateTimeRenderer
        {...restRenderProps}
        value={value ? new Date(value) : null}
        placeholder={intl.current.formatMessage(defaultValuePlaceholdersDictionary.DATETIME)}
      />
    );
  },
};

const localeSubFieldMapper: DefaultValueSubFieldMapper<
  SelectAutoCompleteRendererExtraProps<SelectableAttributeValue>,
  string
> = {
  render: ({ renderProps }) => {
    return (
      <SelectAutoCompleteRenderer<SelectableAttributeValue>
        {...renderProps}
        selector={selectors.selectLocales}
        dataMapper={option => ({ label: option?.displayName || '', value: option?.name || '' })}
        placeholder={intl.current.formatMessage(defaultValuePlaceholdersDictionary.LOCALE)}
      />
    );
  },
};

const timezoneSubFieldMapper: DefaultValueSubFieldMapper<
  SelectAutoCompleteRendererExtraProps<SelectableAttributeValue>,
  string
> = {
  render: ({ renderProps }) => {
    return (
      <SelectAutoCompleteRenderer<SelectableAttributeValue>
        {...renderProps}
        selector={selectors.selectTimezones}
        dataMapper={option => ({ label: option?.displayName || '', value: option?.name || '' })}
        placeholder={intl.current.formatMessage(defaultValuePlaceholdersDictionary.TIMEZONE)}
      />
    );
  },
};

const getDefaultValueInputsSpreader =
  (subFieldMapper: DefaultValueSubFieldMapper<any, any>) => (fieldValues: DefaultValueComponentProps) =>
    getInputsSpreader(subFieldMapper, DefaultValueWrapper, extractFunction, fieldValues);

export const defaultValueComponentsMap: DefaultValueComponentsMap<React.FC<any>> = {
  [AttributeTypesEnum.Label]: getDefaultValueInputsSpreader(labelSubFieldMapper),
  [AttributeTypesEnum.Name]: getDefaultValueInputsSpreader(nameSubFieldMapper),
  [AttributeTypesEnum.Address]: getDefaultValueInputsSpreader(addressSubFieldMapper),
  [AttributeTypesEnum.SingleSelect]: getDefaultValueInputsSpreader(singleSelectSubFieldMapper),
  [AttributeTypesEnum.MultiSelect]: getDefaultValueInputsSpreader(multiSelectSubFieldMapper),
  [AttributeTypesEnum.Boolean]: getDefaultValueInputsSpreader(booleanSubFieldMapper),
  [AttributeTypesEnum.Integer]: getDefaultValueInputsSpreader(integerSubFieldMapper),
  [AttributeTypesEnum.Decimal]: getDefaultValueInputsSpreader(decimalSubFieldMapper),
  [AttributeTypesEnum.Phone]: getDefaultValueInputsSpreader(phoneSubFieldMapper),
  [AttributeTypesEnum.Email]: getDefaultValueInputsSpreader(labelSubFieldMapper),
  [AttributeTypesEnum.Paragraph]: getDefaultValueInputsSpreader(paragraphSubFieldMapper),
  [AttributeTypesEnum.Date]: getDefaultValueInputsSpreader(dateSubFieldMapper),
  [AttributeTypesEnum.DateTime]: getDefaultValueInputsSpreader(dateTimeSubFieldMapper),
  [AttributeTypesEnum.Locale]: getDefaultValueInputsSpreader(localeSubFieldMapper),
  [AttributeTypesEnum.Timezone]: getDefaultValueInputsSpreader(timezoneSubFieldMapper),
};

export const getDefaultValueRenderComponent = (type: AttributeTypesEnum) => {
  return defaultValueComponentsMap[type] ? defaultValueComponentsMap[type] : null;
};
