import styled from 'styled-components';

export const DefaultWrapper = styled.div`
  padding: 18px 6px 0 18px;
  display: flex;
  gap: 10px;
`;

export const ValueWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  width: 700px;
  align-items: center;
  font-size: ${props => props.theme.typography.body1['font-size']};
  padding: 2px 0 0;
`;

export const Label = styled.div`
  font-size: ${props => props.theme.typography.body1['font-size']};
  min-width: fit-content;
  display: flex;
  flex-direction: row;
  padding: 4px 0;
`;

export const InfoTooltip = styled.div`
  padding: 2px 8px 0 0;
`;

export const EditIconWrapper = styled.div`
  padding: 8px 2px 0 8px;
`;
