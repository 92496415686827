import { getSdkApi } from '@biotmed/sdk-api-provider';
import { AxiosResponse } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';

function* loadOrganizations(action: ReturnType<typeof actions.loadOrganizations>) {
  const response: AxiosResponse<any> = yield call(getSdkApi().organization.organizationApi.searchOrganizations, {});
  yield put(actions.onLoadOrganizationSuccess(response.data.data));
}
export default function* watchEntityActions() {
  yield all([takeLatest(actions.loadOrganizations, loadOrganizations)]);
}
