import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { selectors as templateSelectors, actions as templateActions } from 'src/redux/data/template';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import TransparentInput from 'src/components/TransparentInput';
import { GetTemplateResponse } from '@biotmed/settings-sdk';
import { EditedFieldEnum } from 'src/redux/data/template/modules/constants';
import { getEntityTypeIntlDisplayName } from '@biotmed/data-components';
import { entityTemplateFieldName } from 'src/routes/Templates/modules/constant';
import { EntityTemplateForm } from 'src/routes/Templates/components/Template';
import { selectors as entitySelectors } from 'src/redux/data/entity';

import {
  FieldsContainer,
  FieldsRow,
  FieldTitle,
  FieldValue,
  ReferenceTypeContainer,
  Separator,
  ParentTemplateName,
} from './ReferenceType.styled';
import { ReferenceTypeVariantComponentProps } from './ReferenceType';
import AdaptedMultiSelect from './AdaptedMultiSelect';
import TypeComponent from '../TypeComponent';

const BuiltInReferenceType: React.FC<ReferenceTypeVariantComponentProps> = props => {
  const { attrFieldName, attributeValues, attributeErrors, attributeTouched, attributeInitialValues, readonly } = props;

  const attributeId = attributeValues?.basePath
    ? `${attributeValues.basePath}.${attributeValues.name}`
    : attributeValues.name;

  const referencedSideAttributeDisplayNameError =
    attributeErrors?.referenceConfiguration?.referencedSideAttributeDisplayName;
  const referencedSideAttributeDisplayNameTouched =
    attributeTouched?.referenceConfiguration?.referencedSideAttributeDisplayName;

  const validTemplatesToReferenceError = attributeErrors?.referenceConfiguration?.validTemplatesToReference;
  const validTemplatesToReferenceTouched = attributeTouched?.referenceConfiguration?.validTemplatesToReference;

  const formik = useFormikContext<EntityTemplateForm>();
  const intl = useIntl();
  const dispatch = useDispatch();
  const iRsanEdited = formik.status?.attributesEditedFields?.builtInAttributes?.[attributeId]?.[EditedFieldEnum.RSAN];

  useEffect(() => {
    dispatch(templateActions.onLoadAllTemplate());
    if (!formik.values?.[entityTemplateFieldName]?.id && !iRsanEdited) {
      const rsanValue = `${formik.values?.[entityTemplateFieldName]?.displayName} ${attributeInitialValues.referenceConfiguration.referencedSideAttributeDisplayName}`;
      setReferencedSideAttributeDisplayName(rsanValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [referencedSideAttributeDisplayName, setReferencedSideAttributeDisplayName] = useState(
    attributeValues.referenceConfiguration.referencedSideAttributeDisplayName ?? '',
  );
  const [entityTemplate, setEntityTemplate] = useState(
    attributeValues.referenceConfiguration.validTemplatesToReference ?? [],
  );

  const { entityType } = attributeValues.referenceConfiguration;

  const entity = useSelector(entitySelectors.selectEntityByEntityName(entityType));

  const entityTemplateList: Array<GetTemplateResponse> = useSelector(
    templateSelectors.getAllTemplatesListByEntityType(entityType) ?? [],
  );

  const handleEditField = (id: string, fieldName: string) => {
    const { status } = formik;
    formik.setStatus({
      ...status,
      attributesEditedFields: {
        ...status?.attributesEditedFields,
        builtInAttributes: {
          ...status?.attributesEditedFields?.builtInAttributes,
          [id]: { ...(status?.attributesEditedFields?.builtInAttributes?.[id] ?? {}), [fieldName]: true },
        },
      },
    });
  };

  const handleChangeEntityTemplate = (value: string[] | null) => {
    setEntityTemplate(value);
  };

  const handleChangeReferencedSideAttributeDisplayName = (event: any) => {
    if (!iRsanEdited) handleEditField(attributeId, EditedFieldEnum.RSAN);
    const { value } = event.target;
    setReferencedSideAttributeDisplayName(value);
  };

  useEffect(() => {
    formik.setFieldValue(`${attrFieldName}.referenceConfiguration.validTemplatesToReference`, entityTemplate);
  }, [entityTemplate]);

  useEffect(() => {
    formik.setFieldValue(
      `${attrFieldName}.referenceConfiguration.referencedSideAttributeName`,
      referencedSideAttributeDisplayName,
      false,
    );
    formik.setFieldValue(
      `${attrFieldName}.referenceConfiguration.referencedSideAttributeDisplayName`,
      referencedSideAttributeDisplayName,
    );
  }, [referencedSideAttributeDisplayName]);

  return (
    <TypeComponent readonly={readonly}>
      <ReferenceTypeContainer>
        <FieldsContainer>
          <FieldsRow>
            <FieldTitle>
              {intl.formatMessage({
                id: 'template.modal.attribute.Reference-type.entityField.label',
                defaultMessage: 'Entity',
              })}
            </FieldTitle>
            <div>-</div>
            <FieldValue>{intl.formatMessage(getEntityTypeIntlDisplayName(entityType))}</FieldValue>
          </FieldsRow>
          <FieldsRow overflow="hidden">
            <FieldTitle>
              {intl.formatMessage({
                id: 'template.modal.attribute.Reference-type.templateField.label',
                defaultMessage: 'Template',
              })}
            </FieldTitle>
            <div>-</div>
            {attributeValues.parentTemplateName ? (
              <ParentTemplateName title={attributeValues.parentTemplateName}>
                {attributeValues.parentTemplateName}
              </ParentTemplateName>
            ) : (
              <AdaptedMultiSelect
                handleChangeEntityTemplate={handleChangeEntityTemplate}
                entityTemplateList={entityTemplateList}
                entityTemplate={entityTemplate}
                entity={entity}
                attrFieldName={attrFieldName}
                disabled={!entity.addingTemplatesSupported}
                error={Boolean(validTemplatesToReferenceError) && validTemplatesToReferenceTouched}
                helperText={validTemplatesToReferenceTouched ? validTemplatesToReferenceError : null}
              />
            )}
          </FieldsRow>
          <FieldsRow>
            <Separator>-</Separator>
            <TransparentInput
              value={referencedSideAttributeDisplayName}
              onChange={event => {
                handleChangeReferencedSideAttributeDisplayName(event);
                if (!event.target.value || event.target.value.length === 1) {
                  formik.handleChange(event);
                }
              }}
              onBlur={formik.handleBlur}
              placeholder={intl.formatMessage({
                id: 'template.modal.attribute.Reference-type.referenced-side.placeholder',
                defaultMessage: 'Referenced side attribute name',
              })}
              error={Boolean(referencedSideAttributeDisplayNameError) && referencedSideAttributeDisplayNameTouched}
              helperText={referencedSideAttributeDisplayNameTouched ? referencedSideAttributeDisplayNameError : null}
              readonly={readonly}
            />
          </FieldsRow>
        </FieldsContainer>
      </ReferenceTypeContainer>
    </TypeComponent>
  );
};
export default BuiltInReferenceType;
