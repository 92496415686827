import React, { useEffect } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { useFormikContext } from 'formik';
import { EntityTemplateForm } from 'src/routes/Templates/components/Template';
import { DefaultValueExtractedRendererProps } from '../../modules';
import { SingleSelectInput } from './styled/Renderers.styled';

export type SingleSelectExtraProps = {
  placeholder: string;
};

export type SingleSelectRendererProps = DefaultValueExtractedRendererProps<string> & SingleSelectExtraProps;

export const SingleSelectRenderer: React.FC<SingleSelectRendererProps> = props => {
  const { error, helperText, InputProps, value, name, placeholder, selectsProps } = props;
  const { endAdornment, ...inputProps } = InputProps;
  const { selectableValues, selectList, shouldFilterSelectList } = selectsProps;

  const formik = useFormikContext<EntityTemplateForm>();

  const handleMenuClose = () => {
    formik.setFieldTouched(name, true);
  };

  const handleChange = (event: SelectChangeEvent<unknown>, child: React.ReactNode) => {
    formik.setFieldValue(name, event.target.value);
  };

  // Filter after a change in the attribute selectable values
  useEffect(() => {
    if (shouldFilterSelectList)
      formik.setFieldValue(name, selectList.filter(item => item.value === value).length > 0 ? value : null);
  }, [selectableValues]);

  return (
    <SingleSelectInput
      inputProps={{ error, helperText, ...inputProps }}
      onClose={handleMenuClose}
      onChange={handleChange}
      value={value || ''}
      selectList={selectList}
      placeholder={placeholder}
    />
  );
};
