import { LocalesResponse } from '@biotmed/settings-sdk';
import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/reducer';
import DATA_STATE_KEY from '../../constants';

export const STATE_KEY = 'locale';

interface LocaleState {
  systemLocale?: string;
  defaultLocale?: string;
}

// eslint-disable-next-line no-unused-vars
export const getInitialState = (state?: any): LocaleState => ({
  systemLocale: state?.systemLocale,
  defaultLocale: state?.defaultLocale,
});

/* eslint-disable no-param-reassign */
const slice = createSlice({
  name: STATE_KEY,
  initialState: getInitialState(),
  reducers: {
    fetchLocalesSuccess: (state, action: PayloadAction<LocalesResponse>) => {
      state.systemLocale = action.payload.systemLocaleCode;
      state.defaultLocale = action.payload.defaultLocaleCode;
    },
  },
});
/* eslint-enable no-param-reassign */

const extraActions = {};

export const actions = { ...slice.actions, ...extraActions };

const getState = (state: RootState) => state[DATA_STATE_KEY][STATE_KEY] || getInitialState();
export const selectors = {
  getSystemLocale: createSelector(getState, state => state.systemLocale),
  getDefaultLocale: createSelector(getState, state => state.defaultLocale),
};

const { reducer } = slice;
export default reducer;
