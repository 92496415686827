import styled from 'styled-components';
import { Accordion as AccordionMui } from '@mui/material';
import { InputNumber, convertTypographySettingsToCss, Button } from '@biotmed/base-components';
import AddFieldButton from 'src/components/AddFieldButton';

export const Accordion = styled(AccordionMui)`
  &.MuiAccordion-root {
    box-shadow: none;
    background-color: transparent;
  }
  .MuiAccordionSummary-root {
    margin: 0px 16px;
    display: flex;
    gap: 18px;
    flex-direction: row-reverse;
    padding: 0px;
    &.Mui-focusVisible {
      background-color: inherit;
    }
    &.Mui-expanded {
      border-bottom: solid 1px ${props => props.theme.palette.grayScale.medium};
    }
  }
  .MuiAccordionSummary-content {
    &.Mui-expanded {
      padding: 10px 0;
      margin: 0;
    }
    gap: 15px;
    display: flex;
    width: 100%;
    align-items: center;
    margin: 0;
    padding: 10px 0;
  }
  .MuiAccordionDetails-root {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 11px;
    align-items: start;
    .delete {
      align-self: flex-end;
    }
    .card {
      width: 100%;
    }
  }
`;
export const NameSectionContainer = styled.div`
  flex: 1;
`;
export const MeasureLabel = styled.div`
  color: ${props => props.theme.palette.grayScale.darker};
  ${props => convertTypographySettingsToCss(props.theme.typography.body2)};
`;

export const SecInput = styled(InputNumber)`
  max-width: 120px;
`;
export const Hr = styled.hr`
  && {
    margin: 0;
    height: 30px;
    width: 1px;
    border-left: ${props => props.theme.palette.grayScale.medium};
    border-top: unset;
  }
`;
export const AddFieldButtonHalf = styled(AddFieldButton)`
  && {
    width: 50%;
  }
`;

export const ChildrenTypeButtonStyled = styled(Button)`
  && {
    width: 100%;
    box-shadow: 0 1px 1px 0 rgba(66, 67, 69, 0.17);
    padding: 15px;
    height: 77px;
    background-color: ${props => props.theme.palette.grayScale.lightest};
    text-align: start;
    &.MuiButton-outlined {
      border-color: ${props => props.theme.palette.grayScale.medium};
      &:hover:not(:disabled),
      &:focus {
        border-color: ${props => props.theme.palette.grayScale.medium};
      }
    }
  }
`;

export const ChildrenTypeText = styled.div`
  flex: 1;
`;

export const DeleteButtonContainer = styled.div`
  cursor: default;
`;
