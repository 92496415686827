import React, { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';
import { StyledConfirmationTooltip } from './UploadTranslationsModal.styled';

export interface ErrorsConfirmationTooltipProps {
  isTooltipOpen: boolean;
  onOkClick: () => void;
  onCancelClick: () => void;
  children: ReactElement;
}

export const ErrorsConfirmationTooltip: React.FC<React.PropsWithChildren<ErrorsConfirmationTooltipProps>> = props => {
  const { isTooltipOpen, onOkClick, onCancelClick, children } = props;
  const theme = useTheme();
  const intl = useIntl();

  return (
    <StyledConfirmationTooltip
      style={{ width: 'fit-contnent' }}
      title={intl.formatMessage({
        id: 'upload-translations.modal.errors-confirmation-tooltip.title',
        defaultMessage: 'Are you sure you want to save the file with these warnings?',
      })}
      open={isTooltipOpen}
      okText={intl.formatMessage({
        id: 'upload-translations.modal.errors-confirmation-tooltip.button.ok',
        defaultMessage: 'Ignore and Save',
      })}
      onOkClick={onOkClick}
      cancelText={intl.formatMessage({
        id: 'upload-translations.modal.errors-confirmation-tooltip.button.cancel',
        defaultMessage: 'Cancel',
      })}
      onCancelClick={onCancelClick}
      showArrow
      placement="top-start"
      withCloseButton={false}
      okButtonStyle={{
        backgroundColor: theme.palette.secondary.medium,
        textColor: theme.palette.grayScale.lightest,
        typography: theme.typography.button.regular,
      }}
      cancelButtonStyle={{ typography: theme.typography.button.regular }}
    >
      {children}
    </StyledConfirmationTooltip>
  );
};

export default ErrorsConfirmationTooltip;
