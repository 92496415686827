import { SelectAutoComplete } from '@biotmed/base-components';
import { AvailableReverseReferenceTabData } from '@biotmed/settings-sdk';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Container, InputWrapper } from './DraggableTab.styled';
import { GenericTabProps } from './interfaces';

export interface ReverseReferenceDraggableTabProps extends GenericTabProps {
  availableData: AvailableReverseReferenceTabData[];
  isAvailableOptionInUse: (option: any) => boolean;
}

const ReverseReferenceDraggableTab: React.FC<ReverseReferenceDraggableTabProps> = props => {
  const { data, onChange, availableData = [], isAvailableOptionInUse, errors, name } = props;

  const intl = useIntl();

  const selectedAttribute = useMemo(
    () => availableData.find(tab => tab?.basePath === data?.basePath && tab?.name === data?.name) || null,
    [data],
  );

  const sortedData = useMemo(
    () =>
      availableData.sort((a, b) =>
        a?.displayName && b?.displayName ? a?.displayName.localeCompare(b?.displayName) : 0,
      ),
    [availableData],
  );

  const updateSelectedAttribute = (event: any, selectedOption: AvailableReverseReferenceTabData | null) => {
    onChange({
      name: selectedOption?.name,
      basePath: selectedOption?.basePath,
    });
  };

  return (
    <Container>
      <InputWrapper>
        <SelectAutoComplete
          value={selectedAttribute}
          options={sortedData}
          isOptionEqualToValue={(option, value) => option?.basePath === value?.basePath && option?.name === value?.name}
          getOptionDisabled={option => isAvailableOptionInUse(option)}
          getOptionLabel={option => option?.displayName || ''}
          inputProps={{
            label: intl.formatMessage({
              id: 'ReverseReferenceDraggableTab.label.attribute',
              defaultMessage: 'Attribute',
            }),
            variant: 'standard',
            error: Boolean(errors?.name),
            helperText: errors?.name ?? ' ',
            // this field for some reason has two fields in formik, basePath and name.
            // we are using here basePath instead of name because FormikOnError scrolls to the first
            // element that has an error, if we'll use name instead of basePath it will still try to
            // find the basePath element and won't find it
            name: `${name}.basePath`,
          }}
          onChange={updateSelectedAttribute}
        />
      </InputWrapper>
    </Container>
  );
};

export default ReverseReferenceDraggableTab;
