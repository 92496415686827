import React from 'react';
import { useFormikContext } from 'formik';
import { EntityTemplateForm } from 'src/routes/Templates/components/Template';
import { InputPropsType, PhoneInput, Value } from '@biotmed/base-components';
import { DefaultValueExtractedRendererProps } from '../../modules';

export type PhoneExtraProps = DefaultValueExtractedRendererProps<string> & { placeholder: string };

export const Phone: React.FC<PhoneExtraProps> = props => {
  const { placeholder, error, value, name, helperText, InputProps } = props;
  const formik = useFormikContext<EntityTemplateForm>();

  const onChange = (number: Value | undefined) => {
    formik.setFieldTouched(name, true, false);
    formik.setFieldValue(name, number, true);
  };

  const inputProps: InputPropsType = {
    ...InputProps,
    error,
    helperText,
  };

  return (
    <PhoneInput value={value ?? ''} onChange={onChange} name={name} inputProps={inputProps} placeholder={placeholder} />
  );
};

export default Phone;
