import React, { useEffect, useState } from 'react';
import { Button, FormikOnError, ModalContent, ModalHeader, SubmitButtonStatus } from '@biotmed/base-components';
import { useIntl } from 'react-intl';
import { StyledModalFooter } from 'src/routes/EnvironmentConfiguration/components/EnvironmentConfiguration.styled';
import { useTheme } from 'styled-components';
import { Formik } from 'formik';
import * as yup from 'yup';
import { EmbeddedError } from '@biotmed/system-notifications';
import { validationMessages } from '@biotmed/data-components';
import { ContentDescription, ContentTitle, StyledUploadTranslationsModal } from './UploadTranslationsModal.styled';
import { UPLOAD_TRANSLATIONS_MODAL_CONTAINER_ID } from '../constants';
import ChooseTranslationsFile from './ChooseTranslationsFile';
import ErrorsConfirmationTooltip from './ErrorsConfirmationTooltip';

export interface UploadTranslationsModalProps {
  isOpen: boolean;
  onCloseClick: (event?: any, reason?: string) => void;
  uploadButtonStatus: SubmitButtonStatus;
  onSubmit: (values: { file?: File }) => void;
  locale: string;
}

export const UploadTranslationsModal = (props: UploadTranslationsModalProps) => {
  const { isOpen, onCloseClick, uploadButtonStatus, onSubmit, locale } = props;
  const [isValidFile, setIsValidFile] = useState(true);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const intl = useIntl();
  const theme = useTheme();

  useEffect(() => {
    if (!isOpen) {
      setIsValidFile(true);
    }
  }, [isOpen]);

  const validationSchema = () =>
    yup.object().shape({
      file: yup.mixed().required(intl.formatMessage(validationMessages.mandatoryMessage)),
    });

  const onToggleTooltip = () => {
    setIsTooltipOpen(prevState => !prevState);
  };

  return (
    <StyledUploadTranslationsModal open={isOpen} onClose={onCloseClick} disableEscapeKeyDown>
      <Formik<{ file?: File }>
        initialValues={{ file: undefined }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnBlur
      >
        {({ handleSubmit, values, errors, isValid: isValidForm }) => (
          <FormikOnError>
            <ModalHeader
              title={intl.formatMessage({
                id: 'upload-translations.modal.title',
                defaultMessage: 'Upload Translation File',
              })}
              onClose={onCloseClick}
              isDisabled={uploadButtonStatus !== SubmitButtonStatus.NORMAL}
            />
            <ModalContent isDisabled={uploadButtonStatus !== SubmitButtonStatus.NORMAL || isTooltipOpen}>
              <EmbeddedError containerId={UPLOAD_TRANSLATIONS_MODAL_CONTAINER_ID} />
              <ContentTitle>
                {intl.formatMessage({ id: 'upload-translations.modal.content.title', defaultMessage: 'Upload File' })}
              </ContentTitle>
              <ContentDescription>
                {intl.formatMessage(
                  {
                    id: 'upload-translations.modal.content.description',
                    defaultMessage:
                      'Please upload the {locale} translation file. Note that any entry in the file that is not blank will replace the existing translation. For any blank entry in the file the existing translation will be kept.',
                  },
                  { locale },
                )}
              </ContentDescription>
              <ChooseTranslationsFile
                locale={locale}
                file={values.file}
                error={errors.file}
                afterValidation={setIsValidFile}
              />
            </ModalContent>
            <StyledModalFooter isDisabled={uploadButtonStatus !== SubmitButtonStatus.NORMAL}>
              <Button paddingVertical="15px" onClick={onCloseClick} textColor={theme.palette.grayScale.darker2}>
                {intl.formatMessage({ id: 'upload-translations.modal.button.cancel', defaultMessage: 'Cancel' })}
              </Button>
              <ErrorsConfirmationTooltip
                onCancelClick={onToggleTooltip}
                onOkClick={() => {
                  onToggleTooltip();
                  handleSubmit();
                }}
                isTooltipOpen={isTooltipOpen}
              >
                <Button
                  submit
                  paddingVertical="15px"
                  onClick={() => {
                    if (isValidFile) {
                      handleSubmit();
                    } else if (isValidForm) {
                      setIsTooltipOpen(true);
                    }
                  }}
                  submitButtonProps={{
                    status: uploadButtonStatus,
                    loadingLabel: intl.formatMessage({
                      id: 'upload-translations.modal.button.loading',
                      defaultMessage: 'Saving',
                    }),
                    successLabel: intl.formatMessage({
                      id: 'upload-translations.modal.button.success',
                      defaultMessage: 'Saved',
                    }),
                  }}
                >
                  {intl.formatMessage({ id: 'upload-translations.modal.button.submit', defaultMessage: 'Save' })}
                </Button>
              </ErrorsConfirmationTooltip>
            </StyledModalFooter>
          </FormikOnError>
        )}
      </Formik>
    </StyledUploadTranslationsModal>
  );
};

export default UploadTranslationsModal;
