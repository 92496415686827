import { Button, Icon, Separator } from '@biotmed/base-components';
import React from 'react';
import { useTheme } from 'styled-components';
import { ExecutionStatusEnum } from '../modules/contants';
import { StatusContainer, StyledLoader } from './EnvironmentConfiguration.styled';

interface ButtonStatusProps {
  onClick: () => void;
  status?: ExecutionStatusEnum;
  text: string;
  icon: React.FC<{ width?: number | string; height?: number | string; color?: string }>;
  statusMessageDictionary?: Record<
    ExecutionStatusEnum,
    string | React.ReactElement<unknown, string> | (string | React.ReactElement<unknown, string>)[] | undefined
  >;
}

export const StatusButton: React.FC<ButtonStatusProps> = props => {
  const { status, onClick, icon, text, statusMessageDictionary } = props;
  const theme = useTheme();

  return (
    <div>
      <StatusContainer $isError={status === ExecutionStatusEnum.Failed}>
        {status === ExecutionStatusEnum.InProgress && <StyledLoader />}
        {statusMessageDictionary?.[status || ExecutionStatusEnum.NoData]}
      </StatusContainer>
      <Separator height="12px" />
      <Button
        disabled={status === ExecutionStatusEnum.InProgress}
        paddingHorizontal="30px"
        variant="outlined"
        startIcon={
          <Icon
            IconComponent={icon}
            width="25"
            height="23"
            color={
              status === ExecutionStatusEnum.InProgress ? theme.palette.grayScale.dark : theme.palette.secondary.medium
            }
          />
        }
        onClick={onClick}
      >
        {text}
      </Button>
    </div>
  );
};

export default StatusButton;
