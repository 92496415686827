import { Button } from '@biotmed/base-components';
import styled from 'styled-components';

export const AddTabButton = styled(Button)`
  color: ${props => props.theme.palette.secondary.light};
  && {
    font-size: 1.3rem;
    margin-right: 20px;
  }
`;

export const Header = styled.div`
  display: flex;
`;
