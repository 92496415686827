import { OrganizationResponse } from '@biotmed/organization-sdk';
import { createSlice, createSelector, createAction, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../../reducer';
import { DATA_STATE_KEY } from '../../constants';

export const STATE_KEY = 'organization';

interface OrganizationState {
  organizationList?: OrganizationResponse[];
}
export const getInitialState = (state?: OrganizationState): OrganizationState => ({
  organizationList: [],
});

/* eslint-disable no-param-reassign */
const slice = createSlice({
  name: STATE_KEY,
  initialState: getInitialState(),
  reducers: {
    onLoadOrganizationSuccess: (state, action: PayloadAction<any>) => {
      state.organizationList = action.payload;
    },
  },
});
/* eslint-enable no-param-reassign */

const getState = (state: RootState) => state[DATA_STATE_KEY][STATE_KEY] || getInitialState();

export const selectors = {
  selectOrganization: createSelector(getState, state => state.organizationList),
};

const extraActions = {
  loadOrganizations: createAction(`${STATE_KEY}/loadOrganizations`),
};

export const actions = { ...slice.actions, ...extraActions };

const { reducer } = slice;
export default reducer;
