import { createReadItemsSaga, ReadItemsSlice } from '@biotmed/data-components';
import { PluginResponse, SearchRequestV2, SearchResponsePluginResponse } from '@biotmed/settings-sdk';
import { FormatterDictionary } from '@biotmed/system-notifications';
import { PLUGIN_ID_SUFFIX } from '../../constants';
import { actions as readSliceActions, selectors as readSliceSelectors } from '../slice/readSlice';
import { pluginListErrorDictionary } from '../../errorDictionaries';

export const readSaga = createReadItemsSaga<
  PluginResponse,
  SearchRequestV2,
  SearchResponsePluginResponse,
  ReadItemsSlice<PluginResponse, SearchRequestV2>
>({
  slice: { actions: readSliceActions, selectors: readSliceSelectors },
  operationIdSuffix: PLUGIN_ID_SUFFIX,
  readApi: api => api.settings.pluginV2Api.search,
  detailsFormatterDictionary: {} as FormatterDictionary,
  errorDictionary: pluginListErrorDictionary,
});
