import {
  convertTypographySettingsToCss,
  Input,
  InputNumber,
  SingleSelect,
  MultiSelect,
  DateTimeInput as DateTime,
  SelectAutoComplete,
} from '@biotmed/base-components';
import styled from 'styled-components';

const standardMaxWidth = 'max-width: 200px';

const commonStyles = `
  height: 40px;
  & .MuiFormHelperText-root.Mui-error.MuiFormHelperText-sizeMedium {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const LabelInput = styled(Input)`
  ${commonStyles}
  ${standardMaxWidth};
`;

export const ParagraphInput = styled(Input)`
  ${props => convertTypographySettingsToCss(props.theme.typography.body1)};
  & .MuiInputBase-input {
    ${props => convertTypographySettingsToCss(props.theme.typography.body1)};
  }
`;

export const NumericInput = styled(InputNumber)`
  ${standardMaxWidth};
  ${commonStyles}
`;

export const SingleSelectInput = styled(SingleSelect)`
  ${standardMaxWidth};
  ${commonStyles}
  & .MuiInputBase-input {
    ${props => convertTypographySettingsToCss(props.theme.typography.body1)};
    ${standardMaxWidth};
  }
`;

export const MultiSelectInput = styled(MultiSelect)`
  ${standardMaxWidth};
  ${commonStyles}
  & .MuiInputBase-input {
    ${props => convertTypographySettingsToCss(props.theme.typography.body1)};
  }
`;

export const DateTimeInput = styled(DateTime)`
  max-width: 300px;
  ${commonStyles}
  & .MuiInputBase-input {
    ${props => convertTypographySettingsToCss(props.theme.typography.body1)};
  }
`;

export const SelectAutoCompleteInput = styled(SelectAutoComplete)`
  ${commonStyles}
  padding: 0;
  ${standardMaxWidth};
  && .MuiAutocomplete-inputRoot {
    padding: 0;
  }
  & .MuiInputBase-input {
    ${props => convertTypographySettingsToCss(props.theme.typography.body1)};
  }
`;
