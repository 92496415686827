import React, { useEffect, useRef, useState } from 'react';
import { SubmitButtonStatus } from '@biotmed/base-components';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { LoginRequestV2 } from '@biotmed/ums-sdk';
import { ResetSystemRequest } from '@biotmed/settings-sdk';
import { scrollToTop } from 'src/utils/scrollUtils';
import { Container, ResetSystemButton, SystemOwnerMessage, Title } from '../EnvironmentConfiguration.styled';
import { selectors, actions } from '../../modules/slice';
import ResetSystemModal from './ResetSystemModal';
import { actions as loginActions } from '../../../../redux/data/login';

interface ResetSystemProps {}

const CONTAINER_ID = 'reset-system-modal';

export const ResetSystem = (props: ResetSystemProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const systemOwner = useSelector(selectors.selectSystemOwnerName);
  const resetSystemNextButtonStatus = useSelector(selectors.selectResetSystemNextButtonStatus);
  const resetSystemSubmitButtonStatus = useSelector(selectors.selectResetSystemSubmitButtonStatus);
  const modalContentRef = useRef<any>(null);

  useEffect(() => {
    if (resetSystemSubmitButtonStatus === SubmitButtonStatus.SUCCESS) {
      setTimeout(() => {
        onCloseClick();
        dispatch(loginActions.logout());
      }, 1000);
    }
  }, [resetSystemSubmitButtonStatus]);

  const onOpenModal = () => {
    setOpen(true);
    dispatch(actions.initResetSystemModal());
  };

  const onCloseClick = (event?: any, reason?: string) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  const onNextClick = (values: LoginRequestV2) => {
    dispatch(actions.onNextClick({ loginRequest: values, containerId: CONTAINER_ID }));
    scrollToTop(modalContentRef);
  };

  const onSubmitClick = (values: ResetSystemRequest) => {
    dispatch(actions.onStartResetSystem({ resetSystemRequest: values, containerId: CONTAINER_ID }));
    scrollToTop(modalContentRef);
  };

  return (
    <Container>
      <Title>
        {intl.formatMessage({
          id: 'reset-system.title',
          defaultMessage: 'Reset Factory Settings',
        })}
      </Title>
      <SystemOwnerMessage>
        {intl.formatMessage(
          {
            id: 'reset-system.current-owner',
            defaultMessage: 'Current Owner: {systemOwner}',
          },
          {
            systemOwner,
          },
        )}
      </SystemOwnerMessage>
      <ResetSystemButton size="medium" variant="contained" onClick={onOpenModal}>
        {intl.formatMessage({
          id: 'reset-system.button',
          defaultMessage: 'Factory Reset',
        })}
      </ResetSystemButton>
      <ResetSystemModal
        isOpen={open}
        containerId={CONTAINER_ID}
        onCloseClick={onCloseClick}
        onNextClick={onNextClick}
        nextButtonStatus={resetSystemNextButtonStatus}
        submitButtonStatus={resetSystemSubmitButtonStatus}
        onSubmit={onSubmitClick}
        modalContentRef={modalContentRef}
      />
    </Container>
  );
};

export default ResetSystem;
