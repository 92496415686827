import React, { useEffect, useState } from 'react';
import { Button, SubmitButtonStatus } from '@biotmed/base-components';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import UploadTranslationsModal from './UploadTranslationsModal';
import { actions, selectors } from '../modules/slice/actionsSlice';

export interface UploadTranslationsProps {
  isFileExist: boolean;
  locale: string;
}

export const UploadTranslations = (props: UploadTranslationsProps) => {
  const { isFileExist, locale } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const uploadButtonStatus = useSelector(selectors.selectUploadButtonStatus);
  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    if (uploadButtonStatus === SubmitButtonStatus.SUCCESS) {
      setTimeout(() => {
        onCloseModalClick();
      }, 1000);
    }
  }, [uploadButtonStatus]);

  const onCloseModalClick = (event?: any, reason?: string) => {
    if (reason !== 'backdropClick') {
      setIsModalOpen(false);
    }
  };

  const onUploadClick = (event: any) => {
    dispatch(actions.initUploadTranslationsModal());
    setIsModalOpen(true);
  };

  const onSubmit = (values: { file?: File }) => {
    if (values.file && locale) {
      dispatch(actions.upload({ file: values.file, locale }));
    }
  };

  return (
    <>
      <Button variant="contained" onClick={onUploadClick}>
        {isFileExist
          ? intl.formatMessage({ id: 'translations.translations-file.upload', defaultMessage: 'Upload' })
          : intl.formatMessage({ id: 'translations.translations-file.update', defaultMessage: 'Update' })}
      </Button>
      <UploadTranslationsModal
        isOpen={isModalOpen}
        onCloseClick={onCloseModalClick}
        onSubmit={onSubmit}
        uploadButtonStatus={uploadButtonStatus}
        locale={locale}
      />
    </>
  );
};

export default UploadTranslations;
