import { convertTypographySettingsToCss } from '@biotmed/base-components';
import styled from 'styled-components';

export const Block = styled.div`
  border-radius: 4px;
  border: solid 1px ${props => props.theme.palette.grayScale.light};
  padding: 23px 25px;
`;
export const OverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  ${props => convertTypographySettingsToCss(props.theme.typography.body1)};
  .modalTitle {
    padding: 10px;
    ${props => convertTypographySettingsToCss(props.theme.typography.body1)};
    &.placeholder {
      color: ${props => props.theme.palette.grayScale.dark};
    }
  }
`;
