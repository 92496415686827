import intl from '@biotmed/i18n';
import { getSdkApi } from '@biotmed/sdk-api-provider';
import { PluginResponse } from '@biotmed/settings-sdk';
import { errorNotice, ErrorTypeEnum, successNotice } from '@biotmed/system-notifications';
import { AxiosResponse } from 'axios';
import { defineMessages } from 'react-intl';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { updatePluginErrorDictionary } from 'src/redux/data/plugin/modules/errorDictionaries';
import { translateEnabledState } from 'src/redux/data/plugin/modules/mappers';
import { actions } from '../slice';
import { actions as readActions } from '../slice/readSlice';

const messages = defineMessages({
  successMessage: {
    id: 'update.plugin.enabled.state.success',
    defaultMessage: 'Plugin {displayName} is now {state}',
  },
});

function* handleEnableStateUpdate(action: ReturnType<typeof actions.onEnableStateUpdate>) {
  const { name, enabledState } = action.payload;
  try {
    const response: AxiosResponse<PluginResponse> = yield call(
      getSdkApi().settings.pluginV2Api.partialUpdate,
      name,
      undefined,
      {
        enabledState,
      },
    );

    const plugin = response?.data;

    yield put(readActions.onLoadItems());
    yield put(
      successNotice({
        message: intl.current.formatMessage(messages.successMessage, {
          displayName: plugin.displayName,
          state: intl.current.formatMessage(translateEnabledState(plugin.enabledState)).toLowerCase(),
        }),
      }),
    );
  } catch (error: any) {
    yield put(
      errorNotice({
        type: ErrorTypeEnum.GENERAL,
        errorParams: { error, dictionary: updatePluginErrorDictionary },
      }),
    );
  }
}

export const saga = function* watch() {
  yield all([takeLatest(actions.onEnableStateUpdate, handleEnableStateUpdate)]);
};
