import React from 'react';
import { SelectChangeEvent } from '@mui/material';
import { BaseSelectableValue } from '@biotmed/settings-sdk';
import { useFormikContext } from 'formik';
import { TemplateAttributeProps } from './TemplateAttribute';
import { AttributeTypeContainer, MultiSelectInput } from './TemplateAttribute.styled';
import { EntityTemplateForm } from '../../Template';

interface MultiSelectTypeProps extends TemplateAttributeProps {}

const MultiSelectType = (props: MultiSelectTypeProps) => {
  const { attributeTouched, attributeErrors, attributeValues, handleChangeValue, attrFieldName } = props;
  const formik = useFormikContext<EntityTemplateForm>();

  const handleChange = (event: SelectChangeEvent<unknown>, child: React.ReactNode) => {
    handleChangeValue(event.target.value as string[]);
  };

  const handleMenuClose = () => {
    formik.setFieldTouched(attrFieldName, true);
  };

  return (
    <AttributeTypeContainer>
      <MultiSelectInput
        selectList={attributeValues.selectableValues.map((selectableValue: BaseSelectableValue) => ({
          title: selectableValue.displayName,
          value: selectableValue.name,
        }))}
        inputProps={{
          error: attributeErrors,
          helperText: attributeTouched?.value ? attributeErrors?.value : '',
          variant: 'standard',
        }}
        onChange={handleChange}
        onClose={handleMenuClose}
        defaultValue={attributeValues.value}
      />
    </AttributeTypeContainer>
  );
};

export default MultiSelectType;
