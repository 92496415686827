import React, { useEffect, useState } from 'react';
import { toJsonNameFormat } from 'src/utils/stringUtils';
import { InputWithTooltipWrapper } from './InputWithTooltip.styled';
import { Title } from '../ModalLayout';
import InputWithTooltip, { InputWithTooltipProps } from './InputWithTooltip';

const FocusInputWithTooltip = <WithCharactersCounter extends boolean | undefined = undefined>(
  props: InputWithTooltipProps<WithCharactersCounter>,
) => {
  const {
    readOnly = false,
    onChange,
    tooltipProps: {
      formatTooltipValue = toJsonNameFormat,
      readOnly: tooltipReadOnly = false,
      autoChange: tooltipAutoChange = true,
      ...restTooltipProps
    } = {},
    onBlur,
    onFocus,
    autoFocus,
    error = false,
    bold = true,
    value,
    placeholder,
    InputProps,
    className,
    ...rest
  } = props;

  const [inputInFocus, setInputInFocus] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);

  useEffect(() => {
    if (autoFocus) {
      setInputInFocus(true);
    }
  }, [autoFocus]);

  const handleBlur = (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (!error) {
      if (!openTooltip) setInputInFocus(false);
    }
    if (event) {
      restTooltipProps?.inputProps?.onBlur?.(event);
      onBlur?.(event);
    }
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    onChange?.(event);

    if (!readOnly && !tooltipReadOnly && tooltipAutoChange) {
      restTooltipProps?.inputProps?.onChange?.({
        target: { value: formatTooltipValue(event.target.value) },
      } as React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>);
    }
  };

  return (
    <InputWithTooltipWrapper bold={bold}>
      {inputInFocus || error || restTooltipProps?.inputProps?.error || openTooltip ? (
        <InputWithTooltip
          {...rest}
          className={`mainInput ${className}`}
          type="text"
          onFocus={() => setInputInFocus(true)}
          onBlur={handleBlur}
          onChange={handleOnChange}
          error={error}
          value={value || ''}
          placeholder={placeholder}
          tooltipProps={{ ...restTooltipProps, readOnly: tooltipReadOnly }}
          autoFocus={autoFocus || inputInFocus}
          onOpenTooltip={() => setOpenTooltip(true)}
          onCloseTooltip={() => setOpenTooltip(false)}
        />
      ) : (
        <Title bold={bold} className={value ? 'value' : 'placeholder'} onClick={() => setInputInFocus(true)}>
          {value && value !== '' ? (value as string) : placeholder}
        </Title>
      )}
    </InputWithTooltipWrapper>
  );
};

export default FocusInputWithTooltip;
