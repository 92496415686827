import React from 'react';
import { SingleSelect } from '@biotmed/base-components';
import { useIntl } from 'react-intl';
import { TypeSectionContainer } from './ComponentSections.styled';
// import { SelectItem } from '../CustomAttribute';

export interface TypeSectionProps {
  type: string;
  // TODO: IMPORT SELECT-ITEM TYPE FROM BASE-COMPONENTS
  typeOptions: any[];
  setType: (value: string) => void;
  helperText: string;
  isError: boolean;
  fieldName: string;
}

const TypeSection: React.FC<TypeSectionProps> = props => {
  const { type, typeOptions, setType, helperText, isError, fieldName } = props;
  const handleTypeChange = (event: any) => {
    setType(event.target.value);
  };
  const intl = useIntl();

  return (
    <TypeSectionContainer>
      <SingleSelect
        placeholder={intl.formatMessage({
          id: 'template-add.modal.select-type.placeholder',
          defaultMessage: 'Choose Type',
        })}
        inputProps={{ helperText, error: isError, name: fieldName }}
        selectList={typeOptions}
        onChange={handleTypeChange}
        defaultValue={type}
      />
    </TypeSectionContainer>
  );
};

export default TypeSection;
