import React, { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Checkbox, DraggableList, Table } from '@biotmed/base-components';

import {
  DraggableListHeader,
  DraggableListSection,
  DraggableListWrapper,
  SectionContent,
  TableWrapper,
} from '../PortalBuilder.styled';

interface PortalCategoryInputProps<T, S> {
  title: string;
  options: T[];
  selections: S[];
  renderOption: (option: T) => ReactNode;
  extractOptionKey: (option: T) => string;
  isOptionDisabled: (option: T) => boolean;
  optionsSorter: (option1: T, option2: T) => number;
  onCheckboxToggle: (checked: boolean, optoin: T) => void;
  renderSelection: (selection: S) => ReactNode;
  extractSelectionKey: (selection: S) => string;
  onOrderChange: (selections: S[]) => void;
  fullScreen: boolean;
}

const PortalCategoryInput = <T, S>(props: PortalCategoryInputProps<T, S>) => {
  const {
    title,
    options = [],
    selections = [],
    renderOption,
    renderSelection,
    onCheckboxToggle,
    fullScreen,
    optionsSorter,
    extractSelectionKey,
    extractOptionKey,
    onOrderChange,
    isOptionDisabled,
  } = props;

  const intl = useIntl();

  return (
    <SectionContent $fullScreen={fullScreen}>
      <TableWrapper>
        <Table
          scroll={{ y: 'auto' }}
          columns={[
            {
              title,
              render: (value, record: T) => renderOption(record),
              showSorterTooltip: false,
              sorter: optionsSorter,
              sortDirections: ['ascend', 'descend', 'ascend'],
              defaultSortOrder: 'ascend',
              colSpan: 2,
            },
          ]}
          rowKey={extractOptionKey}
          dataSource={options}
          rowSelection={{
            columnWidth: 40,
            hideSelectAll: true,
            selectedRowKeys: selections?.map((selection: S) => extractSelectionKey(selection)),
            renderCell: (checked: boolean, record: T) => {
              return (
                <Checkbox
                  key={`checkbox${extractOptionKey(record)}`}
                  checked={checked}
                  disabled={isOptionDisabled(record)}
                  onClick={() => {
                    onCheckboxToggle(checked, record);
                  }}
                />
              );
            },
          }}
          pagination={{ hideOnSinglePage: true, pageSize: options.length, total: options.length }}
        />
      </TableWrapper>
      <DraggableListSection>
        <DraggableListHeader>
          {intl.formatMessage({
            id: 'portal-category-input.portalBuilder.ViewOrder',
            defaultMessage: 'View order',
          })}
        </DraggableListHeader>
        <DraggableListWrapper $fullScreen={fullScreen}>
          <DraggableList
            items={selections}
            getItemId={extractSelectionKey}
            renderItem={renderSelection}
            onItemsChange={onOrderChange}
          />
        </DraggableListWrapper>
      </DraggableListSection>
    </SectionContent>
  );
};

export default PortalCategoryInput;
