import styled from 'styled-components';
import { PageMainTitle, PageLayout } from 'src/components/CommonStyledComponents';
import { convertTypographySettingsToCss, fontWeightNameMappings, getOpaqueColor } from '@biotmed/base-components';

export const TextWrapper = styled.div``;

export const Title = styled.div`
  ${props => convertTypographySettingsToCss(props.theme.typography.h5)};
`;

export const Description = styled.div`
  ${props => convertTypographySettingsToCss(props.theme.typography.body1)};
  color: ${props => props.theme.palette.grayScale.darker};
  margin-top: 5px;
`;

export const FieldArrayContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 11px;
`;

export const TemplatesPageLayout = styled.div`
  display: grid;
  grid-template-columns: 5fr minmax(200px, 320px);
  height: 100%;
`;

export const ContentWrapper = styled(PageLayout)`
  overflow-y: auto;
  padding-right: 20px;

  .ant-pagination-item {
    line-height: 32px;
  }

  .ant-pagination-total-text {
    margin-top: 2px;
  }

  tr[data-row-key='selected-template'] {
    background-image: ${props =>
      getOpaqueColor(
        props.theme.palette.grayScale.lightest,
        props.theme.opacity.almostTransparent,
        props.theme.palette.primary.dark,
      )};
  }
`;

export const StyledPageTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TemplatesTitle = styled(PageMainTitle)``;

export const TemplatesTotalSubTitle = styled.div`
  ${props => convertTypographySettingsToCss(props.theme.typography.h7)};
  margin-right: 10px;
  margin-top: 5px;
`;

export const TemplatesTotalRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px;
`;

export const CategoryContent = styled.div`
  font-weight: ${fontWeightNameMappings.semiBold};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const RightSide = styled.div`
  width: fit-content;
`;

export const FreeTextSearchContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
`;

/** This span prevents disabled button events (which are disabled as well) to disable the tooltip */
export const AlignedDeleteActionButtonWrapper = styled.span.attrs(props => ({
  className: 'aligned-action-button-wrapper',
}))``;
