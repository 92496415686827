import intl from '@biotmed/i18n';
import { defineMessages } from 'react-intl';

export const isTemplateInUse = (entitiesCount?: null | number) =>
  entitiesCount === null || entitiesCount === undefined ? undefined : entitiesCount > 0;

export const messages = defineMessages({
  BASE_MESSAGE: {
    id: 'template.delete-disabled.tooltip.base',
    defaultMessage: 'Template can not be deleted for the following reasons: ',
  },
  BASE_MESSAGE_CHILD: {
    id: 'template.delete-disabled.tooltip.base-child',
    defaultMessage: 'Child template can not be deleted for the following reasons:',
  },
  DELETE_DISABLED_DEFAULT_REASON_TOOLTIP: {
    id: 'template.delete-disabled.tooltip.in-use',
    defaultMessage: 'Template can not be deleted',
  },
  DELETE_DISABLED_IN_USE_TOOLTIP: {
    id: 'template.delete-disabled.tooltip.in-use',
    defaultMessage: 'The template is in use',
  },
  DELETE_DISABLED_SYSTEM_TOOLTIP: {
    id: 'template.delete-disabled.tooltip.system',
    defaultMessage: 'The template type can not be deleted',
  },
});

export const mapDisabledDeleteToReason = (reasons: string[]) => {
  if (!reasons.length) return intl.current.formatMessage(messages.DELETE_DISABLED_DEFAULT_REASON_TOOLTIP);
  return `${intl.current.formatMessage(messages.BASE_MESSAGE)} ${reasons.join(', ')}`;
};

type DisabledWithReasons = { isDisabled: boolean; reasons: string[] };

export const isTemplateDeleteDisabled = (
  entitiesCount?: null | number,
  templateRemovable?: boolean,
): DisabledWithReasons => {
  const templateIsInUse = isTemplateInUse(entitiesCount);

  const templateNotRemovable = templateRemovable === false;

  const isDeleteDisabled = templateIsInUse || templateNotRemovable;

  const disabledWithReasons: DisabledWithReasons = {
    isDisabled: isDeleteDisabled,
    reasons: [],
  };
  if (!disabledWithReasons.isDisabled) return disabledWithReasons;

  if (templateIsInUse) {
    disabledWithReasons.reasons.push(intl.current.formatMessage(messages.DELETE_DISABLED_IN_USE_TOOLTIP));
  } else if (templateNotRemovable) {
    disabledWithReasons.reasons.push(intl.current.formatMessage(messages.DELETE_DISABLED_SYSTEM_TOOLTIP));
  }

  return disabledWithReasons;
};
