import React from 'react';
import { otherIcons, Icon } from '@biotmed/base-components';
import { Tooltip } from '@mui/material';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';
import { ReactComponent as EditIcon } from 'src/images/edit.svg';
import { WrapperProps } from '../InputsSpreader/types';
import { Label, DefaultWrapper, InfoTooltip, ValueWrapper, EditIconWrapper } from './DefaultValueWrapper.styled';

export const DefaultValueWrapper: React.FC<React.PropsWithChildren<WrapperProps<undefined, { disabled?: boolean }>>> =
  props => {
    const intl = useIntl();

    const { children } = props;

    const { info } = otherIcons; // TODO: Ori: Change this to i or lightbulb

    const theme = useTheme();

    return (
      <DefaultWrapper>
        <Label>
          {intl.formatMessage({
            id: 'template.modal.attribute.default-value-for-existing-entities-renderer.pre-inputs-label',
            defaultMessage: `Default value`,
          })}
        </Label>
        <Tooltip
          componentsProps={{ tooltip: { style: { fontSize: theme.typography.body1['font-size'] } } }}
          title={intl.formatMessage({
            id: 'template.modal.attribute.default-value-for-existing-entities-renderer.pre-inputs-tooltip',
            defaultMessage: `Default value for existing entities. If an instance of this template was already created, it must be filled with this value.`,
          })}
        >
          <InfoTooltip>
            <Icon IconComponent={info} color={theme.palette.grayScale.darker} width="16px" />
          </InfoTooltip>
        </Tooltip>
        <EditIconWrapper>
          <Icon IconComponent={EditIcon} />
        </EditIconWrapper>
        <ValueWrapper>{children}</ValueWrapper>
      </DefaultWrapper>
    );
  };

export default DefaultValueWrapper;
