import { actionIcons, ErrorAlignment, Icon, SelectAutoComplete, Separator, Switch } from '@biotmed/base-components';
import { CodeSnippet, Parameter } from '@biotmed/data-components';
import { AvailableTab, SelectedTab, SelectedTabTypeEnum, ViewTabsSupportedTypesEnum } from '@biotmed/settings-sdk';
import React, { PropsWithChildren, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { viewTabsSupportedTypesDictionary } from 'src/routes/PortalBuilder/modules/dictionaries';
import { useTheme } from 'styled-components';
import { useFeature } from '@biotmed/react-flags-lib';
import { LowCodeValueTypeEnum } from '@biotmed/utils';
import {
  ButtonsContainer,
  DeleteButton,
  InputsContainer,
  InputWrapper,
  StyledDraggableTab,
} from './DraggableTab.styled';
import ExternalDraggableTab from './ExternalDraggableTab';
import ReverseReferenceDraggableTab from './ReverseReferenceDraggableTab';
import TemplateDraggableTab from './TemplateDraggableTab';

export interface DraggableTabProps {
  tab: SelectedTab;
  onChange: (newTab: SelectedTab) => void;
  onDelete?: () => void;
  typeOptions: ViewTabsSupportedTypesEnum[];
  available: Record<string, AvailableTab[]>;
  isAvailableOptionInUse: (option: any) => boolean;
  errors?: any;
  name?: string;
  codeSnippetAvailableParameters: Parameter[];
}

interface RenderFunctionProps {
  available?: any;
  isAvailableOptionInUse: (option: any) => boolean;
  tab: SelectedTab;
  onChange: (newTab: SelectedTab) => void;
  errors: any;
  name?: string;
}

const typeToRenderFunctionMapper = (tabType: ViewTabsSupportedTypesEnum) => {
  const map = new Map<ViewTabsSupportedTypesEnum, (props: RenderFunctionProps) => ReactNode>([
    [
      ViewTabsSupportedTypesEnum.Template,
      ({ available, isAvailableOptionInUse, tab, onChange, errors, name }) => (
        <TemplateDraggableTab
          availableData={available?.[tabType]?.map((availableTab: AvailableTab) => availableTab.template)}
          isAvailableOptionInUse={option => isAvailableOptionInUse({ template: option })}
          data={tab?.template}
          onChange={typeToOnChangeMapper(tabType, tab, onChange)}
          errors={errors?.template}
          name={`${name}.template`}
        />
      ),
    ],
    [
      ViewTabsSupportedTypesEnum.ExternalPage,
      ({ tab, onChange, errors, name }) => (
        <ExternalDraggableTab
          data={tab?.externalPage}
          onChange={typeToOnChangeMapper(tabType, tab, onChange)}
          errors={errors?.externalPage}
          name={`${name}.externalPage`}
        />
      ),
    ],
    [
      ViewTabsSupportedTypesEnum.ReverseReference,
      ({ available, isAvailableOptionInUse, tab, onChange, errors, name }) => (
        <ReverseReferenceDraggableTab
          availableData={available?.[tabType]?.map((availableTab: AvailableTab) => availableTab.reverseReference)}
          isAvailableOptionInUse={option => isAvailableOptionInUse({ reverseReference: option })}
          data={tab?.reverseReference}
          onChange={typeToOnChangeMapper(tabType, tab, onChange)}
          errors={errors?.reverseReference}
          name={`${name}.reverseReference`}
        />
      ),
    ],
  ]);

  return tabType ? map.get(tabType) : undefined;
};

const typeToOnChangeMapper = (
  tabType: ViewTabsSupportedTypesEnum,
  tab: SelectedTab,
  onChange: (newTab: any) => void,
) => {
  const map = new Map<ViewTabsSupportedTypesEnum, (newData: any) => void>([
    [
      ViewTabsSupportedTypesEnum.Template,
      newData =>
        onChange({
          ...tab,
          template: newData,
        }),
    ],
    [
      ViewTabsSupportedTypesEnum.ExternalPage,
      newData =>
        onChange({
          ...tab,
          externalPage: newData,
        }),
    ],
    [
      ViewTabsSupportedTypesEnum.ReverseReference,
      newData =>
        onChange({
          ...tab,
          reverseReference: newData,
        }),
    ],
  ]);

  return map.get(tabType) || ((newData: any) => {});
};

const WithAlignment = (props: PropsWithChildren<{ error: boolean }>) => {
  const { children, error } = props;

  return (
    <ErrorAlignment error={error}>
      <div style={{ visibility: 'hidden' }}>-</div>
      {children}
    </ErrorAlignment>
  );
};

const DraggableTab: React.FC<DraggableTabProps> = props => {
  const {
    tab,
    onDelete,
    onChange,
    typeOptions,
    available,
    isAvailableOptionInUse,
    errors,
    name,
    codeSnippetAvailableParameters,
  } = props;

  const intl = useIntl();
  const theme = useTheme();
  const { ActiveFeature: CodeSnippetActiveFeature } = useFeature({ flag: 'CONDITIONAL_VIEWS_BY_CODE_SNIPPET' });

  const updateSelectedType = (event: any, typeOption: ViewTabsSupportedTypesEnum | null) => {
    onChange({
      type: typeOption as unknown as SelectedTabTypeEnum,
    } as SelectedTab);
  };

  return (
    <StyledDraggableTab>
      <InputsContainer>
        <InputWrapper>
          <SelectAutoComplete
            value={(tab?.type as unknown as ViewTabsSupportedTypesEnum) || null}
            options={typeOptions}
            getOptionLabel={option => intl.formatMessage(viewTabsSupportedTypesDictionary[option] || option)}
            inputProps={{
              label: intl.formatMessage({
                id: 'DraggableTab.type',
                defaultMessage: 'Type',
              }),
              variant: 'standard',
              error: Boolean(errors?.type),
              helperText: errors?.type ?? ' ',
              name: `${name}.type`,
            }}
            onChange={updateSelectedType}
          />
        </InputWrapper>
        {tab?.type
          ? typeToRenderFunctionMapper(tab?.type as unknown as ViewTabsSupportedTypesEnum)?.({
              available,
              isAvailableOptionInUse,
              tab,
              onChange,
              errors,
              name,
            })
          : null}
      </InputsContainer>
      <Separator width="20px" />
      <ButtonsContainer>
        <CodeSnippetActiveFeature>
          <WithAlignment error={errors}>
            <CodeSnippet
              value={tab?.display ?? { value: true, codeSnippetId: null, type: LowCodeValueTypeEnum.CONST }}
              title={intl.formatMessage({
                id: 'draggable-tab.codesnippet.title',
                defaultMessage: 'Display',
              })}
              // below is not intl on purpose
              returnType="boolean"
              parameters={codeSnippetAvailableParameters}
              onChange={val => {
                onChange({ ...tab, display: val });
              }}
              renderInput={({ onChange: onInputChange, value, disabled }) => (
                <Switch disabled={disabled} onChange={e => onInputChange(e.target.checked)} checked={value ?? false} />
              )}
              // eslint-disable-next-line react/jsx-boolean-value
              inputResetValue={true}
            />
          </WithAlignment>
        </CodeSnippetActiveFeature>
        <WithAlignment error={errors}>
          <DeleteButton
            variant="text"
            onClick={onDelete}
            Icon={<Icon IconComponent={actionIcons.delete} color={theme.palette.grayScale.darker3} height="16px" />}
          />
        </WithAlignment>
      </ButtonsContainer>
    </StyledDraggableTab>
  );
};

export default DraggableTab;
