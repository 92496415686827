import styled from 'styled-components';
import { convertTypographySettingsToCss, Tabs as BaseTabs, Button } from '@biotmed/base-components';

export const Tabs = styled(BaseTabs)`
  flex: 0;
  ${props => convertTypographySettingsToCss(props.theme.typography.body1)};
`;

export const SubmitButton = styled(Button)`
  &&& {
    min-width: 145px;
  }
`;
