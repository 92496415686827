// eslint-disable-next-line no-shadow
export enum ExecutionStatusEnum {
  InProgress = 'IN_PROGRESS',
  Failed = 'FAILED',
  Success = 'SUCCESS',
  NoData = 'NO_DATA',
}

export const NEXT_CLICK_OPERATION_ID = 'next-click-operation-id';
export const START_RESET_OPERATION_ID = 'start-reset-operation-id';
