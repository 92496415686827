import { LoginResponse } from '@biotmed/auth-pages';
import { MANUFACTURER_ORGANIZATION_ID } from './consts';

/**
 * This is to prevent unauthorized users from entering to the application via login or login in another tab.
 * The check is used in multiple locations and destined to be refactored in the future.
 * @param loginSuccess the response from the login request
 */
export const isAuthorized = (loginSuccess: LoginResponse) => {
  return loginSuccess?.ownerOrganizationId === MANUFACTURER_ORGANIZATION_ID;
};
