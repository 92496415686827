import { getEntityTypeIntlDisplayName } from '@biotmed/data-components';
import intl from '@biotmed/i18n';
import { getSdkApi } from '@biotmed/sdk-api-provider';
import {
  BuiltInAttributeTypeEnum,
  CreateTemplateRequest,
  ErrorResponse,
  GetTemplateResponse,
} from '@biotmed/settings-sdk';
import { ApiError, errorNotice, ErrorNoticeParams, ErrorTypeEnum, successNotice } from '@biotmed/system-notifications';
import { AxiosResponse } from 'axios';
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';
import {
  mapChildTemplateToCreateTemplateRequest,
  mapTemplateToCreateTemplateRequest,
  mapTemplateToUpdateTemplateRequest,
} from 'src/redux/data/template/modules/mappers';
import {
  addTemplatePartialErrorDictionary,
  editTemplatePartialErrorDictionary,
  templateListErrorDictionary,
} from 'src/routes/Templates/modules/dictionaries';
import {
  AddTemplateForceErrorCodesType,
  DeleteTemplateForceErrorCodesType,
  EditTemplateForceErrorCodesType,
} from 'src/routes/Templates/components/formStepsComponents/forceConfirmation/types';
import { Template } from '../../entity/modules/interfaces';
import {
  ADD_FORCE_CONFIRMATION_ERROR_CODES,
  DELETE_FORCE_CONFIRMATION_ERROR_CODES,
  EDIT_FORCE_CONFIRMATION_ERROR_CODES,
} from './constants';
import { TemplateSagaDictionary, TemplateSagaDictionaryKey } from './dictionaries';
import { actions, selectors } from './slice';

const SUCCESS_DELAY = 500;
function* onLoadTemplate(action: ReturnType<typeof actions.onLoadTemplate>): any {
  yield call(loadTemplates);
}

function* loadTemplates(): any {
  try {
    const searchRequest = yield select(selectors.selectSearchRequest);
    const response: AxiosResponse<any> = yield call(getSdkApi().settings.templatesApi.search1, searchRequest);
    const templatesList = response?.data?.data;
    const templatesTotal = response?.data?.metadata?.page?.totalResults;
    yield put(actions.onLoadTemplatesSuccess({ templatesList, templatesTotal }));
  } catch (e: any) {
    console.error(e);
    yield put(actions.onLoadTemplatesFail());

    const apiError = (e?.response?.data || e) as ErrorResponse;
    yield put(
      errorNotice({
        type: ErrorTypeEnum.GENERAL,
        errorParams: {
          error: apiError,
          dictionary: templateListErrorDictionary,
        },
      }),
    );
  }
}

function* onLoadAllTemplate(): any {
  try {
    const response: AxiosResponse<any> = yield call(getSdkApi().settings.templatesApi.minimalSearch, { limit: 1000 });
    const templatesList = response?.data.data;
    yield put(actions.onLoadAllTemplatesSuccess(templatesList));
  } catch (e: any) {
    console.error(e);
    yield put(actions.onLoadTemplatesFail());

    const apiError = (e?.response?.data || e) as ApiError;
    yield put(
      errorNotice({
        type: ErrorTypeEnum.GENERAL,
        errorParams: {
          error: apiError,
          dictionary: templateListErrorDictionary,
        },
      }),
    );
  }
}

function* createTemplate(template: CreateTemplateRequest, force: boolean): any {
  return yield call(getSdkApi().settings.templatesApi.createTemplate, template, force);
}

function updateChildrenBuiltInAttributes(
  childTemplate: Template,
  parentEntityTypeName: string,
  parentTemplateId: string,
) {
  const builtInAttributes = childTemplate.builtInAttributes?.map(builtInAttribute => {
    let updatedBuiltInAttribute = { ...builtInAttribute };
    if (
      updatedBuiltInAttribute.type === BuiltInAttributeTypeEnum.Reference &&
      updatedBuiltInAttribute.referenceConfiguration?.entityType &&
      updatedBuiltInAttribute.referenceConfiguration.entityType === parentEntityTypeName
    ) {
      updatedBuiltInAttribute = {
        ...updatedBuiltInAttribute,
        referenceConfiguration: {
          ...updatedBuiltInAttribute.referenceConfiguration,
          validTemplatesToReference: [parentTemplateId] as unknown as Set<string>,
        },
      };
    }

    return updatedBuiltInAttribute;
  });

  return builtInAttributes;
}

function* onCreateTemplate(action: ReturnType<typeof actions.createTemplate>): any {
  const { entityTemplate, childrenTemplates, containerId, force } = action.payload;

  try {
    const createResponse = yield call(createTemplate, mapTemplateToCreateTemplateRequest(entityTemplate), force);
    const parentTemplateId = createResponse.data.id;
    if (childrenTemplates) {
      let childTemplate: any;
      try {
        for (let index = 0; index < childrenTemplates.length; index++) {
          childTemplate = childrenTemplates[index];
          // Updating the validTemplateToReference in each built in reference attribute that references to the parent's entity type
          // to include only the parent template id
          const builtInAttributes = updateChildrenBuiltInAttributes(
            childTemplate,
            entityTemplate.entityTypeName || '',
            parentTemplateId,
          );

          const childTemplateReq = {
            ...childTemplate,
            parentTemplateId,
            ownerOrganizationId: entityTemplate.ownerOrganizationId,
            builtInAttributes,
          };

          yield call(createTemplate, mapChildTemplateToCreateTemplateRequest(childTemplateReq), force);
        }
        // Success All
        yield put(
          successNotice({
            message: intl.current.formatMessage(TemplateSagaDictionary[TemplateSagaDictionaryKey.CREATE_SUCCESS], {
              name: entityTemplate?.displayName,
            }),
          }),
        );

        yield call(loadTemplates);
      } catch (e: any) {
        // Partially failed
        yield put(actions.createTemplateFailedPartial());
        yield call(loadTemplates);
        yield put(actions.closeModal());

        const apiError = (e?.response?.data || e) as ErrorResponse;
        yield put(
          errorNotice({
            type: ErrorTypeEnum.GENERAL,
            errorParams: {
              error: apiError,
              additionalData: {
                entityName: intl.current.formatMessage(getEntityTypeIntlDisplayName(childTemplate.entityTypeName)),
                templateName: childTemplate.displayName,
              },
              dictionary: addTemplatePartialErrorDictionary,
            },
          }),
        );
      }
    }
    // Partially/All Success
    yield put(actions.createTemplateSuccess());
    yield delay(SUCCESS_DELAY);
    yield put(actions.closeModal());
  } catch (e: any) {
    console.error(e);
    // Failed All
    yield put(actions.createTemplateFail());

    const apiError = (e?.response?.data || e) as ApiError;
    const { code, details } = apiError;

    yield call(
      handleForceAddEditError,
      code,
      {
        errorCode: code as AddTemplateForceErrorCodesType,
        errorDetails: details,
      },
      ADD_FORCE_CONFIRMATION_ERROR_CODES,
      {
        type: ErrorTypeEnum.EMBEDDED,
        containerId,
        errorParams: {
          error: apiError,
        },
      },
    );
  }
}

function* editTemplate(action: ReturnType<typeof actions.editTemplate>): any {
  const {
    templateId,
    entityTemplate,
    childrenTemplates,
    originalChildrenTemplates,
    forceUpdate = false,
    containerId,
  } = action.payload;
  try {
    yield call(
      getSdkApi().settings.templatesApi.updateTemplate,
      templateId,
      {
        ...mapTemplateToUpdateTemplateRequest(entityTemplate),
      },
      forceUpdate,
    );

    const deletedChildrenTemplates = originalChildrenTemplates
      ?.slice()
      ?.filter(
        (childTemplate: GetTemplateResponse) => !childrenTemplates.map(child => child.id).includes(childTemplate.id),
      );

    let childTemplate: any;

    try {
      if (deletedChildrenTemplates) {
        for (childTemplate of deletedChildrenTemplates) {
          yield call(getSdkApi().settings.templatesApi.deleteTemplate, childTemplate.id, forceUpdate);
        }
      }
      if (childrenTemplates) {
        for (childTemplate of childrenTemplates) {
          if (childTemplate?.id) {
            const childTemplateReq = mapTemplateToUpdateTemplateRequest({
              ...childTemplate,
              ownerOrganizationId: entityTemplate.ownerOrganizationId,
            });
            yield call(
              getSdkApi().settings.templatesApi.updateTemplate,
              childTemplate?.id,
              childTemplateReq,
              forceUpdate,
            );
          } else {
            // Updating the validTemplateToReference in each built in reference attribute that references to the parent's entity type
            // to include only the parent template id
            const builtInAttributes = updateChildrenBuiltInAttributes(
              childTemplate,
              entityTemplate.entityTypeName || '',
              templateId,
            );

            const childTemplateReq = mapChildTemplateToCreateTemplateRequest({
              ...childTemplate,
              parentTemplateId: templateId,
              builtInAttributes,
            });

            yield call(getSdkApi().settings.templatesApi.createTemplate, childTemplateReq, forceUpdate);
          }
        }
      }

      yield put(
        successNotice({
          message: intl.current.formatMessage(TemplateSagaDictionary[TemplateSagaDictionaryKey.UPDATE_SUCCESS], {
            name: entityTemplate?.displayName,
          }),
        }),
      );
      yield call(loadTemplates);
      yield put(actions.editTemplateSuccess());
      yield put(actions.closeModal());
    } catch (e: any) {
      yield put(actions.editTemplateFailedPartial());

      const apiError = (e?.response?.data || e) as ApiError;
      yield put(
        errorNotice({
          type: ErrorTypeEnum.GENERAL,
          errorParams: {
            error: apiError,
            additionalData: {
              entityName: intl.current.formatMessage(getEntityTypeIntlDisplayName(childTemplate.entityTypeName)),
              templateName: childTemplate.displayName,
            },
            dictionary: editTemplatePartialErrorDictionary,
          },
        }),
      );

      yield call(loadTemplates);
      yield put(actions.closeModal());
    }
  } catch (e: any) {
    yield put(actions.editTemplateFail());

    const apiError = (e?.response?.data || e) as ApiError;
    const { code, details } = apiError;

    yield call(
      handleForceAddEditError,
      code,
      { errorCode: code as EditTemplateForceErrorCodesType, errorDetails: details },
      EDIT_FORCE_CONFIRMATION_ERROR_CODES,
      {
        type: ErrorTypeEnum.EMBEDDED,
        containerId,
        errorParams: {
          error: apiError,
        },
      },
    );
  }
}

function* deleteTemplate(action: ReturnType<typeof actions.deleteTemplate>): any {
  const { templateId, templateName, force } = action.payload;
  try {
    yield call(getSdkApi().settings.templatesApi.deleteTemplate, templateId, force);
    yield call(loadTemplates);
    yield put(actions.deleteTemplateSuccess());
    yield put(
      successNotice({
        message: intl.current.formatMessage(TemplateSagaDictionary[TemplateSagaDictionaryKey.DELETE_SUCCESS], {
          name: templateName,
        }),
      }),
    );
  } catch (e: any) {
    yield put(actions.deleteTemplateFailed());

    const apiError = (e?.response?.data || e) as ErrorResponse;
    const { code, details } = apiError;

    if (code && DELETE_FORCE_CONFIRMATION_ERROR_CODES.includes(code as string)) {
      yield put(
        actions.onForceDeleteError({ errorCode: code as DeleteTemplateForceErrorCodesType, errorDetails: details }),
      );
    } else {
      yield put(
        errorNotice({
          type: ErrorTypeEnum.GENERAL,
          errorParams: {
            error: apiError,
          },
        }),
      );
    }
  }
}

function* handleForceAddEditError<T>(
  errorCode: T,
  forceActionPayload: { errorCode: T; errorDetails: any },
  errorCodeList: string[],
  errorNoticeParams: ErrorNoticeParams,
) {
  if (errorCode && errorCodeList.includes(errorCode as string)) {
    yield put(actions.onForceAddEditError(forceActionPayload));
  } else {
    yield put(errorNotice(errorNoticeParams));
  }
}

export default function* watchEntityActions() {
  yield all([
    takeLatest(actions.onLoadTemplate, onLoadTemplate),
    takeLatest(actions.onLoadAllTemplate, onLoadAllTemplate),
    takeLatest(actions.createTemplate, onCreateTemplate),
    takeLatest(actions.editTemplate, editTemplate),
    takeLatest(actions.deleteTemplate, deleteTemplate),
  ]);
}
