import React from 'react';
import { useIntl } from 'react-intl';
import { Button, Icon, SubmitButtonStatus, otherIcons } from '@biotmed/base-components';
import { useTheme } from 'styled-components';
import { ButtonsWrapper, StyledFooter, WarningContainer } from './PortalBuilder.styled';
import SaveButton from '../modules/SaveButton';
import { SaveButtonTypeEnum } from '../modules/constant';

export interface FooterProps {
  handleSaveClick: (type: SaveButtonTypeEnum) => void;
  selectedSaveButtonType: SaveButtonTypeEnum;
  saveButtonStatus: SubmitButtonStatus;
  warningMessage?: string;
  handleDiscardClick: () => void;
  isDisabled: boolean;
}
export const Footer = (props: FooterProps) => {
  const { handleSaveClick, selectedSaveButtonType, saveButtonStatus, warningMessage, handleDiscardClick, isDisabled } =
    props;
  const intl = useIntl();
  const theme = useTheme();

  return (
    <StyledFooter>
      <WarningContainer>
        {warningMessage && (
          <>
            <Icon IconComponent={otherIcons.error} color={theme.palette.warning.dark} />
            {warningMessage}
          </>
        )}
      </WarningContainer>
      <ButtonsWrapper>
        <Button
          paddingHorizontal="32px"
          paddingVertical="15px"
          size="medium"
          onClick={handleDiscardClick}
          disabled={isDisabled}
        >
          {intl.formatMessage({
            id: 'portal-builder.discard-button',
            defaultMessage: 'Discard',
          })}
        </Button>
        <SaveButton
          buttonType={SaveButtonTypeEnum.SAVE}
          onClick={() => handleSaveClick(SaveButtonTypeEnum.SAVE)}
          submitButtonProps={{
            status: selectedSaveButtonType === SaveButtonTypeEnum.SAVE ? saveButtonStatus : undefined,
          }}
          disabled={isDisabled}
        />
        <SaveButton
          buttonType={SaveButtonTypeEnum.SAVE_AND_NEXT}
          onClick={() => handleSaveClick(SaveButtonTypeEnum.SAVE_AND_NEXT)}
          submitButtonProps={{
            status: selectedSaveButtonType === SaveButtonTypeEnum.SAVE_AND_NEXT ? saveButtonStatus : undefined,
          }}
          disabled={isDisabled}
        />
      </ButtonsWrapper>
    </StyledFooter>
  );
};

export default Footer;
