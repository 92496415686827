import React from 'react';
import { GetTemplateResponse } from '@biotmed/settings-sdk';
import { TypeComponentProps, TypeVariantComponentProps } from '../interfaces';
import CustomReferenceType from './CustomReferenceType';
import BuiltInReferenceType from './BuiltInReferenceType';

export interface ReferenceTypeVariantComponentProps extends TypeVariantComponentProps {}
export interface ReferenceTypeProps extends TypeComponentProps {}
const ReferenceType: React.FC<ReferenceTypeProps> = props => {
  const { variant, ...rest } = props;
  const variantMap: Record<typeof variant, React.FC<ReferenceTypeVariantComponentProps>> = {
    custom: CustomReferenceType,
    builtIn: BuiltInReferenceType,
  };
  const TypeComponent = variantMap[variant];
  return <TypeComponent {...rest} />;
};
export default ReferenceType;

export interface AdaptedMultiSelectProps {
  handleChangeEntityTemplate: (event: any) => void;
  entityTemplateList: Array<GetTemplateResponse>;
  entityTemplate: string[];
  entity: any;
  attrFieldName: string;
  disabled?: boolean;
  error?: string;
  helperText?: boolean;
}
