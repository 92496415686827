import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import { Radio, Separator } from '@biotmed/base-components';
import { OrganizationSelectionConfiguration } from '@biotmed/settings-sdk';
import { OrganizationResponse } from '@biotmed/organization-sdk';
import { EntityTemplateForm } from '../../../Template';
import OrganizationsAutoComplete from '../OrganizationsAutoComplete';
import { Container } from './TemplateAssignOwnerOrganization.styled';

interface TemplateAssignOwnerOrganizationProps {
  attrFieldName: string;
  configurationValues?: OrganizationSelectionConfiguration;
}

const OrganizationSelectOptions = {
  MANUAL: 'MANUAL',
  ALL: 'ALL',
};

const TemplateAssignOwnerOrganization: React.FC<TemplateAssignOwnerOrganizationProps> = props => {
  const { attrFieldName, configurationValues } = props;
  const intl = useIntl();
  const { setFieldValue } = useFormikContext<EntityTemplateForm>();

  const [organizationSelectOption, setOrganizationSelectOption] = useState<string>(
    configurationValues?.all ? OrganizationSelectOptions.ALL : OrganizationSelectOptions.MANUAL,
  );

  const handleOptionChange = (value: string) => {
    setOrganizationSelectOption(value);
    if (value === OrganizationSelectOptions.MANUAL) {
      setFieldValue(`${attrFieldName}.all`, false);
      setFieldValue(`${attrFieldName}.selected`, []);
    } else if (value === OrganizationSelectOptions.ALL) {
      setFieldValue(`${attrFieldName}.all`, true);
      setFieldValue(`${attrFieldName}.selected`, null);
    }
  };

  const handleChangeAssignOwnerOrganization = (value: OrganizationResponse[]) => {
    setFieldValue(
      `${attrFieldName}.selected`,
      value.map((val: OrganizationResponse) => ({
        id: val._id,
      })),
    );
  };

  return (
    <Container>
      <Radio
        radioObjects={[
          {
            label: intl.formatMessage({
              id: 'template-attribute.radio-filter.organization-select.option.manual',
              defaultMessage: 'Manual organization select',
            }),
            value: OrganizationSelectOptions.MANUAL,
            isChecked: organizationSelectOption === OrganizationSelectOptions.MANUAL,
          },
          {
            label: intl.formatMessage({
              id: 'template-attribute.radio-filter.organization-select.option.all',
              defaultMessage: 'Select all (including future organizations)',
            }),
            value: OrganizationSelectOptions.ALL,
            isChecked: organizationSelectOption === OrganizationSelectOptions.ALL,
          },
        ]}
        onChangeRadio={handleOptionChange}
      />
      <Separator height="20px" />
      {organizationSelectOption === OrganizationSelectOptions.MANUAL && (
        <OrganizationsAutoComplete
          onChange={handleChangeAssignOwnerOrganization}
          // value={initialSelectedIdsList}
          value={configurationValues?.selected}
          multiple
        />
      )}
    </Container>
  );
};
export default TemplateAssignOwnerOrganization;
