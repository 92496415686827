import { otherIcons, SubmitButtonStatus } from '@biotmed/base-components';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectors, actions } from '../../modules/slice';
import StatusButton from '../StatusButton';
import { ExecutionStatusEnum } from '../../modules/contants';
import ExportModal from './ExportModal';
import { Container, Title } from '../EnvironmentConfiguration.styled';

interface ExportProps {}

const CONTAINER_ID = 'export-modal';

export const Export: React.FC<ExportProps> = props => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const exportButtonStatus = useSelector(selectors.selectExportButtonStatus);
  const exportStatus = useSelector(selectors.selectExportStatus);
  const exportStatusApiError = useSelector(selectors.selectExportStatusApiError);
  const lastExportMetadata = useSelector(selectors.selectLastExportMetadata);
  const dispatch = useDispatch();

  useEffect(() => {
    if (exportButtonStatus === SubmitButtonStatus.SUCCESS) {
      setTimeout(() => {
        onCloseClick();
      }, 1000);
    }
  }, [exportButtonStatus]);

  const handleSubmitExport = (values: { name: string; description?: string }) => {
    dispatch(actions.onStartExport({ ...values, containerId: CONTAINER_ID }));
  };

  const onCloseClick = (event?: any, reason?: string) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  const statusMessageDictionary = useMemo(() => {
    return {
      [ExecutionStatusEnum.InProgress]: intl.formatMessage({
        id: 'export.status.in-progress',
        defaultMessage: 'Exporting...',
      }),

      [ExecutionStatusEnum.Success]: intl.formatMessage(
        {
          id: 'export.status.success',
          defaultMessage: 'Last export date {creationTime}, finished successully',
        },
        {
          creationTime: intl.formatDate(lastExportMetadata?.creationTime, { dateStyle: 'short', timeStyle: 'short' }),
        },
      ),
      [ExecutionStatusEnum.Failed]: intl.formatMessage(
        {
          id: 'export.status.fail',
          defaultMessage: 'Last export attempt date {creationTime}, failed with message:{lineBreak}`{errorMessage}`', // TODO: Can this be changed to a solution with /n ?
        },
        {
          creationTime: intl.formatDate(lastExportMetadata?.creationTime, { dateStyle: 'short', timeStyle: 'short' }),
          errorMessage: exportStatusApiError,
          lineBreak: <br />,
        },
      ),
      [ExecutionStatusEnum.NoData]: intl.formatMessage({
        id: 'export.status.no-data',
        defaultMessage: 'No exports found',
      }),
    };
  }, [exportStatusApiError, lastExportMetadata?.creationTime]);

  const onOpenModal = () => {
    setOpen(true);
    dispatch(actions.initExportModal());
  };

  return (
    <Container>
      <Title>
        {intl.formatMessage({
          id: 'export.title',
          defaultMessage: 'Export',
        })}
      </Title>
      <StatusButton
        onClick={onOpenModal}
        status={(exportStatus || ExecutionStatusEnum.NoData) as unknown as ExecutionStatusEnum}
        text={intl.formatMessage({
          id: 'export.button',
          defaultMessage: 'Export File',
        })}
        icon={otherIcons.export}
        statusMessageDictionary={statusMessageDictionary}
      />
      <ExportModal
        isOpen={open}
        onCloseClick={onCloseClick}
        exportButtonStatus={exportButtonStatus}
        onSubmit={handleSubmitExport}
        containerId={CONTAINER_ID}
      />
    </Container>
  );
};

export default Export;
