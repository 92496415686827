import { Parameter, measurementViewClassificationToIntlDisplayNameMap } from '@biotmed/data-components';
import {
  MeasurementPortalAttributes,
  SelectedMeasurementAttributes,
  SelectedPortalAttribute,
  SelectedMeasurementAttributesViewsEnum,
} from '@biotmed/settings-sdk';
import React from 'react';
import { useIntl } from 'react-intl';
import { useFeature } from '@biotmed/react-flags-lib';

import { AttributesWrapper } from '../PortalBuilder.styled';
import PortalViewCategoryRenderer from '../PortalViewCategoryRenderer/AttributesPortalCategoryRenderer';
import PortalBuilderSection from './PortalBuilderSection';
import MeasurementViewsCategoryRenderer from '../PortalViewCategoryRenderer/MeasurementViewsCategoryRenderer';

interface MeasurementsBuilderProps {
  available: MeasurementPortalAttributes;
  fullScreen: boolean;
  selectedMeasurements?: SelectedMeasurementAttributes;
  setMeasurements: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  codeSnippetAvailableParameters: Parameter[];
}

const MeasurementsBuilder: React.FC<MeasurementsBuilderProps> = props => {
  const {
    available,
    fullScreen = false,
    selectedMeasurements: measurements,
    setMeasurements,
    codeSnippetAvailableParameters,
  } = props;

  const intl = useIntl();
  const { ActiveFeature: ObservationViewsActiveFeature } = useFeature({ flag: 'OBSERVATION_VIEW_DEFAULT_TAB' });

  const handleChange = (measurementsValues: SelectedMeasurementAttributes) =>
    setMeasurements('measurements', measurementsValues);

  const handleChangeChart = (selected: SelectedPortalAttribute[]) => {
    handleChange({
      ...measurements,
      chart: selected,
    });
  };

  const handleChangeHeadline = (selected: SelectedPortalAttribute[]) => {
    handleChange({
      ...measurements,
      headline: selected,
    });
  };

  const handleChangeViews = (
    selected: SelectedMeasurementAttributesViewsEnum[],
    defaultView: SelectedMeasurementAttributesViewsEnum,
  ) => {
    handleChange({
      ...measurements,
      views: selected,
      defaultView,
    });
  };

  return (
    <PortalBuilderSection
      title={intl.formatMessage({
        id: 'portalBuilder.MeasurementsSectionTitle',
        defaultMessage: 'Measurements Section - select measurements, then order',
      })}
    >
      <AttributesWrapper>
        <PortalViewCategoryRenderer
          key="chart"
          title={intl.formatMessage(measurementViewClassificationToIntlDisplayNameMap?.chart) || 'chart'}
          attributes={available.attributes}
          selected={measurements?.chart}
          onChange={handleChangeChart}
          fullScreen={fullScreen}
          codeSnippetAvailableParameters={codeSnippetAvailableParameters}
        />
        <PortalViewCategoryRenderer
          key="headline"
          title={intl.formatMessage(measurementViewClassificationToIntlDisplayNameMap?.headline) || 'headline'}
          attributes={available.attributes}
          selected={measurements?.headline}
          onChange={handleChangeHeadline}
          fullScreen={fullScreen}
          codeSnippetAvailableParameters={codeSnippetAvailableParameters}
        />
        <ObservationViewsActiveFeature>
          <MeasurementViewsCategoryRenderer
            title={intl.formatMessage(measurementViewClassificationToIntlDisplayNameMap?.views) || 'Views'}
            views={measurements?.views || []}
            defaultView={measurements?.defaultView || SelectedMeasurementAttributesViewsEnum.Live}
            onChange={handleChangeViews}
            fullScreen={fullScreen}
          />
        </ObservationViewsActiveFeature>
      </AttributesWrapper>
    </PortalBuilderSection>
  );
};

export default MeasurementsBuilder;
