import React from 'react';
import { useIntl } from 'react-intl';
import { InfoLabel } from '@biotmed/base-components';

const BuiltIn: React.FC = props => {
  const intl = useIntl();

  return (
    <InfoLabel
      text={intl.formatMessage({
        id: 'templates.attribute.builtin-phi-section.built-in-attribute',
        defaultMessage: 'Built in attribute',
      })}
    />
  );
};

export default BuiltIn;
