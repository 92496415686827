import React from 'react';
import { Checkbox } from '@biotmed/base-components';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import { TemplateAttributeProps } from './TemplateAttribute';
import { AttributeTypeContainer } from './TemplateAttribute.styled';
import { EntityTemplateForm } from '../../Template';

interface BooleanTypeProps extends TemplateAttributeProps {}

const BooleanType = (props: BooleanTypeProps) => {
  const { attributeValues, attrFieldName } = props;
  const formik = useFormikContext<EntityTemplateForm>();
  const intl = useIntl();

  const handleSetIsChecked = (event: any, checked: boolean) => {
    formik.setFieldValue(`${attrFieldName}.value`, checked);
  };

  return (
    <AttributeTypeContainer>
      <Checkbox checked={attributeValues?.value} classes={{ root: 'rootControl' }} onChange={handleSetIsChecked} />
      {intl.formatMessage({ id: 'template.template-attribute.boolean.enable', defaultMessage: 'Enable' })}
    </AttributeTypeContainer>
  );
};

export default BooleanType;
