import { createAction, createSlice } from '@reduxjs/toolkit';
import { PLUGIN_STATE_KEY } from './constants';

export const STATE_KEY = 'plugin-configuration-actions';

interface PluginConfiguraionActionsState {}

export const getInitialState = (state?: PluginConfiguraionActionsState): PluginConfiguraionActionsState => ({});

/* eslint-disable no-param-reassign */
const slice = createSlice({
  name: STATE_KEY,
  initialState: getInitialState(),
  reducers: {},
});

const getState = (state: { [PLUGIN_STATE_KEY]: { [STATE_KEY]: PluginConfiguraionActionsState } }) =>
  state[PLUGIN_STATE_KEY][STATE_KEY] || getInitialState();

export const selectors = {};

const extraActions = {
  onEnableStateUpdate: createAction<{ name: string; enabledState: PluginConfiguraionActionsState }>(
    `${STATE_KEY}/onEnableStateUpdate`,
  ),
};

const { reducer } = slice;

export const actions = { ...slice.actions, ...extraActions };

export default reducer;
