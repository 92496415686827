import React, { ReactNode } from 'react';
import { StatusContent } from './ADBPage.styled';

interface ADBStatusProps {
  Icon: ReactNode;
  statusText: string;
}

const ADBStatus = (props: ADBStatusProps) => {
  const { statusText, Icon } = props;

  return (
    <StatusContent>
      {Icon}
      {statusText}
    </StatusContent>
  );
};

export default ADBStatus;
