import React from 'react';
import {
  EntityTypeEnum,
  categoryToIntlDisplayNameMap,
  Category as CategoryType,
  getEntityTypeIntlDisplayNamePlural,
  EntityType,
} from '@biotmed/data-components';
import { Entity } from 'src/redux/data/entity/modules/interfaces';
import { Step } from 'src/components/Modals/types';
import intl from '@biotmed/i18n';
import DetailsFields from '../components/formStepsComponents/DetailsFields/DetailsFields';
import Overview from '../components/formStepsComponents/Overview/Overview';
import { validationMap } from './validationFactory';
import ChildrenTypes from '../components/formStepsComponents/ChildrenTypes/ChildrenTypes';

type ChildrenEntityNames =
  | EntityTypeEnum.USAGE_SESSION
  | EntityTypeEnum.COMMAND
  | EntityTypeEnum.DEVICE_ALERT
  | EntityTypeEnum.PATIENT_ALERT;

type ChildrenEntity = Omit<Entity, 'name'> & { name: ChildrenEntityNames };

const overviewStepCreator = (formSteps: Step[], withSelectOwnerOrganization: boolean) => {
  formSteps.push({
    tabName: intl.current.formatMessage({ id: 'template.modal.overview-tab.tab-name', defaultMessage: 'Overview' }),
    render: () => <Overview key="overview" withSelectOwnerOrganization={withSelectOwnerOrganization} />,
    validations: validationMap(intl.current).overview,
  });
};

const detailsFieldStepCreator = (entity: Entity, formSteps: Step[]) => {
  entity.attributeCategories.forEach(category => {
    formSteps.push({
      tabName: `${intl.current.formatMessage(
        categoryToIntlDisplayNameMap[category.name as CategoryType],
      )} ${intl.current.formatMessage({ id: 'template.modal.category-tab.tab-name', defaultMessage: 'Fields' })}`,
      render: () => <DetailsFields category={category} key={category.name} />,
      validations: validationMap(intl.current).detailsField(category.name),
    });
  });
};

const getChildStep = (childEntity: ChildrenEntity) => ({
  tabName: intl.current.formatMessage(getEntityTypeIntlDisplayNamePlural(childEntity.name)),
  render: () => <ChildrenTypes entity={childEntity} key={childEntity.name} />,
  validations: validationMap(intl.current)[childEntity.name],
});

const childrenCreator = (entity: Entity, formSteps: Step[]) => {
  entity?.children?.forEach(childEntity => {
    const step = getChildStep(childEntity as ChildrenEntity);
    if (step) {
      formSteps.push(step);
    }
  });
};

export const stepComponentsMap = (entity: Entity): Step[] => {
  return getMapComponentsForEntity(entity.name)(entity);
};

const mapComponentsByEntity: Record<EntityTypeEnum, (entity: Entity) => Step[]> = {
  [EntityTypeEnum.DEVICE]: entity => {
    const formSteps: Step[] = [];
    overviewStepCreator(formSteps, true);
    detailsFieldStepCreator(entity, formSteps);
    childrenCreator(entity, formSteps);
    return formSteps;
  },
  [EntityTypeEnum.ORGANIZATION]: entity => {
    const formSteps: Step[] = [];
    overviewStepCreator(formSteps, false);
    detailsFieldStepCreator(entity, formSteps);
    return formSteps;
  },
  [EntityTypeEnum.PATIENT]: entity => {
    const formSteps: Step[] = [];
    overviewStepCreator(formSteps, false);
    detailsFieldStepCreator(entity, formSteps);
    childrenCreator(entity, formSteps);
    return formSteps;
  },
  [EntityTypeEnum.ORGANIZATION_USER]: entity => {
    const formSteps: Step[] = [];
    overviewStepCreator(formSteps, true);
    detailsFieldStepCreator(entity, formSteps);
    return formSteps;
  },
  [EntityTypeEnum.CAREGIVER]: entity => {
    const formSteps: Step[] = [];
    overviewStepCreator(formSteps, true);
    detailsFieldStepCreator(entity, formSteps);
    return formSteps;
  },
  [EntityTypeEnum.MANUFACTURER_USER]: entity => {
    const formSteps: Step[] = [];
    overviewStepCreator(formSteps, false);
    detailsFieldStepCreator(entity, formSteps);
    return formSteps;
  },
  [EntityTypeEnum.USAGE_SESSION]: entity => {
    const formSteps: Step[] = [];
    overviewStepCreator(formSteps, false);
    detailsFieldStepCreator(entity, formSteps);
    return formSteps;
  },
  [EntityTypeEnum.GENERIC_ENTITY]: entity => {
    const formSteps: Step[] = [];
    overviewStepCreator(formSteps, true);
    detailsFieldStepCreator(entity, formSteps);
    return formSteps;
  },
  [EntityTypeEnum.COMMAND]: entity => {
    const formSteps: Step[] = [];
    overviewStepCreator(formSteps, false);
    detailsFieldStepCreator(entity, formSteps);
    return formSteps;
  },
  [EntityTypeEnum.DEVICE_ALERT]: entity => {
    const formSteps: Step[] = [];
    overviewStepCreator(formSteps, false);
    detailsFieldStepCreator(entity, formSteps);
    return formSteps;
  },
  [EntityTypeEnum.PATIENT_ALERT]: entity => {
    const formSteps: Step[] = [];
    overviewStepCreator(formSteps, false);
    detailsFieldStepCreator(entity, formSteps);
    return formSteps;
  },
  [EntityTypeEnum.REGISTRATION_CODE]: entity => {
    const formSteps: Step[] = [];
    overviewStepCreator(formSteps, true);
    detailsFieldStepCreator(entity, formSteps);
    return formSteps;
  },
};

const getMapComponentsForEntity = (name: string) => {
  return mapComponentsByEntity[name as EntityType] ?? (() => []);
};
