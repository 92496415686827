import { Parameter, categoryToIntlDisplayNameMap } from '@biotmed/data-components';
import {
  AllPortalAttributesGroup,
  SelectedPortalAttribute,
  SelectedPortalAttributesGroup,
} from '@biotmed/settings-sdk';
import React from 'react';
import { useIntl } from 'react-intl';

import { AttributesWrapper } from '../PortalBuilder.styled';
import PortalViewCategoryRenderer from '../PortalViewCategoryRenderer/AttributesPortalCategoryRenderer';
import PortalBuilderSection from './PortalBuilderSection';

interface AttributesBuilderProps {
  available: AllPortalAttributesGroup[];
  fullScreen: boolean;
  selectedAttributes?: SelectedPortalAttributesGroup[];
  setAttributes: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  codeSnippetAvailableParameters: Parameter[];
}

const AttributesBuilder: React.FC<AttributesBuilderProps> = props => {
  const {
    available,
    fullScreen = false,
    selectedAttributes: attributes,
    setAttributes,
    codeSnippetAvailableParameters,
  } = props;

  const intl = useIntl();

  const handleChange = (attributesValues: SelectedPortalAttribute[], classification?: string) => {
    const newAttributes = attributes ? [...attributes] : [];
    const newClassificationObj = {
      ...newAttributes.find(obj => classification && obj.classification === classification),
    };
    if (classification && newClassificationObj) {
      const classificationObjIndex = newAttributes.findIndex(
        obj => classification && obj.classification === classification,
      );
      newClassificationObj.selected = attributesValues;
      newAttributes[classificationObjIndex] = newClassificationObj;
    } else if (newAttributes[0]) {
      newAttributes[0] = { ...newAttributes[0], selected: attributesValues };
    }
    setAttributes('attributes', newAttributes);
  };

  const attributesTitle = intl.formatMessage({ id: 'portalBuilder.attributes-title.', defaultMessage: 'Attributes' });

  return (
    <PortalBuilderSection
      title={intl.formatMessage({
        id: 'portalBuilder.AttributesSectionTitle',
        defaultMessage: 'Attributes Section - select attributes, then order',
      })}
    >
      <>
        {available.map((categoryObject: AllPortalAttributesGroup) => {
          return (
            categoryObject.attributes && (
              <AttributesWrapper key={categoryObject.classification ?? null}>
                <PortalViewCategoryRenderer
                  title={`${
                    categoryObject.classification
                      ? intl.formatMessage(
                          categoryToIntlDisplayNameMap[
                            categoryObject.classification as 'REGULAR' | 'STATUS' | 'CONFIGURATION'
                          ],
                        )
                      : ''
                  } ${attributesTitle}`}
                  attributes={categoryObject.attributes}
                  selected={
                    attributes?.find(val => val.classification === categoryObject.classification)?.selected || []
                  }
                  onChange={selectedValues => handleChange(selectedValues, categoryObject.classification)}
                  fullScreen={fullScreen}
                  codeSnippetAvailableParameters={codeSnippetAvailableParameters}
                />
              </AttributesWrapper>
            )
          );
        })}
      </>
    </PortalBuilderSection>
  );
};

export default AttributesBuilder;
