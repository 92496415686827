import { getSdkApi } from '@biotmed/sdk-api-provider';
import { AttributeMetadataResponse } from '@biotmed/settings-sdk';
import { AxiosResponse } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { AttributeTypesEnum } from '../../../../utils/attributeTypeEnum';
import { actions } from './slice';
import { actions as loginActions } from '../../login/modules/slice';

function* loadLocales() {
  const response: AxiosResponse<AttributeMetadataResponse> = yield call(
    getSdkApi().settings?.attributesApi?.getTypeMetadata,
    AttributeTypesEnum.Locale,
  );
  if (response.data.fixedSelectableValues) {
    yield put(actions.onLoadLocalesSuccess(response.data.fixedSelectableValues));
  }
}

function* loadTimezones() {
  const response: AxiosResponse<AttributeMetadataResponse> = yield call(
    getSdkApi().settings?.attributesApi?.getTypeMetadata,
    AttributeTypesEnum.Timezone,
  );
  if (response.data.fixedSelectableValues) {
    yield put(actions.onLoadTimezonesSuccess(response.data.fixedSelectableValues));
  }
}

function* loadAttributeSelectableValues() {
  try {
    yield loadLocales();
    yield loadTimezones();
  } catch (e) {
    console.log('Error while trying to load Attribute selectable values', e);
  }
}

export default function* watchEntityActions() {
  yield all([takeLatest(loginActions.loginSuccess, loadAttributeSelectableValues)]);
}
