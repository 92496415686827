import React from 'react';
import { getEntityTypeIntlDisplayName, joinFieldPath } from '@biotmed/data-components';
import {
  AllPortalAttributesGroup,
  AvailablePortalAttribute,
  AvailableTab,
  AvailableTabTypeEnum,
  GetViewResponse,
  MeasurementPortalAttributes,
  SelectedPortalAttribute,
  SelectedTab,
  SelectedTabTypeEnum,
  UpdateViewRequest,
  View,
  ViewTabs,
  ViewTabsSupportedTypesEnum,
  ViewTypeEnum,
} from '@biotmed/settings-sdk';
import { IntlShape } from 'react-intl';
import { getDummyDescriptor } from '@biotmed/base-components';
import { viewTypesDictionary } from './dictionaries';

export const getTabIdByTypeMapper = (
  tabType: ViewTabsSupportedTypesEnum | AvailableTabTypeEnum | SelectedTabTypeEnum,
) => {
  const map = new Map<
    ViewTabsSupportedTypesEnum | AvailableTabTypeEnum | SelectedTabTypeEnum,
    (tab: AvailableTab | SelectedTab) => any
  >([
    [
      ViewTabsSupportedTypesEnum.Template || SelectedTabTypeEnum.Template || AvailableTabTypeEnum.Template,
      tab => tab?.template?.id,
    ],
    [
      ViewTabsSupportedTypesEnum.ReverseReference ||
        SelectedTabTypeEnum.ReverseReference ||
        AvailableTabTypeEnum.ReverseReference,
      tab => `${tab?.reverseReference?.basePath ?? ''}.${tab?.reverseReference?.name}`,
    ],
  ]);

  return map.get(tabType);
};

export const mapListByTypeKey = <T,>(tabsList: T[]) => {
  return tabsList.reduce<Record<string, T[]>>((prevVal, tab: any) => {
    const object = prevVal;
    if (tab?.type) {
      if (!object[tab.type]) {
        object[tab.type] = [];
      }
      object[tab.type].push(tab);
    }
    return object;
  }, {});
};

export const mapViewLabel = (viewItem: View, intl: IntlShape) => {
  const { entityTypeName, type, template } = viewItem;
  const entityType = entityTypeName && intl.formatMessage(getEntityTypeIntlDisplayName(entityTypeName));
  const viewType = intl.formatMessage(viewTypesDictionary[type] || getDummyDescriptor(type));
  return {
    [ViewTypeEnum.EntityList]: `${entityType} - ${viewType}`,
    [ViewTypeEnum.TemplateExpand]: `${entityType} - ${template?.displayName} - ${viewType}`,
    [ViewTypeEnum.TemplatePreview]: `${entityType} - ${template?.displayName} - ${viewType}`,
    [ViewTypeEnum.TemplateList]: `${entityType} - ${template?.displayName} - ${viewType}`,
    [ViewTypeEnum.Main]: `${viewType}`,
    [ViewTypeEnum.OperationalEntityList]: `${entityType} - ${viewType}`,
    [ViewTypeEnum.OperationalTemplatePreview]: `${entityType} - ${template?.displayName} - ${viewType}`,
    default: `${entityType} - ${template?.displayName || ''} - ${viewType}`,
  };
};

export const mapAttributes = (attributes: Array<AvailablePortalAttribute>) =>
  attributes.reduce<Record<string, AvailablePortalAttribute>>((obj, item) => {
    const object = obj;
    object[joinFieldPath(item.name || '', item.basePath)] = item;
    return object;
  }, {});

export const adaptAllAttributesToUpdateRequest: (selectedValues: GetViewResponse) => UpdateViewRequest = (
  selectedValues: GetViewResponse,
) => {
  return {
    ...mapAllPortalAttributesGroupToUpdateViewRequest(selectedValues?.attributes),
    ...mapMeasurementsToAttributeBuilderDataStructure(selectedValues?.measurements),
    ...mapTabsToAttributeBuilderDataStructure(selectedValues?.tabs),
  };
};

export const mapAllPortalAttributesGroupToUpdateViewRequest = (attributes?: Array<AllPortalAttributesGroup>) => ({
  attributes: attributes
    ? attributes.map(attribute => ({
        classification: attribute.classification,
        selected: attribute.selected,
      }))
    : undefined,
});

export const mapTabsToAttributeBuilderDataStructure = (tabs?: ViewTabs) => ({
  tabs: tabs ? tabs.selected : undefined,
});

export const mapMeasurementsToAttributeBuilderDataStructure = (measurements?: MeasurementPortalAttributes) => ({
  measurements: measurements ? measurements.selected : undefined,
});

// TODO: this might need a special interface that draws from items in the sdk for measurements
export const mapPortalMeasurementsBackToUpdateViewRequest = (measurements: any) => {
  const measurementsRequestItems: { [X in string]: any } = {};

  measurements.forEach((item: any) => {
    if (item.classification) measurementsRequestItems[String(item.classification)] = item.selected;
  });
  return measurementsRequestItems;
};

export const filterSelectedAttributesByExistAttributes = (
  selectedAttributes: Array<SelectedPortalAttribute>,
  mappedAttributes: any,
) => {
  return selectedAttributes.filter(selected => {
    const key = selected.basePath ? selected.basePath + selected.name : selected.name;
    return mappedAttributes[key as string];
  });
};
