import { EnvType } from '@biot/env';

declare global {
  interface Window {
    env: any;
  }
}

const env: EnvType = { ...process.env, ...window.env };

const APP_CONFIG = Object.freeze({
  ...process.env,
  APP_DOMAIN: env.REACT_APP_DOMAIN,
  API_URL: env.REACT_APP_API_URL,
  PLATFORMS_CONSOLE_URL: env.REACT_APP_PLATFORMS_CONSOLE_URL,
  PLATFORMS_MANUFACTURER_URL: env.REACT_APP_PLATFORMS_MANUFACTURER_URL,
  PLATFORMS_ORGANIZATION_URL: env.REACT_APP_PLATFORMS_ORGANIZATION_URL,
  REQUEST_FEAUTE_URL: env.REACT_APP_REQUEST_FEAUTE_URL,
  API_REFERENCE_URL: env.REACT_APP_API_REFERENCE_URL,
  DEV_GUIDE_URL: env.REACT_APP_DEV_GUIDE_URL,
  TERMS: env.REACT_APP_TERMS,
  PRIVACY: env.REACT_APP_PRIVACY,
  TEMPLATES_LEARN_MORE_URL: env.REACT_APP_TEMPLATES_LEARN_MORE_URL,
  PORTAL_BUILDER_LEARN_MORE_URL: env.REACT_APP_PORTAL_BUILDER_LEARN_MORE_URL,
  DEVICE_TEMPLATE_LEARN_MORE_URL: env.REACT_APP_DEVICE_TEMPLATE_LEARN_MORE_URL,
  ORGANIZATION_TEMPLATE_LEARN_MORE_URL: env.REACT_APP_ORGANIZATION_TEMPLATE_LEARN_MORE_URL,
  ORGANIZATION_USER_TEMPLATE_LEARN_MORE_URL: env.REACT_APP_ORGANIZATION_USER_TEMPLATE_LEARN_MORE_URL,
  CAREGIVER_TEMPLATE_LEARN_MORE_URL: env.REACT_APP_CAREGIVER_TEMPLATE_LEARN_MORE_URL,
  PATIENT_TEMPLATE_LEARN_MORE_URL: env.REACT_APP_PATIENT_TEMPLATE_LEARN_MORE_URL,
  GENERIC_ENTITY_TEMPLATE_LEARN_MORE_URL: env.REACT_APP_GENERIC_ENTITY_TEMPLATE_LEARN_MORE_URL,
  USAGE_TYPE_TEMPLATE_LEARN_MORE_URL: env.REACT_APP_USAGE_TYPE_TEMPLATE_LEARN_MORE_URL,
  COMMANDS_TEMPLATE_LEARN_MORE_URL: env.REACT_APP_COMMANDS_TEMPLATE_LEARN_MORE_URL,
  PLUGIN_CONFIGURATION_LEARN_MORE_URL: env.REACT_APP_PLUGIN_CONFIGURATION_LEARN_MORE_URL,
  REGISTRATION_CODE_TEMPLATE_LEARN_MORE_URL: env.REACT_APP_REGISTRATION_CODE_TEMPLATE_LEARN_MORE_URL,
  ENVIRONMENT_CONFIGURATION_LEARN_MORE_URL: env.REACT_APP_ENVIRONMENT_CONFIGURATION_LEARN_MORE_URL,
  NO_CODE_PARAMETERS_LEARN_MORE_URL: env.REACT_APP_NO_CODE_PARAMETERS_LEARN_MORE_URL,
  PATIENT_ALERT_TEMPLATE_LEARN_MORE_URL: env.REACT_APP_PATIENT_ALERT_TEMPLATE_LEARN_MORE_URL || '', // TODO: Once we have the url in our environment variables remove these two ||s
  DEVICE_ALERT_TEMPLATE_LEARN_MORE_URL: env.REACT_APP_DEVICE_ALERT_TEMPLATE_LEARN_MORE_URL || '',
  ENVIRONMENT_NAME: env.REACT_APP_ENVIRONMENT_NAME,
  VERSIONS_URL: env.REACT_APP_VERSIONS_URL,
  TRANSLATION_FILES_BASE_URL: env.REACT_APP_TRANSLATION_FILES_BASE_URL,
  IDLE_TIMEOUT_MS: env.REACT_APP_IDLE_TIMEOUT_MS,
  EPIC_RELEASE_FLAGS_URL: env.REACT_APP_EPIC_RELEASE_FLAGS_URL,
  CONTACT_BIOT_SUPPORT_URL: env.REACT_APP_CONTACT_BIOT_SUPPORT_URL || 'https://docs.biot-med.com/docs/customer-support',
  ADB_LEARN_MORE_URL: env.REACT_APP_ADB_LEARN_MORE_URL,
});

export default APP_CONFIG;
