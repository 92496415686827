import { GetTemplateResponse } from '@biotmed/settings-sdk';
import { SelectChangeEvent } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { AdaptedMultiSelectProps } from './ReferenceType';
import { MultiSelectStyled } from './ReferenceType.styled';

export const AdaptedMultiSelect: React.FC<AdaptedMultiSelectProps> = props => {
  const { handleChangeEntityTemplate, entityTemplateList, entityTemplate, attrFieldName, disabled } = props;

  const intl = useIntl();

  // TODO: Temporary! Remove this when the BE updates the API so it can send null (for "any")
  const adaptValue = (value: string[] | null) => {
    return Array.isArray(value) && !value.length ? null : value;
  };

  // TODO: Temporary! Remove this when the BE updates the API so it can receive null (for "any")
  const adaptChangeValue = (event: SelectChangeEvent<unknown>) =>
    event.target.value === null ? [] : event.target.value;

  return (
    <MultiSelectStyled
      disabled={disabled}
      onChange={(event: SelectChangeEvent<unknown>) => handleChangeEntityTemplate(adaptChangeValue(event))}
      // TODO: WHEN THE GET_TEMPLATE_RESPONSE INTERFACE UPDATE IN SDK REMOVE '& {DISPLAY_NAME:STRING}'.
      selectList={
        entityTemplateList?.map((template: GetTemplateResponse) => ({
          value: template.id || '',
          title: template.displayName,
        })) || []
      }
      value={entityTemplate}
      defaultValue={adaptValue(entityTemplate)}
      inputProps={{
        variant: 'standard',
      }}
      name={`${attrFieldName}.referenceConfiguration.validTemplatesToReference`}
      placeholder={intl.formatMessage({
        id: 'template.modal.attribute.builtIn.Reference-type.entity-template.placeholder',
        defaultMessage: 'Select Template',
      })}
      withAny
      anyProps={{
        anyLabel: intl.formatMessage({
          id: 'template.modal.attribute.builtIn.Reference-type.entity-template.anyLabel',
          defaultMessage: 'Any',
        }),
      }}
    />
  );
};

export default AdaptedMultiSelect;
