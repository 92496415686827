import { Input, InputPropsType } from '@biotmed/base-components';
import { toJsonNameFormat } from 'src/utils/stringUtils';
import React, { useEffect, useRef, useState } from 'react';
import { TooltipButton } from './Tooltip';

export type InputWithTooltipProps<WithCharactersCounter extends boolean | undefined = undefined> =
  InputPropsType<WithCharactersCounter> & {
    bold?: boolean;
    tooltipProps?: TooltipProps<WithCharactersCounter>;
    readOnly?: boolean;
    value?: string;
    onOpenTooltip?: () => void;
    onCloseTooltip?: () => void;
  };

export type TooltipProps<WithCharactersCounter extends boolean | undefined = undefined> = {
  readOnly?: boolean;
  autoChange?: boolean;
  inputProps?: InputPropsType<WithCharactersCounter>;
  handleSave?: (value: string | null) => void;
  formatTooltipValue?: (value: string) => string;
};

/**
 * This Component renders 2 regular inputs - one for the main input and one for the tooltip
 *
 * onChange -
 * every wrapper of this component should set the value of the tooltip field when the main input field changes
 */
const InputWithTooltip = <WithCharactersCounter extends boolean | undefined = undefined>(
  props: InputWithTooltipProps<WithCharactersCounter>,
) => {
  const {
    tooltipProps: { readOnly: tooltipReadOnly = false, ...restTooltipProps } = {},
    value,
    InputProps,
    onOpenTooltip,
    onCloseTooltip,
    className,
    ...rest
  } = props;

  const [openTooltip, setOpenTooltip] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (openTooltip) {
      onOpenTooltip?.();
    } else {
      onCloseTooltip?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openTooltip]);

  const handleSave = (newValue: string | null) => {
    restTooltipProps?.handleSave?.(newValue);
    setOpenTooltip(false);
    inputRef?.current?.focus();
  };

  return (
    <Input
      {...rest}
      className={className}
      type="text"
      inputRef={inputRef}
      value={value || ''}
      InputProps={{
        ...InputProps,
        endAdornment: (
          <TooltipButton
            {...restTooltipProps}
            open={openTooltip}
            onOpenChange={setOpenTooltip}
            handleSave={handleSave}
            readOnly={tooltipReadOnly}
          />
        ),
      }}
    />
  );
};

export default InputWithTooltip;
