import { Entity } from 'src/redux/data/entity/modules/interfaces';
import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { childrenTemplatesFieldName, entityTemplateFieldName } from 'src/routes/Templates/modules/constant';
import { EntityType } from '@biotmed/data-components';
import { EntityTemplateForm } from '../../../Template';
import { getAttributeToAdjustByParent, adjustTemplateAttributesByParent } from './utils';

const useAdjustFormChildrenEntity = (entity: Entity) => {
  const formik = useFormikContext<EntityTemplateForm>();

  // This gets the name of the attribute to be modified by the template's parent template (from ParentTemplateNameToChildAttributeTemplateName enum)
  const attributeToAdjustByParent = getAttributeToAdjustByParent(entity.parentTemplateType as EntityType);

  // These useEffect updates the formik values.
  // The formik values are for edit children type template

  useEffect(() => {
    const childrenTypes = [...formik.values?.[childrenTemplatesFieldName]] ?? [];

    childrenTypes?.forEach((childrenType: any, index: number) => {
      // This finds the attribute to change (a reference attribute), in the array of attributes, and modifies it's parentTemplateName
      const builtInAttributes = adjustTemplateAttributesByParent(
        childrenType.builtInAttributes,
        formik.values?.[entityTemplateFieldName]?.displayName,
        attributeToAdjustByParent,
      );

      if (attributeToAdjustByParent)
        formik.setFieldValue(`${childrenTemplatesFieldName}.${index}`, {
          ...formik.values?.[childrenTemplatesFieldName][index],
          ...(builtInAttributes ? { builtInAttributes } : {}),
        });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useAdjustFormChildrenEntity;
