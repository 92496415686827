import React from 'react';
import { Card } from '@biotmed/base-components';
import { TemplateAttributeTypeEnum } from '@biotmed/settings-sdk';
import { useFormikContext } from 'formik';
import { NameSection, TemplateAttributeTypesComponentsMap } from 'src/components/AttributeLayout';
import { EntityTemplateForm } from '../../Template';
import TemplateAssignOwnerOrganization from '../../formStepsComponents/Overview/TemplateAssignOwnerOrganization';

export interface TemplateAttributeProps {
  attrFieldName: string;
  type?: TemplateAttributeTypeEnum;
  attributeTouched?: any;
  attributeValues?: any;
  attributeInitialValues: any;
  attributeErrors?: any;
  handleChangeValue: <V>(value: V) => void;
  isOrganizationSelectionAllowed: boolean;
}

const TemplateAttribute = (props: Omit<TemplateAttributeProps, 'handleChangeValue'>) => {
  const { type, attributeValues, attributeTouched, attributeErrors, attrFieldName, isOrganizationSelectionAllowed } =
    props;
  const formik = useFormikContext<EntityTemplateForm>();

  const TypeComponent =
    TemplateAttributeTypesComponentsMap[type as string as keyof typeof TemplateAttributeTypesComponentsMap] ?? null;

  const handleChangeDisplayName = (name: string | null) => {
    formik.setFieldValue(`${attrFieldName}.displayName`, name, false);
  };

  const handleChangeValue = <V,>(value: V) => {
    formik.setFieldValue(`${attrFieldName}.value`, value, false);
    formik.setFieldTouched(`${attrFieldName}.value`, true, false);
  };

  return (
    <Card padding="26px 26px 12px">
      <NameSection
        canChangeTooltipValue={false}
        value={attributeValues.displayName}
        tooltipValue={attributeValues.name && attributeValues.name}
        onChange={handleChangeDisplayName}
        autoFocus={false}
        fieldName={`${attrFieldName}.displayName`}
        tooltipFieldName={`${attrFieldName}.name`}
        helperText={attributeTouched?.displayName ? attributeErrors?.displayName : ''}
        error={attributeTouched?.displayName && Boolean(attributeErrors?.displayName)}
        attributeId={attributeValues.id || attributeValues.arrayId}
      />
      {/* SOFT-6244: TypeComponent - Maybe we should change multiSelect to autocomplete.  not sure...? */}
      <TypeComponent {...props} handleChangeValue={handleChangeValue} />
      {isOrganizationSelectionAllowed && (
        <TemplateAssignOwnerOrganization
          attrFieldName={`${attrFieldName}.organizationSelection.configuration`}
          configurationValues={attributeValues.organizationSelection.configuration}
        />
      )}
    </Card>
  );
};

export default TemplateAttribute;
