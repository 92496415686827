import React from 'react';
import { InputPropsType } from '@biotmed/base-components';
import { TransparentEditInput, TransparentNonEditInput } from './TransparentInput.styled';
import EditInputAdornment from '../EditAdormentInput';

export interface TransparentInputProps {
  readonly?: boolean | undefined;
}

const TransparentInput: React.FC<InputPropsType & TransparentInputProps> = props => {
  const { value, readonly = false, ...restProps } = props;

  return readonly ? (
    <TransparentNonEditInput>{value as string}</TransparentNonEditInput>
  ) : (
    <TransparentEditInput
      value={value}
      {...restProps}
      InputProps={{
        startAdornment: <EditInputAdornment position="end" />,
      }}
    />
  );
};
export default TransparentInput;
