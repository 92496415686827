import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'src/redux/data/template';
import { CustomAttribute } from '@biotmed/settings-sdk';
import { useIntl } from 'react-intl';
import messages from '../dictionaries';

interface ForceConfirmationText {
  title: string;
  submitLabel: string;
  information: () => ReactNode;
}

interface ForceConfirmationParams<T> {
  textMapper: (
    errorCode: T,
    errorDetails?: any,
    attributesMapper?: { [key: string]: CustomAttribute },
  ) => ForceConfirmationText;
  attributesMapper?: { [key: string]: CustomAttribute };
  forceErrorCode: T;
}

const useForceConfirmationProps = <T,>(params: ForceConfirmationParams<T>) => {
  const { attributesMapper = {}, textMapper, forceErrorCode } = params;
  const errorDetails = useSelector(selectors.getErrorDetails);
  const intl = useIntl();

  const DEFAULT_PROPS = {
    title: intl.formatMessage(messages.FORCE_ERROR_DEFAULT_TITLE),
    submitLabel: intl.formatMessage(messages.FORCE_ERROR_DEFAULT_SUBMIT),
    information: () => <>{intl.formatMessage(messages.FORCE_ERROR_DEFAULT_CONSEQUENCES_ACTION)}</>,
  };

  if (forceErrorCode) {
    const textProps = textMapper(forceErrorCode, errorDetails, attributesMapper);
    if (textProps) {
      return textProps;
    }
  }
  return DEFAULT_PROPS;
};

export default useForceConfirmationProps;
