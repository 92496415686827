import React from 'react';
import { DefaultWrapper } from './DefaultWrapper';
import { GetExtractFunction, SubFieldMapper, WrapperProps } from './types';

export const getInputsSpreader = <
  FV extends Record<string, unknown> = Record<string, unknown>,
  EXTRA_PROPS = Record<string, unknown>,
  UNIQUE_RENDERER_PROPS = unknown,
  V = unknown,
>(
  subFieldMapper: SubFieldMapper<EXTRA_PROPS, UNIQUE_RENDERER_PROPS, V>,
  Wrapper: React.FC<React.PropsWithChildren<WrapperProps<V, UNIQUE_RENDERER_PROPS>>> = DefaultWrapper as React.FC<
    WrapperProps<V, UNIQUE_RENDERER_PROPS>
  >,
  getExtractFunction: GetExtractFunction<FV, EXTRA_PROPS, UNIQUE_RENDERER_PROPS, V>,
  fieldValues: FV,
) => {
  const extractSubFieldValues = getExtractFunction(fieldValues);

  const subFieldMapperArray = Array.isArray(subFieldMapper) ? subFieldMapper : [subFieldMapper];

  return (
    <Wrapper>
      {subFieldMapperArray.map(subField => {
        const renderProps = extractSubFieldValues(subField);
        return subField.render({ renderProps });
      })}
    </Wrapper>
  );
};

export default getInputsSpreader;
