import { fontWeightNameMappings, Link } from '@biotmed/base-components';
import styled from 'styled-components';

export const StyledLink = styled(Link)`
  display: flex;
  alignitems: center;
`;

export const PluginPreviewRow = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  flex-wrap: wrap;
  overflow-wrap: anywhere;
`;

export const PluginPreviewBlock = styled.div``;

export const PluginPreviewKey = styled.div`
  font-weight: ${fontWeightNameMappings.medium};
`;

export const PluginPreviewContainer = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: column;
`;
