import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { EntityType } from '@biotmed/settings-sdk';
import { RootState } from '../../../reducer';
import { DATA_STATE_KEY } from '../../constants';

export const STATE_KEY = 'entity';

interface EntityState {
  entityTypes: { [x: string]: EntityType };
}

export const getInitialState = (state?: EntityState): EntityState => ({
  entityTypes: {},
});

const mapEntityTypes = (entityTypeList: EntityType[]) =>
  entityTypeList.reduce<{ [name: string]: EntityType }>((obj, entity) => {
    const object = obj;
    object[entity.name] = entity;
    return object;
  }, {});

/* eslint-disable no-param-reassign */
const slice = createSlice({
  name: STATE_KEY,
  initialState: getInitialState(),
  reducers: {
    loadEntityTypeSucceeded: (state, action: PayloadAction<EntityType[]>) => {
      state.entityTypes = mapEntityTypes(action.payload);
    },
  },
});
/* eslint-enable no-param-reassign */

const getState = (state: RootState) => state[DATA_STATE_KEY][STATE_KEY] || getInitialState();

const selectEntityTypes = createSelector(getState, state => {
  return state.entityTypes;
});
const selectEntityTypeNamesHierarchyTree = createSelector([selectEntityTypes], entityTypes => {
  const hierarchy: { [name: string]: string[] } = {};
  Object.keys(entityTypes).forEach(key => {
    const parent = entityTypes[key]?.parentTemplateType;
    if (parent) {
      if (!hierarchy[parent]) {
        hierarchy[parent] = [];
      }
      hierarchy[parent].push(key);
    }
  });

  return hierarchy;
});

const combineEntityTypeWithChildren = (
  entityType: EntityType,
  entityTypes: { [x: string]: EntityType },
  hierarchy: { [x: string]: string[] },
) => {
  const children = hierarchy?.[entityType?.name]?.map(childName => entityTypes[childName]);
  return { ...entityType, children };
};

const SelectSupportedToAddEntityTypes = createSelector(
  [selectEntityTypes, selectEntityTypeNamesHierarchyTree],
  (entityTypes, hierarchy) => {
    return Object.values(entityTypes)
      .filter(item => item.addingTemplatesSupported && !item.parentTemplateType)
      .map(entityType => combineEntityTypeWithChildren(entityType, entityTypes, hierarchy));
  },
);

const SelectOrphanEntityTypes = createSelector([selectEntityTypes], entityTypes => {
  return Object.values(entityTypes).filter(item => !item.parentTemplateType);
});

const selectEntityTypeNames = createSelector([selectEntityTypes], entityTypes => Object.keys(entityTypes));

const selectEntityByEntityName = (entityName: string) =>
  createSelector([selectEntityTypes, selectEntityTypeNamesHierarchyTree], (entityTypes, hierarchy) =>
    combineEntityTypeWithChildren(entityTypes[entityName], entityTypes, hierarchy),
  );

export const selectors = {
  selectEntityTypeNames,
  SelectSupportedToAddEntityTypes,
  selectEntityByEntityName,
  SelectOrphanEntityTypes,
  selectEntityTypes,
};

export const actions = { ...slice.actions };

const { reducer } = slice;
export default reducer;
