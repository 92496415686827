import React, { useEffect, useRef } from 'react';
import { useTheme } from 'styled-components';
import { StyledJSONButton, StyledJSONIcon } from '../InputWithTooltip.styled';
import Tooltip from './Tooltip';
import { TooltipContentProps } from './TooltipContent';
import { ReactComponent as JsonIcon } from '../../../images/json-button.svg';

const JSONButtonIcon: React.FC<{ color?: string }> = props => {
  const { color } = props;

  return (
    <StyledJSONIcon>
      <JsonIcon color={color} />
    </StyledJSONIcon>
  );
};

export interface TooltipButtonProps extends TooltipContentProps {
  open: boolean;
  onOpenChange: (value: boolean) => void;
  disabled?: boolean;
}
const TooltipButton: React.FC<TooltipButtonProps> = props => {
  const { open, onChange, onOpenChange, onClickSave, name, error, disabled = false, ...restProps } = props;
  const theme = useTheme();

  // JSON Name popper control
  const JSONButtonRef = useRef(null);
  useEffect(() => {
    if (error) {
      onOpenChange(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const handleJSONButtonMouseDown = (event: React.MouseEvent<HTMLElement>) => {
    if (!open) event.preventDefault();
    toggleJSONNamePopper(true);
  };

  const toggleJSONNamePopper = (value: undefined | boolean) => {
    if (!error || !open) {
      onOpenChange(!open);
    }
  };
  const handleClickAway = (value: string | null) => {
    if (open && (!error || value)) {
      onChange?.(value, 'clickAway');
      onOpenChange(false);
    }
  };

  return (
    <Tooltip
      name={name}
      open={open}
      error={error}
      onTogglePopper={toggleJSONNamePopper}
      anchorEl={JSONButtonRef.current}
      handleClickAway={handleClickAway}
      onChange={onChange}
      onClickSave={onClickSave}
      {...restProps}
    >
      <StyledJSONButton
        clickable
        onMouseDown={handleJSONButtonMouseDown}
        classes={{ clickable: 'chipControl' }}
        ref={JSONButtonRef}
        icon={
          // eslint-disable-line
          <JSONButtonIcon color={disabled ? theme.palette.grayScale.darker : theme.palette.primary.medium} />
        } // eslint-disable-line
        $disabled={disabled}
      />
    </Tooltip>
  );
};

export default TooltipButton;
