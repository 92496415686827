import React from 'react';
import { splitLocale } from '@biotmed/i18n';
import ISO6391 from 'iso-639-1';
import { InfoLabel } from '@biotmed/base-components';
import { useIntl } from 'react-intl';
import { LanguageColumn as StyledLanguageColumn } from './UploadTranslationsModal.styled';

export interface LanguageColumnProps {
  systemLocale?: string;
  defaultLocale?: string;
  value: string;
}

export const LanguageColumn = (props: LanguageColumnProps) => {
  const { systemLocale, defaultLocale, value } = props;
  const { language } = splitLocale(value);
  const intl = useIntl();

  const languageName = ISO6391.getName(language) || language;

  const SystemLocale = systemLocale === value && (
    <InfoLabel
      text={intl.formatMessage({
        id: 'translations.table.column-language.english-locale',
        defaultMessage: 'English Locale',
      })}
      tooltipText={intl.formatMessage({
        id: 'translations.table.column-language.english-locale.info-tooltip',
        defaultMessage:
          'The English Locale is the one used by BioT Console. It cannot be deleted. You cannot upload a translation file for the English Locale. You may use the BioT Console to directly change any of the English labels.',
      })}
    />
  );

  const DefaultLocale = defaultLocale === value && (
    <InfoLabel
      text={intl.formatMessage({
        id: 'translations.table.column-language.default-locale',
        defaultMessage: 'Default Locale',
      })}
      tooltipText={intl.formatMessage({
        id: 'translations.table.column-language.default-locale.info-tooltip',
        defaultMessage:
          'Default Locale is the locale you chose as the default to be used when users open their portals and apps for the first time.',
      })}
    />
  );

  return (
    <StyledLanguageColumn>
      {languageName}
      {SystemLocale}
      {DefaultLocale}
    </StyledLanguageColumn>
  );
};

export default LanguageColumn;
