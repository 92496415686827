import { defineMessages } from 'react-intl';

export const uploadTranslationsErrorDictionary = defineMessages({
  INVALID_FILE_FORMAT: {
    id: 'translations.translations-file.upload.error.invalid-file-format',
    defaultMessage: 'Invalid file format',
  },
  // TODO: maybe we should change to "english locale" instead of "system locale"
  UPDATE_SYSTEM_LOCALE_TRANSLATIONS_IS_NOT_ALLOWED: {
    id: 'translations.translations-file.upload.error.update-system-locale-translations',
    defaultMessage: 'Update system locale translations is not allowed',
  },
  LOCALE_NOT_FOUND: {
    id: 'translations.translations-file.upload.error.locale-not-found',
    defaultMessage: 'Locale not found',
  },
});
