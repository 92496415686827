import React, { useState } from 'react';
import { actionIcons, DocumentFileTypes, FileUploadInputModes, Icon, otherIcons } from '@biotmed/base-components';
import { ApiError, EmbeddedError, errorNotice, ErrorTypeEnum, successNotice } from '@biotmed/system-notifications';
import axios, { CancelTokenSource } from 'axios';
import { getSdkApi } from '@biotmed/sdk-api-provider';
import { InvalidTranslation } from '@biotmed/settings-sdk';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';
import { useDispatch } from 'react-redux';
import { flushSync } from 'react-dom';
import { UPLOAD_TRANSLATIONS_FILE_FIELD_CONTAINER_ID } from '../constants';
import {
  InvalidTranslationList,
  InvalidTranslationListItem,
  InvalidTranslationsContainer,
  InvalidTranslationsTitle,
  ValidTranslationsContainer,
  ValidTranslationsSubtitle,
  ValidTranslationsTitle,
  StyledFileFieldWrapper,
  InvalidTranslationItemWarningMessage,
  InvalidTranslationItemKey,
} from './UploadTranslationsModal.styled';
import { uploadTranslationsErrorDictionary } from '../errorsDictionary';

export interface ChooseTranslationsFileProps {
  file?: File;
  locale: string;
  error?: string;
  afterValidation: (isValid: boolean) => void;
}

export const ChooseTranslationsFile = (props: ChooseTranslationsFileProps) => {
  const { file, locale, error, afterValidation } = props;
  const [cancelToken, setCancelToken] = useState<CancelTokenSource>();
  const [inputMode, setInputMode] = useState<FileUploadInputModes>(FileUploadInputModes.empty);
  const [invalidTranslations, setInvalidTranslations] = useState<InvalidTranslation[]>();
  const intl = useIntl();
  const theme = useTheme();
  const dispatch = useDispatch();

  const validateFile = async (fileToValidate: File) => {
    if (fileToValidate) {
      const axiosCancelToken = axios.CancelToken?.source();
      setCancelToken(axiosCancelToken);
      const response = await getSdkApi().settings.translationApi.validateLocaleTranslations(locale, fileToValidate, {
        cancelToken: axiosCancelToken?.token,
      });

      if (response) {
        setInputMode(FileUploadInputModes.loaded);
        setInvalidTranslations(response.data.invalidTranslations);
        afterValidation(response.data.invalidTranslations?.length === 0);
      }

      dispatch(
        successNotice({
          containerId: UPLOAD_TRANSLATIONS_FILE_FIELD_CONTAINER_ID,
        }),
      );
    }
  };

  const handleCancel = () => {
    if (cancelToken) {
      cancelToken.cancel?.();
    }
  };

  const handleDelete = (setFile: (value: any, shouldValidate?: boolean | undefined) => void) => () => {
    setFile(undefined);
    setInvalidTranslations(undefined);
    afterValidation(false);
    setInputMode(FileUploadInputModes.empty);
  };

  const onUploadFile =
    (setFile: (value: any, shouldValidate?: boolean | undefined) => void) =>
    async (getUserUploadedFile: (acceptedFileTypes?: string) => Promise<File>) => {
      try {
        const uploadedFile = await getUserUploadedFile(DocumentFileTypes.CSV);
        setInputMode(FileUploadInputModes.uploading);

        await validateFile(uploadedFile);
        flushSync(() => setFile(uploadedFile));
      } catch (e: any) {
        setInputMode(FileUploadInputModes.errorUploading);

        if (e.code === 'ERR_CANCELED') {
          console.error('Validation canceled by the user');
          setInputMode(FileUploadInputModes.empty);
          flushSync(() => setFile(undefined));
        } else {
          const apiError = (e?.response?.data || e) as ApiError;

          console.error('Failed validating file, error', apiError);
          dispatch(
            errorNotice({
              type: ErrorTypeEnum.EMBEDDED,
              containerId: UPLOAD_TRANSLATIONS_FILE_FIELD_CONTAINER_ID,
              errorParams: {
                error: apiError,
                dictionary: uploadTranslationsErrorDictionary,
              },
            }),
          );
        }
      }
    };

  const renderValidationInfo = () => {
    return invalidTranslations && invalidTranslations?.length > 0 ? (
      <InvalidTranslationsContainer>
        <InvalidTranslationsTitle>
          <Icon IconComponent={otherIcons.error} color={theme.palette.warning.dark} />
          {intl.formatMessage(
            {
              id: 'upload-translations.modal.invalid-translations-title',
              defaultMessage:
                '{amount, plural, =0 {} one {{amount} warning} other {{amount} warnings}} found in this file',
            },
            { amount: invalidTranslations?.length },
          )}
        </InvalidTranslationsTitle>
        <InvalidTranslationList>
          {invalidTranslations?.map((invalidTranslation, index) => {
            return (
              <InvalidTranslationListItem
                $index={index}
                $totalItems={invalidTranslations.length}
                key={invalidTranslation.translationKey}
              >
                <InvalidTranslationItemWarningMessage>{`${invalidTranslation.error}: `}</InvalidTranslationItemWarningMessage>
                <InvalidTranslationItemKey>{invalidTranslation.translationKey}</InvalidTranslationItemKey>
              </InvalidTranslationListItem>
            );
          })}
        </InvalidTranslationList>
      </InvalidTranslationsContainer>
    ) : (
      <ValidTranslationsContainer>
        <ValidTranslationsTitle>
          <Icon IconComponent={actionIcons.enable} color={theme.palette.secondary.dark} />
          {intl.formatMessage({
            id: 'upload-translations.modal.valid-translations-title',
            defaultMessage: 'File Valid',
          })}
        </ValidTranslationsTitle>
        <ValidTranslationsSubtitle>
          {intl.formatMessage({
            id: 'upload-translations.modal.valid-translations-subtitle',
            defaultMessage: '0 warnings',
          })}
        </ValidTranslationsSubtitle>
      </ValidTranslationsContainer>
    );
  };

  return (
    <>
      <StyledFileFieldWrapper
        name="file"
        {...(file ? { fileData: { name: file.name, mimeType: file.type, size: file.size } } : {})}
        onUploadFile={onUploadFile}
        onCancel={handleCancel}
        onDeleteFile={handleDelete}
        inputMode={inputMode}
        inputModesMessages={{
          [FileUploadInputModes.empty]: 'Choose File',
          [FileUploadInputModes.uploading]: 'Validating',
          [FileUploadInputModes.errorUploading]: 'Error Validating File',
          [FileUploadInputModes.deleting]: 'Deleting File',
          [FileUploadInputModes.loading]: 'Loading...',
        }}
        error={error}
      />
      <EmbeddedError containerId={UPLOAD_TRANSLATIONS_FILE_FIELD_CONTAINER_ID} />
      {file && invalidTranslations && renderValidationInfo()}
    </>
  );
};

export default ChooseTranslationsFile;
