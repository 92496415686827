import React from 'react';
import { SelectedMeasurementAttributesToIntlMapper } from '@biotmed/data-components';
import { useIntl } from 'react-intl';
import { SelectedMeasurementAttributesViewsEnum } from '@biotmed/settings-sdk';
import PortalCategoryInput from './PortalCategoryInput';

interface MeasurementViewsCategoryRendererProps {
  title: string;
  views: SelectedMeasurementAttributesViewsEnum[];
  defaultView: SelectedMeasurementAttributesViewsEnum;
  onChange: (
    views: SelectedMeasurementAttributesViewsEnum[],
    defaultView: SelectedMeasurementAttributesViewsEnum,
  ) => void;
  fullScreen: boolean;
}

interface InternalOption {
  value: SelectedMeasurementAttributesViewsEnum;
  label: string;
}

const READONLY_MEASUREMENT_OPTIONS: SelectedMeasurementAttributesViewsEnum[] = [
  SelectedMeasurementAttributesViewsEnum.History,
];

const MeasurementViewsCategoryRenderer = (props: MeasurementViewsCategoryRendererProps) => {
  const { views, defaultView, title, onChange, fullScreen } = props;

  const intl = useIntl();

  const getMeasurementOptionLabel = (view: SelectedMeasurementAttributesViewsEnum) => {
    return intl.formatMessage(SelectedMeasurementAttributesToIntlMapper[view]);
  };

  const renderSelection = (view: SelectedMeasurementAttributesViewsEnum) => {
    const defaultText = intl.formatMessage({
      id: 'MeasurementViewsCategoryRenderer.defaultView',
      defaultMessage: '(default)',
    });

    return `${getMeasurementOptionLabel(view)} ${view === defaultView ? defaultText : ''}`;
  };

  const viewToInternalItem = (view: SelectedMeasurementAttributesViewsEnum): InternalOption => {
    return {
      value: view,
      label: getMeasurementOptionLabel(view),
    };
  };

  const internalItemToView = (item: InternalOption) => item.value;

  const onCheckBoxToggle = (checked: boolean, item: InternalOption) => {
    let newViews: SelectedMeasurementAttributesViewsEnum[];

    if (checked) {
      newViews = views.filter(
        (view: SelectedMeasurementAttributesViewsEnum) => view !== internalItemToView(item) ?? [],
      );
    } else {
      newViews = [...(views ?? []), internalItemToView(item)];
    }

    onChange(newViews, newViews[0]);
  };

  const onOrderChange = (selections: SelectedMeasurementAttributesViewsEnum[]) => {
    const defaultVuew = selections[0];
    onChange(selections, defaultVuew);
  };

  return (
    <PortalCategoryInput
      title={title}
      options={Object.values(SelectedMeasurementAttributesViewsEnum).map(viewToInternalItem)}
      selections={views}
      renderOption={(option: InternalOption) => option.label}
      renderSelection={renderSelection}
      onCheckboxToggle={onCheckBoxToggle}
      onOrderChange={onOrderChange}
      fullScreen={fullScreen}
      optionsSorter={(a: InternalOption, b: InternalOption) => a.label.localeCompare(b.label)}
      extractSelectionKey={(selection: SelectedMeasurementAttributesViewsEnum) => selection}
      extractOptionKey={(option: InternalOption) => option.value}
      isOptionDisabled={(option: InternalOption) => READONLY_MEASUREMENT_OPTIONS.includes(option.value)}
    />
  );
};

export default MeasurementViewsCategoryRenderer;
