import React from 'react';
import { NumberFieldWrapper, NumberFieldWrapperProps } from '@biotmed/base-components';

import { editFieldInputProps, editFieldVariant } from './constants';

const EditNumberFieldWrapper = (props: NumberFieldWrapperProps) => {
  const { InputProps, ...restProps } = props;

  return (
    <NumberFieldWrapper
      {...restProps}
      {...editFieldVariant}
      InputProps={{
        ...InputProps,
        ...editFieldInputProps,
      }}
    />
  );
};

export default EditNumberFieldWrapper;
