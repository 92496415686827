import React, { useContext } from 'react';
import { useFormikContext } from 'formik';
import { MuiPickersAdapterContextValue } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { MuiPickersAdapterContext } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { EntityTemplateForm } from 'src/routes/Templates/components/Template';
import { DateTimeInput } from './styled/Renderers.styled';
import { DefaultValueExtractedRendererProps } from '../../modules';

const dateFns = new AdapterDateFns();

export type DateTimeRendererProps = DefaultValueExtractedRendererProps<Date | null> & {
  placeholder: string;
};

export const DateTimeRenderer: React.FC<DateTimeRendererProps> = props => {
  const { value, InputProps, error, helperText, name } = props;
  const { endAdornment, disabled, ...inputProps } = InputProps;

  const formik = useFormikContext<EntityTemplateForm>();

  const context = useContext(MuiPickersAdapterContext as React.Context<MuiPickersAdapterContextValue<Date> | null>);
  const { isValid, date } = context?.utils || dateFns;

  const handleChange = (_date: Date | null | undefined) => {
    // TODO: Can this be removed ? What is it's purpose?
    const newDate = _date && isValid(date(_date)) ? _date?.toISOString() : _date?.toString() ?? null;
    formik.setFieldTouched(name);
    formik.setFieldValue(name, newDate);
  };

  const handleMenuClose = () => {
    formik.setFieldTouched(name, true, false);
  };

  return (
    <DateTimeInput
      value={value}
      withTime
      disabled={disabled}
      inputProps={{ error, helperText, onBlur: handleMenuClose, name, ...inputProps }}
      datePickerProps={{ onClose: handleMenuClose }}
      onChange={handleChange}
    />
  );
};

export default DateTimeRenderer;
