import React from 'react';
import { useFormikContext } from 'formik';
import { EntityTemplateForm } from 'src/routes/Templates/components/Template';
import { LENGTH } from '@biotmed/data-components';
import { ParagraphInput } from './styled/Renderers.styled';
import { DefaultValueExtractedRendererProps } from '../../modules';

export type ParagraphRendererExtraProps = DefaultValueExtractedRendererProps<string> & { placeholder: string };

export const ParagraphRenderer: React.FC<ParagraphRendererExtraProps> = props => {
  const { name, value, error, helperText, InputProps, placeholder } = props;

  const formik = useFormikContext<EntityTemplateForm>();

  const handleBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = event => {
    formik.handleBlur(event);
  };
  const handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = event => {
    formik.setFieldValue(name, event.target.value);
    formik.setFieldTouched(name, true, false);
  };

  const { endAdornment, ...inputProps } = InputProps;

  const passedProps = {
    multiline: true,
    rows: 2,
    value,
    error,
    helperText,
    placeholder,
    onChange: handleChange,
    onBlur: handleBlur,
    ...inputProps,
  };

  return <ParagraphInput {...passedProps} withCharactersCounter maxLength={LENGTH.PARAGRAPH} />;
};

export default ParagraphRenderer;
