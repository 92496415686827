import { createSlice, createSelector, createAction, PayloadAction } from '@reduxjs/toolkit';
import { SubmitButtonStatus } from '@biotmed/base-components';
import {
  ExportExecutionResponse,
  ExportsMetadataResponse,
  FileConfigurationMetadataResponse,
  ImportExecutionResponse,
  ResetSystemRequest,
  StartExportConfigurationRequest,
} from '@biotmed/settings-sdk';
import { LoginRequestV2 } from '@biotmed/ums-sdk';
import { BasicReferencedEntityDataUUIDId } from '@biotmed/organization-sdk';
import { errorMatcher, successMatcher } from '@biotmed/system-notifications';
import { NEXT_CLICK_OPERATION_ID, START_RESET_OPERATION_ID } from './contants';

export const STATE_KEY = 'environment-configuration';

interface EnvironmentConfigurationState {
  exportButtonStatus: SubmitButtonStatus;
  lastExportMetadata?: ExportExecutionResponse;
  importButtonStatus: SubmitButtonStatus;
  lastImportMetadata?: ImportExecutionResponse;
  exportsMetadata?: ExportsMetadataResponse;
  loadingExportsMetadataTable: boolean;
  systemOwnerToken?: string;
  resetSystemNextButtonStatus: SubmitButtonStatus;
  resetSystemSubmitButtonStatus: SubmitButtonStatus;
  systemOwner?: BasicReferencedEntityDataUUIDId;
}

export const getInitialState = (state?: EnvironmentConfigurationState): EnvironmentConfigurationState => ({
  exportButtonStatus: SubmitButtonStatus.NORMAL,
  importButtonStatus: SubmitButtonStatus.NORMAL,
  loadingExportsMetadataTable: false,
  resetSystemNextButtonStatus: SubmitButtonStatus.NORMAL,
  resetSystemSubmitButtonStatus: SubmitButtonStatus.NORMAL,
});

/* eslint-disable no-param-reassign */
const slice = createSlice({
  name: STATE_KEY,
  initialState: getInitialState(),
  reducers: {
    onPollingLastExportMetadataSuccess: (state, action: PayloadAction<ExportExecutionResponse>) => {
      state.lastExportMetadata = action.payload;
    },
    onPollingLastExportNoData: state => {
      state.lastExportMetadata = { status: undefined };
    },
    onPollingLastImportMetadataSuccess: (state, action: PayloadAction<ImportExecutionResponse>) => {
      state.lastImportMetadata = action.payload;
    },
    onPollingLastImportNoData: state => {
      state.lastImportMetadata = { status: undefined };
    },
    onStartExport: (state, action: PayloadAction<StartExportConfigurationRequest & { containerId: string }>) => {
      state.exportButtonStatus = SubmitButtonStatus.LOADING;
    },
    onStartExportSuccess: (state, action: PayloadAction<ExportExecutionResponse>) => {
      state.lastExportMetadata = action.payload;
      state.exportButtonStatus = SubmitButtonStatus.SUCCESS;
    },
    onStartExportFail: state => {
      // @ts-ignore
      state.exportButtonStatus = SubmitButtonStatus.NORMAL;
    },
    onGetExportsMetadataSuccess: (state, action: PayloadAction<ExportsMetadataResponse>) => {
      state.exportsMetadata = action.payload;
      state.loadingExportsMetadataTable = false;
    },
    onGetExportsMetadataFail: state => {
      state.loadingExportsMetadataTable = false;
    },
    onStartImport: (state, action: PayloadAction<FileConfigurationMetadataResponse & { containerId: string }>) => {
      state.importButtonStatus = SubmitButtonStatus.LOADING;
    },
    onStartImportSuccess: (state, action: PayloadAction<ImportExecutionResponse>) => {
      state.lastImportMetadata = action.payload;
      state.importButtonStatus = SubmitButtonStatus.SUCCESS;
    },
    onStartImportFail: state => {
      state.importButtonStatus = SubmitButtonStatus.NORMAL;
    },
    initExportModal: state => {
      state.exportButtonStatus = SubmitButtonStatus.NORMAL;
    },
    initImportModal: state => {
      state.loadingExportsMetadataTable = true;
      state.importButtonStatus = SubmitButtonStatus.NORMAL;
    },
    onGetSystemOwnerSuccess: (
      state,
      action: PayloadAction<{ primaryAdministrator: BasicReferencedEntityDataUUIDId }>,
    ) => {
      state.systemOwner = action.payload.primaryAdministrator;
    },
    onNextClick: (state, action: PayloadAction<{ loginRequest: LoginRequestV2; containerId: string }>) => {
      state.resetSystemNextButtonStatus = SubmitButtonStatus.LOADING;
    },
    onHandleNextClickSuccess: (state, action: PayloadAction<{ token?: string }>) => {
      state.systemOwnerToken = action.payload.token;
      state.resetSystemNextButtonStatus = SubmitButtonStatus.SUCCESS;
    },
    onStartResetSystem: (
      state,
      action: PayloadAction<{ resetSystemRequest: ResetSystemRequest; containerId: string }>,
    ) => {
      state.resetSystemSubmitButtonStatus = SubmitButtonStatus.LOADING;
    },
    initResetSystemModal: state => {
      state.resetSystemNextButtonStatus = SubmitButtonStatus.NORMAL;
      state.resetSystemSubmitButtonStatus = SubmitButtonStatus.NORMAL;
      state.systemOwnerToken = undefined;
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(successMatcher(START_RESET_OPERATION_ID), (state, action) => {
        state.resetSystemSubmitButtonStatus = SubmitButtonStatus.SUCCESS;
      })
      .addMatcher(errorMatcher(NEXT_CLICK_OPERATION_ID), (state, action) => {
        state.resetSystemNextButtonStatus = SubmitButtonStatus.NORMAL;
      })
      .addMatcher(errorMatcher(START_RESET_OPERATION_ID), (state, action) => {
        state.resetSystemSubmitButtonStatus = SubmitButtonStatus.NORMAL;
      });
  },
});
/* eslint-enable no-param-reassign */

const getState = (state: { [STATE_KEY]: EnvironmentConfigurationState }) => state[STATE_KEY] || getInitialState();

export const selectors = {
  selectExportButtonStatus: createSelector(getState, state => state.exportButtonStatus),
  selectExportStatus: createSelector(getState, state => state.lastExportMetadata?.status),
  selectExportStatusApiError: createSelector(getState, state => state.lastExportMetadata?.errorMessage),
  selectLastExportMetadata: createSelector(getState, state => state.lastExportMetadata),
  selectImportButtonStatus: createSelector(getState, state => state.importButtonStatus),
  selectImportStatus: createSelector(getState, state => state.lastImportMetadata?.status),
  selectImportStatusApiError: createSelector(getState, state => state.lastImportMetadata?.errorMessage),
  selectLastImportMetadata: createSelector(getState, state => state.lastImportMetadata),
  selectExportsMetadata: createSelector(getState, state => state.exportsMetadata || undefined),
  selectLoadingExportsMetadataTable: createSelector(getState, state => state.loadingExportsMetadataTable),
  selectSystemOwnerName: createSelector(getState, state => state.systemOwner?.name),
  selectSystemOwnerId: createSelector(getState, state => state.systemOwner?.id),
  selectSystemOwnerToken: createSelector(getState, state => state.systemOwnerToken),
  selectResetSystemNextButtonStatus: createSelector(getState, state => state.resetSystemNextButtonStatus),
  selectResetSystemSubmitButtonStatus: createSelector(getState, state => state.resetSystemSubmitButtonStatus),
};

const extraActions = {
  onMount: createAction(`${STATE_KEY}/onMount`),
  onUnmount: createAction(`${STATE_KEY}/onUnmount`),
};

export const actions = { ...slice.actions, ...extraActions };

const { reducer } = slice;
export default reducer;
