import { createReadItemsSlice } from '@biotmed/data-components';
import { PluginResponse, SearchRequestV2 } from '@biotmed/settings-sdk';
import { PLUGIN_ID_SUFFIX } from '../../constants';
import { extractId } from '../../utils';
import { PLUGIN_STATE_KEY } from './constants';

const STATE_KEY = 'plugin-configuration-read';

const { reducer, actions, selectors, getInitialState } = createReadItemsSlice<PluginResponse, SearchRequestV2>({
  extractId,
  operationIdSuffix: PLUGIN_ID_SUFFIX,
  stateKey: STATE_KEY,
  getRootState: state => state[PLUGIN_STATE_KEY],
});

export { STATE_KEY, actions, selectors, getInitialState };

export default reducer;
