import { Icon, InfoTooltip, otherIcons, Link } from '@biotmed/base-components';
import { useFeature } from '@biotmed/react-flags-lib';
import { InputAdornment } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import APP_CONFIG from 'src/config/AppConfig';
import { useTheme } from 'styled-components';
import {
  Container,
  InfoIconContainer,
  InputWrapper,
  StyledExpandableInput,
  StyledInput,
  UrlInputWrapper,
} from './DraggableTab.styled';
import { GenericTabProps } from './interfaces';

export interface ExternalDraggableTabProps extends GenericTabProps {}

const ExternalDraggableTab: React.FC<ExternalDraggableTabProps> = props => {
  const { data = {}, onChange, errors, name } = props;
  const { isFeatureActive: isNoCodeParamsFeatureActive } = useFeature({ flag: 'NO_CODE_PARAMS' });

  const intl = useIntl();
  const theme = useTheme();

  const handleChange = (value: any) => {
    onChange(value);
  };

  const updateTitle: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined = event => {
    const newValue = {
      ...data,
      title: event.target.value,
    };
    handleChange(newValue);
  };

  const updateUrl: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined = event => {
    const url = event.target.value.replaceAll(/[\s]+/g, '');
    const newValue = {
      ...data,
      url,
    };

    handleChange(newValue);
  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
    }
  };

  return (
    <Container>
      <InputWrapper>
        <StyledInput
          fullWidth={false}
          label={intl.formatMessage({
            id: 'external-draggable-tab.title.label',
            defaultMessage: 'Title',
          })}
          variant="standard"
          error={Boolean(errors?.title)}
          helperText={errors?.title ?? ' '}
          value={data?.title}
          onChange={updateTitle}
          name={`${name}.title`}
        />
      </InputWrapper>
      <UrlInputWrapper>
        <StyledExpandableInput
          expandable
          label={intl.formatMessage({
            id: 'external-draggable-tab.url.label',
            defaultMessage: 'URL',
          })}
          placeholder="https://example.com"
          variant="standard"
          error={Boolean(errors?.url)}
          helperText={errors?.url ?? ' '}
          value={data?.url}
          onChange={updateUrl}
          name={`${name}.url`}
          InputProps={{
            onKeyPress: handleKeyPress,
          }}
          expandableProps={{
            title: intl.formatMessage({
              id: 'external-draggable-tab.expanded-url.title',
              defaultMessage: 'URL',
            }),
            minimizedInputProps: {
              InputProps: isNoCodeParamsFeatureActive
                ? {
                    startAdornment: (
                      <InputAdornment position="end" disablePointerEvents={false}>
                        <InfoTooltip
                          title={intl.formatMessage(
                            {
                              id: 'external-draggable-tab.url.info-tooltip',
                              defaultMessage:
                                'You can build a dynamic URL that includes contextual parameters like the logged in user and the subject entity. {learnMoreLink}',
                            },
                            {
                              learnMoreLink: (
                                <Link
                                  onClick={() => {
                                    window.open(APP_CONFIG.NO_CODE_PARAMETERS_LEARN_MORE_URL);
                                  }}
                                >
                                  {intl.formatMessage({
                                    id: 'external-draggable-tab.url.info-tooltip.link',
                                    defaultMessage: 'Learn more',
                                  })}
                                </Link>
                              ),
                            },
                          )}
                        >
                          <InfoIconContainer>
                            <Icon IconComponent={otherIcons.info} color={theme.palette.grayScale.dark} height="16px" />
                          </InfoIconContainer>
                        </InfoTooltip>
                      </InputAdornment>
                    ),
                  }
                : {},
            },
          }}
        />
      </UrlInputWrapper>
    </Container>
  );
};

export default ExternalDraggableTab;
