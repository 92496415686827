import { FormikErrors } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';

import { GetViewResponse, GetViewResponseTypeEnum, UpdateViewRequest } from '@biotmed/settings-sdk';
import { capitilizeFirstLetter } from 'src/utils/stringUtils';
import { Parameter } from '@biotmed/data-components';
import { AttributesWrapper, HRLine, SectionsOuterWrapper, Spacer } from './PortalBuilder.styled';

import AttributesBuilder from './ViewBuilders/AttributesBuilder';
import MeasurementsBuilder from './ViewBuilders/MeasurementsBuilder';
import TabsBuilder from './ViewBuilders/TabsBuilder/TabsBuilder';

const userParameter: Parameter = {
  name: 'user',
  type: 'Logged in user',
};

const entityParameterOptionalViews: GetViewResponseTypeEnum[] = [GetViewResponseTypeEnum.TemplateExpand];

export interface ViewTabsSelectorProps {
  available: GetViewResponse;
  errors: FormikErrors<UpdateViewRequest>;
  values: UpdateViewRequest;
  setValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

const ViewTabsSelector: React.FC<ViewTabsSelectorProps> = props => {
  const { available, errors, values, setValue } = props;
  const { attributes, measurements, tabs } = available;
  const intl = useIntl();

  const fullScreen = [attributes, measurements, tabs].filter(Boolean).length <= 1;

  const createEntityParameter = (optional: boolean): Parameter => ({
    name: 'entity',
    type: capitilizeFirstLetter(available?.entityTypeName),
    optional,
    description: optional
      ? intl.formatMessage({
          id: 'view-tabs-selector.codesnippet.parameters.entity.type.optional',
          defaultMessage: 'The parameter will not be available when the view is used for adding the entity',
        })
      : '',
  });

  return (
    <SectionsOuterWrapper>
      <AttributesWrapper>
        {available.attributes && (
          <AttributesBuilder
            available={available.attributes}
            fullScreen={fullScreen}
            selectedAttributes={values.attributes}
            setAttributes={setValue}
            codeSnippetAvailableParameters={[
              userParameter,
              ...(available.type === GetViewResponseTypeEnum.OperationalTemplatePreview ||
              available.type === GetViewResponseTypeEnum.TemplateExpand ||
              available.type === GetViewResponseTypeEnum.TemplatePreview
                ? [createEntityParameter(entityParameterOptionalViews.includes(available.type))]
                : []),
              ...(available.type === GetViewResponseTypeEnum.OperationalTemplatePreview
                ? [{ name: 'alerts', type: 'Alert[]' }]
                : []),
            ]}
          />
        )}
        {available.measurements && (
          <>
            <Spacer />
            <HRLine />
            <Spacer />
            <MeasurementsBuilder
              available={available.measurements}
              fullScreen={fullScreen}
              selectedMeasurements={values.measurements}
              setMeasurements={setValue}
              codeSnippetAvailableParameters={[userParameter, createEntityParameter(false)]}
            />
          </>
        )}
        {available.tabs && (
          <>
            <Spacer />
            <HRLine />
            <Spacer />
            <TabsBuilder
              tabs={available.tabs}
              fullScreen={fullScreen}
              errors={errors.tabs}
              selectedTabs={values.tabs}
              setTabs={setValue}
              codeSnippetAvailableParameters={[
                userParameter,
                ...(available.type === GetViewResponseTypeEnum.TemplateExpand ? [createEntityParameter(false)] : []),
              ]}
            />
          </>
        )}
      </AttributesWrapper>
    </SectionsOuterWrapper>
  );
};

export default ViewTabsSelector;
