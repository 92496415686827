import { Card } from '@biotmed/base-components';
import React from 'react';

interface AttributeLayoutProps {
  header: React.ReactNode;
  content: React.ReactNode;
  footer: React.ReactNode;
}
const AttributeLayout: React.FC<AttributeLayoutProps> = props => {
  const { header, content, footer } = props;
  return (
    <Card padding="26px 26px 12px">
      {header}
      {content}
      <hr />
      {footer}
    </Card>
  );
};
export default AttributeLayout;
