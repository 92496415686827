import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { SelectAutoComplete } from '@biotmed/base-components';
import { SelectedOrganization } from '@biotmed/settings-sdk';
import { selectors } from 'src/redux/data/organization';

interface OrganizationsAutoCompleteProps {
  onChange: (value: any) => void;
  multiple: boolean;
  // eslint-disable-next-line react/require-default-props
  value?: SelectedOrganization[];
}

const OrganizationsAutoComplete = (props: OrganizationsAutoCompleteProps) => {
  const { onChange, multiple, value } = props;
  const organizationOptions = useSelector(selectors.selectOrganization) ?? [];

  const val = useMemo(() => {
    return value?.map((item: SelectedOrganization) => {
      return organizationOptions.find(option => option._id === item.id);
    });
  }, [organizationOptions, value]);

  return (
    // SOFT-6244: Change it to use EntityAutoComplete. and remove the usage of selectors.selectOrganization. it requires thinking...
    <SelectAutoComplete
      options={organizationOptions}
      getOptionLabel={option => option?._caption ?? ''}
      onChange={(event, value) => onChange(value)}
      multiple={multiple}
      value={val}
    />
  );
};

export default OrganizationsAutoComplete;
