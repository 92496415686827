import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import LanguageProvider from 'src/components/LocaleProvider';
import APP_CONFIG from 'src/config/AppConfig';
import theme from 'src/themes/theme';
import { ThemeProvider } from 'styled-components';
import { SessionExpiredModal, useIdleTimer } from '@biotmed/base-components';
import { DataComponentsParamsProvider } from '@biotmed/data-components';
import GlobalStyle from '../../global.styled';
import { actions as loginActions, selectors as loginSelectors } from '../../redux/data/login/modules/slice';
import { selectors as userSelectors } from '../../redux/data/user/modules/slice';
import AppComponent from '../Navigation';

interface AppProps {}

const App: React.FC<AppProps> = () => {
  const dispatch = useDispatch();
  const isLoggedIn: boolean = useSelector(loginSelectors.getIsLoggedIn);
  const userData = useSelector(userSelectors.getUserDetails);
  const promptTimeoutInMs = 30000;

  const [idleTimerManager] = useIdleTimer({
    isLoggedIn,
    timeout: APP_CONFIG.IDLE_TIMEOUT_MS,
    promptTimeout: promptTimeoutInMs, // The time when the user becomes idle until the onIdle function is called
    onIdle: () => dispatch(loginActions.logout()),
  });

  useEffect(() => {
    if (isLoggedIn) {
      idleTimerManager?.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterDateFns} /* locale={language} */>
        <ThemeProvider theme={theme}>
          <DataComponentsParamsProvider currentLoggedInUserData={userData}>
            <LanguageProvider>
              <>
                <GlobalStyle />
                <SessionExpiredModal
                  open={!!idleTimerManager?.isPrompted}
                  onContinue={() => idleTimerManager?.activate()}
                  onLogOff={() => {
                    dispatch(loginActions.logout());
                    idleTimerManager?.setIsPrompted(false);
                  }}
                  countdownTimeout={promptTimeoutInMs}
                />
                <AppComponent />
              </>
            </LanguageProvider>
          </DataComponentsParamsProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </BrowserRouter>
  );
};

export default App;
