import { CustomAttribute } from '@biotmed/settings-sdk';
import { ANALYTICS_DB_DATA_SYNCHRONIZATION_ON_ERROR_CODE } from 'src/routes/ADB/components/force/constants';
import adbEditForceConfirmationProps from 'src/routes/ADB/components/force/props/editForceConfirmationProps';
import { editForceObservationsConfirmationProps, CUSTOM_ATTRIBUTE_IN_USE_ERROR_CODE } from 'src/routes/Observation';
import { EditTemplateForceErrorCodesType } from '../types';

const editForceConfirmationTextMapper = (errorDetails: any, attributesMapper?: { [key: string]: CustomAttribute }) => {
  return {
    [CUSTOM_ATTRIBUTE_IN_USE_ERROR_CODE]: editForceObservationsConfirmationProps(errorDetails, attributesMapper),
    [ANALYTICS_DB_DATA_SYNCHRONIZATION_ON_ERROR_CODE]: adbEditForceConfirmationProps(),
  };
};

const getEditForceConfirmationText = (
  errorCode: EditTemplateForceErrorCodesType,
  errorDetails: any,
  attributesMapper?: { [key: string]: CustomAttribute },
) => {
  return editForceConfirmationTextMapper(errorDetails, attributesMapper)[errorCode];
};

export default getEditForceConfirmationText;
