import { defineMessages } from 'react-intl';

// TODO: params passed to messages (like {fieldName} or {correctFormat}) also need to use intl (maybe they can use the label)

export const factoryIntlMessages = defineMessages({
  inputIsRequired: {
    id: 'template.modal.input.error.is-required',
    defaultMessage: 'Required',
    description: 'Any required input in templates modal not filled',
  },
  inputNoLeadingTrailingSpaces: {
    id: 'template.modal.input.error.leading-trailing-spaces',
    defaultMessage: 'cannot include leading and trailing spaces',
    description: 'Error for any input that forbids leading or trailing spaces',
  },
  inputWrongFormat: {
    id: 'template.modal.input.error.wrong-format',
    defaultMessage: 'Wrong format ({correctFormat})',
    description: 'Error for any input that has a specific format',
  },
  inputWrongFirstLetter: {
    id: 'template.modal.input.error.wrong-first-letter',
    defaultMessage: 'First character {requirement} ',
    description: 'Error for any input that has a specific error for first letter',
  },
  inputMaxLength: {
    id: 'template.modal.input.error.max-length',
    defaultMessage: 'This value can not be longer than {maxLength} characters',
    description: 'Any input in templates modal exceeding its max length',
  },
  selectableValuesArrayMinOptionsLength: {
    id: 'template.modal.selectableValuesArray.error.min-options-length',
    defaultMessage: 'At least {minOptionsLength} options are required',
    description: 'Any selectable values array in templates modal with less items then required',
  },
  inputNumberNotInteger: {
    id: 'template.modal.input-number.integer.error.not-integer',
    defaultMessage: 'This value must be an integer',
    description: 'Any number input that has to be an integer but is not',
  },
  numberMinMessage: {
    id: 'entity-modal.validation.error.min.default',
    defaultMessage: 'Value must be greater than or equal to {min}',
    description: 'Error: Value should be greater than or equal to {min} (default message)',
  },
  numberMoreThanMessage: {
    id: 'entity-modal.validation.error.number.more.than',
    defaultMessage: 'Value must be greater than {min}',
    description: 'Error: Value should be greater than {min} (default message)',
  },
  numberMaxMessage: {
    id: 'entity-modal.validation.error.max.default',
    defaultMessage: 'Value must be less than or equal to {max}',
    description: 'Error: Value should be less than {max} (default message)',
  },
  arrayMinMessage: {
    id: 'entity-modal.validation.error.usage-type.min',
    defaultMessage: 'At least {min} usage type is required',
    description: 'Error for minimum {min} usage type',
  },
});
