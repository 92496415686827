import styled from 'styled-components';
import { convertTypographySettingsToCss } from '@biotmed/base-components';

export interface StyledAttributeProps {
  $isFocused?: boolean;
}
// eslint-disable-next-line
export const StyledAttribute = styled.div<StyledAttributeProps>`
  width: 100%;
  padding: 26px 26px 12px;
  background-color: ${props => props.theme.palette.grayScale.lightest};
  border: 1px solid #e2e4e9;
  border-radius: 5px;
  box-shadow: 1px 1px 1px 1px #ebeced;
  &:focus-within {
    box-shadow: 1px 1px 1px 1px #ebeced, inset 4px 0px 0px 0px ${props => props.theme.palette.primary.medium};
  }
  hr {
    border-top: #909090;
    margin: 22px 0 14px;
  }
`;

export const VerticalSection = styled.div`
  ${props => convertTypographySettingsToCss(props.theme.typography.body1)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 6px;
  gap: 40px;
`;
