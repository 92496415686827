import React, { cloneElement } from 'react';
import { Grid } from '@mui/material';

export const ResetSystemModalSteps = (props: any) => {
  const { stepIndex, formik, steps } = props;

  const activeStep = steps && steps[stepIndex];
  return steps && steps.length ? <Grid>{cloneElement(activeStep, { parentState: { ...props }, formik })}</Grid> : null;
};

export default ResetSystemModalSteps;
