import React from 'react';
import { useIntl } from 'react-intl';
import { Button, ButtonProps } from '@biotmed/base-components';
import { SaveButtonTypeEnum } from './constant';

interface SaveButtonProps extends Omit<ButtonProps, 'submit'> {
  buttonType: SaveButtonTypeEnum;
}

const SaveButton: React.FC<SaveButtonProps> = props => {
  const { buttonType, onClick, disabled, submitButtonProps } = props;
  const intl = useIntl();

  return (
    <Button
      size="medium"
      variant="contained"
      paddingHorizontal="32px"
      paddingVertical="15px"
      onClick={onClick}
      submit
      disabled={disabled}
      submitButtonProps={{
        loadingLabel: intl.formatMessage({
          id: 'portal-builder.save-button.submit-button.loading',
          defaultMessage: 'Saving',
        }),
        successLabel: intl.formatMessage({
          id: 'portal-builder.save-button.submit-button.success',
          defaultMessage: 'Saved',
        }),
        ...submitButtonProps,
      }}
    >
      {buttonType === SaveButtonTypeEnum.SAVE_AND_NEXT
        ? intl.formatMessage({
            id: 'portalBuilder.save-and-next-button',
            defaultMessage: 'Save & Next',
          })
        : intl.formatMessage({
            id: 'portal-builder.save-button.save-button',
            defaultMessage: 'Save',
          })}
    </Button>
  );
};

export default SaveButton;
