import React from 'react';
import { Icon } from '@biotmed/base-components';
import { ReactComponent as EditIcon } from 'src/images/edit.svg';
import { InputAdornment, InputAdornmentProps } from '@mui/material';

const EditInputAdornment: React.FC<InputAdornmentProps> = props => {
  return (
    <InputAdornment {...props}>
      <Icon IconComponent={EditIcon} />
    </InputAdornment>
  );
};
export default EditInputAdornment;
