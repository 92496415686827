import { getSdkApi } from '@biotmed/sdk-api-provider';
import { all, call, delay, put, race, select, take, takeLatest, fork } from 'redux-saga/effects';

import intl from '@biotmed/i18n';
import { OrganizationResponse } from '@biotmed/organization-sdk';
import {
  ExportExecutionResponse,
  ExportsMetadataResponse,
  ImportExecutionResponse,
  StartImportConfigurationRequest,
  StartImportConfigurationRequestImportTypeEnum,
} from '@biotmed/settings-sdk';
import { errorNotice, ErrorTypeEnum, successNotice } from '@biotmed/system-notifications';
import { LoginResponse } from '@biotmed/ums-sdk';
import { AxiosResponse } from 'axios';
import { MANUFACTURER_ORGANIZATION_ID } from 'src/utils/consts';
import { visibilityActions } from '@biotmed/base-components';
import { NEXT_CLICK_OPERATION_ID, START_RESET_OPERATION_ID } from './contants';
import { importErrorDictionary } from './errorDictionary/import';
import { actions, selectors } from './slice';

function* handleMount() {
  yield fork(getSystemOwner);
  yield race({
    pollingLastExportMetadata: call(pollLastExportWhenAppNotVisible),
    pollingLastImportMetadata: call(pollLastImportWhenAppNotVisible),
    unmount: take(actions.onUnmount),
  });
}

function* getSystemOwner() {
  try {
    const organizationResponse: AxiosResponse<OrganizationResponse> = yield call(
      getSdkApi().organization.organizationApi.getOrganization,
      MANUFACTURER_ORGANIZATION_ID,
    );

    yield put(
      actions.onGetSystemOwnerSuccess({ primaryAdministrator: organizationResponse.data._primaryAdministrator }),
    );
  } catch (e: any) {
    yield put(errorNotice({ type: ErrorTypeEnum.GENERAL, errorParams: { error: e?.response?.data || e } }));
  }
}

function* pollLastExportWhenAppNotVisible() {
  for (;;) {
    const { appHidden } = yield race({
      polling: call(pollingLastExportMetadata),
      appHidden: take(visibilityActions.appHidden),
    });

    if (appHidden) {
      yield take(visibilityActions.appVisible);
    } else {
      break;
    }
  }
}

function* pollingLastExportMetadata() {
  while (true) {
    try {
      const lastExportExecutionResponse: AxiosResponse<ExportExecutionResponse> = yield call(
        getSdkApi().settings.exportImportConfigurationApi.getLastExportExecution,
      );

      yield put(actions.onPollingLastExportMetadataSuccess(lastExportExecutionResponse.data));
    } catch (e: any) {
      if (e.response?.data?.code === 'EXPORTS_NOT_FOUND') {
        yield put(actions.onPollingLastExportNoData());
      } else {
        console.error('Failed to poll last export metadata', e);
      }
    }

    yield delay(5 * 1000);
  }
}

function* pollLastImportWhenAppNotVisible() {
  for (;;) {
    const { appHidden } = yield race({
      polling: call(pollingLastImportMetadata),
      appHidden: take(visibilityActions.appHidden),
    });

    if (appHidden) {
      yield take(visibilityActions.appVisible);
    } else {
      break;
    }
  }
}

function* pollingLastImportMetadata() {
  const api = getSdkApi();

  while (true) {
    try {
      const lastImportExecutionResponse: AxiosResponse<ImportExecutionResponse> = yield call(
        api.settings.exportImportConfigurationApi.getLastImportExecution,
      );

      yield put(actions.onPollingLastImportMetadataSuccess(lastImportExecutionResponse.data));
    } catch (e: any) {
      if (e.response?.data?.code === 'IMPORTS_NOT_FOUND') {
        yield put(actions.onPollingLastImportNoData());
      } else {
        console.error('Failed to poll last import metadata', e);
      }
    }

    yield delay(5 * 1000);
  }
}

function* handleStartExport(action: ReturnType<typeof actions.onStartExport>) {
  try {
    const startExportConfigurationRequest = action.payload;

    const response: AxiosResponse<ExportExecutionResponse> = yield call(
      getSdkApi().settings.exportImportConfigurationApi.startExport,
      startExportConfigurationRequest,
    );
    yield put(actions.onStartExportSuccess(response.data));
  } catch (e: any) {
    const { containerId } = action.payload;
    console.error(e);
    yield put(actions.onStartExportFail());
    yield put(
      errorNotice({ type: ErrorTypeEnum.EMBEDDED, containerId, errorParams: { error: e?.response?.data || e } }),
    );
  }
}

function* getExportsMetadata(action: ReturnType<typeof actions.initImportModal>) {
  try {
    const response: AxiosResponse<ExportsMetadataResponse> = yield call(
      getSdkApi().settings.exportImportConfigurationApi.getExportsMetadata,
    );

    yield put(actions.onGetExportsMetadataSuccess(response.data));
  } catch (e: any) {
    yield put(errorNotice({ type: ErrorTypeEnum.GENERAL, errorParams: { error: e?.response?.data || e } }));
    yield put(actions.onGetExportsMetadataFail());
    console.error(e);
  }
}

function* handleStartImport(action: ReturnType<typeof actions.onStartImport>) {
  try {
    const { id } = action.payload;
    const startImportConfigurationRequest: StartImportConfigurationRequest = {
      exportId: id || '',
      importType: StartImportConfigurationRequestImportTypeEnum.CleanImport,
    };

    const response: AxiosResponse<ImportExecutionResponse> = yield call(
      getSdkApi().settings.exportImportConfigurationApi.startImport,
      startImportConfigurationRequest,
    );
    yield put(actions.onStartImportSuccess(response.data));
  } catch (e: any) {
    const { containerId } = action.payload;
    yield put(
      errorNotice({
        type: ErrorTypeEnum.EMBEDDED,
        errorParams: { error: e?.response?.data || e, dictionary: importErrorDictionary },
        containerId,
      }),
    );

    yield put(actions.onStartImportFail());
    console.error(e);
  }
}

function* handleNextClick(action: ReturnType<typeof actions.onNextClick>) {
  const { containerId, loginRequest } = action.payload;
  try {
    const loginResponse: AxiosResponse<LoginResponse> = yield call(getSdkApi().ums.loginApi.login, loginRequest);
    const systemOwnerId: string = yield select(selectors.selectSystemOwnerId);
    if (loginResponse.data.userId === systemOwnerId) {
      yield put(actions.onHandleNextClickSuccess({ token: loginResponse.data.accessJwt?.token }));
      yield put(successNotice({ containerId }));
    } else {
      throw intl.current.formatMessage({
        id: 'reset-system.modal.reset-confirmation.exception.not-admin',
        defaultMessage: 'Wrong credentials, please provide an admin user credentials',
      });
    }
  } catch (e: any) {
    yield put(
      errorNotice({
        type: ErrorTypeEnum.EMBEDDED,
        containerId,
        errorParams: { error: e?.response?.data || e },
        operationId: NEXT_CLICK_OPERATION_ID,
      }),
    );
  }
}

function* handleStartResetSystem(action: ReturnType<typeof actions.onStartResetSystem>) {
  const { containerId, resetSystemRequest } = action.payload;
  try {
    const systemOwnerToken: string = yield select(selectors.selectSystemOwnerToken);
    yield call(getSdkApi().settings.systemApi.resetSystem, resetSystemRequest, {
      headers: {
        Authorization: `Bearer ${systemOwnerToken}`,
      },
    });

    yield put(successNotice({ operationId: START_RESET_OPERATION_ID }));
  } catch (e: any) {
    yield put(
      errorNotice({
        type: ErrorTypeEnum.EMBEDDED,
        containerId,
        errorParams: { error: e?.response?.data || e },
        operationId: START_RESET_OPERATION_ID,
      }),
    );
    console.error(e);
  }
}

export default function* watch() {
  yield all([
    takeLatest(actions.onMount, handleMount),
    takeLatest(actions.onStartExport, handleStartExport),
    takeLatest(actions.initImportModal, getExportsMetadata),
    takeLatest(actions.onStartImport, handleStartImport),
    takeLatest(actions.onNextClick, handleNextClick),
    takeLatest(actions.onStartResetSystem, handleStartResetSystem),
  ]);
}
