import { defineMessages } from 'react-intl';

export const addTemplateErrorDictionary = defineMessages({
  REQUEST_VALIDATION_FAILED: {
    id: 'template.create.error.validation_failed',
    defaultMessage: 'Create template failed: validation failed',
  },
  TEMPLATE_DUPLICATE_NAME: {
    id: 'template.create.error.duplicate_template_name',
    defaultMessage: 'Create template failed: duplicate template name',
  },
  TEMPLATES_ADDING_DISALLOWED: {
    id: 'template.create.error.adding_not_allowed',
    defaultMessage: 'Create template failed: templates adding not allowed',
  },
  ENTITY_TYPE_NOT_FOUND: {
    id: 'template.create.error.entity_type_not_found',
    defaultMessage: 'Create template failed: entity type not found',
  },
  BUILT_IN_ATTRIBUTE_NOT_FOUND: {
    id: 'template.create.error.builtin_attribute_not_found',
    defaultMessage: 'Create template failed: builtin attribute not found',
  },
});

export const addTemplatePartialErrorDictionary = defineMessages({
  REQUEST_VALIDATION_FAILED: {
    id: 'template.create.partial.error.validation_failed',
    defaultMessage:
      'The template has been partially created. Adding template with name `{templateName}` and type `{entityName}` failed: validation failed',
  },
  TEMPLATE_DUPLICATE_NAME: {
    id: 'template.create.partial.error.duplicate_template_name',
    defaultMessage:
      'The template has been partially created. Adding template with name `{templateName}` and type `{entityName}` failed: duplicate name',
  },
  TEMPLATES_ADDING_DISALLOWED: {
    id: 'template.create.partial.error.adding_not_allowed',
    defaultMessage:
      'The template has been partially created. Adding template with name `{templateName}` and type `{entityName}` failed: Not allowed',
  },
  ENTITY_TYPE_NOT_FOUND: {
    id: 'template.create.partial.error.entity_type_not_found',
    defaultMessage:
      'The template has been partially created. Adding template with name `{templateName}` and type `{entityName}` failed: entity type not found',
  },
  BUILT_IN_ATTRIBUTE_NOT_FOUND: {
    id: 'template.create.partial.error.builtin_attribute_not_found',
    defaultMessage:
      'The template has been partially created. Create template with name `{templateName}` and type `{entityName}` failed: builtin attribute not found',
  },
});

export const editTemplateErrorDictionary = defineMessages({
  TEMPLATE_DUPLICATE_NAME: {
    id: 'template.update.error.template_duplicate_name',
    defaultMessage: 'Update template failed: template duplicate name',
  },
  MISSING_TEMPLATE_ID: {
    id: 'template.update.error.missing_template_id',
    defaultMessage: 'Update template failed: missing template id',
  },
  TEMPLATE_IN_USE: {
    id: 'template.update.error.template_in_use',
    defaultMessage: 'Update template failed: template in use',
  },
  TEMPLATE_NOT_FOUND: {
    id: 'template.update.error.template_not_found',
    defaultMessage: 'Update template failed: template not found',
  },
});

export const editTemplatePartialErrorDictionary = defineMessages({
  TEMPLATE_DUPLICATE_NAME: {
    id: 'template.update.partial.error.template_duplicate_name',
    defaultMessage:
      'The template has been partially updated. Update template with name `{templateName}` and type `{entityName}` failed: duplicate name',
  },
  MISSING_TEMPLATE_ID: {
    id: 'template.update.partial.error.missing_template_id',
    defaultMessage:
      'The template has been partially updated. Update template with name `{templateName}` and type `{entityName}` failed: missing id',
  },
  TEMPLATE_IN_USE: {
    id: 'template.update.partial.error.template_in_use',
    defaultMessage:
      'The template has been partially updated. Update template with name `{templateName}` and type `{entityName}` failed: template in use',
  },
  TEMPLATE_NOT_FOUND: {
    id: 'template.update.partial.error.template_not_found',
    defaultMessage:
      'The template has been partially updated. Update template with name `{templateName}` and type `{entityName}` failed: template not found',
  },
  REQUEST_VALIDATION_FAILED: {
    id: 'template.update.partial.error.request_validation-failed',
    defaultMessage:
      'The template has been partially updated. Update template with name `{templateName}` and type `{entityName}` failed: request validation failed',
  },
});

export const templateListErrorDictionary = defineMessages({
  SOME_ERROR: {
    id: 'template.list.error.some_error',
    defaultMessage: 'Get template list failed: some error',
  },
});

export const templateOverviewErrorDictionary = defineMessages({
  REQUEST_VALIDATION_FAILED: {
    id: 'template.overview.error.some_error',
    defaultMessage: 'Get template overview failed',
  },
});

export const deleteTemplateErrorDictionary = defineMessages({
  TEMPLATE_IN_USE: {
    id: 'template.delete.error.template_in_use',
    defaultMessage: 'Delete template failed: template in use',
  },
  TEMPLATE_REFERENCE_IN_USE: {
    id: 'template.delete.error.template_reference_in_use',
    defaultMessage: 'Delete template failed: template reference in use',
  },
});
