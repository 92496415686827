import React from 'react';
import { otherIcons } from '@biotmed/base-components';
import { AnalyticsDBInformationResponseActivationStateEnum } from '@biotmed/dms-sdk';

const analyticsDBIconsMapper = {
  [AnalyticsDBInformationResponseActivationStateEnum.Inactive]: <otherIcons.syncOff />,
  [AnalyticsDBInformationResponseActivationStateEnum.Initializing]: <otherIcons.initializing />,
  [AnalyticsDBInformationResponseActivationStateEnum.StoppingInitialization]: <otherIcons.initializing />,
  [AnalyticsDBInformationResponseActivationStateEnum.ActiveAndSyncOn]: <otherIcons.syncOn />,
  [AnalyticsDBInformationResponseActivationStateEnum.ActiveAndSyncOff]: <otherIcons.syncOff />,
};

export const getIcon = (syncState: AnalyticsDBInformationResponseActivationStateEnum) => {
  return analyticsDBIconsMapper[syncState];
};
