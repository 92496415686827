import { SubmitButtonStatus } from '@biotmed/base-components';
import { errorMatcher, successMatcher } from '@biotmed/system-notifications';
import { createAction, createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { UPLOAD_TRANSLATION_OPERATION_ID } from '../../constants';
import { TRANSLATIONS_STATE_KEY } from './constants';

export const STATE_KEY = 'translations-actions';

interface TranslationsActionsState {
  uploadButtonStatus: SubmitButtonStatus;
}

export const getInitialState = (state?: TranslationsActionsState): TranslationsActionsState => ({
  uploadButtonStatus: SubmitButtonStatus.NORMAL,
});

/* eslint-disable no-param-reassign */
const slice = createSlice({
  name: STATE_KEY,
  initialState: getInitialState(),
  reducers: {
    upload: (state, action: PayloadAction<{ locale: string; file: File }>) => {
      state.uploadButtonStatus = SubmitButtonStatus.LOADING;
    },
    initUploadTranslationsModal: state => {
      state.uploadButtonStatus = SubmitButtonStatus.NORMAL;
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(successMatcher(UPLOAD_TRANSLATION_OPERATION_ID), (state, action) => {
        state.uploadButtonStatus = SubmitButtonStatus.SUCCESS;
      })
      .addMatcher(errorMatcher(UPLOAD_TRANSLATION_OPERATION_ID), (state, action) => {
        state.uploadButtonStatus = SubmitButtonStatus.NORMAL;
      });
  },
});

const getState = (state: { [TRANSLATIONS_STATE_KEY]: { [STATE_KEY]: TranslationsActionsState } }) =>
  state[TRANSLATIONS_STATE_KEY][STATE_KEY] || getInitialState();

export const selectors = {
  selectUploadButtonStatus: createSelector(getState, state => state.uploadButtonStatus),
};

const extraActions = {
  download: createAction<{ locale: string }>(`${STATE_KEY}/download`),
};

const { reducer } = slice;

export const actions = { ...slice.actions, ...extraActions };

export default reducer;
