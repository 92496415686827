import { convertTypographySettingsToCss, MultiSelect, SingleSelect } from '@biotmed/base-components';
import styled from 'styled-components';

export const InputsContainer = styled.div`
  display: grid;
  grid-template-columns: 25% 0.5% 25% 0.5% 30%;
  flex-direction: column;
  gap: 19px 28px;
  justify-content: flex-start;
  align-items: center;
`;
export const ReferenceTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const Separator = styled.div``;
export const SingleSelectStyled = styled(SingleSelect)`
  .MuiInputBase-root {
    ${props => convertTypographySettingsToCss(props.theme.typography.body1)};
  }
`;
export const MultiSelectStyled = styled(MultiSelect)`
  .MuiInputBase-root {
    ${props => convertTypographySettingsToCss(props.theme.typography.body1)};
    min-width: 140px;
  }
`;
export const FieldsContainer = styled.div`
  display: flex;
  gap: 23px;
`;
export const FieldsRow = styled.div<{ overflow?: string }>`
  display: flex;
  gap: 7px;
  align-items: center;
  overflow: ${props => props.overflow ?? 'none'};
`;
export const FieldTitle = styled.div`
  ${props => convertTypographySettingsToCss(props.theme.typography.body1)};
`;
export const FieldValue = styled.div`
  ${props => convertTypographySettingsToCss(props.theme.typography.h7)};
`;

export const ParentTemplateName = styled.div`
  ${props => convertTypographySettingsToCss(props.theme.typography.body1)};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
