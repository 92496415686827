import { SelectableAttributeValue } from '@biotmed/settings-sdk';
import { createSlice, createSelector, createAction, PayloadAction, Selector } from '@reduxjs/toolkit';

import { RootState } from '../../../reducer';
import { DATA_STATE_KEY } from '../../constants';

export const STATE_KEY = 'attribute';

interface AttributeState {
  locales?: SelectableAttributeValue[];
  timezones?: SelectableAttributeValue[];
}
export const getInitialState = (state?: AttributeState): AttributeState => ({
  timezones: [],
  locales: [],
});

/* eslint-disable no-param-reassign */
const slice = createSlice({
  name: STATE_KEY,
  initialState: getInitialState(),
  reducers: {
    onLoadLocalesSuccess: (state, action: PayloadAction<SelectableAttributeValue[]>) => {
      state.locales = action.payload;
    },
    onLoadTimezonesSuccess: (state, action: PayloadAction<SelectableAttributeValue[]>) => {
      state.timezones = action.payload;
    },
  },
});
/* eslint-enable no-param-reassign */

const getState = (state: RootState) => state[DATA_STATE_KEY][STATE_KEY] || getInitialState();

export const selectors = {
  selectTimezones: createSelector<[Selector<any, any>], SelectableAttributeValue[]>(getState, state => state.timezones),
  selectLocales: createSelector<[Selector<any, any>], SelectableAttributeValue[]>(getState, state => state.locales),
};

const extraActions = {
  loadTimezones: createAction(`${STATE_KEY}/loadTimezones`),
  loadLocales: createAction(`${STATE_KEY}/loadLocales`),
};

export const actions = { ...slice.actions, ...extraActions };

const { reducer } = slice;
export default reducer;
