import { createReadItemsSaga, ReadItemsSlice } from '@biotmed/data-components';
import {
  LocaleTranslationsOverview,
  SearchRequestV2,
  SearchResponseLocaleTranslationsOverview,
} from '@biotmed/settings-sdk';
import { FormatterDictionary } from '@biotmed/system-notifications';
import { OPERATION_ID_SUFFIX } from '../../constants';
import { actions as readSliceActions, selectors as readSliceSelectors } from '../slice/readSlice';

export const readSaga = createReadItemsSaga<
  LocaleTranslationsOverview,
  SearchRequestV2,
  SearchResponseLocaleTranslationsOverview,
  ReadItemsSlice<LocaleTranslationsOverview, SearchRequestV2>
>({
  slice: { actions: readSliceActions, selectors: readSliceSelectors },
  operationIdSuffix: OPERATION_ID_SUFFIX,
  readApi: api => api.settings.translationApi.searchLocaleTranslationOverviews,
  detailsFormatterDictionary: {} as FormatterDictionary,
});
