import { all, call, put, race, take, takeLatest, delay } from 'redux-saga/effects';
import { getSdkApi } from '@biotmed/sdk-api-provider';
import { AxiosResponse } from 'axios';
import { ErrorTypeEnum, errorNotice } from '@biotmed/system-notifications';
import { AnalyticsDBInformationResponse } from '@biotmed/dms-sdk';
import { visibilityActions } from '@biotmed/base-components';
import { actions as loginActions } from '../../../redux/data/login';
import { actions } from './slice';

function* fetchAnalyticsDBInformation() {
  try {
    const response: AxiosResponse<AnalyticsDBInformationResponse> = yield call(
      getSdkApi().dms.analyticsApi.getAnalyticsDBInformation,
    );
    yield put(actions.onFetchAnalyticsDBInformationSuccess(response.data));
  } catch (error: any) {
    yield put(errorNotice({ type: ErrorTypeEnum.GENERAL, errorParams: { error: error?.response?.data || error } }));
    console.error('Failed to load ADB information. with error: ', error);
  }
}

function* startAnalyticsDBInitialization() {
  try {
    const response: AxiosResponse<AnalyticsDBInformationResponse> = yield call(
      getSdkApi().dms.analyticsApi.startAnalyticsDBInitialization,
    );
    yield put(actions.onStartAnalyticsDBInitializationSuccess(response.data));
  } catch (error: any) {
    yield put(errorNotice({ type: ErrorTypeEnum.GENERAL, errorParams: { error: error?.response?.data || error } }));
    console.error('Failed to start ADB initialization. with error: ', error);
  }
}

function* stopAnalyticsDBInitialization() {
  try {
    const response: AxiosResponse<AnalyticsDBInformationResponse> = yield call(
      getSdkApi().dms.analyticsApi.stopAnalyticsDBInitialization,
    );
    yield put(actions.onStopAnalyticsDBInitializationSuccess(response.data));
  } catch (error: any) {
    yield put(errorNotice({ type: ErrorTypeEnum.GENERAL, errorParams: { error: error?.response?.data || error } }));
    console.error('Failed to stop ADB initialization. with error: ', error);
  }
}

function* stopAnalyticsDBSynchronization() {
  try {
    const response: AxiosResponse<AnalyticsDBInformationResponse> = yield call(
      getSdkApi().dms.analyticsApi.stopAnalyticsDBSynchronization,
    );
    yield put(actions.onStopAnalyticsDBSynchronizationSuccess(response.data));
  } catch (error: any) {
    yield put(errorNotice({ type: ErrorTypeEnum.GENERAL, errorParams: { error: error?.response?.data || error } }));
    console.error('Failed to stop ADB synchronization. with error: ', error);
  }
}

function* handleMount() {
  yield race({
    pollingAnalyticsDBInfo: call(pollingAnalyticsDBInformationWhenAppVisible),
    unmount: take(actions.onUnmount),
  });
}

function* pollingAnalyticsDBInformationWhenAppVisible() {
  for (;;) {
    const { appHidden } = yield race({
      polling: call(pollingAnalyticsDBInformation),
      appHidden: take(visibilityActions.appHidden),
    });

    if (appHidden) {
      yield take(visibilityActions.appVisible);
    } else {
      break;
    }
  }
}

function* pollingAnalyticsDBInformation() {
  while (true) {
    yield call(fetchAnalyticsDBInformation);
    yield delay(10 * 1000);
  }
}

export default function* watchEntityActions() {
  yield all([
    takeLatest(loginActions.loginSuccess, fetchAnalyticsDBInformation),
    takeLatest(actions.onFetchAnalyticsDBInformation, fetchAnalyticsDBInformation),
    takeLatest(actions.onStartInitialization, startAnalyticsDBInitialization),
    takeLatest(actions.onStopInitialization, stopAnalyticsDBInitialization),
    takeLatest(actions.onStopSynchronization, stopAnalyticsDBSynchronization),
    takeLatest(actions.onMount, handleMount),
  ]);
}
