import { SubmitButtonStatus } from '@biotmed/base-components';
import { PortalType } from '@biotmed/data-components';
import { GetViewResponse, View } from '@biotmed/settings-sdk';
import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 } from 'uuid';
import { LoadViewStatus } from './constant';

export const STATE_KEY = 'portalBuilder';

export interface portalBuilderState {
  viewsList: View[];
  selectedView?: GetViewResponse;
  errors: { updateError?: string };
  saveBuilderStatus: SubmitButtonStatus;
  loadBuilderStatus: LoadViewStatus;
}

const getSelectedViewWithSelectedTabId = (viewData: GetViewResponse) => {
  return {
    ...viewData,
    tabs: viewData.tabs
      ? {
          ...viewData.tabs,
          selected: viewData.tabs?.selected?.map(tab => ({ ...tab, arrayId: v4() })),
        }
      : undefined,
  };
};

export const getInitialState = (state?: portalBuilderState): portalBuilderState => ({
  viewsList: [],
  selectedView: undefined,
  errors: { updateError: undefined },
  saveBuilderStatus: SubmitButtonStatus.NORMAL,
  loadBuilderStatus: LoadViewStatus.NORMAL,
});
/* eslint-disable no-param-reassign */
const slice = createSlice({
  name: STATE_KEY,
  initialState: getInitialState(),
  reducers: {
    onLoadViews: (state, action: PayloadAction<{ portalType: PortalType }>) => {
      state.viewsList = getInitialState(state).viewsList;
    },
    onLoadViewsSuccess: (state, action) => {
      state.viewsList = action.payload;
    },
    onLoadSelectedView: (state, action) => {
      state.saveBuilderStatus = SubmitButtonStatus.NORMAL;
      state.loadBuilderStatus = LoadViewStatus.LOADING;
    },
    onLoadSelectedViewSuccess: (state, action: PayloadAction<GetViewResponse>) => {
      state.selectedView = getSelectedViewWithSelectedTabId(action.payload);
      state.loadBuilderStatus = LoadViewStatus.SUCCESS;
    },
    onLoadSelectedViewFail: state => {
      state.loadBuilderStatus = LoadViewStatus.NORMAL;
    },
    onUpdateView: (state, action) => {
      state.saveBuilderStatus = SubmitButtonStatus.LOADING;
    },
    onUpdateViewSuccess: (state, action: PayloadAction<GetViewResponse>) => {
      state.selectedView = getSelectedViewWithSelectedTabId(action.payload);
      state.saveBuilderStatus = SubmitButtonStatus.SUCCESS;
    },
    afterUpdateViewSuccess: state => {
      state.saveBuilderStatus = SubmitButtonStatus.NORMAL;
    },
    onUpdateViewFail: state => {
      state.saveBuilderStatus = SubmitButtonStatus.NORMAL;
    },
    resetSelectedView: state => {
      state.selectedView = getInitialState(state).selectedView;
    },
  },
});
/* eslint-enable no-param-reassign */

const getState = (state: any) => state[STATE_KEY] || getInitialState();

export const selectors = {
  getViewsList: createSelector(getState, state => state.viewsList),
  getSelectedView: createSelector(getState, state => state.selectedView),
  getUpdateError: createSelector(getState, state => state.errors.updateError),
  getSaveBuilderStatus: createSelector(getState, state => state.saveBuilderStatus),
  getLoadBuilderStatus: createSelector(getState, state => state.loadBuilderStatus),
};
const extraActions = {};

export const actions = { ...slice.actions, ...extraActions };

const { reducer } = slice;
export default reducer;
