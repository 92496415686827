import { getSdkApi } from '@biotmed/sdk-api-provider';
import { LocalesResponse } from '@biotmed/settings-sdk';
import { errorNotice, ErrorTypeEnum } from '@biotmed/system-notifications';
import { AxiosResponse } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { actions as appActions } from '../../app/modules/slice';
import { actions } from './slice';

function* fetchLocales(action: ReturnType<typeof appActions.appStart>) {
  try {
    const response: AxiosResponse<LocalesResponse> = yield call(getSdkApi().settings.localeApi.getLocales);
    yield put(actions.fetchLocalesSuccess(response.data));
  } catch (error: any) {
    yield put(errorNotice({ type: ErrorTypeEnum.GENERAL, errorParams: { error: error?.response?.data || error } }));
    console.error('Failed to fetch locales configuration. with error: ', error);
  }
}

export default function* watch() {
  yield all([takeLatest(appActions.appStart, fetchLocales)]);
}
