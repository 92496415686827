import { SearchRequestV2 } from '@biotmed/sdk-api-provider/lib/types/settings';
import { PluginResponse, PluginResponseEnabledStateEnum } from '@biotmed/settings-sdk';
import { createAction, createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../reducer';
import { DATA_STATE_KEY } from '../../constants';

export const STATE_KEY = 'plugin';

interface PluginState {
  searchRequest: SearchRequestV2;
  freeTextSearch: string;
  plugins: any;
  organizationList?: any;
  loading?: boolean;
}

const pluginsAdapter = createEntityAdapter<PluginResponse>({ selectId: plugin => plugin.name });

export const getInitialState = (state?: PluginState): PluginState => ({
  searchRequest: { limit: 10 },
  plugins: pluginsAdapter.getInitialState({ pluginsTotal: 0 }),
  loading: false,
  freeTextSearch: '',
});

/* eslint-disable no-param-reassign */
const slice = createSlice({
  name: STATE_KEY,
  initialState: getInitialState(),
  reducers: {
    onLoadPlugin: state => {
      state.loading = true;
    },
    onLoadPluginsSuccess: (
      state,
      action: PayloadAction<{ pluginsList: Array<PluginResponse>; pluginsTotal: number }>,
    ) => {
      pluginsAdapter.setAll(state.plugins, action.payload.pluginsList);
      state.plugins.pluginsTotal = action.payload.pluginsTotal;
      state.loading = false;
    },
    onLoadPluginsFail: state => {
      state.loading = false;
    },
    updateFreeTextSearch: (state, action: PayloadAction<{ freeTextSearch: string }>) => {
      state.freeTextSearch = action.payload.freeTextSearch;
      state.searchRequest.page = 0;
    },
    updateSearchRequest: (state, action: PayloadAction<SearchRequestV2>) => {
      state.searchRequest = action.payload;
    },
    resetSearchRequest: state => {
      state.searchRequest = getInitialState().searchRequest;
      state.freeTextSearch = getInitialState().freeTextSearch;
    },
  },
});
/* eslint-enable no-param-reassign */

const getState = (state: RootState) => state[DATA_STATE_KEY][STATE_KEY] || getInitialState();

export const selectors = {
  getPluginsTotal: createSelector(getState, state => state.plugins.pluginsTotal),
  selectFreeTextSearch: createSelector(getState, state => state.freeTextSearch),
  selectSearchRequest: createSelector(getState, state => ({
    ...state.searchRequest,
    filter: { ...state.searchRequest.filter },
    freeTextSearch: state.freeTextSearch,
  })),
  selectPageLimit: createSelector(getState, state => state.searchRequest.limit ?? 0),
  selectCurrentPage: createSelector(getState, state => state.searchRequest.page ?? 0),
  getPluginsList: createSelector(getState, state => pluginsAdapter.getSelectors().selectAll(state.plugins)),
  getPluginByName: (pluginId: string) =>
    createSelector(getState, state => pluginsAdapter.getSelectors().selectById(state.plugins, pluginId)),
  selectLoading: createSelector(getState, state => state.loading),
};

const extraActions = {
  onEnableStateUpdate: createAction<{ name: string; enabledState: PluginResponseEnabledStateEnum }>(
    `${STATE_KEY}/onEnableStateUpdate`,
  ),
};

export const actions = { ...slice.actions, ...extraActions };

const { reducer } = slice;
export default reducer;
