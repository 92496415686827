import React from 'react';
import { Category } from 'src/redux/data/entity/modules/interfaces';
import { useIntl } from 'react-intl';
import { categoryToIntlDisplayNameMap, Category as CategoryType } from '@biotmed/data-components';
import { Hr } from 'src/components/Hr/Hr.styled';
import { FieldArrayContainer, TextWrapper, Title } from '../../Templates.styled';
import BuiltInAttributesArrayField from './builtInArrayField';
import { DetailsFieldContainer } from './DetailsFields.styled';
import CustomAttributesArrayField from './customArrayField';

interface DetailsFieldsProps {
  category: Category;
}

const DetailsFields: React.FC<DetailsFieldsProps> = props => {
  const { category } = props;
  const intl = useIntl();

  return (
    <DetailsFieldContainer>
      <TextWrapper>
        <Title>
          {`${intl.formatMessage(categoryToIntlDisplayNameMap[category.name as CategoryType])} 
      ${intl.formatMessage({ id: 'template.modal.category-tab.tab-name', defaultMessage: 'Fields' })}`}
        </Title>
      </TextWrapper>
      <FieldArrayContainer>
        <BuiltInAttributesArrayField category={category} />
        <Hr />
        <CustomAttributesArrayField category={category} />
      </FieldArrayContainer>
    </DetailsFieldContainer>
  );
};
export default DetailsFields;
