import { DraggableList, FlexSeparator } from '@biotmed/base-components';
import { SelectedTab, ViewTabs } from '@biotmed/settings-sdk';
import { ArrayHelpers, FieldArray } from 'formik';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { v4 } from 'uuid';

import { Parameter } from '@biotmed/data-components';
import {
  AttributesWrapper,
  DraggableListHeader,
  DraggableListSection,
  DraggableListWrapper,
} from '../../PortalBuilder.styled';

import { getTabIdByTypeMapper, mapListByTypeKey } from '../../../modules/mappers';
import PortalBuilderSection from '../PortalBuilderSection';
import DraggableTab from './DraggableTab';
import { AddTabButton, Header } from './TabsBuilder.styled';

interface TabsBuilderProps {
  tabs: ViewTabs;
  fullScreen: boolean;
  errors: any;
  selectedTabs?: SelectedTab[];
  setTabs: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  codeSnippetAvailableParameters: Parameter[];
}

export const createEmptyTab = (uuid: string) => ({
  type: null,
  template: null,
  externalPage: null,
  reverseReference: null,
  arrayId: uuid,
});

const TabsBuilder: React.FC<TabsBuilderProps> = props => {
  const {
    tabs: { available = [], supportedTypes = [] },
    fullScreen = false,
    errors,
    selectedTabs: tabs,
    setTabs,
    codeSnippetAvailableParameters,
  } = props;

  const intl = useIntl();

  const mappedAvailable = useMemo(() => mapListByTypeKey(available), [available]);

  const selectedIds = useMemo(() => {
    const ids = tabs?.reduce((prevVal: string[], tab) => {
      const getIdFunction = getTabIdByTypeMapper(tab.type);
      prevVal.push(`${tab.type}.${getIdFunction?.(tab)}`);
      return prevVal;
    }, []);

    return new Set(ids);
  }, [tabs]);

  const handleChangeTabs = (selectedTabs: SelectedTab[]) => setTabs('tabs', selectedTabs);

  return (
    <PortalBuilderSection
      title={intl.formatMessage({
        id: 'portalBuilder.TabsSectionTitle',
        defaultMessage: 'Tabs Section',
      })}
    >
      <FieldArray name="tabs">
        {(arrayHelpers: ArrayHelpers) => {
          const { push, remove } = arrayHelpers;
          return (
            <AttributesWrapper>
              <DraggableListSection $fullScreen>
                <Header>
                  <DraggableListHeader>
                    {intl.formatMessage({
                      id: 'tabs-builder.portalBuilder.ViewOrder',
                      defaultMessage: 'View order',
                    })}
                  </DraggableListHeader>
                  <FlexSeparator />
                  <AddTabButton onClick={() => push(createEmptyTab(v4()))}>
                    {intl.formatMessage({
                      id: 'portalBuilder.tabsBuilder.addTab',
                      defaultMessage: '+ Add tab',
                    })}
                  </AddTabButton>
                </Header>
                <DraggableListWrapper $fullScreen={fullScreen}>
                  <DraggableList<SelectedTab>
                    items={tabs || []}
                    renderItem={(tab, onItemChange, index) => (
                      <DraggableTab
                        typeOptions={supportedTypes}
                        available={mappedAvailable}
                        isAvailableOptionInUse={option => {
                          const id = getTabIdByTypeMapper(tab.type)?.(option);
                          return selectedIds.has(`${tab.type}.${id}`);
                        }}
                        tab={tab}
                        onChange={(newItem: SelectedTab) =>
                          onItemChange({ ...newItem, arrayId: (tab as any).arrayId } as any)
                        }
                        errors={errors?.[index]}
                        onDelete={() => remove(index)}
                        name={`tabs.${index}`}
                        codeSnippetAvailableParameters={codeSnippetAvailableParameters}
                      />
                    )}
                    getItemId={item => (item as any).arrayId}
                    onItemsChange={handleChangeTabs}
                  />
                </DraggableListWrapper>
              </DraggableListSection>
            </AttributesWrapper>
          );
        }}
      </FieldArray>
    </PortalBuilderSection>
  );
};

export default TabsBuilder;
