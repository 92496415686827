import React from 'react';
import { TableProps } from '@biotmed/base-components';
import { useIntl } from 'react-intl';
import { FileConfigurationMetadataResponse } from '@biotmed/settings-sdk';
import { StyledColumn, StyledExportsMetadataTable } from '../EnvironmentConfiguration.styled';

const ExportsMetadataTable = (props: TableProps<FileConfigurationMetadataResponse>) => {
  const intl = useIntl();

  return (
    <StyledExportsMetadataTable
      columns={[
        {
          title: intl.formatMessage({ id: 'import.modal.exports-metadata-table.column.name', defaultMessage: 'Name' }),
          dataIndex: 'name',
          key: 'name',
          render: (value, record, index) => {
            return (
              <StyledColumn key={`name-${index}`} width="200px">
                {value}
              </StyledColumn>
            );
          },
        },
        {
          title: intl.formatMessage({
            id: 'import.modal.exports-metadata-table.column.description',
            defaultMessage: 'Description',
          }),
          dataIndex: 'description',
          key: 'description',
          render: (value, record, index) => {
            return (
              <StyledColumn key={`description-${index}`} width="100px">
                {value}
              </StyledColumn>
            );
          },
        },
        {
          title: intl.formatMessage({
            id: 'import.modal.exports-metadata-table.column.date',
            defaultMessage: 'Date',
          }),
          dataIndex: 'creationTime',
          key: 'creationTime',
          render: (value, record, index) => intl.formatDate(value, { dateStyle: 'short', timeStyle: 'short' }),
          sorter: (record1, record2) => {
            if (!record1?.creationTime || !record2?.creationTime) return 0;

            const date1 = new Date(record1.creationTime);
            const date2 = new Date(record2.creationTime);

            return date1 < date2 ? -1 : 1;
          },
        },
      ]}
      {...props}
    />
  );
};

export default ExportsMetadataTable;
