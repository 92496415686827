import deleteForceConfirmationProps from 'src/routes/ADB/components/force/props/deleteForceConfirmationProps';
import { ANALYTICS_DB_DATA_SYNCHRONIZATION_ON_ERROR_CODE } from 'src/routes/ADB/components/force/constants';
import { DeleteTemplateForceErrorCodesType } from '../types';

const deleteTemplateForceConfirmationTextMapper = {
  [ANALYTICS_DB_DATA_SYNCHRONIZATION_ON_ERROR_CODE]: deleteForceConfirmationProps(),
};

const getDeleteTemplateForceConfirmationText = (errorCode: DeleteTemplateForceErrorCodesType) => {
  return deleteTemplateForceConfirmationTextMapper[errorCode];
};

export default getDeleteTemplateForceConfirmationText;
