import { Explained, Separator } from '@biotmed/base-components';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import AppConfig from 'src/config/AppConfig';
import { useDispatch } from 'react-redux';
import Export from './export/Export';
import {
  ButtonsContainer,
  EnvironmentConfigurationPageLayout,
  EnvironmentConfigurationTitle,
  StyledPageTitleContainer,
} from './EnvironmentConfiguration.styled';
import Import from './import/Import';
import ResetSystem from './reset/ResetSystem';
import { actions } from '../modules/slice';

interface EnvironmentConfigurationProps {}

export const EnvironmentConfiguration: React.FC<EnvironmentConfigurationProps> = props => {
  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.onMount());

    return () => {
      dispatch(actions.onUnmount());
    };
  }, []);

  const onLearnMoreClick = () => {
    window.open(AppConfig.ENVIRONMENT_CONFIGURATION_LEARN_MORE_URL);
  };

  return (
    <EnvironmentConfigurationPageLayout>
      <StyledPageTitleContainer>
        <EnvironmentConfigurationTitle>
          {intl.formatMessage({
            id: 'environment-configuration.mainTitle',
            defaultMessage: 'Environment Configuration',
          })}
        </EnvironmentConfigurationTitle>
      </StyledPageTitleContainer>
      <Explained
        text={intl.formatMessage({
          id: 'environment-configuration.explained',
          defaultMessage: `Export/Import the environment's configuration allows you extract the configuration of your environment (using export) or to load a configuration of a different environment to create a duplicated environment (using import)`,
        })}
        onLearnMore={onLearnMoreClick}
      />
      <ButtonsContainer>
        <Import />
        <Export />
        <ResetSystem />
      </ButtonsContainer>
    </EnvironmentConfigurationPageLayout>
  );
};

export default EnvironmentConfiguration;
