import { combineReducers } from 'redux';

import readReducer, {
  STATE_KEY as readStateKey,
  actions as readSliceActions,
  selectors as readSliceSelectors,
  getInitialState as readSliceGetInitialState,
} from './readSlice';

import actionsReducer, {
  STATE_KEY as actionsStateKey,
  actions as actionsSliceActions,
  selectors as actionSliceSelectors,
  getInitialState as actionsSliceGetInitialState,
} from './actionsSlice';

import { PLUGIN_STATE_KEY } from './constants';

const STATE_KEY = PLUGIN_STATE_KEY;

const reducer = combineReducers({
  [readStateKey]: readReducer,
  [actionsStateKey]: actionsReducer,
});

const actions = { ...readSliceActions, ...actionsSliceActions };
const selectors = { ...readSliceSelectors, ...actionSliceSelectors };

const getInitialState = (state?: any) => ({
  [readStateKey]: readSliceGetInitialState(state[readStateKey]),
  [actionsStateKey]: actionsSliceGetInitialState(state[actionsStateKey]),
});

export { reducer, STATE_KEY, actions, selectors, getInitialState };
