import React from 'react';

import { InputFieldWrapper, SubmitButtonStatus } from '@biotmed/base-components';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { EditModal } from 'src/components/Modals';

export interface PluginModalProps {
  isOpen: boolean;
}

const PluginEditModal = (props: PluginModalProps) => {
  const { isOpen } = props;
  const intl = useIntl();

  const steps = [
    {
      tabName: intl.formatMessage({ id: 'plugin-edit.modal.details-tab.tab-name', defaultMessage: 'Plugin Details' }),
      render: () => (
        <div>
          <InputFieldWrapper name="firstName" />
          <InputFieldWrapper name="lastName" />
        </div>
      ),
      validations: () =>
        Yup.object().shape({
          firstName: Yup.string(),
          lastName: Yup.string(),
        }),
    },
    {
      tabName: intl.formatMessage({ id: 'plugin-edit.modal.behavoir-tab.tab-name', defaultMessage: 'Plugin Behavior' }),
      render: () => (
        <div>
          <InputFieldWrapper name="age" />
          <InputFieldWrapper name="address" />
        </div>
      ),
      validations: () =>
        Yup.object().shape({
          age: Yup.number(),
          address: Yup.string(),
        }),
    },
  ];

  const initialValues = { firstName: 'Gil', lastName: 'Levy', age: '29', address: 'Jerusalem' };

  return (
    <EditModal
      isOpen={isOpen}
      handleSubmit={(values: any) => alert(`submitting form with values: ${JSON.stringify(values)}`)}
      initialValues={initialValues}
      handleClose={() => alert('closing form')}
      renderTitle={() => intl.formatMessage({ id: 'plugin-edit.modal.header-title', defaultMessage: 'Edit Plugin' })}
      steps={steps}
      containerId="PLUGIN_EDIT_MODAL"
      submitButtonStatus={SubmitButtonStatus.NORMAL}
    />
  );
};

export default PluginEditModal;
