import React from 'react';
import { SubRendererProps } from './types';

export type DefaultWrapperProps<V> = {
  children: React.ReactElement<unknown & SubRendererProps<V>, string | React.JSXElementConstructor<unknown>>[];
};

export const DefaultWrapper: React.FC<React.PropsWithChildren<DefaultWrapperProps<undefined>>> = props => {
  const { children } = props;
  return <>{children}</>;
};
