import {
  AnalyticsDBInformationResponse,
  AnalyticsDBInformationResponseActivationStateEnum,
  AnalyticsDBInformationResponseDeploymentStateEnum,
} from '@biotmed/dms-sdk';
import { PayloadAction, createAction, createSelector, createSlice } from '@reduxjs/toolkit';

export const STATE_KEY = 'analyticsDB';

interface AnalyticsDBState {
  activationState?: AnalyticsDBInformationResponseActivationStateEnum;
  deploymentState?: AnalyticsDBInformationResponseDeploymentStateEnum;
}

export const getInitialState = (state?: AnalyticsDBState): AnalyticsDBState => ({
  activationState: state?.activationState ?? AnalyticsDBInformationResponseActivationStateEnum.Inactive,
  deploymentState: state?.deploymentState ?? AnalyticsDBInformationResponseDeploymentStateEnum.NotDeployed,
});

/* eslint-disable no-param-reassign */
const updateStateHelper = (state: AnalyticsDBState, action: PayloadAction<AnalyticsDBInformationResponse>) => {
  state.activationState = action.payload.activationState;
  state.deploymentState = action.payload.deploymentState;
};

const slice = createSlice({
  name: STATE_KEY,
  initialState: getInitialState(),
  reducers: {
    onFetchAnalyticsDBInformationSuccess: (state, action: PayloadAction<AnalyticsDBInformationResponse>) => {
      updateStateHelper(state, action);
    },
    onStartAnalyticsDBInitializationSuccess: (state, action: PayloadAction<AnalyticsDBInformationResponse>) => {
      updateStateHelper(state, action);
    },
    onStopAnalyticsDBInitializationSuccess: (state, action: PayloadAction<AnalyticsDBInformationResponse>) => {
      updateStateHelper(state, action);
    },
    onStopAnalyticsDBSynchronizationSuccess: (state, action: PayloadAction<AnalyticsDBInformationResponse>) => {
      updateStateHelper(state, action);
    },
  },
});

const extraActions = {
  onStartInitialization: createAction(`${STATE_KEY}/onStartInitialization`),
  onStopInitialization: createAction(`${STATE_KEY}/onStopInitialization`),
  onStopSynchronization: createAction(`${STATE_KEY}/onStopSynchronization`),
  onFetchAnalyticsDBInformation: createAction(`${STATE_KEY}/onFetchAnalyticsDBInformation`),
  onMount: createAction(`${STATE_KEY}/onMount`),
  onUnmount: createAction(`${STATE_KEY}/onUnmount`),
};

export const actions = { ...slice.actions, ...extraActions };

const getState = (state: { [STATE_KEY]: AnalyticsDBState }) => state[STATE_KEY] || getInitialState();

export const selectors = {
  getActivationState: createSelector(getState, state => state.activationState),
  getDeploymentState: createSelector(getState, state => state.deploymentState),
};

const { reducer } = slice;
export default reducer;
