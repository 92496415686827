import React from 'react';
import {
  Button,
  convertTypographySettingsToCss,
  getOpaqueColor,
  Loader,
  ModalFooter,
  PopupModal,
  Table,
  TableProps,
} from '@biotmed/base-components';
import { FormControlLabel } from '@mui/material';
import { PageLayout, PageMainTitle } from 'src/components/CommonStyledComponents';
import styled from 'styled-components';
import { FileConfigurationMetadataResponse } from '@biotmed/settings-sdk';

export const EnvironmentConfigurationPageLayout = styled(PageLayout)`
  padding-top: 20px;
  overflow-y: auto;
  padding-right: 20px;
`;

export const StyledPageTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const EnvironmentConfigurationTitle = styled(PageMainTitle)``;

export const StyledImportModal = styled(PopupModal)`
  && {
    .MuiDialog-paper {
      width: 600px;
      height: 500px;
    }
  }
`;

export const StyledExportModal = styled(PopupModal)`
  && {
    .MuiDialog-paper {
      width: 500px;
    }
  }
`;

export const StyledModalFooter = styled(ModalFooter)`
  gap: 20px;
`;

export const StatusContainer = styled.div<{ $isError: boolean }>`
  display: flex;
  gap: 8px;
  ${props =>
    props.$isError ? { color: props.theme.palette.error.medium } : { color: props.theme.palette.grayScale.darkest }}
  space-wrap: pre-line;
`;

export const StyledLoader = styled(Loader)`
  max-height: 15px;
  max-width: 15px;
`;

export const Title = styled.div`
  ${props => convertTypographySettingsToCss(props.theme.typography.h7)};
`;

export const Container = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
`;

export const FileMetadataResponseTable = (props: TableProps<FileConfigurationMetadataResponse>) => <Table {...props} />;

export const StyledExportsMetadataTable = styled(FileMetadataResponseTable)`
  .ant-table-tbody {
    white-space: pre-wrap;
    text-overflow: ellipsis;
  }

  tr[data-row-key='selected-export'] {
    background-image: ${props =>
      getOpaqueColor(
        props.theme.palette.grayScale.lightest,
        props.theme.opacity.almostTransparent,
        props.theme.palette.primary.dark,
      )};
  }
`;

export const FreeTextSearchContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledColumn = styled.div<{ width: string }>`
  text-overflow: ellipsis;
  overflow: hidden;
  width: ${props => props.width};
`;

export const ResetSystemButton = styled(Button)`
  &&& {
    background-color: ${props => props.theme.palette.error.light};
    color: ${props => props.theme.palette.grayScale.lightest};
    border-radius: 3px;
    text-align: center;
    width: 158px;
    height: 35px;

    &:hover,
    &:hover:not(:disabled) {
      background-color: ${props => props.theme.palette.error.medium};
    }

    &:disabled {
      background-color: ${props => props.theme.palette.error.lighter};
    }

    &:active {
      background-color: ${props => props.theme.palette.error.darkest};
    }
  }
`;

export const HrWrapper = styled.div<{ $customPadding?: string }>`
  width: 100%;
  padding: ${props => props.$customPadding && props.$customPadding};
  hr {
    border: none;
    ${props => `border-top: 1px solid ${props.theme.palette.grayScale.medium}`};
    padding: 0;
    margin: 0;
  }
`;

export const RequiredField = styled.span`
  color: ${props => props.theme.palette.error.darkest};
`;

export const Label = styled.div`
  ${props => convertTypographySettingsToCss(props.theme.typography.h7)};
`;

export const StyledResetSystemModal = styled(PopupModal)`
  && {
    .MuiDialog-paper {
      width: 500px;
    }
  }
`;

export const StyledName = styled.div`
  display: flex;
  gap: 24px;
`;

export const ResetSystemModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SystemOwnerMessage = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledStep = styled.div`
  display: flex;
  flex-direction: column;
  gap: 34px;
  padding-bottom: 10px;
`;

export const StyledResetSystemForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  .anchor-ref-wrapper {
    width: 100%;
  }
`;

export const StyledPasswordField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const StyledField = styled.div`
  flex: 1;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  gap: 3px;
  ${props => convertTypographySettingsToCss(props.theme.typography.body1)};
  flex-direction: column;
`;

export const HelperTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  color: ${props => props.theme.palette.error.dark};
  ${props => convertTypographySettingsToCss(props.theme.typography.body2)};
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  display: flex;
  gap: 12px;

  &&.MuiFormControlLabel-root {
    align-items: flex-start;
    margin-left: 0px;
    margin-right: 0px;
  }
  .MuiFormControlLabel-label {
    ${props => convertTypographySettingsToCss(props.theme.typography.body1)};
  }
`;

export const ResetSystemContentTitle = styled.div`
  ${props => convertTypographySettingsToCss(props.theme.typography.h7)};
`;

export const ResetSystemContentText = styled.div`
  ${props => convertTypographySettingsToCss(props.theme.typography.body1)};
`;

export const ResetSystemContentMessage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ResetSystemModalInnerContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
