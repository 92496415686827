import {
  Button,
  FreeTextSearch,
  ModalContent,
  ModalHeader,
  SearchTerms,
  Separator,
  SubmitButtonStatus,
} from '@biotmed/base-components';
import { ExportsMetadataResponse, FileConfigurationMetadataResponse } from '@biotmed/settings-sdk';
import { EmbeddedError } from '@biotmed/system-notifications';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';
import { StyledModalFooter, FreeTextSearchContainer, StyledImportModal } from '../EnvironmentConfiguration.styled';
import ExportsMetadataTable from './ExportsMetadaTable';

interface ImportModalProps {
  isOpen: boolean;
  onCloseClick: (event?: any, reason?: string) => void;
  importButtonStatus: SubmitButtonStatus;
  onSubmit: (value: FileConfigurationMetadataResponse) => void;
  exportsMetadata?: ExportsMetadataResponse;
  loadingExportsMetadataTable: boolean;
  containerId: string;
}

export const ImportModal: React.FC<ImportModalProps> = props => {
  const {
    isOpen,
    importButtonStatus,
    onCloseClick,
    onSubmit,
    exportsMetadata,
    loadingExportsMetadataTable,
    containerId,
  } = props;
  const intl = useIntl();
  const theme = useTheme();
  const [selectedRow, setSelectedRow] = useState<FileConfigurationMetadataResponse>();
  const [freeTextSearch, setFreeTextSearch] = useState('');
  const [filteredExportsMetadata, setFilteredExportsMetadata] = useState<FileConfigurationMetadataResponse[]>();

  useEffect(() => {
    cleanup();
  }, [isOpen]);

  const cleanup = () => {
    setFreeTextSearch('');
    setSelectedRow(undefined);
  };

  useEffect(() => {
    setFilteredExportsMetadata(exportsMetadata?.filesMetadata);
  }, [exportsMetadata]);

  useEffect(() => {
    const searchTerms = freeTextSearch.split(' ');
    const results = exportsMetadata?.filesMetadata?.filter(fileMetadata => {
      return searchTerms.every(
        searchTerm => fileMetadata.description?.includes(searchTerm) || fileMetadata.name?.includes(searchTerm),
      );
    });

    setFilteredExportsMetadata(results);
  }, [freeTextSearch]);

  const onFreeTextSearchChange = (value: string) => {
    setFreeTextSearch(value);
  };

  const exportsMetadataDataSource = useMemo(() => {
    return (
      filteredExportsMetadata?.map((exportMetadata: FileConfigurationMetadataResponse, index: number) => ({
        ...exportMetadata,
        key: exportMetadata.id === selectedRow?.id ? 'selected-export' : `export-${index}`,
      })) || undefined
    );
  }, [filteredExportsMetadata, exportsMetadata, selectedRow]);

  const onSubmitClick = () => {
    if (selectedRow) {
      onSubmit(selectedRow);
    }
  };

  return (
    <StyledImportModal open={isOpen} onClose={onCloseClick} disableEscapeKeyDown>
      <ModalHeader
        title={intl.formatMessage({
          id: 'import.modal.title',
          defaultMessage: 'Import Environment Configuration',
        })}
        onClose={onCloseClick}
        isDisabled={importButtonStatus !== SubmitButtonStatus.NORMAL}
      />
      <ModalContent isDisabled={importButtonStatus !== SubmitButtonStatus.NORMAL}>
        <EmbeddedError containerId={containerId} />
        <FreeTextSearchContainer>
          <FreeTextSearch freeTextSearch={freeTextSearch} onFreeTextSearchChange={onFreeTextSearchChange} />
        </FreeTextSearchContainer>
        <SearchTerms freeTextSearch={freeTextSearch} onFreeTextSearchChange={onFreeTextSearchChange} />
        <ExportsMetadataTable
          dataSource={exportsMetadataDataSource}
          onRow={(record, rowIndex) => ({
            onClick: () => {
              setSelectedRow(record);
            },
          })}
          isLoading={loadingExportsMetadataTable}
        />
        <Separator height="10px" />
      </ModalContent>
      <StyledModalFooter isDisabled={importButtonStatus !== SubmitButtonStatus.NORMAL}>
        <Button paddingVertical="15px" onClick={onCloseClick} textColor={theme.palette.grayScale.darker2}>
          {intl.formatMessage({ id: 'import.modal.button.cancel', defaultMessage: 'Cancel' })}
        </Button>
        <Button
          submit
          paddingVertical="15px"
          onClick={onSubmitClick}
          submitButtonProps={{
            status: importButtonStatus,
            loadingLabel: intl.formatMessage({
              id: 'import.modal.button.loading',
              defaultMessage: 'Starting import',
            }),
            successLabel: intl.formatMessage({
              id: 'import.modal.button.success',
              defaultMessage: 'Import started',
            }),
          }}
          disabled={!selectedRow}
        >
          {intl.formatMessage({ id: 'import.modal.button.submit', defaultMessage: 'Start Import' })}
        </Button>
      </StyledModalFooter>
    </StyledImportModal>
  );
};

export default ImportModal;
