import { BuiltInAttribute } from '@biotmed/settings-sdk';
import { EntityType } from '@biotmed/data-components';

// This maps the parent template name to the child's reference attribute template type
// For instance, a patient's alert child template till have a _patient attribute, this attribute will have a template type of Patient
// eslint-disable-next-line no-shadow
export enum ParentTemplateNameToChildAttributeTemplateName {
  'patient' = '_patient',
  'device' = '_device',
}

// This is the above enums values ("_patient" | "_device")
type ChildAttributeTemplateNames = `${ParentTemplateNameToChildAttributeTemplateName}`;

function adjustTemplateName<T extends { name: string; parentTemplateName: string; basePath?: string }>(
  builtInAttribute: T,
  parentTemplateName: string,
  attributeToUpdateByParent?: ChildAttributeTemplateNames,
) {
  const adjustedBuiltInAttribute = { ...builtInAttribute };

  if (builtInAttribute.name === attributeToUpdateByParent && !builtInAttribute.basePath) {
    adjustedBuiltInAttribute.parentTemplateName = parentTemplateName;
  }

  return adjustedBuiltInAttribute;
}

export const getAttributeToAdjustByParent = (parentTemplateType?: EntityType) => {
  return parentTemplateType && Object.keys(ParentTemplateNameToChildAttributeTemplateName).includes(parentTemplateType)
    ? ParentTemplateNameToChildAttributeTemplateName[
        parentTemplateType as keyof typeof ParentTemplateNameToChildAttributeTemplateName
      ]
    : undefined;
};

export const adjustTemplateAttributesByParent = (
  attributes: BuiltInAttribute[],
  parentEntityTemplateDisplayName: string,
  attributeToUpdateByParent?: ChildAttributeTemplateNames,
) => {
  // This callback function finds the attribute to change (a reference attribute), in the array of attributes, and modifies it's parentTemplateName
  return attributeToUpdateByParent
    ? attributes.map((builtInAttribute: any) => {
        return adjustTemplateName(builtInAttribute, parentEntityTemplateDisplayName, attributeToUpdateByParent);
      })
    : null;
};
