import React, { useEffect, useRef, useState } from 'react';
import { ClickAwayListener, PopperProps } from '@mui/material';
import TooltipContent, { TooltipContentProps } from './TooltipContent';
import { Arrow, StyledPopper, StyledTooltipWrapper } from '../InputWithTooltip.styled';

export interface TooltipProps extends TooltipContentProps, Omit<PopperProps, 'onChange' | 'title'> {
  open: boolean;
  onTogglePopper: (value: boolean | undefined) => void;
  anchorEl: null | HTMLElement;
  handleClickAway: (value: string | null) => void;
}
export const Tooltip: React.FC<React.PropsWithChildren<TooltipProps>> = props => {
  const {
    onTogglePopper,
    value,
    open,
    anchorEl,
    children,
    onChange,
    title,
    className,
    handleClickAway,
    ...tooltipProps
  } = props;
  const [firstValue, setFirstValue] = useState(value || '');

  useEffect(() => {
    setFirstValue(value ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const popperRef = useRef(null);
  const arrowRef = useRef(null);

  const onClickAwayHandler = () => {
    handleClickAway(firstValue);
  };

  return (
    <ClickAwayListener onClickAway={onClickAwayHandler}>
      <StyledTooltipWrapper ref={popperRef}>
        {children}
        <StyledPopper
          placement="top"
          {...tooltipProps}
          anchorEl={anchorEl}
          open={open}
          modifiers={[
            {
              name: 'flip',
              enabled: false,
            },
            {
              name: 'preventOverflow',
              enabled: true,
              options: {
                boundariesElement: 'scrollParent',
              },
            },
            {
              name: 'arrow',
              enabled: true,
              options: {
                element: arrowRef.current,
              },
            },
            { name: 'offset', options: { offset: [0, 8] } },
          ]}
        >
          <TooltipContent
            onChange={onChange}
            {...(value || value === null ? { value } : {})}
            title={title}
            {...tooltipProps}
          />
          <Arrow className="arrow" ref={arrowRef} />
        </StyledPopper>
      </StyledTooltipWrapper>
    </ClickAwayListener>
  );
};

export default Tooltip;
