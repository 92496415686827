import intl from '@biotmed/i18n';
import { actionIcons, getDummyDescriptor } from '@biotmed/base-components';
import { PluginResponseEnabledStateEnum } from '@biotmed/settings-sdk';
import { defineMessages } from 'react-intl';

const oppositeStateMap = {
  [PluginResponseEnabledStateEnum.Enabled]: {
    text: intl.current.formatMessage({ id: 'update.enabled.state.disable', defaultMessage: 'Disable' }),
    logo: actionIcons.disable,
    state: PluginResponseEnabledStateEnum.Disabled,
  },
  [PluginResponseEnabledStateEnum.Disabled]: {
    text: intl.current.formatMessage({ id: 'update.enabled.state.enable', defaultMessage: 'Enable' }),
    logo: actionIcons.enable,
    state: PluginResponseEnabledStateEnum.Enabled,
  },
};

export const mapStateToOppositeState = (state: PluginResponseEnabledStateEnum) => {
  return (
    oppositeStateMap[state] ?? {
      text: state,
      logo: null,
      state,
    }
  );
};

const enabledStateEnumTranslations = defineMessages({
  [PluginResponseEnabledStateEnum.Enabled]: {
    id: 'plugin.state.enabled',
    defaultMessage: 'Enabled',
  },
  [PluginResponseEnabledStateEnum.Disabled]: {
    id: 'plugin.state.disabled',
    defaultMessage: 'Disabled',
  },
});

export const translateEnabledState = (state: PluginResponseEnabledStateEnum) => {
  return enabledStateEnumTranslations[state] ?? getDummyDescriptor(state);
};
