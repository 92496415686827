import React from 'react';
import intl from '@biotmed/i18n';
import { CustomAttribute } from '@biotmed/settings-sdk';
import messages from './dictionary';
import Information from './Information';

const editForceObservationsConfirmationProps = (
  errorDetails: any,
  attributesMapper?: { [key: string]: CustomAttribute },
) => {
  return {
    submitLabel: intl.current.formatMessage(messages.TEMPLATE_IN_USE_ERROR_SUBMIT),
    title: intl.current.formatMessage(messages.TEMPLATE_IN_USE_ERROR_TITLE),
    information: () => <Information errorDetails={errorDetails} attributesMapper={attributesMapper} />,
  };
};

export default editForceObservationsConfirmationProps;
