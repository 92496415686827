import styled from 'styled-components';
import { convertTypographySettingsToCss } from '@biotmed/base-components';

export const StyledTitle = styled.div`
  ${props => convertTypographySettingsToCss(props.theme.typography.body1)};
`;

export const Separator = styled.div`
  width: 20px;
`;

export const HelperText = styled.div`
  color: ${props => props.theme.palette.error.medium};
  ${props => convertTypographySettingsToCss(props.theme.typography.body1)};
  font-size: 1rem;
`;

export const MaxSizeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
`;

export const SizeValueContainer = styled.div`
  & .MuiFormControl-root {
    width: 100px;
  }
`;

export const SizeUnitContainer = styled.div`
  & .MuiFormControl-root {
    width: 70px;
  }
  && .MuiSelect-select {
    padding-bottom: 12px;
    ${props => convertTypographySettingsToCss(props.theme.typography.body1)};
    min-height: 0px;
    margin-right: 5px;
  }
  & svg {
    bottom: 17px;
  }
`;
