import { FieldType, FormField } from '@biotmed/base-components';
import { useField } from 'formik';
import React from 'react';
import { InputWithTooltipProps } from './InputWithTooltip';
import FocusInputWithTooltip from './FocusInputWithTooltip';

export type FocusInputWithTooltipWrapperProps<WithCharactersCounter extends boolean | undefined = undefined> =
  InputWithTooltipProps<WithCharactersCounter> & {
    inputField: FieldType;
    tooltipField: FieldType;
  };

const FocusInputWithTooltipWrapper = <WithCharactersCounter extends boolean | undefined = undefined>(
  props: FocusInputWithTooltipWrapperProps<WithCharactersCounter>,
) => {
  const {
    tooltipProps: { inputProps: tooltipInputProps = {}, ...restTooltipProps } = {},
    inputField: inputFieldProps,
    tooltipField: tooltipFieldProps,
    ...restProps
  } = props;

  const [inputField, inputMeta] = useField(inputFieldProps.name);
  const [tooltipField, tooltipMeta, tooltipHelpers] = useField(tooltipFieldProps.name);

  const inputConfig = {
    ...restProps,
    ...inputField,
    helperText: ' ',
  };

  if (inputMeta && inputMeta.touched && inputMeta.error) {
    inputConfig.error = true;
    inputConfig.helperText = inputMeta.error;
  }

  const tooltipConfig = {
    ...tooltipInputProps,
    ...tooltipField,
    helperText: ' ',
  };

  if (tooltipMeta && tooltipMeta.touched && tooltipMeta.error) {
    tooltipConfig.error = true;
    tooltipConfig.helperText = tooltipMeta.error;
  }

  const handleTooltipChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    tooltipHelpers.setValue(event.target.value);
  };

  return (
    <FormField label={inputFieldProps.label} required={inputFieldProps.required}>
      <FocusInputWithTooltip
        {...inputConfig}
        tooltipProps={{
          ...restTooltipProps,
          autoChange: !tooltipMeta.touched,
          inputProps: {
            ...tooltipConfig,
            onChange: handleTooltipChange,
          },
        }}
      />
    </FormField>
  );
};

export default FocusInputWithTooltipWrapper;
