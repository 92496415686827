import React, { ReactNode, useMemo } from 'react';
import { Input, Button, InputPropsType, CopyToClipboard } from '@biotmed/base-components';
import { useTheme } from 'styled-components';
import { useIntl } from 'react-intl';
import {
  BasePath,
  ConfirmationButtonsWrapper,
  HeaderContainer,
  TooltipContentStyled,
  TooltipInputWrapper,
} from '../InputWithTooltip.styled';

export type TooltipContentProps<WithCharactersCounter extends boolean | undefined = undefined> = {
  saveButtonText?: string | ReactNode;
  handleSave?: (name: string | null) => void;
  readOnly: boolean;
  basePath?: string;
  inputProps?: InputPropsType<WithCharactersCounter>;
};

export const TooltipContent = <WithCharactersCounter extends boolean | undefined = undefined>(
  props: TooltipContentProps<WithCharactersCounter>,
) => {
  const intl = useIntl();
  const defaultSaveButtonText = intl.formatMessage({ id: 'jsonTooltipSaveButton', defaultMessage: 'Save' });
  const {
    saveButtonText = defaultSaveButtonText,
    handleSave,
    readOnly,
    basePath,
    inputProps: {
      title = intl.formatMessage({
        id: 'templates.change-name-tooltip.title-text',
        defaultMessage: 'Json name',
      }),
      error,
      value,
      ...restInputProps
    } = {},
    ...restProps
  } = props;
  const theme = useTheme();

  const handleClickSave = (event: any) => {
    handleSave?.(event.target.value ?? '');
  };

  const valueToCopy = useMemo(() => {
    const prefix = basePath ? `${basePath}.` : '';

    return `${prefix}${value}`;
  }, [basePath, value]);

  return (
    <TooltipContentStyled>
      <HeaderContainer>
        {title}
        <CopyToClipboard valueToCopy={valueToCopy} />
      </HeaderContainer>
      <TooltipInputWrapper>
        <Input
          disabled={readOnly}
          autoFocus
          error={error}
          variant="standard"
          fullWidth
          title={(value as string) || ''}
          InputProps={{
            startAdornment: <BasePath $readOnly={readOnly}>{basePath ? `${basePath}.` : ''}</BasePath>,
          }}
          value={value}
          {...restInputProps}
          {...restProps}
        />
      </TooltipInputWrapper>
      <ConfirmationButtonsWrapper>
        <Button
          disabled={readOnly || error}
          textColor={theme.palette.grayScale.darkest}
          size="medium"
          onClick={handleClickSave}
        >
          {saveButtonText}
        </Button>
      </ConfirmationButtonsWrapper>
    </TooltipContentStyled>
  );
};

export default TooltipContent;
