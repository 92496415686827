import { fork, all } from 'redux-saga/effects';
import { saga as authSaga } from '@biotmed/auth-pages';
import { systemNotificationsSaga } from '@biotmed/system-notifications';
import { saga as portalBuilderSaga } from 'src/routes/PortalBuilder';
import { saga as environmentConfigurationSaga } from 'src/routes/EnvironmentConfiguration';
import { saga as languagesSaga } from 'src/routes/Languages';
import { saga as pluginConfigurationSaga } from 'src/routes/PluginConfigurationV2';
import { visibilitySaga } from '@biotmed/base-components';
import { saga as analyticsDBSaga } from 'src/routes/ADB';
import { sagas as dataSaga } from '../data';
/*
 * The entry point for all general sagas used in this application.
 */
export default function* root() {
  yield all([
    fork(dataSaga),
    fork(authSaga),
    fork(portalBuilderSaga),
    fork(environmentConfigurationSaga),
    fork(systemNotificationsSaga),
    fork(languagesSaga),
    fork(pluginConfigurationSaga),
    fork(visibilitySaga),
    fork(analyticsDBSaga),
  ]);
}
