import React from 'react';
import { InputNumber, InputNumberProps } from '@biotmed/base-components';
import EditInputAdornment from '../EditAdormentInput';

const NumberEditInput: React.FC<InputNumberProps> = props => (
  <InputNumber
    {...props}
    InputProps={{
      startAdornment: <EditInputAdornment position="end" />,
    }}
  />
);
export default NumberEditInput;
