import styled from 'styled-components';
import { PageMainTitle, PageLayout } from 'src/components/CommonStyledComponents';
import { convertTypographySettingsToCss, Link, fontWeightNameMappings, getOpaqueColor } from '@biotmed/base-components';
// FlagOff: PLUGIN_CONFIGURATION_PAGE-V2

export const PluginsPageLayout = styled.div`
  display: grid;
  grid-template-columns: 5fr minmax(200px, 320px);
  height: 100%;
`;

export const ContentWrapper = styled(PageLayout)`
  padding-top: 20px;
  overflow-y: auto;
  padding-right: 20px;

  .ant-pagination-item {
    line-height: 32px;
  }

  .ant-pagination-total-text {
    margin-top: 2px;
  }

  tr[data-row-key='selected-plugin'] {
    background-image: ${props =>
      getOpaqueColor(
        props.theme.palette.grayScale.lightest,
        props.theme.opacity.almostTransparent,
        props.theme.palette.primary.dark,
      )};
  }
`;

export const StyledPageTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PluginsTitle = styled(PageMainTitle)``;

export const PluginsTotalSubTitle = styled.div`
  ${props => convertTypographySettingsToCss(props.theme.typography.h7)};
  margin-right: 10px;
  margin-top: 5px;
`;

export const PluginsTotalRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px;
`;

export const RightSide = styled.div`
  width: 296px;
`;

export const StyledLink = styled(Link)`
  display: flex;
  alignitems: center;
`;

export const PluginPreviewRow = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  flex-wrap: wrap;
  overflow-wrap: anywhere;
`;

export const PluginPreviewBlock = styled.div``;

export const PluginPreviewKey = styled.div`
  font-weight: ${fontWeightNameMappings.medium};
`;

export const PluginPreviewContainer = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: column;
`;

export const FreeTextSearchContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
`;
