export type UseDefaultValueOptions = {
  initiallyMandatory: boolean;
  attributeMandatory: boolean;
  initiallyWithDefaultValue?: boolean;
  templateInUse?: boolean;
};

export const shouldUseDefaultValue = (options: UseDefaultValueOptions) => {
  const { initiallyMandatory, attributeMandatory, templateInUse, initiallyWithDefaultValue } = options;

  if (!templateInUse) return false;
  return !!((initiallyWithDefaultValue && !!attributeMandatory) || (!initiallyMandatory && !!attributeMandatory));
};

export const shouldValidateDefaultValue = (options: UseDefaultValueOptions) => {
  return (
    shouldUseDefaultValue({
      ...options,
    }) && !options.initiallyWithDefaultValue
  );
};
