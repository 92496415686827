import React, { useContext } from 'react';
import { useFormikContext } from 'formik';
import { MuiPickersAdapterContextValue } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { MuiPickersAdapterContext } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { makeDateOnlyString } from '@biotmed/data-components';
import { EntityTemplateForm } from 'src/routes/Templates/components/Template';
import { DateTimeInput } from './styled/Renderers.styled';
import { DefaultValueExtractedRendererProps } from '../../modules';

const dateFns = new AdapterDateFns();

export type DateRendererProps = DefaultValueExtractedRendererProps<Date | null> & {
  placeholder: string;
};

export const DateRenderer: React.FC<DateRendererProps> = props => {
  const { value, InputProps, error, helperText, name } = props;
  const { endAdornment, disabled, ...inputProps } = InputProps;

  const formik = useFormikContext<EntityTemplateForm>();

  const { setFieldValue, setFieldTouched } = formik;

  const context = useContext(MuiPickersAdapterContext as React.Context<MuiPickersAdapterContextValue<Date> | null>);
  const { isValid, date } = context?.utils || dateFns;

  const handleChange = (_date: Date | null | undefined) => {
    setFieldTouched(name);
    // TODO: Can this be removed ? What is it's purpose?
    const newDate = _date && isValid(date(_date)) ? makeDateOnlyString(_date) : _date?.toString() ?? null;
    setFieldValue(name, newDate);
  };

  const handleMenuClose = () => {
    setFieldTouched(name, true, false);
  };

  return (
    <DateTimeInput
      value={value}
      inputProps={{ error, helperText, onBlur: handleMenuClose, name, ...inputProps }}
      disabled={InputProps.disabled}
      datePickerProps={{ onClose: handleMenuClose }}
      onChange={handleChange}
    />
  );
};

export default DateRenderer;
