import { getSdkApi } from '@biotmed/sdk-api-provider';
import { GetTemplateResponse, ErrorResponse, TemplateOverviewResponse } from '@biotmed/settings-sdk';
import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { ErrorTypeEnum, errorNotice } from '@biotmed/system-notifications';
import { useDispatch } from 'react-redux';

import { childrenTemplatesFieldName } from '../modules/constant';
import { EntityTemplateForm } from './Template';
import { getTemplateOverview } from '../hooks/useTemplateOverview';
import { templateOverviewErrorDictionary } from '../modules/dictionaries';

interface LoadChildrenTemplateProps {
  templateId: string;
  setOriginalChildrenTemplates: React.Dispatch<React.SetStateAction<GetTemplateResponse[]>>;
}

type FulfilledResult = { status: 'fulfilled'; value: Record<string, TemplateOverviewResponse> };

const LoadChildrenTemplate: React.FC<LoadChildrenTemplateProps> = props => {
  const { templateId, setOriginalChildrenTemplates } = props;
  const formik = useFormikContext<EntityTemplateForm>();
  const dispatch = useDispatch();

  const getChildrenOverviews = async (childrenTemplates: GetTemplateResponse[]) => {
    const childrenTemplatesOverviewResponses = await Promise.allSettled(
      childrenTemplates.map(
        template =>
          new Promise((resolve, reject) => {
            (async () => {
              const templateId = template.id;
              if (!templateId) return Promise.reject(new Error('No template id'));
              try {
                const overview = await getTemplateOverview(templateId, dispatch);
                return resolve({ [templateId]: overview });
              } catch (error: any) {
                const apiError = (error?.response?.data || error) as ErrorResponse;
                dispatch(
                  errorNotice({
                    type: ErrorTypeEnum.GENERAL,
                    errorParams: {
                      error: apiError,
                      dictionary: templateOverviewErrorDictionary,
                    },
                  }),
                );
                return reject(error);
              }
            })();
          }),
      ),
    );
    return childrenTemplatesOverviewResponses;
  };

  useEffect(() => {
    if (templateId) {
      getChildrenTemplates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateId]);

  const getChildrenTemplates = async () => {
    formik.setStatus({
      ...formik.status,
      isLoadingChildren: true,
    });

    try {
      // if there are more than 100 results, we need to add an iterator that receives a request
      // and returns all of the results from the BE
      const childrenTemplatesResponse = await getSdkApi().settings.templatesApi.search1({
        filter: {
          parentTemplateId: {
            in: [templateId],
          },
        },
      });

      const childrenTemplates = childrenTemplatesResponse?.data?.data.sort((a: any, b: any) =>
        a?.displayName?.localeCompare(b?.displayName),
      );
      const childrenOverviewsResolves = await getChildrenOverviews(childrenTemplates);

      const childrenOverviewsArray = childrenOverviewsResolves
        .filter(res => res.status === 'fulfilled')
        .map(res => (res as FulfilledResult).value);

      const childrenOverviews = Object.assign({}, ...childrenOverviewsArray);

      formik.setStatus({
        ...formik.status,
        isLoadingChildren: false,
        childrenTemplates,
        childrenOverviews,
      });

      formik.setFieldValue(childrenTemplatesFieldName, childrenTemplates);
      setOriginalChildrenTemplates(childrenTemplates);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Failed to get children templates e', e);
    }
  };

  return null;
};

export default LoadChildrenTemplate;
