import { defineMessages } from 'react-intl';

export const defaultValuePlaceholdersDictionary = defineMessages({
  LABEL: {
    id: 'template.modal.attribute.default-value-for-existing-entities-renderer.label.placeholder',
    defaultMessage: `Set default value`,
  },
  PHONE: {
    id: 'template.modal.attribute.default-value-for-existing-entities-renderer.phone.placeholder',
    defaultMessage: `Set phone`,
  },
  PARAGRAPH: {
    id: 'template.modal.attribute.default-value-for-existing-entities-renderer.paragraph.placeholder',
    defaultMessage: `Set paragraph`,
  },
  NAME_FIRST: {
    id: 'template.modal.attribute.default-value-for-existing-entities-renderer.name.first.placeholder',
    defaultMessage: `Set first name`,
  },
  NAME_LAST: {
    id: 'template.modal.attribute.default-value-for-existing-entities-renderer.name.last.placeholder',
    defaultMessage: `Set last name`,
  },
  ADDRESS_COUNTRY_CODE: {
    id: 'template.modal.attribute.default-value-for-existing-entities-renderer.address.countryCode.placeholder',
    defaultMessage: `Set country code`,
  },
  ADDRESS_STATE: {
    id: 'template.modal.attribute.default-value-for-existing-entities-renderer.address.state.placeholder',
    defaultMessage: `Set state`,
  },
  ADDRESS_CITY: {
    id: 'template.modal.attribute.default-value-for-existing-entities-renderer.address.city.placeholder',
    defaultMessage: `Set city`,
  },
  ADDRESS_ZIPCODE: {
    id: 'template.modal.attribute.default-value-for-existing-entities-renderer.address.zipCode.placeholder',
    defaultMessage: `Set zip-Code`,
  },
  ADDRESS_STREET: {
    id: 'template.modal.attribute.default-value-for-existing-entities-renderer.address.street.placeholder',
    defaultMessage: `Set street`,
  },
  ADDRESS_ADDITIONAL_INFO: {
    id: 'template.modal.attribute.default-value-for-existing-entities-renderer.address.additionalInfo.placeholder',
    defaultMessage: `Set additional info`,
  },
  SINGLE_SELECT: {
    id: 'entity-modal.defaultValue.renderers.singleSelect.placeholder',
    defaultMessage: 'Select option',
  },
  MULTI_SELECT: {
    id: 'entity-modal.defaultValue.renderers.multiSelect.placeholder',
    defaultMessage: 'Select options',
  },
  BOOLEAN: {
    id: 'entity-modal.defaultValue.renderers.boolean.placeholder',
    defaultMessage: 'Select option',
  },
  INTEGER: {
    id: 'entity-modal.defaultValue.renderers.integer.placeholder',
    defaultMessage: 'Type a number',
  },
  DECIMAL: {
    id: 'entity-modal.defaultValue.renderers.decimal.placeholder',
    defaultMessage: 'Type a number',
  },
  DATE: {
    id: 'entity-modal.defaultValue.renderers.date.placeholder',
    defaultMessage: 'Pick a date',
  },
  DATETIME: {
    id: 'entity-modal.defaultValue.renderers.dateTime.placeholder',
    defaultMessage: 'Pick a date',
  },
  LOCALE: {
    id: 'entity-modal.defaultValue.renderers.locale.placeholder',
    defaultMessage: 'Pick locale',
  },
  TIMEZONE: {
    id: 'entity-modal.defaultValue.renderers.timezone.placeholder',
    defaultMessage: 'Pick timezone',
  },
});
