import { DetailsDisplay } from '@biotmed/base-components';
import { PluginResponse } from '@biotmed/settings-sdk';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { translateEnabledState } from '../mappers';
import {
  PluginPreviewContainer,
  PluginPreviewKey,
  PluginPreviewRow,
  PluginPreviewBlock,
  StyledLink,
} from './PluginConfiguration.styled';

const More: React.FC = () => {
  return <div>...</div>;
};

const PluginDetailsDisplay: React.FC<{ selectedRow?: PluginResponse }> = props => {
  const { selectedRow } = props;
  const intl = useIntl();

  const renderEnvironmentVariables = () => {
    return (
      <PluginPreviewContainer>
        {Object.keys(selectedRow?.environmentVariables ?? {})?.map(environmentVariableKey => (
          <PluginPreviewRow>
            <PluginPreviewKey>{environmentVariableKey}: </PluginPreviewKey>
            {
              (selectedRow?.environmentVariables ?? {})[
                environmentVariableKey as keyof Pick<PluginResponse, 'environmentVariables'>
              ]
            }
          </PluginPreviewRow>
        ))}
      </PluginPreviewContainer>
    );
  };

  const renderInterceptions = () => {
    return (
      <PluginPreviewContainer>
        {selectedRow?.subscriptions?.interceptions?.slice(0, 2).map((interception: any, index: number) => {
          return (
            <PluginPreviewBlock>
              <PluginPreviewRow>
                <PluginPreviewKey>
                  {intl.formatMessage({
                    id: 'plugin-attribute.subscriptions.interceptions.type',
                    defaultMessage: 'Type',
                  })}
                  :
                </PluginPreviewKey>
                {interception.type}
              </PluginPreviewRow>
              <PluginPreviewRow>
                <PluginPreviewKey>
                  {intl.formatMessage({
                    id: 'plugin-attribute.subscriptions.interceptions.api-id',
                    defaultMessage: 'Api id',
                  })}
                  :
                </PluginPreviewKey>
                {interception.apiId}
              </PluginPreviewRow>
            </PluginPreviewBlock>
          );
        })}
        {(selectedRow?.subscriptions?.interceptions?.length as number) > 2 && <More />}
      </PluginPreviewContainer>
    );
  };

  const renderNotifications = () => {
    return (
      <PluginPreviewContainer>
        {selectedRow?.subscriptions?.notifications?.slice(0, 2).map((notification: any) => (
          <PluginPreviewRow>
            {notification.entityTypeName} - {notification.actionName}
          </PluginPreviewRow>
        ))}
        {(selectedRow?.subscriptions?.notifications?.length as number) > 2 && <More />}
      </PluginPreviewContainer>
    );
  };

  // eslint-disable-next-line no-undef
  const detailsDisplayRowsArray: { key: string; value: string | JSX.Element; forceTwoRows?: boolean }[] =
    useMemo(() => {
      if (selectedRow?.name) {
        // eslint-disable-next-line no-undef
        return [
          {
            key: intl.formatMessage({
              id: 'plugin-attribute.name',
              defaultMessage: 'Name',
            }),
            value: selectedRow.name,
          },
          {
            key: intl.formatMessage({
              id: 'plugin-attribute.display-name',
              defaultMessage: 'Display Name',
            }),
            value: selectedRow.displayName,
          },
          {
            key: intl.formatMessage({
              id: 'plugin-attribute.enable-state',
              defaultMessage: 'State',
            }),
            value: intl.formatMessage(translateEnabledState(selectedRow.enabledState)),
          },
          {
            key: intl.formatMessage({
              id: 'plugin-attribute.version',
              defaultMessage: 'Version',
            }),
            value: selectedRow.version.toString(),
          },
          {
            key: intl.formatMessage({
              id: 'plugin-attribute.endpoint-url',
              defaultMessage: 'Endpoint Url',
            }),
            value: selectedRow.endpointUrl,
          },
          {
            key: intl.formatMessage({
              id: 'plugin-attribute.runtime',
              defaultMessage: 'Runtime',
            }),
            value: selectedRow.runtime,
          },
          {
            key: intl.formatMessage({
              id: 'plugin-attribute.timeout',
              defaultMessage: 'Timeout',
            }),
            value: selectedRow.timeout.toString(),
          },
          {
            key: intl.formatMessage({
              id: 'plugin-attribute.link-to-console',
              defaultMessage: 'Link',
            }),
            value: (
              <StyledLink
                onClick={() => {
                  window.open(selectedRow.linkToConsole, '_blank');
                }}
              >
                {intl.formatMessage({
                  id: 'plugin-attribute.link-to-console.press-here',
                  defaultMessage: 'Press here',
                })}
              </StyledLink>
            ),
          },
          {
            key: intl.formatMessage({
              id: 'plugin-attribute.subscriptions.interceptions',
              defaultMessage: 'Interceptions',
            }),
            value: renderInterceptions(),
            forceTwoRows: true,
          },
          {
            key: intl.formatMessage({
              id: 'plugin-attribute.subscriptions.notifications',
              defaultMessage: 'Notifications',
            }),
            value: renderNotifications(),
            forceTwoRows: true,
          },
          {
            key: intl.formatMessage({
              id: 'plugin-attribute.last-modified-time',
              defaultMessage: 'Last Modified Time',
            }),
            value: selectedRow.lastModifiedTime,
          },
          {
            key: intl.formatMessage({
              id: 'plugin-attribute.creationTime',
              defaultMessage: 'Creation Time',
            }),
            value: selectedRow.creationTime,
          },
          {
            key: intl.formatMessage({
              id: 'plugin-attribute.environment-variables',
              defaultMessage: 'Environment Variables',
            }),
            value: renderEnvironmentVariables(),
            forceTwoRows: true,
          },
        ];
      }

      return [];
    }, [selectedRow]);

  return detailsDisplayRowsArray.length ? <DetailsDisplay detailsDisplayRowsList={detailsDisplayRowsArray} /> : null;
};

export default PluginDetailsDisplay;
