import { Button } from '@biotmed/base-components';
import styled from 'styled-components';

export const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 0;
  justify-content: space-between;
`;

export const SubmitButton = styled(Button)`
  &&& {
    min-width: 145px;
  }
`;
