import React from 'react';
import { useIntl } from 'react-intl';
import { InUseAttributesList } from 'src/routes/Templates/components/formStepsComponents/forceConfirmation/ForceConfirmationPopup.styled';
import { Separator } from '@biotmed/base-components';
import { CustomAttribute } from '@biotmed/settings-sdk';
import { checkIsAnalyticsDBDeployed } from 'src/utils/analyticsDBUtils';
import { useSelector } from 'react-redux';
import messages from './dictionary';
import { selectors as analyticsDBSelector } from '../../ADB';

interface InformationProps {
  errorDetails: any;
  attributesMapper?: { [key: string]: CustomAttribute };
}

const Information = (props: InformationProps) => {
  const { errorDetails, attributesMapper = {} } = props;
  const intl = useIntl();
  const isAnalyticsDBDeployed = checkIsAnalyticsDBDeployed(useSelector(analyticsDBSelector.getDeploymentState));

  return (
    <>
      {intl.formatMessage(messages.TEMPLATE_IN_USE_ERROR_CONTENT)}
      <InUseAttributesList>
        {errorDetails?.attributes?.map((attr: CustomAttribute) => (
          <li>{attributesMapper?.[attr.id]?.name ?? attr.name}</li>
        ))}
      </InUseAttributesList>
      {intl.formatMessage(messages.TEMPLATE_IN_USE_ERROR_CONSEQUENCES_ACTION)}
      <Separator height="20px" />
      {isAnalyticsDBDeployed && intl.formatMessage(messages.TEMPLATE_IN_USE_ERROR_NOTE)}
    </>
  );
};

Information.defaultProps = { attributesMapper: {} };

export default Information;
