import React, { useEffect, useState } from 'react';
import { getSdkApi } from '@biotmed/sdk-api-provider';
import { ErrorResponse, TemplateOverviewResponse } from '@biotmed/settings-sdk';
import { ErrorTypeEnum, errorNotice } from '@biotmed/system-notifications';
import { useDispatch } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { templateOverviewErrorDictionary } from '../modules/dictionaries';

export const getTemplateOverview = async (templateId: string, dispatch: Dispatch<AnyAction>) => {
  const { data } = await getSdkApi().settings.templatesApi.getTemplateOverview(templateId);
  return data;
};

export const useTemplateOverview = (templateId?: string) => {
  const [templateOverview, setTemplateOverview] = useState<TemplateOverviewResponse | undefined>();
  const [currentTemplateId, setCurrentTemplateId] = useState<string | undefined>();

  const dispatch = useDispatch();
  const loadAndSetTemplateOverview = async (templateIdToSet: string) => {
    try {
      const overview = await getTemplateOverview(templateIdToSet, dispatch);
      setTemplateOverview(overview);
    } catch (error: any) {
      const apiError = (error?.response?.data || error) as ErrorResponse;
      dispatch(
        errorNotice({
          type: ErrorTypeEnum.GENERAL,
          errorParams: {
            error: apiError,
            dictionary: templateOverviewErrorDictionary,
          },
        }),
      );
      setTemplateOverview(undefined);
    }

    setCurrentTemplateId(templateIdToSet);
  };

  useEffect(() => {
    if (!templateId) {
      setTemplateOverview(undefined);
      setCurrentTemplateId(undefined);
    } else if (templateId !== currentTemplateId) {
      setTemplateOverview(undefined);
      (async () => loadAndSetTemplateOverview(templateId))();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateId]);

  return templateOverview;
};
