import React from 'react';
import { BaseButtonProps } from '@biotmed/base-components';
import { useTheme } from 'styled-components';
import { AddFieldButtonStyled } from './AddFieldButton.styled';

export interface AddFieldButtonProps extends BaseButtonProps {
  halfWidth?: boolean;
  shadow?: boolean;
}
const AddFieldButton: React.FC<AddFieldButtonProps> = props => {
  const { halfWidth, shadow, ...restProps } = props;
  const theme = useTheme();
  return (
    <AddFieldButtonStyled
      variant="outlined"
      size="large"
      textColor={theme.palette.grayScale.darkest}
      type="button"
      $shadow={shadow}
      {...restProps}
    />
  );
};
export default AddFieldButton;
