import { defineMessages } from 'react-intl';

const messages = defineMessages({
  TEMPLATE_IN_USE_ERROR_TITLE: {
    id: 'templates.edit.in-use-fields.title',
    defaultMessage: 'Delete All Observations?',
  },
  TEMPLATE_IN_USE_ERROR_SUBMIT: {
    id: 'templates.edit.in-use-fields.submit',
    defaultMessage: 'Delete Observations Database',
  },
  TEMPLATE_IN_USE_ERROR_CONTENT: {
    id: 'templates.edit.in-use-fields.content',
    defaultMessage: 'You have changed/added/removed the following observation attributes:',
  },
  TEMPLATE_IN_USE_ERROR_CONSEQUENCES_ACTION: {
    id: 'templates.edit.in-use-fields.consequences-action',
    defaultMessage:
      'In order to apply these changes all historical observation data will be deleted from the database.\n Are you sure you want to proceed?',
  },
  TEMPLATE_IN_USE_ERROR_NOTE: {
    id: 'templates.edit.in-use-fields.note',
    defaultMessage:
      'Note: In case you are using the analytics DB this operation will stop any active sync and a full re-sync will be needed in order to continue working with the analytics DB. For implication of resync please refer to the following article',
  },
});

export default messages;
