/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import {
  reducer as authReducer,
  getInitialState as getAuthInitialState,
  STATE_KEY as authStateKey,
} from '@biotmed/auth-pages';

import portalBuilderReducer, {
  getInitialState as getPortalBuilderInitialState,
  STATE_KEY as portalBuilderStateKey,
} from 'src/routes/PortalBuilder/modules/slice';

import environmentConfigurationReducer, {
  getInitialState as getEnvironmentConfigurationInitialState,
  STATE_KEY as environmentConfigurationStateKey,
} from 'src/routes/EnvironmentConfiguration/modules/slice';

import {
  SNACKBAR_SLICE_KEY as snackbarKey,
  snackbarReducer,
  getInitialState as getSnackbarInitialState,
} from '@biotmed/base-components';

import {
  SYSTEM_NOTIFICATIONS_STATE_KEY as systemNotificationsKey,
  systemNotificationsReducer,
  getSystemNotificationsInitialState,
} from '@biotmed/system-notifications';

import {
  STATE_KEY as translationsStateKey,
  getInitialState as getTranslationsSliceInitialState,
  reducer as translationsReducer,
} from 'src/routes/Languages';

import {
  STATE_KEY as pluginConfigurationStateKey,
  getInitialState as getPluginConfigurationStateKeySliceInitialState,
  reducer as pluginConfigurationStateKeyReducer,
} from 'src/routes/PluginConfigurationV2';

import {
  STATE_KEY as analyticsDBKey,
  reducer as analyticsDBReducer,
  getInitialState as getAnalyticsDBInitialState,
} from 'src/routes/ADB';

import { reducer as dataReducer, STATE_KEY as dataStateKey, getDataInitialState } from '../data';

import { actions as loginActions } from '../data/login';

// eslint-disable-next-line no-use-before-define
const getInitialState = (state?: RootState) => ({
  [dataStateKey]: getDataInitialState(state?.[dataStateKey]),
  [authStateKey]: getAuthInitialState(state?.[authStateKey]),
  [portalBuilderStateKey]: getPortalBuilderInitialState(state?.[portalBuilderStateKey]),
  [environmentConfigurationStateKey]: getEnvironmentConfigurationInitialState(
    state?.[environmentConfigurationStateKey],
  ),
  [snackbarKey]: getSnackbarInitialState(),
  [systemNotificationsKey]: getSystemNotificationsInitialState(),
  [translationsStateKey]: getTranslationsSliceInitialState(state?.[translationsStateKey]),
  [pluginConfigurationStateKey]: getPluginConfigurationStateKeySliceInitialState(state?.[pluginConfigurationStateKey]),
  [analyticsDBKey]: getAnalyticsDBInitialState(state?.[analyticsDBKey]),
});

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
const appReducer = combineReducers({
  [dataStateKey]: dataReducer,
  [authStateKey]: authReducer,
  [snackbarKey]: snackbarReducer,
  [portalBuilderStateKey]: portalBuilderReducer,
  [environmentConfigurationStateKey]: environmentConfigurationReducer,
  [systemNotificationsKey]: systemNotificationsReducer,
  [translationsStateKey]: translationsReducer,
  [pluginConfigurationStateKey]: pluginConfigurationStateKeyReducer,
  [analyticsDBKey]: analyticsDBReducer,
});

// rootReducer in charge of resetting the store on logout.
// see https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
const reducer: typeof appReducer = (state: any, action: { type: string }) => {
  if (loginActions.logoutFinish.type === action.type) {
    return appReducer(getInitialState(state), action);
  }
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof appReducer>;

export default reducer;
