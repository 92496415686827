import { createDeleteItemsSlice } from '@biotmed/data-components';
import { TRANSLATIONS_STATE_KEY } from './constants';

const STATE_KEY = 'translations-delete';

const { reducer, actions, selectors, getInitialState } = createDeleteItemsSlice({
  stateKey: STATE_KEY,
  getRootState: state => state[TRANSLATIONS_STATE_KEY],
});

export { STATE_KEY, actions, selectors, getInitialState };

export default reducer;
