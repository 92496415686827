import React from 'react';
import { useIntl } from 'react-intl';
import { Switch } from '@biotmed/base-components';
import { StyledRequired } from './ComponentSections.styled';

export interface RequiredProps {
  required: boolean;
  setRequired: (checked: boolean) => void;
  readOnly?: boolean;
}

const BuiltIn: React.FC<RequiredProps> = props => {
  const { required, setRequired, readOnly } = props;

  const intl = useIntl();

  const handleRequiredChange = (event: any, checked: boolean) => {
    setRequired(checked);
  };

  return (
    <StyledRequired>
      <span>
        {intl.formatMessage({
          id: 'template.modal.attribute.trash-required-section.required',
          defaultMessage: 'Required',
        })}
      </span>
      <Switch disabled={readOnly} checked={required} onChange={handleRequiredChange} />
    </StyledRequired>
  );
};

export default BuiltIn;
