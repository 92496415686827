import { Field } from 'formik';
import React from 'react';
import styled from 'styled-components';

interface FieldErrorProps {
  errors: any;
  attributeName: string;
  touched: any;
  // eslint-disable-next-line react/require-default-props
  name: string;
}

const StyledWarning = styled(Field).attrs({ as: 'div' })`
  color: ${props => props.theme.palette.error.medium};
`;

export const FieldError = ({ errors, attributeName, touched, name }: FieldErrorProps) => {
  return errors &&
    typeof errors?.[attributeName] === 'string' &&
    touched?.[attributeName] &&
    Boolean(errors?.[attributeName]) ? (
    <StyledWarning name={name}>{errors?.[attributeName]}</StyledWarning>
  ) : null;
};
