/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-shadow
export enum RoutesEnum {
  AUTH = 'auth',
  PORTAL_BUILDER = 'portal-builder',
  TEMPLATES = 'templates',
  CHANGE_PASSWORD = 'change-password',
  ENVIRONMENT_CONFIGURATION = 'environment-configuration',
  TECHNICAL_INFORMATION = 'technical-information',
  PLUGIN_CONFIGURATION = 'plugin-configuration',
  LANGUAGES = 'languages',
  ANALYTICS_DB = 'analytics-db',
}

export default RoutesEnum;
