import { Button } from '@biotmed/base-components';
import styled from 'styled-components';

export const AddFieldButtonStyled = styled(Button)<{ $shadow?: boolean }>`
  && {
    width: 100%;
    box-shadow: ${props => (props.$shadow ? '0 1px 1px 0 rgba(66,67,69,0.17)' : 'unset')};
    padding: 15px;
    background-color: ${props => props.theme.palette.grayScale.lightest};
    &.MuiButton-outlined {
      border-color: ${props => props.theme.palette.grayScale.medium};
      &:hover,
      &:hover:not(:disabled),
      &:focus {
        border-color: ${props => props.theme.palette.grayScale.medium};
      }
    }
  }
`;
