import { UserResponse } from '@biotmed/organization-sdk';
import { getSdkApi } from '@biotmed/sdk-api-provider';
import { AxiosResponse } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { actions as loginActions } from 'src/redux/data/login';
import { actions } from '..';
import { isAuthorized } from '../../../../utils/auth';

function* getSelfUser(action: ReturnType<typeof loginActions.loginSuccess>): any {
  try {
    if (isAuthorized(action.payload?.loginResponse)) {
      const response: AxiosResponse<UserResponse> = yield call(getSdkApi().organization.userApi.getSelf);
      yield put(actions.userDetailsLoaded(response.data));
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('error in getSelf: ', e);
  }
}

export default function* watch() {
  yield all([takeLatest(loginActions.loginSuccess, getSelfUser)]);
}
