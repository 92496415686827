import React from 'react';
import { useIntl } from 'react-intl';
import { AttributeTypesEnum } from 'src/utils/attributeTypeEnum';
import { TypeSectionContainer } from './ComponentSections.styled';
import { mapAttributesTypeToLabel } from '../typesComponents/modules/factory';

export interface TypeSectionReadOnlyProps {
  type: string;
}

const TypeSectionReadOnly: React.FC<TypeSectionReadOnlyProps> = props => {
  const { type } = props;
  const intl = useIntl();
  const label = mapAttributesTypeToLabel.get(type as AttributeTypesEnum);
  return <TypeSectionContainer>{label ? intl.formatMessage(label) : type}</TypeSectionContainer>;
};

export default TypeSectionReadOnly;
