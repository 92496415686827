import * as Yup from 'yup';
import {
  childrenTemplatesFieldName,
  entityTemplateFieldName,
  validationConstants,
} from 'src/routes/Templates/modules/constant';
import { Category } from '@biotmed/sdk-api-provider/lib/types/settings';
import { IntlShape } from 'react-intl';
import { EntityTypeEnum } from '@biotmed/data-components';
import { FullDetailsPortalAttributeTypeEnum } from '@biotmed/settings-sdk';
import { factoryIntlMessages as messages } from './factoryIntlMessages';
import { AttributeTypesEnum } from '../../../utils/attributeTypeEnum';
import { getYupByAttributeType } from './validationDefaultValues';
import {
  defaultValueComponentsMap,
  shouldValidateDefaultValue,
} from '../../../components/AttributeLayout/defaultValueComponents/modules';
import { exists } from '../../../utils/generalUtils';

export const defaultValuesValidationData = {};

const referenceConfigurationYup = (intl: IntlShape, stepCategory?: string) => {
  return Yup.mixed().when(['type', 'category'], {
    is: (type: any, category: Category) => {
      return type === AttributeTypesEnum.Reference && (category.name === stepCategory || !stepCategory);
    },
    then: Yup.object().shape({
      referencedSideAttributeDisplayName: Yup.string().required(intl.formatMessage(messages.inputIsRequired)),
      validTemplatesToReference: Yup.array().of(Yup.string()).nullable(),
      entityType: Yup.string().required(intl.formatMessage(messages.inputIsRequired)),
    }),
    otherwise: Yup.object().nullable(),
  });
};

const attributeDisplayNameYup = (intl: IntlShape, stepCategory?: string) => {
  return Yup.mixed().when(['category'], {
    is: (category: Category) => {
      return category.name === stepCategory || !stepCategory;
    },
    then: Yup.string()
      .trim(intl.formatMessage(messages.inputNoLeadingTrailingSpaces))
      .max(
        validationConstants.templateModal.builtinAttributes.displayName.maxLength,
        intl.formatMessage(messages.inputMaxLength, {
          maxLength: validationConstants.templateModal.builtinAttributes.displayName.maxLength,
        }),
      )
      .required(intl.formatMessage(messages.inputIsRequired)),
    otherwise: Yup.string().nullable(),
  });
};

const attributeNameYup = (intl: IntlShape, stepCategory?: string) => {
  return Yup.mixed().when(['category'], {
    is: (category: Category) => {
      return category?.name === stepCategory || !stepCategory;
    },
    then: Yup.string()
      .matches(
        /^[a-zA-Z0-9_]*$/g,
        intl.formatMessage(messages.inputWrongFormat, {
          correctFormat: '^[a-zA-Z0-9_]*$',
        }),
      )
      .matches(
        /^[a-zA-Z]/,
        intl.formatMessage(messages.inputWrongFirstLetter, {
          requirement: 'must be a letter',
        }),
      )
      .max(
        validationConstants.templateModal.customAttributes.name.maxLength,
        intl.formatMessage(messages.inputMaxLength, {
          maxLength: validationConstants.templateModal.customAttributes.name.maxLength,
        }),
      )
      .required(intl.formatMessage(messages.inputIsRequired)),
    otherwise: Yup.string().nullable(),
  });
};

const attributeTypeYup = (intl: IntlShape, stepCategory?: string) => {
  return Yup.string().required(intl.formatMessage(messages.inputIsRequired));
};

const attributeSelectableValuesYup = (intl: IntlShape, stepCategory?: string) => {
  return Yup.mixed().when(['type', 'category'], {
    is: (type: AttributeTypesEnum, category: Category) => {
      return (
        (type === AttributeTypesEnum.SingleSelect || type === AttributeTypesEnum.MultiSelect) &&
        (category.name === stepCategory || !stepCategory)
      );
    },
    then: Yup.array()
      .of(
        Yup.object().shape({
          displayName: Yup.string()
            .trim(intl.formatMessage(messages.inputNoLeadingTrailingSpaces))
            .required(intl.formatMessage(messages.inputIsRequired))
            .max(
              validationConstants.templateModal.selectableAttributes.displayName.maxLength,
              intl.formatMessage(messages.inputMaxLength, {
                maxLength: validationConstants.templateModal.selectableAttributes.displayName.maxLength,
              }),
            )
            .strict(),
          name: attributeNameYup(intl),
        }),
      )
      .min(
        validationConstants.templateModal.customAttributes.selectableValuesArray.minOptionsLength,
        intl.formatMessage(messages.selectableValuesArrayMinOptionsLength, {
          minOptionsLength: validationConstants.templateModal.customAttributes.selectableValuesArray.minOptionsLength,
        }),
      ),
    otherwise: Yup.array().nullable(),
  });
};

const defaultValueYup = (
  type: FullDetailsPortalAttributeTypeEnum,
  initiallyMandatory: boolean,
  initiallyWithDefaultValue?: boolean,
  templateInUse?: boolean,
) =>
  Yup.mixed().when(
    ['mandatory', 'regex', 'min', 'max'],
    // @ts-ignore
    (mandatory: boolean, regex: string, min: number, max: number) => {
      return shouldValidateDefaultValue({
        initiallyMandatory,
        attributeMandatory: mandatory,
        initiallyWithDefaultValue,
        templateInUse,
      })
        ? getYupByAttributeType(type, { mandatory, regex, min, max })
        : Yup.mixed();
    },
  );

const attributeValidationYup = (
  intl: IntlShape,
  stepCategory?: string,
  validationSpecificData?: {
    templateInUse?: boolean;
    initialValues?: Record<string, any>;
  },
) => {
  return Yup.mixed()
    .when(
      ['type', 'id'],
      // @ts-ignore
      (type: FullDetailsPortalAttributeTypeEnum, id: string) => {
        const initialAttribute =
          (validationSpecificData || {}).initialValues?.entityTemplate?.customAttributes.find(
            (attribute: any) => attribute.id === id,
          ) ||
          (validationSpecificData || {}).initialValues?.entityTemplate?.builtInAttributes.find(
            (attribute: any) => attribute.id === id,
          );

        const initiallyMandatory = initialAttribute?.validation?.mandatory;
        const initiallyWithDefaultValue = exists(initialAttribute?.validation?.defaultValue);

        return Object.keys(defaultValueComponentsMap).includes(type)
          ? Yup.object().shape({
              defaultValue: defaultValueYup(
                type,
                initiallyMandatory,
                initiallyWithDefaultValue,
                validationSpecificData?.templateInUse,
              ),
            })
          : Yup.mixed().nullable();
      },
    )
    .when(['type', 'category'], {
      is: (type: FullDetailsPortalAttributeTypeEnum, category: Category) => {
        return (
          (type === FullDetailsPortalAttributeTypeEnum.File || type === FullDetailsPortalAttributeTypeEnum.Image) &&
          (category.name === stepCategory || !stepCategory)
        );
      },
      then: Yup.object()
        .shape({
          max: Yup.number()
            .moreThan(0, intl.formatMessage(messages.numberMoreThanMessage, { min: 0 }))
            .required(intl.formatMessage(messages.inputIsRequired)),
        })
        .required(intl.formatMessage(messages.inputIsRequired)),
    });
};

const customAttributesYup = (
  intl: IntlShape,
  stepCategory?: string,
  validationSpecificData?: { templateInUse?: boolean; initialValues?: Record<string, any> },
) => {
  return Yup.array().of(
    Yup.object().shape({
      displayName: attributeDisplayNameYup(intl, stepCategory),
      name: attributeNameYup(intl, stepCategory),
      type: attributeTypeYup(intl, stepCategory),
      selectableValues: attributeSelectableValuesYup(intl, stepCategory),
      referenceConfiguration: referenceConfigurationYup(intl, stepCategory),
      validation: attributeValidationYup(intl, stepCategory, validationSpecificData),
    }),
  );
};

const templateAttributesYup = (intl: IntlShape, stepCategory?: string) => {
  return Yup.array().of(
    Yup.object().shape({
      displayName: attributeDisplayNameYup(intl, stepCategory),
      value: Yup.mixed().when(['type'], {
        is: (type: AttributeTypesEnum) => {
          return type === AttributeTypesEnum.Integer;
        },
        then: () => {
          return Yup.number()
            .typeError(intl.formatMessage(messages.inputNumberNotInteger))
            .test({
              name: 'validateNumericValue',
              exclusive: true,
              test(value) {
                const { max, min } = this.parent.validation;

                if (value === undefined || value === null) {
                  if (this.parent.validation.mandatory) {
                    return this.createError({ message: intl.formatMessage(messages.inputIsRequired) });
                  }

                  return true;
                }

                if (max !== null && max !== undefined && value > max) {
                  return this.createError({
                    message: intl.formatMessage(messages.numberMaxMessage, {
                      max,
                    }),
                  });
                }

                if (min !== null && min !== undefined && value < min) {
                  return this.createError({
                    message: intl.formatMessage(messages.numberMinMessage, {
                      min,
                    }),
                  });
                }

                return true;
              },
            })
            .nullable();
        },
      }),
    }),
  );
};

const templateDisplayNameYup = (intl: IntlShape) => {
  return Yup.string()
    .trim(intl.formatMessage(messages.inputNoLeadingTrailingSpaces))
    .required(intl.formatMessage(messages.inputIsRequired))
    .max(
      validationConstants.templateModal.templateName.maxLength,
      intl.formatMessage(messages.inputMaxLength, {
        maxLength: validationConstants.templateModal.templateName.maxLength,
      }),
    );
};

export const validationMap = (intl: IntlShape) => ({
  overview: () =>
    Yup.object().shape({
      [entityTemplateFieldName]: Yup.object().shape({
        displayName: templateDisplayNameYup(intl),
        name: attributeNameYup(intl),
        templateAttributes: templateAttributesYup(intl),
      }),
    }),
  detailsField:
    (stepCategory: string) =>
    (validationSpecificData?: { templateInUse?: boolean; initialValues: Record<string, any> }) =>
      Yup.object().shape({
        [entityTemplateFieldName]: Yup.object().shape({
          builtInAttributes: Yup.array().of(
            Yup.object().shape({
              validation: attributeValidationYup(intl, stepCategory, validationSpecificData || {}),
              displayName: attributeDisplayNameYup(intl, stepCategory),
              name: Yup.string(),
              referenceConfiguration: referenceConfigurationYup(intl, stepCategory),
            }),
          ),
          customAttributes: customAttributesYup(intl, stepCategory, validationSpecificData || {}),
        }),
      }),
  [EntityTypeEnum.USAGE_SESSION]: () =>
    Yup.object().shape({
      [childrenTemplatesFieldName]: Yup.array()
        .compact(child => child.entityTypeName !== EntityTypeEnum.USAGE_SESSION)
        .min(
          1,
          intl.formatMessage(messages.arrayMinMessage, {
            min: '1',
          }),
        ),
    }),
  [EntityTypeEnum.COMMAND]: () =>
    Yup.object().shape({
      [childrenTemplatesFieldName]: Yup.array().compact(child => child.entityTypeName !== EntityTypeEnum.COMMAND),
    }),
  [EntityTypeEnum.DEVICE_ALERT]: () =>
    Yup.object().shape({
      [childrenTemplatesFieldName]: Yup.array().compact(child => child.entityTypeName !== EntityTypeEnum.DEVICE_ALERT),
    }),
  [EntityTypeEnum.PATIENT_ALERT]: () =>
    Yup.object().shape({
      [childrenTemplatesFieldName]: Yup.array().compact(child => child.entityTypeName !== EntityTypeEnum.PATIENT_ALERT),
    }),
});
