import { Checkbox, SingleSelect, Card } from '@biotmed/base-components';
import { Field, FieldProps, useFormikContext } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { OrganizationResponse } from '@biotmed/organization-sdk';
import { AssignOwnerContainer, CheckboxContainer } from './AssignOwnerOrganization.styled';
import { EntityTemplateForm } from '../../../Template';

interface AssignOwnerOrganizationProps {
  assignOwnerOrganization: boolean;
  handleChangeAssignOwnerOrganization: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  organizationOptions: OrganizationResponse[];
}

const AssignOwnerOrganization: React.FC<AssignOwnerOrganizationProps> = props => {
  const { assignOwnerOrganization, handleChangeAssignOwnerOrganization, organizationOptions } = props;
  const intl = useIntl();
  const formik = useFormikContext<EntityTemplateForm>();

  return (
    <Card padding="23px 25px">
      <AssignOwnerContainer>
        <CheckboxContainer>
          <Checkbox checked={assignOwnerOrganization} onChange={handleChangeAssignOwnerOrganization} />
          <div>
            {intl.formatMessage({
              id: 'template.modal.overview.assign-template.title',
              defaultMessage: 'Assign Template to organization',
            })}
          </div>
        </CheckboxContainer>
        {assignOwnerOrganization && (
          <Field
            name="entityTemplate.ownerOrganizationId"
            render={({ field }: FieldProps) => (
              <SingleSelect
                defaultValue={formik.values.entityTemplate.ownerOrganizationId}
                selectList={
                  organizationOptions
                    ? organizationOptions.map((option: any) => ({ value: option._id, title: option._name }))
                    : []
                }
                placeholder={intl.formatMessage({
                  id: 'add-edit-template-modal.overview.portal-select-placeholder',
                  defaultMessage: 'Select Owner Organization',
                })}
                {...field}
              />
            )}
          />
        )}
      </AssignOwnerContainer>
    </Card>
  );
};
export default AssignOwnerOrganization;
