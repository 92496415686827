import adbAddForceConfirmationProps from 'src/routes/ADB/components/force/props/addForceConfirmationProps';
import { ANALYTICS_DB_DATA_SYNCHRONIZATION_ON_ERROR_CODE } from 'src/routes/ADB/components/force/constants';
import { AddTemplateForceErrorCodesType } from '../types';

const addForceConfirmationTextMapper = {
  [ANALYTICS_DB_DATA_SYNCHRONIZATION_ON_ERROR_CODE]: adbAddForceConfirmationProps(),
};

const getAddForceConfirmationText = (errorCode: AddTemplateForceErrorCodesType) => {
  return addForceConfirmationTextMapper[errorCode];
};

export default getAddForceConfirmationText;
