import { combineReducers } from 'redux';

import readReducer, {
  STATE_KEY as readStateKey,
  actions as readSliceActions,
  selectors as readSliceSelectors,
  getInitialState as readSliceGetInitialState,
} from './readSlice';

import deleteReducer, {
  STATE_KEY as deleteStateKey,
  actions as deleteSliceActions,
  selectors as deleteSliceSelectors,
  getInitialState as deleteSliceGetInitialState,
} from './deleteSlice';

import actionsReducer, {
  STATE_KEY as actionsStateKey,
  actions as actionsSliceActions,
  selectors as actionSliceSelectors,
  getInitialState as actionsSliceGetInitialState,
} from './actionsSlice';
import { TRANSLATIONS_STATE_KEY } from './constants';

const STATE_KEY = TRANSLATIONS_STATE_KEY;

const reducer = combineReducers({
  [readStateKey]: readReducer,
  [actionsStateKey]: actionsReducer,
  [deleteStateKey]: deleteReducer,
});

const actions = { ...readSliceActions, ...actionsSliceActions, ...deleteSliceActions };
const selectors = { ...readSliceSelectors, ...actionSliceSelectors, ...deleteSliceSelectors };

const getInitialState = (state?: any) => ({
  [readStateKey]: readSliceGetInitialState(state[readStateKey]),
  [actionsStateKey]: actionsSliceGetInitialState(state[actionsStateKey]),
  [deleteStateKey]: deleteSliceGetInitialState(state[deleteStateKey]),
});

export { reducer, STATE_KEY, actions, selectors, getInitialState };
