import React, { useState } from 'react';
import { ActionTriggerComponentProps } from '@biotmed/data-components';
import { LocaleTranslationsOverview } from '@biotmed/settings-sdk';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ValidationModal, ValidationModalProps } from '@biotmed/base-components';
import { actions } from '../modules/slice/deleteSlice';

type DeleteTranslationsProps = {
  translationOverview: LocaleTranslationsOverview;
  TriggerComponent: React.FC<ActionTriggerComponentProps>;
  // eslint-disable-next-line react/require-default-props
  onClose?: () => void;
};

export const DeleteTranslations = (props: DeleteTranslationsProps) => {
  const { translationOverview, TriggerComponent, onClose } = props;

  const intl = useIntl();
  const dispatch = useDispatch();
  const { locale } = translationOverview || {};

  const [validationModalOpen, setValidationModalOpen] = useState<boolean>(false);

  const onDeleteConfirm = () => {
    if (locale) {
      dispatch(actions.onDeleteItem({ id: locale }));
      onClose?.();
    }
  };

  const onDeleteClick = () => setValidationModalOpen(true);

  const onCloseModal = () => {
    setValidationModalOpen(false);
    onClose?.();
  };

  const validationModalProps: ValidationModalProps = {
    open: validationModalOpen,
    onClose: onCloseModal,
    agreeButtonLabel: intl.formatMessage({
      id: 'translations.action.delete.validation-modal.agree.label',
      defaultMessage: 'Delete',
    }),
    dismissButtonLabel: intl.formatMessage({
      id: 'translations.action.delete.validation-modal.dismiss.label',
      defaultMessage: 'Cancel',
    }),
    onAgree: onDeleteConfirm,
    children: intl.formatMessage({
      id: 'translations.action.delete.validation-modal.message',
      defaultMessage: 'Are you sure you wish to delete all translations for this language?',
    }),
    onDismiss: onCloseModal,
  };

  return (
    <>
      <ValidationModal {...validationModalProps} />
      <TriggerComponent
        text={intl.formatMessage({
          id: 'translations.action.clear',
          defaultMessage: 'Clear',
        })}
        onClick={onDeleteClick}
      />
    </>
  );
};
