import React from 'react';
import { useFormikContext } from 'formik';
import { CountryInput, InputPropsType } from '@biotmed/base-components';
import { EntityTemplateForm } from 'src/routes/Templates/components/Template';
import { DefaultValueExtractedRendererProps } from '../../modules';

export type CountryExtraProps = DefaultValueExtractedRendererProps<string> & { placeholder: string };

export const Country: React.FC<CountryExtraProps> = props => {
  const { placeholder, error, value, name, helperText, InputProps } = props;
  const formik = useFormikContext<EntityTemplateForm>();

  const onChange = (countryCode: string) => {
    formik.setFieldTouched(name, true, false);
    formik.setFieldValue(name, countryCode, true);
  };

  const inputProps: InputPropsType = {
    ...InputProps,
    error,
    helperText,
  };

  return (
    <CountryInput
      value={value ?? ''}
      onChange={onChange}
      name={name}
      placeholder={placeholder}
      inputProps={inputProps}
    />
  );
};

export default Country;
