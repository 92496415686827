import styled from 'styled-components';
import { convertTypographySettingsToCss, Input } from '@biotmed/base-components';

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  ${props => convertTypographySettingsToCss(props.theme.typography.body1)};
`;
export const InputsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 20px;
`;
export const NumericTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 26px;
`;
export const RangeTitle = styled.div`
  ${props => convertTypographySettingsToCss(props.theme.typography.body1)};
`;

export const NumberNonEditInput = styled(Input)`
  ${props => convertTypographySettingsToCss(props.theme.typography.body1)};
  margin: 1px 12px;
  padding: 12px;
`;
