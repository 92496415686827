import {
  ConfirmationTooltip,
  convertTypographySettingsToCss,
  FileFieldWrapper,
  PopupModal,
} from '@biotmed/base-components';
import styled from 'styled-components';

export const StyledUploadTranslationsModal = styled(PopupModal)`
  && {
    .MuiDialog-paper {
      width: 500px;
      height: 520px;
    }
  }
`;

export const InvalidTranslationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-height: 60%;
`;

export const InvalidTranslationsTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  ${props => convertTypographySettingsToCss(props.theme.typography.h7)};
`;

export const InvalidTranslationList = styled.div`
  overflow-y: scroll;
`;

export const InvalidTranslationListItem = styled.div<{ $totalItems: number; $index: number }>`
  border: 1px solid ${props => props.theme.palette.grayScale.medium};
  ${props => props.$index !== props.$totalItems - 1 && 'border-bottom: 0px'};
  ${props => convertTypographySettingsToCss(props.theme.typography.body2)};
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
`;

export const InvalidTranslationItemWarningMessage = styled.span`
  font-weight: bold;
`;

export const InvalidTranslationItemKey = styled.span``;

export const ValidTranslationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-height: 65%;
`;

export const ValidTranslationsTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  ${props => convertTypographySettingsToCss(props.theme.typography.h7)};
`;

export const ValidTranslationsSubtitle = styled.div`
  ${props => convertTypographySettingsToCss(props.theme.typography.body2)};
`;

export const StyledConfirmationTooltip = styled(ConfirmationTooltip)`
  &&& .MuiTooltip-tooltip {
    width: fit-content;
    text-align: center;
    padding: 10px;
    box-shadow: 0 0 4px 0 rgba(166, 165, 167, 0.25);
    border-radius: 5px;
  }
`;

export const ContentTitle = styled.div`
  ${props => convertTypographySettingsToCss(props.theme.typography.h7)};
`;

export const ContentDescription = styled.div`
  ${props => convertTypographySettingsToCss(props.theme.typography.body2)};
`;

export const StyledFileFieldWrapper = styled(FileFieldWrapper)``;

export const LanguageColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;
