import { Explained, SelectAutoComplete, SingleSelect, Loader, NoData } from '@biotmed/base-components';
import { PortalType, PortalTypeEnum } from '@biotmed/data-components';
import { UpdateViewRequest, View, ViewTypeEnum } from '@biotmed/settings-sdk';
import { EmbeddedError } from '@biotmed/system-notifications';
import { SelectChangeEvent } from '@mui/material';
import { Formik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import AppConfig from 'src/config/AppConfig';
import { actions, selectors } from 'src/routes/PortalBuilder';
import { LoadViewStatus, viewsTypesOrder } from '../modules/constant';
import { adaptAllAttributesToUpdateRequest, mapViewLabel } from '../modules/mappers';
import { PortalBuilderHeader, PortalBuilderTitle, SelectorsRow, StyledPortalBuilderPage } from './PortalBuilder.styled';
import { schemaValidatorObject } from './ViewBuilders/TabsBuilder/validations';
import FormikContent from './FormikContent';

interface PortalBuilderProps {}

const ENTITY_RELATED_VIEW_TYPES: ViewTypeEnum[] = [
  ViewTypeEnum.EntityList,
  ViewTypeEnum.TemplateExpand,
  ViewTypeEnum.TemplateList,
  ViewTypeEnum.TemplatePreview,
  ViewTypeEnum.OperationalEntityList,
  ViewTypeEnum.OperationalTemplatePreview,
];

export const CONTAINER_ID = 'portal-builder-container-id';

const PortalBuilder: React.FC<PortalBuilderProps> = props => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [portal, setPortal] = useState<PortalType>(PortalTypeEnum.MANUFACTURER_PORTAL);
  const [view, setView] = useState<View | null>(null);

  const selectedView = useSelector(selectors.getSelectedView);
  const viewList: View[] = useSelector(selectors.getViewsList);
  const loadViewStatus: LoadViewStatus = useSelector(selectors.getLoadBuilderStatus);

  const onLoadViews = (portalValue: PortalType) => dispatch(actions.onLoadViews({ portalType: portalValue }));

  const onLoadSelectedView = (portalValue: PortalType, viewId?: string) => {
    if (viewId) {
      dispatch(actions.onLoadSelectedView({ portalType: portalValue, viewId }));
    }
  };

  const onUpdateView = (portalValue: PortalType, viewId: string, selected: UpdateViewRequest) => {
    if (viewId) {
      dispatch(actions.onUpdateView({ portalType: portalValue, viewId, attributes: selected }));
    }
  };

  const resetSelectedView = () => dispatch(actions.resetSelectedView());

  useEffect(() => {
    onLoadViews(portal);
    return () => {
      resetSelectedView();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const portalOptions = [
    {
      value: PortalTypeEnum.MANUFACTURER_PORTAL,
      title: intl.formatMessage({
        id: 'portalBuilder.manufacturerPortalOption',
        defaultMessage: 'Manufacturer Portal',
      }),
    },
    {
      value: PortalTypeEnum.ORGANIZATION_PORTAL,
      title: intl.formatMessage({
        id: 'portalBuilder.organizationPortalOption',
        defaultMessage: 'Organization Portal',
      }),
    },
  ];

  const onPortalChange = (event: SelectChangeEvent<unknown>) => {
    const { value } = event.target;
    setPortal(value as PortalType);
    onLoadViews(value as PortalType);
    setView(null);
    resetSelectedView();
  };

  const updateSelectedView = (value: View | null) => {
    setView(value);
    if (value?.id) {
      onLoadSelectedView(portal, value?.id);
    } else {
      resetSelectedView();
    }
  };

  const viewsOptions = useMemo(() => {
    const notEntityRelatedViews = viewList.slice().filter(item => !ENTITY_RELATED_VIEW_TYPES.includes(item.type));
    const entityRelatedViews = viewList
      .slice()
      .filter(item => ENTITY_RELATED_VIEW_TYPES.includes(item.type))
      .sort(
        (a, b) =>
          a?.entityTypeName?.localeCompare(b?.entityTypeName ?? '') ||
          (a?.template?.displayName ?? '').localeCompare(b?.template?.displayName ?? '') ||
          viewsTypesOrder[a?.type] - viewsTypesOrder[b?.type],
      );

    return [...notEntityRelatedViews, ...entityRelatedViews];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewList]);

  const onLearnMoreClick = () => {
    window.open(AppConfig.PORTAL_BUILDER_LEARN_MORE_URL);
  };

  const goToNextView = () => {
    const currentViewIndex = viewsOptions.findIndex(val => val.id === view?.id);
    const nextViewIndex = (currentViewIndex + 1) % viewsOptions.length;

    // Move to the next view
    const nextView = viewsOptions[nextViewIndex];
    updateSelectedView(nextView);
  };

  return (
    <StyledPortalBuilderPage>
      <PortalBuilderHeader>
        <PortalBuilderTitle>
          {intl.formatMessage({ id: 'portalBuilder.mainTitle', defaultMessage: 'Portal Builder' })}
        </PortalBuilderTitle>
        <EmbeddedError containerId={CONTAINER_ID} />
        <Explained
          text={intl.formatMessage({
            id: 'portalBuilder.explained',
            defaultMessage: "Portal Builder enable you to customize BioT's portals to your needs",
          })}
          onLearnMore={onLearnMoreClick}
        />
        <SelectorsRow>
          <SingleSelect
            value={portal}
            selectList={portalOptions}
            placeholder={intl.formatMessage({
              id: 'portalBuilder.portalSelectPlaceholder',
              defaultMessage: 'Select Portal',
            })}
            onChange={onPortalChange}
          />
          <SelectAutoComplete
            getOptionLabel={option => {
              const labelMapper = mapViewLabel(option, intl);
              return labelMapper[option.type] || labelMapper.default;
            }}
            value={view}
            options={viewsOptions}
            inputProps={{
              placeholder: intl.formatMessage({
                id: 'portalBuilder.viewSelectPlaceholder',
                defaultMessage: 'Select View/Component',
              }),
            }}
            onChange={(event, value) => {
              updateSelectedView(value);
            }}
            loading={viewsOptions.length === 0}
          />
        </SelectorsRow>
      </PortalBuilderHeader>
      {loadViewStatus === LoadViewStatus.LOADING ? (
        <Loader />
      ) : loadViewStatus === LoadViewStatus.SUCCESS ? (
        <Formik<UpdateViewRequest>
          initialValues={adaptAllAttributesToUpdateRequest({ ...selectedView })}
          onSubmit={values => {
            if (view) {
              onUpdateView(portal, view.id, values);
            }
          }}
          validationSchema={schemaValidatorObject}
          enableReinitialize
        >
          {() => <FormikContent loadViewStatus={loadViewStatus} goToNextView={goToNextView} />}
        </Formik>
      ) : selectedView ? (
        <NoData />
      ) : null}
    </StyledPortalBuilderPage>
  );
};

export default PortalBuilder;
