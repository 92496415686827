import { createReadItemsSlice } from '@biotmed/data-components';
import { LocaleTranslationsOverview, SearchRequestV2 } from '@biotmed/settings-sdk';
import { OPERATION_ID_SUFFIX } from '../../constants';
import { extractId } from '../../utils';
import { TRANSLATIONS_STATE_KEY } from './constants';

const STATE_KEY = 'translations-read';

const { reducer, actions, selectors, getInitialState } = createReadItemsSlice<
  LocaleTranslationsOverview,
  SearchRequestV2
>({
  extractId,
  operationIdSuffix: OPERATION_ID_SUFFIX,
  stateKey: STATE_KEY,
  getRootState: state => state[TRANSLATIONS_STATE_KEY],
});

export { STATE_KEY, actions, selectors, getInitialState };

export default reducer;
