import styled from 'styled-components';
import { convertTypographySettingsToCss, Button } from '@biotmed/base-components';
import { PageLayout, PageMainTitle } from 'src/components/CommonStyledComponents';
import { contentPadding } from 'src/components/AppLayout';
import { Hr } from 'src/components/Hr/Hr.styled';

export const Layout = styled(PageLayout)`
  padding-top: ${contentPadding};
`;

export const PageTitle = styled(PageMainTitle)``;

export const Content = styled.div`
  ${props => convertTypographySettingsToCss(props.theme.typography.body1)};
  display: inline-flex;
  row-gap: 5px;
  flex-direction: column;
  line-height: 2;
`;

export const PopperComponent = styled(Content)`
  padding: 10px;
  min-width: 200px;
`;

export const Box = styled(Content)<{ $capitalize?: boolean }>`
  white-space: pre-line;
  padding-top: 0;
  padding-right: 15px;
  overflow: auto;
  max-height: 320px;
  text-transform: ${props => (props.$capitalize ? 'capitalize;' : 'unset')};
`;

export const PopperWrapper = styled(Content)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SubTitle = styled.div`
  ${props => convertTypographySettingsToCss(props.theme.typography.h7)};
`;

export const CloseButton = styled(Button)`
  align-self: self-end;
  && {
    padding: 0;
    justify-content: right;
  }
`;

export const PopperHeader = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  padding-bottom: 0;
`;

export const HR = styled(Hr)`
  && {
    margin: 0;
  }
`;
