import intl from '@biotmed/i18n';
import { getSdkApi } from '@biotmed/sdk-api-provider';
import { ErrorResponse, PluginResponse } from '@biotmed/settings-sdk';
import { errorNotice, ErrorTypeEnum, successNotice } from '@biotmed/system-notifications';
import { AxiosResponse } from 'axios';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { defineMessages } from 'react-intl';
import { pluginListErrorDictionary, updatePluginErrorDictionary } from './errorDictionaries';
import { actions, selectors } from './slice';
import { translateEnabledState } from './mappers';

const messages = defineMessages({
  successMessage: {
    id: 'update.plugin.enabled.state.success',
    defaultMessage: 'Plugin {displayName} is now {state}',
  },
});

function* onLoadPlugin(action: ReturnType<typeof actions.onLoadPlugin>): any {
  yield call(loadPlugins);
}

function* loadPlugins(): any {
  try {
    const searchRequest = yield select(selectors.selectSearchRequest);
    const response: AxiosResponse<any> = yield call(getSdkApi().settings.pluginApi.search2, searchRequest);
    const pluginsList = response?.data?.data;
    const pluginsTotal = response?.data?.metadata?.page?.totalResults;
    yield put(actions.onLoadPluginsSuccess({ pluginsList, pluginsTotal }));
  } catch (e: any) {
    console.error(e);
    yield put(actions.onLoadPluginsFail());
    const apiError = (e?.response?.data || e) as ErrorResponse;
    yield put(
      errorNotice({
        type: ErrorTypeEnum.GENERAL,
        errorParams: {
          error: apiError,
          dictionary: pluginListErrorDictionary,
        },
      }),
    );
  }
}

function* handleEnableStateUpdate(action: ReturnType<typeof actions.onEnableStateUpdate>) {
  const { name, enabledState } = action.payload;
  try {
    const response: AxiosResponse<PluginResponse> = yield call(
      getSdkApi().settings.pluginV2Api.partialUpdate,
      name,
      undefined,
      {
        enabledState,
      },
    );

    const plugin = response?.data;

    yield put(actions.onLoadPlugin());
    yield put(
      successNotice({
        message: intl.current.formatMessage(messages.successMessage, {
          displayName: plugin.displayName,
          state: intl.current.formatMessage(translateEnabledState(plugin.enabledState)).toLowerCase(),
        }),
      }),
    );
  } catch (error: any) {
    yield put(
      errorNotice({
        type: ErrorTypeEnum.GENERAL,
        errorParams: { error, dictionary: updatePluginErrorDictionary },
      }),
    );
  }
}

export default function* watchEntityActions() {
  yield all([
    takeLatest(actions.onLoadPlugin, onLoadPlugin),
    takeLatest(actions.onEnableStateUpdate, handleEnableStateUpdate),
  ]);
}
