import React, { useCallback, useEffect, useRef } from 'react';
import { useTheme } from 'styled-components';
import { ReactComponent as JsonIcon } from '../../../images/json-button.svg';
import { StyledJSONButton, StyledJSONIcon } from '../InputWithTooltip.styled';
import Tooltip from './Tooltip';
import { TooltipContentProps } from './TooltipContent';

const JSONButtonIcon: React.FC<{ color?: string }> = props => {
  const { color } = props;

  return (
    <StyledJSONIcon>
      <JsonIcon color={color} />
    </StyledJSONIcon>
  );
};

export type TooltipButtonProps<WithCharactersCounter extends boolean | undefined = undefined> =
  TooltipContentProps<WithCharactersCounter> & {
    open: boolean;
    onOpenChange: (value: boolean) => void;
    disabled?: boolean;
  };
const TooltipButton = <WithCharactersCounter extends boolean | undefined = undefined>(
  props: TooltipButtonProps<WithCharactersCounter>,
) => {
  const { open, onOpenChange, inputProps = {}, disabled = false, ...restProps } = props;
  const theme = useTheme();

  const { error = false, onChange, ...restInputProps } = inputProps;
  // JSON Name popper control
  const JSONButtonRef = useRef(null);
  useEffect(() => {
    if (error) {
      onOpenChange(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const handleJSONButtonMouseDown = (event: React.MouseEvent<HTMLElement>) => {
    // While the tooltip's chldren are always mounted, if we preventDefault, the ref of the popper is changing.
    // That's why this line is commented. if you detect any problem - note that the above should be checked.
    // if (!open) event.preventDefault();
    toggleJSONNamePopper();
  };

  const toggleJSONNamePopper = () => {
    if (!error || !open) {
      onOpenChange(!open);
    }
  };

  const handleClickAway = (value: string) => {
    if (open && (!error || value)) {
      onChange?.({ target: { value } } as React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>);
      onOpenChange(false);
    }
  };

  return (
    <Tooltip
      tooltipPopperProps={{
        open,
        onTogglePopper: toggleJSONNamePopper,
        anchorEl: JSONButtonRef.current,
        handleClickAway,
      }}
      inputProps={inputProps}
      {...restProps}
    >
      <StyledJSONButton
        clickable
        onMouseDown={handleJSONButtonMouseDown}
        classes={{ clickable: 'chipControl' }}
        ref={JSONButtonRef}
        icon={
          // eslint-disable-line
          <JSONButtonIcon color={disabled ? theme.palette.grayScale.darker : theme.palette.primary.medium} />
        } // eslint-disable-line
        $disabled={disabled}
      />
    </Tooltip>
  );
};

export default TooltipButton;
