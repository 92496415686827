import { GetTemplateResponse, TemplateOverviewResponse } from '@biotmed/settings-sdk';
import React from 'react';
import { useSelector } from 'react-redux';
import { Entity, Template as TemplateType } from 'src/redux/data/entity/modules/interfaces';
import { selectors as templateSelectors } from 'src/redux/data/template';
import AddTemplate from './AddTemplate';
import EditTemplate from './EditTemplate';

export interface EntityTemplateForm {
  entityTemplate: TemplateType;
  childrenTemplates: TemplateType[];
}

// eslint-disable-next-line no-shadow
export enum TemplateMode {
  ADD = 'ADD',
  EDIT = 'EDIT',
}

export type Mode = `${TemplateMode}`;

const modeMap = {
  [TemplateMode.ADD]: AddTemplate,
  [TemplateMode.EDIT]: EditTemplate,
};

export interface TemplateProps<T extends Mode> {
  open: boolean;
  entity?: Entity;
  mode: Mode;
  template?: GetTemplateResponse;
  handleClose: () => void;
  widthDiff?: string;
  onFormSubmit: (
    entityType: string,
    templateId: string,
    values: EntityTemplateForm,
    originalChildrenTemplates: any,
    forceUpdate: boolean,
  ) => void;
  containerId: string;
  currentTemplateOverview?: T extends 'EDIT' ? TemplateOverviewResponse : never;
}

const Template = <T extends Mode>(props: React.PropsWithoutRef<TemplateProps<T>>) => {
  const { open, entity, mode, template, handleClose, onFormSubmit, widthDiff, containerId, currentTemplateOverview } =
    props;
  const ModalChildren = modeMap[mode];

  const submitButtonStatus = useSelector(templateSelectors.getAddEditStatus);

  return (
    <ModalChildren
      modalWidthDiff={widthDiff}
      isOpen={open}
      entity={entity}
      template={template}
      handleClose={handleClose}
      submitButtonStatus={submitButtonStatus}
      submitForm={onFormSubmit}
      containerId={containerId}
      currentTemplateOverview={currentTemplateOverview}
    />
  );
};

export default Template;
