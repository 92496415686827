import styled from 'styled-components';
import { convertTypographySettingsToCss, Button } from '@biotmed/base-components';
import { PageLayout, PageMainTitle } from 'src/components/CommonStyledComponents';
import { contentPadding } from 'src/components/AppLayout';
import { Link } from 'react-router-dom';

export const Layout = styled(PageLayout)`
  padding-top: ${contentPadding};
`;

export const PageTitle = styled(PageMainTitle)``;

export const Content = styled.div`
  ${props => convertTypographySettingsToCss(props.theme.typography.body1)};
  display: flex;
  flex-direction: column;
`;

export const LoaderWrapper = styled.div`
  height: fill-content;
  width: fill-content;
  display: flex;
`;

export const SubTitle = styled.div`
  ${props => convertTypographySettingsToCss(props.theme.typography.h7)};
`;

export const DataContainer = styled.div`
  ${props => convertTypographySettingsToCss(props.theme.typography.body2)};
`;

export const StatusWrapper = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  height: fill-content;
  width: fill-content;
`;

export const SyncControlWrapper = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
`;

export const StatusContent = styled(DataContainer)`
  align-items: center;
  display: flex;
  gap: 5px;
`;

export const ContactUsContainer = styled(DataContainer)`
  display: flex;
  align-items: end;
  gap: 5px;
  color: ${props => props.theme.palette.grayScale.darker2};
`;

export const ContactUsLink = styled(Link)`
  color: ${props => props.theme.palette.secondary.light};
`;

export const SyncButton = styled(Button)`
  &&& {
    color: ${props => props.theme.palette.grayScale.lightest};
    border-radius: 3px;
    text-align: center;
    min-width: 158px;
    min-height: 35px;
    width: fit-content;
  }
`;

export const StartSyncButton = styled(SyncButton)`
  &&& {
    background-color: ${props => props.theme.palette.secondary.light};

    &:hover:not(:disabled) {
      background-color: ${props => props.theme.palette.secondary.medium};
    }
  }
`;

export const StopSyncButton = styled(SyncButton)`
  &&& {
    background-color: ${props => props.theme.palette.error.light};

    &:hover:not(:disabled) {
      background-color: ${props => props.theme.palette.error.medium};
    }

    &:disabled {
      background-color: ${props => props.theme.palette.error.lighter};
    }
  }
`;

export const ModalTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Text = styled.div`
  ${props => convertTypographySettingsToCss(props.theme.typography.body2)};
`;

export const BoldText = styled.div`
  ${props => convertTypographySettingsToCss(props.theme.typography.h7)};
`;
