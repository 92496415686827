import styled from 'styled-components';

export const WaveformContainer = styled.div`
  display: flex;
  gap: 26px;
  flex-direction: column;
`;

export const SubTypesContainer = styled.div`
  & .MuiFormGroup-root {
    display: flex;
    flex-direction: row;
    gap: 26px;
  }
`;

export const RangeContainer = styled.div`
  padding: 18px 6px 0 18px;
`;
