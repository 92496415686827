/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useFormikContext } from 'formik';
import { EntityTemplateForm } from 'src/routes/Templates/components/Template';
import { useSelector } from 'react-redux';
import { AutocompleteOption } from '@biotmed/base-components';
import { DefaultValueExtractedRendererProps } from '../../modules';
import { SelectAutoCompleteInput } from './styled/Renderers.styled';

export type SelectAutoCompleteWithSelector<T> = {
  selector: (state: any) => T[];
  selectableOptions?: never;
};

export type SelectAutoCompleteWithOptions<T> = {
  selectableOptions: T[];
  selector?: never;
};

export type SelectAutoCompleteRendererExtraProps<T> = {
  placeholder: string;
  dataMapper: (data: T) => AutocompleteOption;
} & (SelectAutoCompleteWithSelector<T> | SelectAutoCompleteWithOptions<T>);

export const SelectAutoCompleteRenderer = <T,>(
  props: DefaultValueExtractedRendererProps<string> & SelectAutoCompleteRendererExtraProps<T>,
) => {
  const { error, helperText, InputProps, name, selectableOptions, selector, dataMapper, value, placeholder } = props;
  const { endAdornment, disabled, ...inputProps } = InputProps;

  const formik = useFormikContext<EntityTemplateForm>();

  const storeItems = useSelector<unknown, T[]>(selector || (n => []));
  const options = selectableOptions ? selectableOptions?.map(dataMapper) : storeItems?.map(dataMapper);

  const handleBlur = () => {
    formik.setFieldTouched(name, true);
  };

  const handleChange = (newValue?: AutocompleteOption | null) => {
    if (newValue) {
      formik.setFieldValue(name, newValue.value);
    } else {
      formik.setFieldValue(name, null);
    }
  };

  return (
    <SelectAutoCompleteInput
      value={value || null}
      options={options || ([] as AutocompleteOption[])}
      isOptionEqualToValue={(option, selectedValue) =>
        (option as AutocompleteOption)?.value === (selectedValue as string)
      }
      onChange={(event, newValue) => {
        handleChange(newValue as AutocompleteOption | null);
      }}
      onBlur={handleBlur}
      disabled={disabled}
      inputProps={{
        error,
        helperText,
        placeholder,
        ...inputProps,
      }}
    />
  );
};
