/* eslint-disable react/require-default-props */
import React from 'react';
import { PasswordTextField } from '@biotmed/auth-pages';
import { Label, RequiredField, StyledPasswordField } from '../EnvironmentConfiguration.styled';

interface PasswordFieldProps {
  label: string;
  value: string;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent<any>) => void;
  handleFocus?: (e: React.FocusEvent<any>) => void;
  name: string;
  required?: boolean;
  error?: string;
  touched?: boolean;
}

export const PasswordField = (props: PasswordFieldProps) => {
  const { label, required, value, error, touched, handleChange, handleBlur, handleFocus, name } = props;
  return (
    <StyledPasswordField>
      <Label>
        {label}
        {required && <RequiredField>*</RequiredField>}
      </Label>
      <PasswordTextField
        value={value}
        onChange={handleChange}
        error={Boolean(error) && touched}
        helperText={touched ? error : ''}
        required
        variant="outlined"
        name={name}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
    </StyledPasswordField>
  );
};

export default PasswordField;
