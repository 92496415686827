import { all, call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { getSdkApi } from '@biotmed/sdk-api-provider';
import { SearchResponseEntityType } from '@biotmed/settings-sdk';
import { actions } from './slice';
import { actions as loginActions } from '../../login';

function* onLoadMainEntityTypes(): any {
  try {
    const response: AxiosResponse<SearchResponseEntityType> = yield call(
      getSdkApi().settings.entityApi.searchEntityTypes,
    );
    const entitiesList = response.data.data || [];
    yield put(actions.loadEntityTypeSucceeded(entitiesList));
  } catch (e) {
    console.error(e);
  }
}

export default function* watchEntityActions() {
  yield all([takeLatest(loginActions.loginSuccess, onLoadMainEntityTypes)]);
}
