import React from 'react';
import EditInputAdornment from 'src/components/EditAdormentInput';
import { InputAdornment } from '@mui/material';
import { TemplateAttributeProps } from './TemplateAttribute';
import { AttributeTypeContainer, NumericInput } from './TemplateAttribute.styled';

interface NumericTypeProps extends TemplateAttributeProps {}

const NumericType = (props: NumericTypeProps) => {
  const { attributeTouched, attributeErrors, attributeValues, handleChangeValue } = props;

  return (
    <AttributeTypeContainer>
      <NumericInput
        type="number"
        onChange={handleChangeValue}
        value={attributeValues?.value}
        error={attributeTouched?.value && Boolean(attributeErrors?.value)}
        helperText={attributeTouched?.value ? attributeErrors?.value : ''}
        variant="standard"
        InputProps={{
          startAdornment: <EditInputAdornment position="end" />,
          inputProps: {
            min: attributeValues.validation?.min,
            max: attributeValues.validation?.max,
          },
          endAdornment: (
            <InputAdornment position="end" disablePointerEvents={false}>
              {attributeValues.numericMetaData?.units}
            </InputAdornment>
          ),
        }}
        integer
      />
    </AttributeTypeContainer>
  );
};

export default NumericType;
