import React from 'react';
import { InputFieldWrapper, InputFieldWrapperProps } from '@biotmed/base-components';

import { editFieldInputProps, editFieldVariant } from './constants';

const EditInputFieldWrapper = (props: InputFieldWrapperProps) => {
  const { InputProps, ...restProps } = props;
  return (
    <InputFieldWrapper
      {...restProps}
      {...editFieldVariant}
      InputProps={{
        ...InputProps,
        ...editFieldInputProps,
      }}
    />
  );
};

export default EditInputFieldWrapper;
