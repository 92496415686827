import { ModalContent, convertTypographySettingsToCss } from '@biotmed/base-components';
import styled from 'styled-components';

export const Steps = styled.div``;

export const Title = styled.div`
  ${props => convertTypographySettingsToCss(props.theme.typography.h5)};
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: ${props => props.theme.palette.grayScale.lightest};
`;

export const ModalHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.palette.grayScale.lightest};
  gap: 8px;
`;

export const ModalContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
  padding: 16px 32px;
  max-height: 100%;
`;

export const StyledModalContent = styled(ModalContent)`
  background-color: ${props => props.theme.palette.primary.lighter};
  && {
    padding: 0px;
  }
`;

export const ModalFooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
  background: ${props => props.theme.palette.grayScale.lightest};
`;
