import React from 'react';
import { SectionTitle, SectionWrapper } from '../PortalBuilder.styled';

interface PortalBuilderSectionProps {
  title: string;
  children: React.ReactNode;
}

const PortalBuilderSection: React.FC<React.PropsWithChildren<PortalBuilderSectionProps>> = props => {
  const { title, children } = props;

  return (
    <SectionWrapper>
      <SectionTitle>{title}</SectionTitle>
      {children}
    </SectionWrapper>
  );
};

export default PortalBuilderSection;
