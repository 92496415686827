import React, { useEffect, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import { AttributeTypeEnum } from '@biotmed/data-components';
import { TypesComponentsMap } from 'src/components/AttributeLayout';
import { CommonAttributeResponse } from '@biotmed/settings-sdk/service/model';
import { shouldUseDefaultValue } from '../../../../components/AttributeLayout/defaultValueComponents/modules';
import { getDefaultValueRenderComponent } from '../../../../components/AttributeLayout/defaultValueComponents/modules/defaultValueMaps';
import BuiltInAttribute, { BuiltInAttributeProps } from './BuiltInAttribute';
import CustomAttribute, { CustomAttributeProps } from './CustomAttribute';
import { EntityTemplateForm } from '../Template';
import { exists } from '../../../../utils/generalUtils';

export interface AttributeBaseProps {
  attrFieldName: string;
  type?: AttributeTypeEnum;
  phi?: boolean;
  required?: boolean;
  setRequired: (checked: boolean) => void;
  setDisplayName: (name: string) => void;
  attributeTouched?: any;
  attributeValues?: any;
  attributeInitialValues: any;
  attributeErrors?: any;
  isNewCustom?: boolean;
  selectedTargetAttribute: CommonAttributeResponse;
  onSelectedTargetAttributeChange: (targetAttribute: CommonAttributeResponse) => void;
  supportMandatoryValidation: boolean;
}

type AttributeTypeSpecificOmittedValues =
  | 'setRequired'
  | 'setDisplayName'
  | 'setPHI'
  | 'setUniquely'
  | 'onSelectedTargetAttributeChange'
  | 'selectedTargetAttribute'
  | 'requiredReadOnly';

export type AttributeProps =
  | Omit<BuiltInAttributeProps, AttributeTypeSpecificOmittedValues>
  | Omit<CustomAttributeProps, AttributeTypeSpecificOmittedValues | 'setName' | 'setType'>;

type FormikFieldProps = {
  attrFieldName?: string;
  attributeValues?: Record<string, any>;
  attributeTouched?: Record<string, any>;
  attributeErrors?: Record<string, any>;
  attributeInitialValues?: Record<string, any>;
};

export type TypeComponentProps = FormikFieldProps & {
  variant?: AttributeProps['variant'];
};

export type DefaultValueComponentProps = FormikFieldProps & {};

export const Attribute: React.FC<AttributeProps> = props => {
  const {
    variant,
    attrFieldName,
    type,
    attributeTouched,
    attributeValues,
    attributeErrors,
    attributeInitialValues,
    isNewCustom,
    supportMandatoryValidation,
    ...restProps
  } = props;

  const formik = useFormikContext<EntityTemplateForm>();
  const [selectedTargetAttribute, setSelectedTargetAttribute] = useState<CommonAttributeResponse>();

  const [attributeTypeSelected, setAttributeTypeSelected] = useState<string>(type || '');

  useEffect(() => {
    if (selectedTargetAttribute && isNewCustom) {
      handleChangeRequired(selectedTargetAttribute.validation?.mandatory || false);
      handleChangePHI(selectedTargetAttribute.phi || false);
      if (selectedTargetAttribute.type?.valueOf() === AttributeTypeEnum.Reference) {
        handleChangeUniquely(selectedTargetAttribute.referenceConfiguration?.uniquely || false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTargetAttribute]);

  const variantComponent: Record<typeof variant, React.FC<any>> = {
    builtIn: BuiltInAttribute,
    custom: CustomAttribute,
  };

  const Comp = variantComponent[variant];

  // const formikValue = formik.values[attrName][index];

  const handleChangeRequired = (checked: boolean) => {
    if (!checked) {
      formik.setFieldValue(`${attrFieldName}.validation.defaultValue`, null);
    } else if (checked && attributeInitialValues?.validation.defaultValue) {
      formik.setFieldValue(`${attrFieldName}.validation.defaultValue`, attributeInitialValues?.validation.defaultValue);
      formik.setFieldTouched(`${attrFieldName}.validation.defaultValue`);
    }
    formik.setFieldValue(`${attrFieldName}.validation.mandatory`, checked);
  };

  // Sets Display Name
  const handleChangeDisplayName = (name: string) => {
    // TODO: CHECK THE SHOULD VALIDATE FALSE
    formik.setFieldValue(`${attrFieldName}.displayName`, name, false);
  };
  // Sets JSON Name
  const handleChangeName = (name: string) => {
    // TODO: CHECK THE SHOULD VALIDATE FALSE
    formik.setFieldValue(`${attrFieldName}.name`, name, false);
  };

  // Sets is PHI
  const handleChangePHI = (value: boolean) => {
    formik.setFieldValue(`${attrFieldName}.phi`, value);
  };

  // Sets is Uniquely
  const handleChangeUniquely = (checked: boolean) => {
    formik.setFieldValue(`${attrFieldName}.referenceConfiguration.uniquely`, checked);
  };

  // TODO: Add enum type when ready :
  const handleChangeType = (typeValue: string) => {
    formik.setFieldValue(`${attrFieldName}.type`, typeValue);
    setAttributeTypeSelected(typeValue);
    // reset validation and selectable values
    formik.setFieldValue(`${attrFieldName}.validation`, {
      mandatory: attributeValues?.validation?.mandatory || false,
      defaultValue: null,
    });
    formik.setFieldTouched(`${attrFieldName}.validation.defaultValue`, false);
    formik.setFieldValue(`${attrFieldName}.selectableValues`, []);
    formik.setFieldValue(`${attrFieldName}.numericValuesUnits`, null);
    formik.setFieldValue(`${attrFieldName}.referenceConfiguration`, null);
    formik.setFieldValue(`${attrFieldName}.numericMetaData`, null);
    formik.setFieldValue(`${attrFieldName}.linkConfiguration`, null);
  };

  const formikFieldProps = {
    attrFieldName,
    attributeValues,
    attributeTouched,
    attributeErrors,
    attributeInitialValues,
  };

  const typeComponentProps: TypeComponentProps = {
    ...formikFieldProps,
    variant,
  };

  const defaultValueComponentProps: DefaultValueComponentProps = formikFieldProps;

  const handleSelectedTargetAttributeChange = (value: CommonAttributeResponse) => {
    setSelectedTargetAttribute(value);
  };

  const TypeComponent = useMemo(
    () => TypesComponentsMap[attributeTypeSelected as keyof typeof TypesComponentsMap] ?? null,
    [attributeTypeSelected],
  );

  const renderDefaultValue = useMemo(() => {
    return shouldUseDefaultValue({
      initiallyMandatory: attributeInitialValues?.validation.mandatory,
      attributeMandatory: attributeValues?.validation?.mandatory,
      initiallyWithDefaultValue: exists(attributeInitialValues?.validation.defaultValue),
      templateInUse: formik.status?.templateInUse,
    })
      ? getDefaultValueRenderComponent(attributeTypeSelected as keyof typeof TypesComponentsMap)
      : null;
  }, [
    attributeTypeSelected,
    formik.status?.templateInUse,
    attributeValues?.validation?.mandatory,
    attributeInitialValues?.validation.mandatory,
    attributeInitialValues?.validation.defaultValue,
  ]);

  const requiredReadOnly = useMemo(
    () =>
      (supportMandatoryValidation === false && !attributeInitialValues) ||
      (supportMandatoryValidation === false && attributeInitialValues?.validation?.mandatory === false) ||
      attributeValues.validationMetadata?.mandatoryReadOnly,
    [supportMandatoryValidation, attributeValues.validationMetadata?.mandatoryReadOnly, attributeInitialValues],
  );

  return (
    <Comp
      attributeValues={attributeValues}
      required={attributeValues?.validation?.mandatory}
      setRequired={handleChangeRequired}
      setDisplayName={handleChangeDisplayName}
      setName={handleChangeName}
      setType={handleChangeType}
      setPHI={handleChangePHI}
      setUniquely={handleChangeUniquely}
      attrFieldName={attrFieldName}
      attributeErrors={attributeErrors}
      attributeTouched={attributeTouched}
      isNew={isNewCustom}
      {...restProps}
      type={type}
      onSelectedTargetAttributeChange={handleSelectedTargetAttributeChange}
      selectedTargetAttribute={selectedTargetAttribute}
      requiredReadOnly={requiredReadOnly}
    >
      {TypeComponent && <TypeComponent {...typeComponentProps} targetAttribute={selectedTargetAttribute} />}
      {renderDefaultValue && renderDefaultValue(defaultValueComponentProps)}
    </Comp>
  );
};
export default Attribute;
