import React, { ReactNode } from 'react';
import { SelectableValue } from '@biotmed/settings-sdk';
import { InputAdornment } from '@mui/material';
import { DefaultValueComponentProps } from 'src/routes/Templates/components/Attribute';
import { SubField } from '../Components/InputsSpreader';
import { SubRendererProps } from '../Components/InputsSpreader/types';
import { exists, returnExists } from '../../../../utils/generalUtils';

export type ExtraPropsFormFieldValues = {
  InputProps: {
    disabled?: boolean;
    variant?: 'outlined' | 'standard' | 'filled';
    padding?: string;
    endAdornment?: ReactNode;
  };
  selectsProps: {
    selectableValues: [];
    selectList: { value: string; title: string }[];
    shouldFilterSelectList: boolean;
  };
};

type DefaultValueSubfield<V> = SubField<ExtraPropsFormFieldValues, unknown, V>;

export type DefaultValueExtractedRendererProps<V> = SubRendererProps<V> & ExtraPropsFormFieldValues;

export const extractValuesFromFields = <FV extends Record<string, unknown>, V>(
  fieldValues: FV,
  subField: DefaultValueSubfield<V>,
): Omit<SubRendererProps<V>, 'key' | 'name'> => {
  const { attrFieldName, attributeValues, attributeErrors, attributeTouched } = fieldValues;
  const extract = (attributeItems: any) => {
    if (attrFieldName && subField.subFieldName)
      return returnExists(attributeItems?.validation?.defaultValue?.[subField.subFieldName]);
    return attrFieldName ? returnExists(attributeItems?.validation?.defaultValue) : undefined;
  };
  const extractTouched = (attributeItems: any) => {
    return attrFieldName ? attributeItems?.validation?.defaultValue : undefined;
  };
  return {
    value: attributeValues && extract(attributeValues),
    error: (attributeErrors && extract(attributeErrors)) || undefined,
    touched: (attributeTouched && extractTouched(attributeTouched)) || false,
    helperText: (attributeErrors && extract(attributeErrors)) || undefined,
  };
};

export const extractNameFormField = <V extends unknown>(
  { attrFieldName }: DefaultValueComponentProps,
  subField: DefaultValueSubfield<V>,
) => `${attrFieldName || ''}.validation.defaultValue${subField?.subFieldName ? `.${subField.subFieldName}` : ''}`;

export const extractExtraPropsFormField = (fieldValues: DefaultValueComponentProps): ExtraPropsFormFieldValues => ({
  InputProps: {
    disabled: exists(fieldValues.attributeInitialValues?.validation.defaultValue),
    variant: 'standard',
    padding: '2px',
    endAdornment: fieldValues.attributeValues?.numericMetaData?.units && (
      <InputAdornment position="end">{fieldValues.attributeValues?.numericMetaData?.units}</InputAdornment>
    ),
  },
  selectsProps: {
    selectableValues:
      (exists(fieldValues.attributeInitialValues?.validation.defaultValue)
        ? fieldValues.attributeInitialValues?.selectableValues
        : fieldValues.attributeValues?.selectableValues) || [],
    selectList:
      (exists(fieldValues.attributeInitialValues?.validation.defaultValue)
        ? fieldValues.attributeInitialValues?.selectableValues
        : fieldValues.attributeValues?.selectableValues
      )?.map((x: SelectableValue) => ({
        value: x.name,
        title: x.displayName,
      })) || [],
    shouldFilterSelectList: !exists(fieldValues.attributeInitialValues?.validation.defaultValue),
  },
});

export const extractFunction =
  <FV extends Record<string, unknown>, V>(
    fieldValues: FV,
  ): ((subField: DefaultValueSubfield<V>) => DefaultValueExtractedRendererProps<V>) =>
  subField => {
    const name = extractNameFormField(fieldValues, subField);

    const { error, touched, helperText, value } = extractValuesFromFields(fieldValues, subField);

    const extraPropsFormField = extractExtraPropsFormField(fieldValues);

    return {
      key: subField.subFieldName || name,
      name,
      value,
      error: touched ? !!error : false,
      helperText: touched ? helperText : undefined,
      ...extraPropsFormField,
    };
  };
