import styled from 'styled-components';
import { convertTypographySettingsToCss } from '@biotmed/base-components';
import { contentPadding } from 'src/components/AppLayout';

export const PageMainTitle = styled.div`
  ${props => convertTypographySettingsToCss(props.theme.typography.h3)};
`;

export const PageLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0px ${contentPadding};
`;
