import { otherIcons, SubmitButtonStatus } from '@biotmed/base-components';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { FileConfigurationMetadataResponse } from '@biotmed/settings-sdk';
import { selectors, actions } from '../../modules/slice';
import StatusButton from '../StatusButton';
import { ExecutionStatusEnum } from '../../modules/contants';
import ImportModal from './ImportModal';
import { Title, Container } from '../EnvironmentConfiguration.styled';

interface ImportProps {}

const CONTAINER_ID = 'import-modal';

export const Import: React.FC<ImportProps> = props => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const importButtonStatus = useSelector(selectors.selectImportButtonStatus);
  const importStatus = useSelector(selectors.selectImportStatus);
  const importStatusApiError = useSelector(selectors.selectImportStatusApiError);
  const lastImportMetadata = useSelector(selectors.selectLastImportMetadata);
  const exportsMetadata = useSelector(selectors.selectExportsMetadata);
  const loadingExportsMetadataTable = useSelector(selectors.selectLoadingExportsMetadataTable);
  const dispatch = useDispatch();

  useEffect(() => {
    if (importButtonStatus === SubmitButtonStatus.SUCCESS) {
      setTimeout(() => {
        onClose();
      }, 1000);
    }
  }, [importButtonStatus]);

  const handleSubmitImport = (value: FileConfigurationMetadataResponse) => {
    dispatch(actions.onStartImport({ ...value, containerId: CONTAINER_ID }));
  };

  const onClose = (event?: any, reason?: string) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  const statusMessageDictionary = useMemo(() => {
    return {
      [ExecutionStatusEnum.InProgress]: intl.formatMessage({
        id: 'import.status.in-progress',
        defaultMessage: 'Importing...',
      }),
      [ExecutionStatusEnum.Success]: intl.formatMessage(
        {
          id: 'import.status.success',
          defaultMessage: 'Last import date {creationTime}, finished successfully',
        },
        {
          creationTime: intl.formatDate(lastImportMetadata?.creationTime, { dateStyle: 'short', timeStyle: 'short' }),
        },
      ),
      [ExecutionStatusEnum.Failed]: intl.formatMessage(
        {
          id: 'import.status.fail',
          defaultMessage: 'Last import attempt date {creationTime}, failed with message:{lineBreak}`{errorMessage}`', // TODO: Can this be changed to a solution with /n ?
        },
        {
          creationTime: intl.formatDate(lastImportMetadata?.creationTime, { dateStyle: 'short', timeStyle: 'short' }),
          errorMessage: importStatusApiError,
          lineBreak: <br />,
        },
      ),
      [ExecutionStatusEnum.NoData]: intl.formatMessage({
        id: 'import.status.no-data',
        defaultMessage: 'No imports found',
      }),
    };
  }, [importStatusApiError, lastImportMetadata?.creationTime]);

  const onOpenModal = () => {
    setOpen(true);
    dispatch(actions.initImportModal());
  };

  return (
    <Container>
      <Title>
        {intl.formatMessage({
          id: 'import.title',
          defaultMessage: 'Import',
        })}
      </Title>
      <StatusButton
        onClick={onOpenModal}
        status={(importStatus || ExecutionStatusEnum.NoData) as unknown as ExecutionStatusEnum}
        text={intl.formatMessage({
          id: 'import.button',
          defaultMessage: 'Import File',
        })}
        icon={otherIcons.import}
        statusMessageDictionary={statusMessageDictionary}
      />
      <ImportModal
        isOpen={open}
        onCloseClick={onClose}
        importButtonStatus={importButtonStatus}
        containerId={CONTAINER_ID}
        onSubmit={handleSubmitImport}
        exportsMetadata={exportsMetadata}
        loadingExportsMetadataTable={loadingExportsMetadataTable}
      />
    </Container>
  );
};

export default Import;
