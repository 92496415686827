import React, { ReactNode } from 'react';
import { Separator } from '@biotmed/base-components';
import { useIntl } from 'react-intl';
import { Content, DataContainer, StatusWrapper, SubTitle, SyncControlWrapper } from './ADBPage.styled';

interface ContentViewProps {
  syncControlText: string;
  ValidationModal?: ReactNode;
  SyncButton: ReactNode;
  AdbStatus: ReactNode;
}

const ContentView: React.FC<ContentViewProps> = props => {
  const { syncControlText, ValidationModal, SyncButton, AdbStatus } = props;
  const intl = useIntl();

  return (
    <Content>
      <StatusWrapper>
        <SubTitle>{intl.formatMessage({ id: 'analytics-db.status-header', defaultMessage: 'Status' })}</SubTitle>
        {AdbStatus}
      </StatusWrapper>
      <Separator height="49px" />
      <SyncControlWrapper>
        <SubTitle>
          {intl.formatMessage({ id: 'analytics-db.sync-control-header', defaultMessage: 'Sync Control' })}
        </SubTitle>
        <DataContainer>{syncControlText}</DataContainer>
        {SyncButton}
      </SyncControlWrapper>
      {ValidationModal}
    </Content>
  );
};

export default ContentView;
