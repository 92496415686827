import { MoreActionsButton } from '@biotmed/data-components';
import { LocaleTranslationsOverview } from '@biotmed/settings-sdk';
import React from 'react';
import { DeleteTranslations } from './DeleteTranslations';
import { DownloadTranslations } from './DownloadTranslations';

export interface MoreActionsColumnProps {
  record: LocaleTranslationsOverview;
  systemLocale?: string;
}

export const MoreActionsColumn = (props: MoreActionsColumnProps) => {
  const { record, systemLocale } = props;

  return (
    <MoreActionsButton
      renderActions={(triggerComponent, onClose) => () => {
        const withDelete =
          record.locale !== systemLocale
            ? [
                <DeleteTranslations
                  key="delete"
                  translationOverview={record}
                  TriggerComponent={triggerComponent}
                  onClose={onClose}
                />,
              ]
            : [];

        return [
          <DownloadTranslations
            key="download"
            translationOverview={record}
            TriggerComponent={triggerComponent}
            onClose={onClose}
          />,
          ...withDelete,
        ];
      }}
      variant="horizontal"
    />
  );
};

export default MoreActionsColumn;
