import { Entity } from 'src/redux/data/entity/modules/interfaces';
import { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { entityTemplateFieldName } from 'src/routes/Templates/modules/constant';
import { EntityType } from '@biotmed/data-components';
import { EntityTemplateForm } from '../../../Template';
import { getAttributeToAdjustByParent, adjustTemplateAttributesByParent } from './utils';

const useAdjustedChildrenEntity = (entity: Entity) => {
  const formik = useFormikContext<EntityTemplateForm>();

  // This gets the name of the attribute to be modified by the template's parent template (from ParentTemplateNameToChildAttributeTemplateName enum)
  const attributeToAdjustByParent = getAttributeToAdjustByParent(entity.parentTemplateType as EntityType);

  // These useMemo changes the entity template.
  // The entity is responsible for initial values for add children type template
  const adjustedEntity = useMemo(() => {
    // This finds the attribute to change (a reference attribute), in the array of attributes, and modifies it's parentTemplateName
    const builtInAttributesForEntity = adjustTemplateAttributesByParent(
      entity.builtInAttributes,
      formik.values?.[entityTemplateFieldName]?.displayName,
      attributeToAdjustByParent,
    );

    return {
      ...entity,
      ...(builtInAttributesForEntity ? { builtInAttributes: builtInAttributesForEntity } : {}),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return adjustedEntity;
};

export default useAdjustedChildrenEntity;
