import {
  Button,
  FormikOnError,
  InputFieldWrapper,
  ModalContent,
  ModalHeader,
  SubmitButtonStatus,
} from '@biotmed/base-components';
import { LENGTH } from '@biotmed/data-components';
import { EmbeddedError } from '@biotmed/system-notifications';
import { Formik } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';
import * as yup from 'yup';
import { StyledModalFooter, StyledExportModal } from '../EnvironmentConfiguration.styled';

interface ExportModalProps {
  isOpen: boolean;
  onCloseClick: (event?: any, reason?: string) => void;
  exportButtonStatus: SubmitButtonStatus;
  onSubmit: (values: ExportValues) => void;
  containerId: string;
}

interface ExportValues {
  name: string;
  description?: string;
}

export const ExportModal: React.FC<ExportModalProps> = props => {
  const { isOpen, exportButtonStatus, onCloseClick, onSubmit, containerId } = props;
  const intl = useIntl();
  const theme = useTheme();

  const transformEmptyString = (value: string) => {
    return value.trim() === '' ? null : value;
  };

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .trim()
      .required(intl.formatMessage({ id: 'export.modal.validation.required', defaultMessage: 'Required' }))
      .max(
        36,
        intl.formatMessage({
          id: 'export.modal.validation.max',
          defaultMessage: 'This value can not be longer than 36 characters',
        }),
      ),
    description: yup.string().nullable(true).transform(transformEmptyString).max(LENGTH.PARAGRAPH),
  });

  const handleSubmitInternal = (values: ExportValues) => {
    const castedValues = validationSchema.cast(values);
    onSubmit(castedValues as ExportValues);
  };

  return (
    <StyledExportModal open={isOpen} onClose={onCloseClick} disableEscapeKeyDown>
      <Formik
        initialValues={{
          name: '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmitInternal}
        validateOnBlur
      >
        {({ handleSubmit }) => (
          <FormikOnError>
            <ModalHeader
              title={intl.formatMessage({
                id: 'export.modal.title',
                defaultMessage: 'Export Environment Configuration',
              })}
              onClose={onCloseClick}
              isDisabled={exportButtonStatus !== SubmitButtonStatus.NORMAL}
            />
            <ModalContent isDisabled={exportButtonStatus !== SubmitButtonStatus.NORMAL}>
              <EmbeddedError containerId={containerId} />
              <InputFieldWrapper
                label={intl.formatMessage({ id: 'export.modal.label.name', defaultMessage: 'Name' })}
                name="name"
                variant="outlined"
                required
              />
              <InputFieldWrapper
                label={intl.formatMessage({ id: 'export.modal.label.description', defaultMessage: 'Description' })}
                name="description"
                multiline
                rows={5}
                padding="0px"
                variant="outlined"
                withCharactersCounter
                maxLength={LENGTH.PARAGRAPH}
              />
            </ModalContent>
            <StyledModalFooter isDisabled={exportButtonStatus !== SubmitButtonStatus.NORMAL}>
              <Button paddingVertical="15px" onClick={onCloseClick} textColor={theme.palette.grayScale.darker2}>
                {intl.formatMessage({ id: 'export.modal.button.cancel', defaultMessage: 'Cancel' })}
              </Button>
              <Button
                submit
                paddingVertical="15px"
                onClick={() => handleSubmit()}
                submitButtonProps={{
                  status: exportButtonStatus,
                  loadingLabel: intl.formatMessage({
                    id: 'export.modal.button.loading',
                    defaultMessage: 'Starting export',
                  }),
                  successLabel: intl.formatMessage({
                    id: 'export.modal.button.success',
                    defaultMessage: 'Export started',
                  }),
                }}
              >
                {intl.formatMessage({ id: 'export.modal.button.submit', defaultMessage: 'Start Export' })}
              </Button>
            </StyledModalFooter>
          </FormikOnError>
        )}
      </Formik>
    </StyledExportModal>
  );
};

export default ExportModal;
